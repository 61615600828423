import ajax from 'axios';
import { API_URL } from '../config'
import queryString from 'query-string'
import setLoading from './actionsShared'

export const RETURN_SUPPLIERS = 'RETURN_SUPPLIERS';
export const GET_SUPPLIER_DETAILS = 'GET_SUPPLIER_DETAILS';
export const POST_SUPPLIER = 'POST_SUPPLIER';

function returnSuppliers(suppliers) {
  return {
    type: RETURN_SUPPLIERS,
    suppliers,
  };
}

export function getAllSuppliers({perPage=100, page=1, orderBy='id',order='asc',search=''}) {
  
  return (dispatch, getState) => {
    const query = {
      perPage,
      page,
      orderBy,
      order,
      search
    }
    const user = getState().user
    return ajax
      .get(`${API_URL}/${user.level}/suppliers?${queryString.stringify(query)}`)
      .then(response => {
        dispatch(returnSuppliers(response.data));
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error);
      });
  };
}

function getSupplierDetails(supplier) {
  return {
    type: GET_SUPPLIER_DETAILS,
    supplier,
  };
}
export function getSupplierById(id) {
  return (dispatch, getState) => {
    return ajax
      .get(API_URL+'/admin/suppliers/'+id)
      .then(function(response) {
        dispatch(getSupplierDetails(response.data.data));
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error.response.data.error);
      });
  };
}


function putSupplier(supplier) {
  return {
    type: POST_SUPPLIER,
    supplier,
  };
}
export function putSupplierById(id,data) {
  return (dispatch, getState) => {
    const user = getState().user
    return ajax({
      method: id === 'create' ? 'POST' : 'PUT',
      url: API_URL+`/${user.level}/suppliers/`+id,
      //url: API_URL+'/admin/suppliers/'+id,
      data: data
    })
      .then(function(response) {
        dispatch(putSupplier(response.data.data));
        return response
      })
      .catch(function(error) {
        return error.response
        //console.log(error.response.data.error);
      });
  };
}
