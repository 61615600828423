import React from 'react'
import PropTypes from 'prop-types';

import {
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

const FormCheckbox = ({
  classnameInput,
  label,
  name,
  value,
  color,
  checked,
  onChange,
  setFieldValue,
  setFieldTouched,
  error,
  errorName,
  touched,
  required,
  context
}) => {
  return (
    <FormControlLabel 
      className={classnameInput} 
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          value={value}
          color={color}
        />
      }
      label={label}
    />
  )
}

FormCheckbox.defaultProps = {
  color: 'primary'
}

FormCheckbox.propTypes = {
  classnameInput: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]),
  color: PropTypes.string,
  onChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  error: PropTypes.object,
  errorName: PropTypes.string,
  required: PropTypes.bool,
  context: PropTypes.object
}

export default FormCheckbox