import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAgentById, setClientPsw, setSnack } from '../actions';
import PropTypes from 'prop-types';
import {
  Paper,
  Grid,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Dialog
} from '@material-ui/core'

import {
  ArrowBack as ArrowBackIcon, 
  Edit as EditIcon,// from '@material-ui/icons/Edit';
  VpnKey as KeyIcon
} from '@material-ui/icons';
import LoadingPage from '../components/LoadingPage'

const ShowField = props => {
  let value = '---'
  if(props.value) {
    value = Array.isArray(props.value) ? props.value.join() : props.value
  }
  return (
    <div>
      <label className={props.classes.label}>{props.label}</label>
      <p className={props.classes.parag}>{value}</p>
    </div>
  )
}


class AgenteDettaglio extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      showDialog: false,
      showError: ''
    }
    this.inputPsw = React.createRef();
    this.submitPsw = this.submitPsw.bind(this);
  }
  
  componentDidMount() {
    const { getAgentById, match, user } = this.props;
    getAgentById(match.params.id)
  }
  
  submitPsw(e) {
    e.preventDefault()
    if(this.inputPsw.current.value === '') {
      this.setState({showError: 'Campo obbligatorio'})
      return false
    }
    if(this.inputPsw.current.value.length <= 3) {
      this.setState({showError: 'La password deve contentere minimo 3 caratteri'})
      return false
    }
    this.props.setClientPsw(this.inputPsw.current.value, this.props.user.id).then((response) => {
      this.setState({
        showDialog: false,
        showError: ''
      })
      this.props.setSnack(true,'Password salvata con successo!','success')
    }).catch((error)=>{})
  }

  render() {
    const { agent, classes, user, match, loading } = this.props
    const TextField = (props) => <ShowField classes={classes} {...props} />

    return (
      <React.Fragment>
        { loading ? <LoadingPage /> :
        //{Object.keys(agent).length === 0 ? "Caricando informazioni dell'agente" : 
          <div>
            <div className={classes.navigationBar}>
              <div className={classes.backForm}>
                <Button size="small" color="primary" className={classes.button} onClick={()=>this.props.history.push(user.id === match.params.id ? '/agenti' : '/sub-agenti')}>
                  <ArrowBackIcon className={classes.leftIcon} />
                  Torna alla lista
                </Button>
              </div>
              <div className={classes.titleForm} >
                <span>{ user.level === 'admin' ? 'Scheda agente': 'Profilo'}</span>
              </div>
              <div className={classes.breadCrumbsForm}>
                <span>W-SHOP > AGENTI > SCHEDA AGENTE</span>
              </div>
            </div>
            <div className={classes.actionsForm}>
              {/* <Button size="small" color="primary" className={classes.button} onClick={()=>this.props.history.push(user.id === match.params.id ? '/agenti' : '/sub-agenti')}>
                <ArrowBackIcon className={classes.leftIcon} />
                Torna alla lista
              </Button> */}
              { user.id == match.params.id &&
                <Button 
                  size="small" 
                  color="primary" 
                  className={classes.buttonRight} 
                  onClick={()=>this.setState({showDialog: true})}
                >
                  <KeyIcon className={classes.leftIcon} />
                  Modifica password
                </Button>
              }
              <Button 
                size="small" 
                color="primary" 
                className={classes.buttonRight} 
                onClick={()=>this.props.history.push(`/agente/edit/${agent['id']}`)}
              >
                <EditIcon className={classes.leftIcon} />
                Modifica
              </Button>
            </div>            
            <Paper className={classes.root} elevation={2}>
              <Grid container className={classes.formContainer}>
                <div className={classes.sectionTitle}>Dati Personali</div>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Ragione sociale" value={agent.denominazione} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Partita iva" value={agent.partita_iva} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Codice fiscale" value={agent.codice_fiscale} /></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="E-mail" value={agent.email}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Nome" value={agent.nome}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Cognome" value={agent.cognome}/></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Telefono" value={agent.cellulare} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Nazione" value={agent.nazione}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Provincia" value={agent.provincia}/></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Città" value={agent.citta}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Indirizzo" value={agent.indirizzo}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Cap" value={agent.cap}/></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Regioni attività" value={agent.regioni}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Province attività" value={agent.province}/></Grid>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.root} elevation={2}>
              <Grid container className={classes.formContainer}>
                <div className={classes.sectionTitle}>Dati di accesso</div>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Username" value={agent.user && agent.user.name}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="E-mail utente" value={agent.user && agent.user.email}/></Grid>
                </Grid>
              </Grid>
            </Paper>
            { agent.is_agent && 
              <Paper className={classes.root} elevation={2}>
                <Grid container className={classes.formContainer}>
                  <div className={classes.sectionTitle}>Lista sub agenti</div>
                  { agent.sub_agents && agent.sub_agents.map((subagent,i)=>(
                    <Grid container className={classes.row} key={i}>
                      <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Denominazione" value={subagent.denominazione}/></Grid>
                      <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Nome" value={ subagent.nome}/></Grid>
                      <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Cognome" value={subagent.cognome}/></Grid>
                    </Grid>
                  )) }
                </Grid>
              </Paper>
            }
            { !agent.is_agent && 
              <Paper className={classes.root} elevation={2}>
                <Grid container className={classes.formContainer}>
                  <div className={classes.sectionTitle}>Agente di riferimento</div>
                    <Grid container className={classes.row}>
                      <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Denominazione" value={agent.parent ? agent.parent.denominazione : '---'}/></Grid>
                      <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Nome" value={ agent.parent ? agent.parent.nome : '---'}/></Grid>
                      <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Cognome" value={agent.parent ? agent.parent.cognome : '---'}/></Grid>
                    </Grid>
                </Grid>
              </Paper>
            }
          </div>
        }
        <Dialog
          open={this.state.showDialog}
          onClose={this.handleCancel}
          aria-labelledby="Cambia password"
          aria-describedby="cambia password di accesso"
        > 
          <form onSubmit={this.submitPsw}>
            <DialogTitle id="change-access-password">Cambia password</DialogTitle>
            <DialogContent>
              { loading && <LoadingPage light absolute /> }
              <DialogContentText>
                Imposta la nuova password di accesso: 
              </DialogContentText>
              <FormControl className={classes.row}>
                <TextField
                  type="password"
                  inputRef={this.inputPsw}
                  label="Password"
                  className={classes.textFieldContainerInput}
                  error={this.state.showError !== ''}
                  helperText={this.state.showError}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({showDialog: false})} color="primary">
                Cancella
              </Button>
              <Button type="submit" color="primary" autoFocus>
                Continua
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    );
  }
}

AgenteDettaglio.propTypes = {
  agent: PropTypes.object.isRequired,
  getAgentById: PropTypes.func.isRequired,
  setClientPsw: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  agent: state.agents.selected,
  user: state.user,
  loading: state.info.loading
});

export default withRouter(
  connect(mapStateToProps, {
    getAgentById,
    setClientPsw,
    setSnack
  })(AgenteDettaglio)
);
