import ajax from 'axios';
import { setSnack, setLoading } from './actionsShared';
import { logout } from './actionsLogin';
import store from '../store';
ajax.interceptors.request.use(
  config => {
    const storage = localStorage.getItem('erp_kr_usr')
    const obj = JSON.parse(storage)
    
    if (obj) {
      config.headers.Authorization = `Bearer ${obj.token}`;
    }
    store.dispatch(setLoading(true))
    return config;
  },
  error => Promise.reject(error)
);
ajax.interceptors.response.use(function (response) {
  // Do something with response data
  store.dispatch(setLoading(false))
  return response
}, async function (error) {
  if(error.response.status === 401) {
    store.dispatch(setSnack(true,'Accesso negato','warning'))
    await store.dispatch(logout())
  }
  if(error.response.status === 403) {
    store.dispatch(setSnack(true,'Accesso non autorizzato','warning'))
  }
  // Do something with response error
  return Promise.reject(error);
});


export * from './actionsShared';
export * from './actionsLogin';
export * from './actionsClients';
export * from './actionsSuppliers';
export * from './actionsProducts';
export * from './actionsCategories';
export * from './actionsCart';
export * from './actionsOrders';
export * from './actionsShippers';
export * from './actionsOperators';
export * from './actionsAgents';
export * from './actionsInventory';