import { combineReducers } from 'redux'
import info from './info'
import user from './login';
import clients from './clients';
import products from './products';
import suppliers from './suppliers';
import categories from './categories';
import cart from './cart';
import orders from './orders';
import shippers from './shippers';
import operators from './operators';
import agents from './agents';
import inventory from './inventory';

export default combineReducers({
  info,
  user,
  clients,
  products,
  suppliers,
  categories,
  cart,
  orders,
  shippers,
  operators,
  agents,
  inventory
})
