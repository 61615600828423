import React from 'react'
import PropTypes from 'prop-types';

import FieldsMap from './FieldsMap'

const UserFields = (props) => {  
  const { fields } = props
  return (
    fields.map( (field,i) =>{
      return (
        <FieldsMap 
          key={`field-${field.name}`} 
          field={field} 
          prefix='user'
          {...props} 
        />
      )
    })
  )
}

// UserFields.defaultProps = {
// }

UserFields.propTypes = {
  slug: PropTypes.string,
  classes: PropTypes.object,
  fields: PropTypes.array,
  values: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.object,
  touched: PropTypes.object,
}

export default UserFields