import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import logo from '../assets/img/logo_WSHOP.svg'

const styles = theme => ({
  root: {
    width: 400,
    padding: theme.spacing(5),
    background: '#fff'
  },
  logoContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(2),
    '& h2': {
      textTransform: 'uppercase',
      fontWeight: 700,
      margin: 0,
      fontSize: 14
    }
  },
  row: {
    width: '100%'
  },
  rowLink: {
    width: '100%',
    textAlign: 'right',
    '& a':{
      fontSize: 12,
      color: '#05c1f0',
      cursor: 'pointer'
    }
  },
  field: {
    marginBottom: theme.spacing(3)
  },
  fieldError: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  buttonContainer: {
    marginTop: theme.spacing(4),
    '& button': {
      boxShadow: 'none'
    }
  }
})

const InnerForm = ({
  externalFunc,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  classes
}) => (
  <form onSubmit={handleSubmit} className={classes.root}>
    <div className={classes.logoContainer}><img src={logo} alt="logo" width="150px" /></div>
    <div className={classes.title}>
      <h2>Login</h2>
      <span>Inserisci i tuoi dati di accesso</span>
    </div>
    <FormControl className={classes.row}>
      <TextField
        type="text"
        name="email"
        label="Indirizzo e-mail"
        fullWidth={true}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        className={classes.field}
        //variant="outlined"
        error={touched.email && errors.email === 'Campo obbligatorio'}
        helperText={touched.email && errors.email && errors.email}
      />
      {/* {touched.email && errors.email && <div className={classes.fieldError}>{errors.email}</div>} */}
    </FormControl>
    <FormControl className={classes.row}>
      <TextField
        type="password"
        name="password"
        label="Password"
        fullWidth={true}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.password}
        className={classes.field}
        //variant="outlined"
        error={touched.password && errors.password === 'Campo obbligatorio'}
        helperText={touched.password && errors.password && errors.password}
      />
      {/* {touched.password && errors.password && <div className={classes.fieldError}>{errors.password}</div>} */}
      <div className={classes.rowLink}>
        <a href="javascript:void(0);" onClick={() => externalFunc(1)}>Password dimenticata?</a>
      </div>
    </FormControl>
    <div className={classes.buttonContainer}>
      <Button variant="contained" size="large" fullWidth color="primary" type="submit">Login</Button>
    </div>
  </form>
);

// Wrap our form with the using withFormik HoC
const LoginForm = withFormik({
  // Transform outer props into form values
  mapPropsToValues: props => ({ email: '', password: '' }),
  // Add a custom validation function (this can be async too!)
  validate: (values, props) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Campo obbligatorio';
    }
    if (!values.password) {
      errors.password = 'Campo obbligatorio';
    }
    return errors;
  },
  // Submission handler
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
      setErrors /* setValues, setStatus, and other goodies */,
    }
  ) => {
    props.login(values).then((response) => {
      if(response && response.status > 400) {
        props.setSnack(true,'Login o password sbagliati','warning')
      }
    }).catch((error)=>{})
    // postNewReport(values).then(
    //   report => {
    //     setSubmitting(false);

    //   },
    //   errors => {
    //     setSubmitting(false);
    //     // Maybe even transform your API's errors into the same shape as Formik's!
    //     setErrors(transformMyApiErrors(errors));
    //   }
    // );
  },
})(withStyles(styles)(InnerForm))

LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  setSnack: PropTypes.func.isRequired,
  externalFunc: PropTypes.func
};

export default LoginForm
