import {
  SET_SNACK, 
  SET_LOADING,
  RETURN_ORDERS,
  RETURN_SUPPLIERS, 
  RETURN_PRODUCTS, 
  RETURN_CLIENTS, 
  RETURN_SHIPPERS, 
  RETURN_OPERATORS,
  RETURN_AGENTS,
  RETURN_ROYALTIES,
  RETURN_INVENTORY
} from '../actions';


const initialState = {
  snack: {
    show: false,
    message: '',
    variant: 'warning'
  },
  pagination: {},
  loading: false
}

export default function info(state = initialState, action) {
  switch (action.type) {
    case RETURN_ORDERS:
      return {
        ...state,
        pagination: action.orders.meta
      }
    case RETURN_PRODUCTS:
      return {
        ...state,
        pagination: action.products.meta
      }
    case RETURN_CLIENTS:
      return {
        ...state,
        pagination: action.clients.meta
      }
    case RETURN_SUPPLIERS:
      return {
        ...state,
        pagination: action.suppliers.meta
      }
    case RETURN_SHIPPERS:
      return {
        ...state,
        pagination: action.shippers.meta
      }
    case RETURN_OPERATORS:
      return {
        ...state,
        pagination: action.operators.meta
      }
    case RETURN_AGENTS:
      return {
        ...state,
        pagination: action.agents.meta
      }
    case RETURN_ROYALTIES:
      return {
        ...state,
        pagination: action.royatlies.meta
      }
    case RETURN_INVENTORY:
     const newPagination = {
       ...action.inventory.meta,
       order: action.order
     }
      return {
        ...state,
        pagination: newPagination
      }
    case SET_SNACK:
      return {
        ...state,
        snack: {
          show: action.show,
          message: action.message,
          variant: action.variant
        }
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading
      }
    default:
      return state
  }
}
