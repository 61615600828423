export default {
  data: [
    {
      id: 0,
      title: 'Dati di accesso',
      fields: [
        {
          element: 'input',
          name: 'name',
          value: '',
          label: 'Nome completo',
          required: true,
        },
        {
          element: 'input',
          name: 'email',
          value: '',
          label: 'E-mail',
          required: true,
        },
        {
          element: 'input',
          name: 'password',
          value: '',
          label: 'Passoword',
          required: true,
          type: 'password'
        }
      ]
    }
  ]
}