import ajax from 'axios';
import { API_URL } from '../config'
export const DELETE_POS = 'DELETE_POS';
export const DELETE_SUPPLIER = 'DELETE_SUPPLIER';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_ORDER = 'DELETE_ORDER';
export const SET_SNACK = 'SET_SNACK';
export const SET_LOADING = 'SET_LOADING';

function deleteItems(data={},type) {
  return {
      type: type,
      data
  };
}
export function removeItems(data,type,id) {
  return (dispatch, getState) => {
    const user = getState().user
    let filter = null
    let url = ''
    const urlPath = user.level === 'pos' ? '/user/'+user.id+'/' : '/admin/'
    if(id) {
      url = API_URL+urlPath+type+'/'+id
    }
    else {
      url = API_URL+urlPath+type
      filter = {filter: JSON.stringify({ id: data })}
    }
    return ajax
      .delete(url, {
        data: filter
      })
      .then(function(response) {
        const TYPE = 'DELETE_' + type.toUpperCase()
        dispatch(deleteItems(data,TYPE));
        return response
      })
      .catch(function(error) {
        return error.response
        //console.log(error.response.data.error);
      });
  };
}

export function setSnack(show,message='',variant='') {
  return {
    type: 'SET_SNACK',
    show,
    message,
    variant
  }
}

export function setLoading(loading) {
  return {
    type: 'SET_LOADING',
    loading
  }
}

export function getAllProvince() {
  return (dispatch, getState) => {
    //dispatch(setLoading(true))
    
    const url = API_URL+'/province'
    
    return ajax
      .get(url)
      .then(function(response) {
        //dispatch(setLoading(false))
        return response.data.data
      })
      .catch(function(error) {
        return error.response
      });
  }; 
}

export function getAllCitiesByProvince(province) {
    
    const url = API_URL+'/province/nome/'+province
    
    return ajax
      .get(url)
      .then(function(response) {        
        return response.data.data
      })
      .catch(function(error) {
        return error.response
      });
}

export function postAllCitiesByProvinces(allProvince) {
  const url = API_URL+'/province/nomi'
  
  return ajax({
      method: 'POST',
      url: url,
      data: {
        province: allProvince
      }
    })
    .then(function(response) {        
      return response.data
    })
    .catch(function(error) {
      return error.response
    });
}
