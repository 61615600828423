import React from 'react'
import PropTypes from 'prop-types';

import {
  Grid,
} from '@material-ui/core';

import Input from './Input'
import AutoSelect from './AutoSelect'
import NativeSelect from './NativeSelect'

import { allNations } from '../../data/nations'
import { allProvinces } from '../../data/provinces'


const Address = ({
  classnameGrid,
  classnameInput,
  slug,
  fields,
  values,
  options,
  onChange,
  onBlur,
  setFieldValue,
  setFieldTouched,
  error,
  touched,
  context
}) => {

  const optionsObj = {
    allNations,
    allProvinces
  } 
  return (
    <> 
      <Grid item className={classnameGrid} xs={6}>
        <AutoSelect 
          key={fields.nazione.name}
          id={fields.nazione.name}
          classnameInput={classnameInput} 
          name={`${slug}.${fields.nazione.name}`} 
          label={fields.nazione.label}
          placeholder={fields.nazione.placeholder}
          value={values[fields.nazione.name]}
          options={allNations} 
          onChange={onChange} 
          //onChange={onChange && fields.nazione.callback} 
          onBlur={onBlur}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          error={error}
          touched={touched}
          errorName={fields.nazione.name}
          required={true}
          //context={props.context}
        />
      </Grid>
      <Grid item className={classnameGrid} xs={6}>
        { 
          values[fields.nazione.name] === 'Italia' 
          ?
          <NativeSelect
            key={fields.provincia.name}
            id={fields.provincia.name}
            name={`${slug}.${fields.provincia.name}`} 
            classnameInput={classnameInput} 
            label={fields.provincia.label}
            value={values[fields.provincia.name]}
            options={allProvinces} 
            onChange={onChange && fields.provincia.callback} 
            onBlur={onBlur}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={error}
            touched={touched}
            errorName={fields.provincia.name}
            required={true}
            context={context}
          />
          :
          <Input 
            key={fields.provincia.name}
            id={fields.provincia.name}
            classnameInput={classnameInput} 
            name={`${slug}.${fields.provincia.name}`} 
            label={fields.provincia.label}
            value={values[fields.provincia.name]}
            onChange={onChange} 
            onBlur={onBlur}
            error={error}
            touched={touched}
            errorName={fields.provincia.name}
            required={true}
          />
        }
      </Grid>
      <Grid item className={classnameGrid} xs={6}>
      { 
          values[fields.nazione.name] === 'Italia' 
          ?
          <NativeSelect
            key={fields.provincia.name}
            id={fields.provincia.name}
            name={`${slug}.${fields.citta.name}`} 
            classnameInput={classnameInput} 
            label={fields.citta.label}
            value={values[fields.citta.name]}
            options={options[fields.citta.name]} 
            onChange={onChange} 
            onBlur={onBlur}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            error={error}
            touched={touched}
            errorName={fields.citta.name}
            required={true}
            context={context}
          />
          :
          <Input 
            key={fields.citta.name}
            id={fields.citta.name}
            classnameInput={classnameInput} 
            name={`${slug}.${fields.citta.name}`} 
            label={fields.citta.label}
            value={values[fields.citta.name]}
            onChange={onChange} 
            onBlur={onBlur}
            error={error}
            touched={touched}
            errorName={fields.citta.name}
            required={true}
          />
        }
      </Grid>
      <Grid item className={classnameGrid} xs={6}>
        <Input 
          key={fields.indirizzo.name}
          id={fields.indirizzo.name}
          classnameInput={classnameInput} 
          name={`${slug}.${fields.indirizzo.name}`} 
          label={fields.indirizzo.label}
          value={values[fields.indirizzo.name]}
          onChange={onChange} 
          onBlur={onBlur}
          error={error}
          touched={touched}
          errorName={fields.indirizzo.name}
          required={true}
        />
      </Grid>
      <Grid item className={classnameGrid} xs={6}>
        <Input 
          key={fields.cap.name}
          id={fields.cap.name}
          classnameInput={classnameInput} 
          name={`${slug}.${fields.cap.name}`} 
          label={fields.cap.label}
          value={values[fields.cap.name]}
          onChange={onChange} 
          onBlur={onBlur}
          error={error}
          touched={touched}
          errorName={fields.cap.name}
          required={true}
        />
      </Grid>
    </>
  )
}

// Address.defaultProps = {
//   type: 'text'
// }

Address.propTypes = {
  classnameGrid: PropTypes.string,
  classnameInput: PropTypes.string,
  slug: PropTypes.string,
  fields: PropTypes.object,
  options: PropTypes.object,
  values: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  error: PropTypes.object,
  touched: PropTypes.object,
  context: PropTypes.object,
}

export default Address