import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Button 
} from '@material-ui/core';

import { 
  getAllSuppliers, 
  removeItems 
} from '../actions';
import Table from '../components/Table';
import LoadingPage from '../components/LoadingPage'
import PageTitle from '../components/PageTitle';

class FornitoriLista extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      dialogConfirmRemove: false,
      dataToRemove: []
    }
  }

  componentDidMount() {
    const { getAllSuppliers, pagination } = this.props;
    getAllSuppliers({perPage:pagination.per_page, page: pagination.current_page});
  }
  
  handleRemove(data) {
    this.props.removeItems(data,'suppliers').then(response => {
      if(response.status === 200) {
        this.props.getAllSuppliers({})
        this.setState({
          dialogConfirmRemove: false,
          dataToRemove: []
        })
      }
    }).catch((error)=>{})
  }

  confirmRemove(data) {
    this.setState({
      dialogConfirmRemove: true,
      dataToRemove: data
    })
  }
  
  goToPage(page) {
    this.props.history.push('fornitore/'+page)
  }
  render() {
    const { 
      loading, 
      suppliers, 
      classes, 
      location, 
      pagination,
      getAllSuppliers,
      user 
    } = this.props
    
    return (
      <React.Fragment>
        { loading && <LoadingPage /> }
        <PageTitle 
          title="Fornitori"
          breadcrumbs={['W-SHOP','FORNITORI']}
          //handleBackClick={()=>history.push('/prodotti')}
          classes={classes}
        />
        <Paper className={classes.root} elevation={2}>
          <Table 
            slug="fornitori"
            tableTitle="Fornitori" 
            location={location}
            choises={suppliers.data}
            pagination={pagination}
            labels={[
              {
                value: "id",
                //numeric: true
              },
              {
                value: "denominazione",
              },
              {
                value: "telefono",
                label: "Telefono",
                unsortable: true
              },
              {
                value: "email",
                label: "E-mail",
              },
              {
                value: "products_count",
                label: "N. Prodotti",
              },
              {
                value: "partita_iva",
                label: "Partita iva",
                unsortable: true
              }
            ]}
            paginate={({perPage,page,orderBy,order,search}) => getAllSuppliers({perPage,page,orderBy,order,search})}
            removeItems={(data) => this.confirmRemove(data)}
            goToPage={(page) => this.goToPage(page)}
            userLevel={user.level}
          />
        </Paper>
        <Dialog
          open={this.state.dialogConfirmRemove}
          aria-labelledby="Sicuro di voler rimuovere il fornitore?"
        >
          <DialogTitle>Sei sicuro di voler rimuovere questi dati</DialogTitle>
          <DialogContent>
            { loading && <LoadingPage light absolute /> }
            <DialogContentText>L’operazione di rimozione fornitore comporterà la cancellazione di tutti i suoi prodotti in modo irreversibile.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { this.setState({ dialogConfirmRemove: false }) } } color="primary">
              Cancella
            </Button>
            <Button 
              onClick={() => this.handleRemove(this.state.dataToRemove)} 
              color="primary"
              //variant="contained"
            >
              Continua
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

FornitoriLista.propTypes = {
  suppliers: PropTypes.object.isRequired,
  getAllSuppliers: PropTypes.func.isRequired,
  removeItems: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  suppliers: state.suppliers.list,
  user: state.user,
  pagination: state.info.pagination,
  loading: state.info.loading
});

export default withRouter(
  connect(mapStateToProps, {
    getAllSuppliers,
    removeItems
  })(FornitoriLista)
);
