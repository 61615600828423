export const allProvinces = [
  {
    "label": "Agrigento",
    "value": "Agrigento",
    "slug": "AG",
    "regione": "Sicilia"
  },
  {
    "label": "Alessandria",
    "value": "Alessandria",
    "slug": "AL",
    "regione": "Piemonte"
  },
  {
    "label": "Ancona",
    "value": "Ancona",
    "slug": "AN",
    "regione": "Marche"
  },
  {
    "label": "Arezzo",
    "value": "Arezzo",
    "slug": "AR",
    "regione": "Toscana"
  },
  {
    "label": "Ascoli Piceno",
    "value": "Ascoli Piceno",
    "slug": "AP",
    "regione": "Marche"
  },
  {
    "label": "Asti",
    "value": "Asti",
    "slug": "AT",
    "regione": "Piemonte"
  },
  {
    "label": "Avellino",
    "value": "Avellino",
    "slug": "AV",
    "regione": "Campania"
  },
  {
    "label": "Bari",
    "value": "Bari",
    "slug": "BA",
    "regione": "Puglia"
  },
  {
    "label": "Barletta-Andria-Trani",
    "value": "Barletta-Andria-Trani",
    "slug": "BT",
    "regione": "Puglia"
  },
  {
    "label": "Belluno",
    "value": "Belluno",
    "slug": "BL",
    "regione": "Veneto"
  },
  {
    "label": "Benevento",
    "value": "Benevento",
    "slug": "BN",
    "regione": "Campania"
  },
  {
    "label": "Bergamo",
    "value": "Bergamo",
    "slug": "BG",
    "regione": "Lombardia"
  },
  {
    "label": "Biella",
    "value": "Biella",
    "slug": "BI",
    "regione": "Piemonte"
  },
  {
    "label": "Bologna",
    "value": "Bologna",
    "slug": "BO",
    "regione": "Emilia-Romagna"
  },
  {
    "label": "Bolzano/Bozen",
    "value": "Bolzano/Bozen",
    "slug": "BZ",
    "regione": "Trentino-Alto Adige/Südtirol"
  },
  {
    "label": "Brescia",
    "value": "Brescia",
    "slug": "BS",
    "regione": "Lombardia"
  },
  {
    "label": "Brindisi",
    "value": "Brindisi",
    "slug": "BR",
    "regione": "Puglia"
  },
  {
    "label": "Cagliari",
    "value": "Cagliari",
    "slug": "CA",
    "regione": "Sardegna"
  },
  {
    "label": "Caltanissetta",
    "value": "Caltanissetta",
    "slug": "CL",
    "regione": "Sicilia"
  },
  {
    "label": "Campobasso",
    "value": "Campobasso",
    "slug": "CB",
    "regione": "Molise"
  },
  {
    "label": "Carbonia-Iglesias",
    "value": "Carbonia-Iglesias",
    "slug": "CI",
    "regione": "Sardegna"
  },
  {
    "label": "Caserta",
    "value": "Caserta",
    "slug": "CE",
    "regione": "Campania"
  },
  {
    "label": "Catania",
    "value": "Catania",
    "slug": "CT",
    "regione": "Sicilia"
  },
  {
    "label": "Catanzaro",
    "value": "Catanzaro",
    "slug": "CZ",
    "regione": "Calabria"
  },
  {
    "label": "Chieti",
    "value": "Chieti",
    "slug": "CH",
    "regione": "Abruzzo"
  },
  {
    "label": "Como",
    "value": "Como",
    "slug": "CO",
    "regione": "Lombardia"
  },
  {
    "label": "Cosenza",
    "value": "Cosenza",
    "slug": "CS",
    "regione": "Calabria"
  },
  {
    "label": "Cremona",
    "value": "Cremona",
    "slug": "CR",
    "regione": "Lombardia"
  },
  {
    "label": "Crotone",
    "value": "Crotone",
    "slug": "KR",
    "regione": "Calabria"
  },
  {
    "label": "Cuneo",
    "value": "Cuneo",
    "slug": "CN",
    "regione": "Piemonte"
  },
  {
    "label": "Enna",
    "value": "Enna",
    "slug": "EN",
    "regione": "Sicilia"
  },
  {
    "label": "Fermo",
    "value": "Fermo",
    "slug": "FM",
    "regione": "Marche"
  },
  {
    "label": "Ferrara",
    "value": "Ferrara",
    "slug": "FE",
    "regione": "Emilia-Romagna"
  },
  {
    "label": "Firenze",
    "value": "Firenze",
    "slug": "FI",
    "regione": "Toscana"
  },
  {
    "label": "Foggia",
    "value": "Foggia",
    "slug": "FG",
    "regione": "Puglia"
  },
  {
    "label": "Forlì-Cesena",
    "value": "Forlì-Cesena",
    "slug": "FC",
    "regione": "Emilia-Romagna"
  },
  {
    "label": "Frosinone",
    "value": "Frosinone",
    "slug": "FR",
    "regione": "Lazio"
  },
  {
    "label": "Genova",
    "value": "Genova",
    "slug": "GE",
    "regione": "Liguria"
  },
  {
    "label": "Gorizia",
    "value": "Gorizia",
    "slug": "GO",
    "regione": "Friuli-Venezia Giulia"
  },
  {
    "label": "Grosseto",
    "value": "Grosseto",
    "slug": "GR",
    "regione": "Toscana"
  },
  {
    "label": "Imperia",
    "value": "Imperia",
    "slug": "IM",
    "regione": "Liguria"
  },
  {
    "label": "Isernia",
    "value": "Isernia",
    "slug": "IS",
    "regione": "Molise"
  },
  {
    "label": "L'Aquila",
    "value": "L'Aquila",
    "slug": "AQ",
    "regione": "Abruzzo"
  },
  {
    "label": "La Spezia",
    "value": "La Spezia",
    "slug": "SP",
    "regione": "Liguria"
  },
  {
    "label": "Latina",
    "value": "Latina",
    "slug": "LT",
    "regione": "Lazio"
  },
  {
    "label": "Lecce",
    "value": "Lecce",
    "slug": "LE",
    "regione": "Puglia"
  },
  {
    "label": "Lecco",
    "value": "Lecco",
    "slug": "LC",
    "regione": "Lombardia"
  },
  {
    "label": "Livorno",
    "value": "Livorno",
    "slug": "LI",
    "regione": "Toscana"
  },
  {
    "label": "Lodi",
    "value": "Lodi",
    "slug": "LO",
    "regione": "Lombardia"
  },
  {
    "label": "Lucca",
    "value": "Lucca",
    "slug": "LU",
    "regione": "Toscana"
  },
  {
    "label": "Macerata",
    "value": "Macerata",
    "slug": "MC",
    "regione": "Marche"
  },
  {
    "label": "Mantova",
    "value": "Mantova",
    "slug": "MN",
    "regione": "Lombardia"
  },
  {
    "label": "Massa-Carrara",
    "value": "Massa-Carrara",
    "slug": "MS",
    "regione": "Toscana"
  },
  {
    "label": "Matera",
    "value": "Matera",
    "slug": "MT",
    "regione": "Basilicata"
  },
  {
    "label": "Medio Campidano",
    "value": "Medio Campidano",
    "slug": "VS",
    "regione": "Sardegna"
  },
  {
    "label": "Messina",
    "value": "Messina",
    "slug": "ME",
    "regione": "Sicilia"
  },
  {
    "label": "Milano",
    "value": "Milano",
    "slug": "MI",
    "regione": "Lombardia"
  },
  {
    "label": "Modena",
    "value": "Modena",
    "slug": "MO",
    "regione": "Emilia-Romagna"
  },
  {
    "label": "Monza e della Brianza",
    "value": "Monza e della Brianza",
    "slug": "MB",
    "regione": "Lombardia"
  },
  {
    "label": "Napoli",
    "value": "Napoli",
    "slug": "NA",
    "regione": "Campania"
  },
  {
    "label": "Novara",
    "value": "Novara",
    "slug": "NO",
    "regione": "Piemonte"
  },
  {
    "label": "Nuoro",
    "value": "Nuoro",
    "slug": "NU",
    "regione": "Sardegna"
  },
  {
    "label": "Ogliastra",
    "value": "Ogliastra",
    "slug": "OG",
    "regione": "Sardegna"
  },
  {
    "label": "Olbia-Tempio",
    "value": "Olbia-Tempio",
    "slug": "OT",
    "regione": "Sardegna"
  },
  {
    "label": "Oristano",
    "value": "Oristano",
    "slug": "OR",
    "regione": "Sardegna"
  },
  {
    "label": "Padova",
    "value": "Padova",
    "slug": "PD",
    "regione": "Veneto"
  },
  {
    "label": "Palermo",
    "value": "Palermo",
    "slug": "PA",
    "regione": "Sicilia"
  },
  {
    "label": "Parma",
    "value": "Parma",
    "slug": "PR",
    "regione": "Emilia-Romagna"
  },
  {
    "label": "Pavia",
    "value": "Pavia",
    "slug": "PV",
    "regione": "Lombardia"
  },
  {
    "label": "Perugia",
    "value": "Perugia",
    "slug": "PG",
    "regione": "Umbria"
  },
  {
    "label": "Pesaro e Urbino",
    "value": "Pesaro e Urbino",
    "slug": "PU",
    "regione": "Marche"
  },
  {
    "label": "Pescara",
    "value": "Pescara",
    "slug": "PE",
    "regione": "Abruzzo"
  },
  {
    "label": "Piacenza",
    "value": "Piacenza",
    "slug": "PC",
    "regione": "Emilia-Romagna"
  },
  {
    "label": "Pisa",
    "value": "Pisa",
    "slug": "PI",
    "regione": "Toscana"
  },
  {
    "label": "Pistoia",
    "value": "Pistoia",
    "slug": "PT",
    "regione": "Toscana"
  },
  {
    "label": "Pordenone",
    "value": "Pordenone",
    "slug": "PN",
    "regione": "Friuli-Venezia Giulia"
  },
  {
    "label": "Potenza",
    "value": "Potenza",
    "slug": "PZ",
    "regione": "Basilicata"
  },
  {
    "label": "Prato",
    "value": "Prato",
    "slug": "PO",
    "regione": "Toscana"
  },
  {
    "label": "Ragusa",
    "value": "Ragusa",
    "slug": "RG",
    "regione": "Sicilia"
  },
  {
    "label": "Ravenna",
    "value": "Ravenna",
    "slug": "RA",
    "regione": "Emilia-Romagna"
  },
  {
    "label": "Reggio di Calabria",
    "value": "Reggio di Calabria",
    "slug": "RC",
    "regione": "Calabria"
  },
  {
    "label": "Reggio nell'Emilia",
    "value": "Reggio nell'Emilia",
    "slug": "RE",
    "regione": "Emilia-Romagna"
  },
  {
    "label": "Rieti",
    "value": "Rieti",
    "slug": "RI",
    "regione": "Lazio"
  },
  {
    "label": "Rimini",
    "value": "Rimini",
    "slug": "RN",
    "regione": "Emilia-Romagna"
  },
  {
    "label": "Roma",
    "value": "Roma",
    "slug": "RM",
    "regione": "Lazio"
  },
  {
    "label": "Rovigo",
    "value": "Rovigo",
    "slug": "RO",
    "regione": "Veneto"
  },
  {
    "label": "Salerno",
    "value": "Salerno",
    "slug": "SA",
    "regione": "Campania"
  },
  {
    "label": "Sassari",
    "value": "Sassari",
    "slug": "SS",
    "regione": "Sardegna"
  },
  {
    "label": "Savona",
    "value": "Savona",
    "slug": "SV",
    "regione": "Liguria"
  },
  {
    "label": "Siena",
    "value": "Siena",
    "slug": "SI",
    "regione": "Toscana"
  },
  {
    "label": "Siracusa",
    "value": "Siracusa",
    "slug": "SR",
    "regione": "Sicilia"
  },
  {
    "label": "Sondrio",
    "value": "Sondrio",
    "slug": "SO",
    "regione": "Lombardia"
  },
  {
    "label": "Taranto",
    "value": "Taranto",
    "slug": "TA",
    "regione": "Puglia"
  },
  {
    "label": "Teramo",
    "value": "Teramo",
    "slug": "TE",
    "regione": "Abruzzo"
  },
  {
    "label": "Terni",
    "value": "Terni",
    "slug": "TR",
    "regione": "Umbria"
  },
  {
    "label": "Torino",
    "value": "Torino",
    "slug": "TO",
    "regione": "Piemonte"
  },
  {
    "label": "Trapani",
    "value": "Trapani",
    "slug": "TP",
    "regione": "Sicilia"
  },
  {
    "label": "Trento",
    "value": "Trento",
    "slug": "TN",
    "regione": "Trentino-Alto Adige/Südtirol"
  },
  {
    "label": "Treviso",
    "value": "Treviso",
    "slug": "TV",
    "regione": "Veneto"
  },
  {
    "label": "Trieste",
    "value": "Trieste",
    "slug": "TS",
    "regione": "Friuli-Venezia Giulia"
  },
  {
    "label": "Udine",
    "value": "Udine",
    "slug": "UD",
    "regione": "Friuli-Venezia Giulia"
  },
  {
    "label": "Valle d'Aosta/Vallée d'Aoste",
    "value": "Valle d'Aosta/Vallée d'Aoste",
    "slug": "AO",
    "regione": "Valle d'Aosta/Vallée d'Aoste"
  },
  {
    "label": "Varese",
    "value": "Varese",
    "slug": "VA",
    "regione": "Lombardia"
  },
  {
    "label": "Venezia",
    "value": "Venezia",
    "slug": "VE",
    "regione": "Veneto"
  },
  {
    "label": "Verbano-Cusio-Ossola",
    "value": "Verbano-Cusio-Ossola",
    "slug": "VB",
    "regione": "Piemonte"
  },
  {
    "label": "Vercelli",
    "value": "Vercelli",
    "slug": "VC",
    "regione": "Piemonte"
  },
  {
    "label": "Verona",
    "value": "Verona",
    "slug": "VR",
    "regione": "Veneto"
  },
  {
    "label": "Vibo Valentia",
    "value": "Vibo Valentia",
    "slug": "VV",
    "regione": "Calabria"
  },
  {
    "label": "Vicenza",
    "value": "Vicenza",
    "slug": "VI",
    "regione": "Veneto"
  },
  {
    "label": "Viterbo",
    "value": "Viterbo",
    "slug": "VT",
    "regione": "Lazio"
  }
]