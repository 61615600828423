import { RETURN_CLIENTS, GET_CLIENT_DETAILS, POST_CLIENT, DELETE_POS } from '../actions';

const initialState = {
  list: {},
  selected: {},
}

export default function clients(state = initialState, action) {
  switch (action.type) {
    case RETURN_CLIENTS:
      return {
        ...state,
        list: action.clients,
        selected: {},
      };
    case GET_CLIENT_DETAILS:
    case POST_CLIENT: 
      return {
        ...state,
        selected: action.client
      };
    default:
    case DELETE_POS:
      return state;
  }
}
