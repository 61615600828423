import ajax from 'axios';
import { API_URL, URL_LEVEL } from '../config'
import queryString from 'query-string'
export const RETURN_ORDERS = 'RETURN_ORDERS';
export const UPDATE_ORDER_BY_ID = 'UPDATE_ORDER_BY_ID';
export const RETURN_ORDER_BY_ID = 'RETURN_ORDER_BY_ID';
export const GET_ORDER_DETAILS = 'GET_ORDER_DETAILS';
export const POST_ORDER = 'POST_ORDER';

function returnOrders(orders) {
  return {
    type: RETURN_ORDERS,
    orders
  };
}

export function getAllOrders({
  perPage=10, 
  page=1, 
  orderBy='id',
  //order='asc',
  posId='',
  status='', 
  rangeDate= '',
  id='admin', 
  trashed=false
}) {
  
  return (dispatch, getState) => {
    const query = {
      perPage,
      page,
      orderBy,
      //order,
      'filter[pos_id]': posId,
      'filter[status]': status,
      'filter[between]': rangeDate,
      'filter[trashed]': trashed
    }
    const user = getState().user
    const urlPath = id === 'admin' ?  'admin' : `${URL_LEVEL['EN'][id]}/` + user.id
    return ajax
      .get(`${API_URL}/${urlPath}/orders?${queryString.stringify(query)}`)
      .then(response => {
        dispatch(returnOrders(response.data));
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error);
      });
  };
}

export function getClientsByOrders(allOrders) {
  return (dispatch, getState) => {

    return ajax
      .get(`${API_URL}/admin/pos/getForFilter`)
      .then(response => {
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error);
      });
  };
}

function returnOrderById(order) {
  return {
    type: RETURN_ORDER_BY_ID,
    order,
  };
}

export function getOrderById({userId, orderId}) {
  return (dispatch, getState) => {
    const user = getState().user
    const urlPath = user.level === 'admin' ?  'admin' : `${URL_LEVEL['EN'][user.level]}/`+user.id
    return ajax
      .get(`${API_URL}/${urlPath}/orders/${orderId}`)
      .then(response => {
        dispatch(returnOrderById(response.data.data));
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error);
      });
  };
}
// link pdf /users/userId/orders/orderId/pdf/get(o download)
export function getOrderPdfById({userId, orderId, doc=false, fileName}) {
  
  return (dispatch, getState) => {
    const user = getState().user
    const urlPath = user.level === 'admin' ?  'admin' : `${URL_LEVEL['EN'][user.level]}/`+user.id
    const typeDocument = doc ? 'document' : 'pdf/download'
    return ajax
      .get(`${API_URL}/${urlPath}/orders/${orderId}/${typeDocument}`, {
          responseType: 'blob',
          timeout: 30000,
        })
      .then(response => {
        //console.log(response.data)
        //dispatch(returnOrderById(response.data.data));
        //const file = new Blob([response], {type: 'application/pdf'});
        //onst file = new Blob([response.body], {type: 'application/pdf'});
        const fileURL = (window.URL || window['webkitURL']).createObjectURL(response.data);
        
        const getFileName = () => {
          if(doc) {
            const extension = fileName.split('.')
            return 'ricevuta_pagamento_'+orderId+ '.'+ extension[extension.length - 1]
          }
          return 'ordine'+orderId+'.pdf';
        }
        
        const downloadLink = document.createElement('a');
        
        downloadLink.href = fileURL;
        //downloadLink.setAttribute("target", "_blank")
        downloadLink.download = getFileName()

        document.body.appendChild(downloadLink);
        //console.log(downloadLink)
        //window.open(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);

        //const fileURL = (window.URL ? URL : window.webkitURL).createObjectURL(response.data);
        // window.open(fileURL);
        return response
      })
      .catch(function(error) {
        //console.log(error);
      });
  };
}

function putOrder(order) {
  return {
    type: POST_ORDER,
    order,
  };
}
export function putOrderById({id,data}) {
  return (dispatch, getState) => {
    const user = getState().user
    const urlPath = user.level === 'admin' ?  '/admin' : `/${URL_LEVEL['EN'][user.level]}`//+user.id
    return ajax({
      method: 'PUT',
      url: API_URL+urlPath+'/orders/'+id,
      data: data
    })
      .then(function(response) {
        dispatch(putOrder(response.data));
        return response
      })
      .catch(function(error) {
        return error
      });
  };
}

function updateOrderById(order, id) {
  return {
    type: UPDATE_ORDER_BY_ID,
    order,
    id
  };
}


export function uploadPaymentConfirmation({userId, orderId, document}) {
  const formData = new FormData();
  formData.append('document',document)
  formData.append('_method','PUT')
  return (dispatch, getState) => {
    const urlPath = '/user/'+userId+'/orders/'+orderId+'/document'
   return ajax({
      method: 'POST',
      url: API_URL+urlPath,
      data: formData
    })
      .then(function(response) {
        console.log(response)
        dispatch(updateOrderById(response.data.data, orderId));
        return response.data.data
      })
      .catch(function(error) {
        return error
      });
  };
}

export function rejectPaymentOrder({orderId}) {
  return (dispatch, getState) => {
    const user = getState().user
    const urlPath = user.level === 'admin' ?  'admin' : 'operator'
    return ajax
      .get(`${API_URL}/${urlPath}/orders/${orderId}/rejectDocument`)
      .then(response => {
        //dispatch(returnOrderById(response.data.data));
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error);
      });
  };
}