import { RETURN_INVENTORY } from '../actions';

const initialState = {
  list: {}
}

export default function inventory(state = initialState, action) {
  switch (action.type) {
    case RETURN_INVENTORY:
      return {
        ...state,
        list: action.inventory
      };
    default:
      return state;
  }
}
