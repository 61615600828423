import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { logout, setUser, toggleCart, setCounterItem, deleteItemCart, deleteAllItemsCart, getCart, restoreUser, setSnack } from '../actions';
import { URL_LEVEL } from '../config'
import { withStyles } from '@material-ui/core/styles';
import logo from '../assets/img/logo_WSHOP-white.svg';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import CartIcon from '@material-ui/icons/ShoppingCart';
import PlusIcon from '@material-ui/icons/Add';
import MinusIcon from '@material-ui/icons/Remove';
import PeopleIcon from '@material-ui/icons/People';
import EcommerceIcon from '@material-ui/icons/ShoppingCart';
import StoreIcon from '@material-ui/icons/Store';
import SupplierIcon from '@material-ui/icons/Contacts';
import CategoryIcon from '@material-ui/icons/EventNote';
import OrdersIcon from '@material-ui/icons/ListAlt';
import DeleteIcon from '@material-ui/icons/Clear';
import ShipperIcon from '@material-ui/icons/LocalShipping';
import AccountIcon from '@material-ui/icons/AccountCircle';
import AgentIcon from '@material-ui/icons/AssignmentInd';
import Badge from '@material-ui/core/Badge';
import ChevronRight from '@material-ui/icons/ChevronRight';
import imgPlaceholder from '../assets/img/imgPlaceholder.png'
import {IMG_URL} from '../config';
import Input from '@material-ui/core/Input';
import debounce from 'lodash/debounce'

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(1)
  },
  toolBar: {
    justifyContent: 'space-evenly',
    '& :first-child':{
      flex: 1,
      'text-align': 'left'
    },
    '& :last-child':{
      flex: 1,
      'text-align': 'right'
    }
  },
  menuUser: {
    marginRight: theme.spacing(2)
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  menuTitleContainer: {
    padding: '0 8px 0 24px',
    background: theme.palette.primary.main,
    color: 'white',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '65px',
    display: 'flex',
    '& button': {
      margin: 0
    }
  },
  menuTitle: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(1)
    }
  },
  list: {
    width: 320,
    backgroundColor: theme.palette.background.paper,
    outline: 'none'
  },
  listTitle: {
    padding: '0 40px',
    textTransform: 'uppercase',
    fontSize: 12,
    color: 'grey'
  },
  listRow: {
    padding: '20px 40px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    '&:hover $listRowLabel, &:hover $listRowIcon': {
      color: 'white',
    }
  },
  listRowIcon: {
    color: theme.palette.primary.main
  },
  listRowLabel: {
    color: '#111',
    margin: 0
  },
  cart: {
    width: 600,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between'
  },
  nolink: {
    textDecoration: 'none'
  },
  badge: {
    top: 1,
    right: -15,
    // The border color match the background color.
    border: `2px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
    }`,
  },
  disable: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  cartContainer: {
    position: 'relative',
    height: '100%',
    overflowY: 'scroll'
  },
  cartContain: {
    padding: 20,
    position: 'absolute',
    top: 0,
    left: 0
  },
  cartRow: {
    padding: '8px 0',
    borderBottom: '1px solid lightgray',
    '&:last-child': {
      border: 0
    }
  },
  cartCol: {

  },
  cartTh: {
    textTransform: 'uppercase',
    fontWeight: 700,
    paddingBottom: 8,
    marginBottom: 8,
    borderBottom: '2px solid lightgrey',
  },
  imgProdotto: {
    float: 'left',
    margin: '8px 16px 8px 0',
    borderRadius: 12
  },
  cartTitleItem: {
    margin: 0,
    color: '#05c1f0',
    fontWeight: 600,

  },
  cartQtnBox: {
    border: '1px solid lightgrey',
    display: 'flex',
    width: 80,
    justifyContent: 'space-around',
    alignItems: 'center',
    fontWeight: 700,
    color: 'grey',
    '& svg': {
      fontSize: 14,
      cursor: 'pointer',
      fill: 'grey'
    },
    '& input': {
      textAlign: 'center'
    }
  },
  updateLink: {
    position: 'absolute',
    bottom: -15,
    left: 0,
    width: '100%',
    cursor: 'pointer',
    color: 'blue',
    fontSize: 10
  },
  cartSlugItem: {
    margin: 0,
    fontSize: 12,
    color: 'grey'
  },
  cartPriceItem: {
    fontWeight: 700,
    margin: 0,
    '& span':{
      fontWeight: 400,
      fontSize: 12,
      paddingLeft: 4
    }
  },
  cartSubtotal: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px 0',
    fontSize: 13,
    '& :last-child': {
      fontWeight: 'bold'
    }
  },
  cartTotal: {
    display: 'flex',
    borderTop: '1px solid lightgrey',
    backgroundColor: 'white',
    '& > div:first-child':{
      paddingBottom: 8,
      marginBottom: 8,
      borderBottom: '1px solid lightgrey'
    }
  },
  field: {
    textAlign: 'right',
    margin: '8px 16px'
  },
  label: {
    color: '#0000008a',
    margin: 4,
    textTransform: 'uppercase',
    fontWeight: 700
  },
  parag: {
    margin: 0,
    fontSize: 24,
    padding: 8,
    background: '#f0f0f0',
    fontWeight: 700,
    borderRadius: 5,
  },
  confirmCart: {
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'center',
    // marginTop: 8,
    // borderTop: '1px solid lightgrey',
    '& button:last-child': {
      backgroundColor: '#339900',
      color: 'white',
      marginLeft: 16
    }
  }
})

class MenuAppBar extends React.Component {

  constructor(props) {
    super(props)
    
    this.state = {
      anchorEl: null,
      drawer: false,
      loading: false,
      cartProducts: [],
    }

    this.handleChangeQty = this.handleChangeQty.bind(this)
    this.handleCounterQty = this.handleCounterQty.bind(this)
    // this.delayedChangeQty = debounce((event,userId,prodId)=>this.handleChangeQty(event,userId,prodId), 1000)
    // this.delayedCounterQty = debounce((key,val,userId,prodId)=>this.handleCounterQty(key,val,userId,prodId), 1000)
    this.delayedSetCounterItem = debounce((userId,counter,prodId,key) => {
      this.setState({
        loading: true
      })
      return this.props.setCounterItem({userId:userId,product: {counter:counter,id:prodId}})
        .then((data)=>{
          if(data.status === 404) {
            this.props.setSnack(true,'Il prodotto non è più disponibile','warning')
          }
          else {
            if(counter !== data.data[key].quantita) {
              this.props.setSnack(true,'Quantità massima raggiunta: '+data.data[key].quantita,'warning')
              this.setState(state => {
                const newCartProducts = [...state.cartProducts]
                newCartProducts[key].quantita = data.data[key].quantita

                return {
                  loading: false,
                  cartProducts: newCartProducts,
                }
              })
            }
            return this.setState({
              loading: false
            })
          }
        }).catch((error)=>{})
      }
      ,500)
  }

  componentDidUpdate(prevProps,prevState) {
    if( JSON.stringify(prevState.cartProducts) !== JSON.stringify(this.props.cart.list) ){
      const newCartProducts = [...this.props.cart.list]
      
      this.setState({
        loading: false,
        cartProducts: newCartProducts
      })
    }
  }
  componentDidMount() {
    // const impersonificate = localStorage.getItem(USER_LST_TMP)
    // const user = localStorage.getItem(USER_LST)
    
    // if (user && 
    //     this.props.user.token === '' && 
    //     this.props.user.level === '' &&
    //     this.props.user.id === '') {
    //   const userJson = JSON.parse(user)
    //   userJson.impersonificate = impersonificate !== null && JSON.parse(impersonificate) || {}
    //   this.props.setUser(userJson);
      
    // }
    setTimeout(()=>{
      if(this.props.user.level !== 'admin') {
        this.props.getCart(this.props.user.id)
        .then((list)=>{

          this.setState({
            cartProducts: [...list],
          })
        })
        .catch((error)=>{})
      }
    },1000)
  }


  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
    
  };
  
  toggleDrawer = ({type,open}) => {
    this.setState({
      [type]: open,
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout() {
    this.props.logout();  
    //this.props.history.push("/login");
  }
  showCart() {
    this.props.toggleCart(true)
  }

  emptyCart(userId) {
    this.props.deleteAllItemsCart({userId:userId})
      .then((resp)=>{
        this.props.toggleCart(false)
      }).catch((error)=>{})
  }

  checkout() {
    this.props.toggleCart(false)
    this.props.history.push('/checkout')
  }

  backUser() {
    const level = this.props.user.impersonificate.level
    this.props.restoreUser(this.props.user.impersonificate)
      .then((response)=>{
        level === 'agent' ? this.props.history.push('/clienti') : this.props.history.push('/prodotti')
      }).catch((error)=>{})
  }

  handleChangeQty(e,userId,prodId, i) {
    const key = e.target.name.split('-')
    if(parseInt(e.target.value) > 0) {
      const val = parseInt(e.target.value) || 1
      this.setState(state => {
        const newCartProducts = [...state.cartProducts]
        
        newCartProducts[parseInt(key[2])].quantita = val
  
        this.delayedSetCounterItem(userId,val,prodId,i)
        return {
          //loading: true,
          cartProducts: newCartProducts,
        }
      })
    }
  }

  handleCounterQty(key,val,userId,prodId,i) {
    this.setState(state => {
      const newCartProducts = [...state.cartProducts]
      newCartProducts[key].quantita = val
      this.delayedSetCounterItem(userId,val,prodId,i)
      return {
        //loading: true,
        cartProducts: newCartProducts,
      }
    })
  }

  render() {
    const { classes, cart, toggleCart, deleteItemCart, user } = this.props;
    const { anchorEl, cartProducts } = this.state;
    const open = Boolean(anchorEl);

    const allLinks = [
      {
        link: "/prodotti",
        icon: <EcommerceIcon />,
        label: "E-commerce",
        level: ['admin','pos','operator','agent']
      },
      {
        link: "/magazzino",
        icon: <StoreIcon />,
        label: "Magazzino",
        level: ['admin','pos','operator']
      },
      {
        link: "/fornitori",
        icon: <SupplierIcon />,
        label: "Fornitori",
        level: ['admin','operator']
      },
      {
        link: "/clienti",
        icon: <PeopleIcon />,
        label: "Clienti",
        level: ['admin','operator','agent']
      },
      {
        link: "/categorie",
        icon: <CategoryIcon />,
        label: "Categorie",
        level: ['admin','operator']
      },
      {
        link: "/ordini",
        icon: <OrdersIcon />,
        label: "Ordini",
        level: ['admin','pos','shipper','operator']
      },
      {
        link: "/shippers",
        icon: <ShipperIcon />,
        label: "Spedizionieri",
        level: ['admin','operator']
      },
      {
        link: "/operatori",
        icon: <AccountIcon />,
        label: "Operatori",
        level: ['admin']
      },
      {
        link: "/agenti",
        icon: <AgentIcon />,
        label: "Agenti",
        level: ['admin']
      },
      {
        link: "/sub-agenti",
        icon: <AgentIcon />,
        label: "Sub Agenti",
        level: [`${user['is_agent']}` === 'true' ? 'agent': 'sub-agent']
      }
      // {
      //   link: `/agente/${user['id']}/commissioni`,
      //   icon: <RoyaltiesIcon />,
      //   label: "Commissioni",
      //   level: ['agent']
      // },
      // {
      //   link: '/commissioni',
      //   icon: <RoyaltiesIcon />,
      //   label: "Commissioni",
      //   level: ['admin']
      // }
    ]
    
    return (
      <div className={classes.root}>
        <AppBar color="primary" position="static">
          <Toolbar className={classes.toolBar}>
            <div>
              <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={()=>this.toggleDrawer({type:'drawer',open:true})}>
                <MenuIcon />
              </IconButton>
            </div>
            <div>
              <img src={logo} alt="logo wcloud" width="100px" />
            </div>
            <div>
              <Button
                aria-owns={open ? 'menu-appbar' : null}
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="inherit"
                className={classes.menuUser}
              >
                {/* <AccountCircle onClick={this.handleMenu} />  */}
                {user.name}
              </Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={this.handleClose}
              >
                {user.impersonificate.level === 'admin' || user.impersonificate.level === 'agent' ? <MenuItem onClick={() => this.backUser() && this.handleClose()}>Torna {URL_LEVEL['IT'][`${user.impersonificate.level}`]}</MenuItem>: null}
                { user.level === 'operator' && <MenuItem onClick={() => this.props.history.push(`/${URL_LEVEL['IT'][user.level]}/add`)}>Aggiungi operatore</MenuItem>}
                { user.level !== 'admin' &&<MenuItem onClick={() => this.props.history.push(`/${URL_LEVEL['IT'][user.level]}/${user.id}`)}>Profilo</MenuItem>}
                <MenuItem onClick={() => this.handleLogout()}>Logout</MenuItem>
                {/* <MenuItem onClick={this.handleClose}>My account</MenuItem> */}
              </Menu>
              {user.level === 'pos' && cart.list.length > 0 && 
              <IconButton className={classes.menuButton} color="inherit" aria-label="Carrello" onClick={()=>this.showCart()}>
                <Badge badgeContent={cart.list.length} color="primary" classes={{ badge: classes.badge }}>
                  <CartIcon />
                </Badge>
              </IconButton>}
            </div>
          </Toolbar>
        </AppBar>
        <Drawer open={this.state.drawer} onClose={()=>this.toggleDrawer({type:'drawer',open:false})}>
          <div>
            <div className={classes.menuTitleContainer}>
              <div className={classes.menuTitle}>
                <span>Menù di navigazione</span>
              </div>
              <IconButton className={classes.menuButton} color="inherit" aria-label="Menu" onClick={()=>this.toggleDrawer({type:'drawer',open:false})}>
                <MenuIcon />
              </IconButton>
            </div>
            <div className={classes.list}>
              {/* <List component="nav" >
                <p className={classes.listTitle} >Preferenze</p>
                <Link to='/' className={classes.nolink}>
                  <ListItem button classes={{button: classes.listRow }}>
                    <ListItemIcon className={classes.listRowIcon}>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText primary='Dashboard' className={classes.listRowLabel} classes={{primary: classes.listRowLabel }} />
                    <ChevronRight className={classes.listRowLabel} />
                  </ListItem>
                </Link>
              </List>
              <Divider /> */}
              <List component="nav" >
                {/* <p className={classes.listTitle} >Funzioni</p> */}
                {allLinks.map((l,i)=>{
                  if(l.level.indexOf(user.level) > -1) {
                    return (
                      <Link key={i} to={l.link} className={classes.nolink} onClick={()=>this.toggleDrawer({type:'drawer',open:false})}>
                        <ListItem button classes={{button: classes.listRow }}>
                          <ListItemIcon className={classes.listRowIcon}>
                            {l.icon}
                          </ListItemIcon>
                          <ListItemText primary={l.label} className={classes.listRowLabel} classes={{primary: classes.listRowLabel }} />
                          <ChevronRight className={classes.listRowLabel} />
                        </ListItem>
                      </Link>
                    )
                  }
                })}
              </List>
            </div>
          </div>
        </Drawer>
        <Drawer id="cart-drawer" anchor="right" open={cart.open} onClose={()=>toggleCart(false)}>
          <div className={classes.cart}> {/*style={{height: this.state.drawerHeight}}>*/}
            <div className={classes.menuTitleContainer}>
              <div className={classes.menuTitle}>
                <CartIcon /> 
                <span>{user.name}</span>
              </div>
              <IconButton className={classes.menuButton} color="inherit" aria-label="Carrello" onClick={()=>this.props.toggleCart(false)}>
                <DeleteIcon />
              </IconButton>
            </div>
            <div className={classes.cartContainer}>
              <Grid container justify="center" className={classes.cartContain}>
                <Grid item className={classes.cartTh} xs={6}>Prodotto</Grid>
                <Grid item className={classes.cartTh} xs={3} align="center">Q.tà</Grid>
                <Grid item className={classes.cartTh} xs={2} align="center">Totale</Grid>
                <Grid item className={classes.cartTh} xs={1} align="center"></Grid>
                {cartProducts.map((p,i)=>(
                  <Grid container key={i} className={classes.cartRow} alignItems="center">
                    <Grid item className={classes.cartCol} xs={6}>
                      {p.immagine && p.immagine !== null && 
                        <img src={IMG_URL+p.immagine} className={classes.imgProdotto} width={50} height={50} alt="preview img prodotto"/> ||
                        <img src={imgPlaceholder} className={classes.imgProdotto} width={50} height={50} alt="placeholder prodotto"/>}
                      {/* <img src={`https://api.erp.andreamungari.com/storage/${p.immagine}`} className={classes.imgProdotto} width={50} height={50} /> */}
                      <div>
                        <p className={classes.cartTitleItem}>{p.titolo}</p>
                        <p className={classes.cartSlugItem}>{p.slug}</p>
                        <p className={classes.cartPriceItem}>€ {p.prezzo.toFixed(2)} <span>(Iva {p.aliquota_iva}%)</span></p>
                      </div>
                      </Grid>
                    <Grid item xs={3} align="center" style={{position: 'relative'}}>
                      {/* <IconButton className={p.quantita === 1 ? classes.disable: ''} 
                                  onClick={()=>setCounterItem({userId:user.id,product: {counter:-1,id:p.id}})} >
                      </IconButton> */}
                      <div className={classes.cartQtnBox}>
                      <MinusIcon className={this.state.cartProducts[i].quantita === 1 ? classes.disable: ''} 
                                onClick={()=>this.handleCounterQty(i,this.state.cartProducts[i].quantita-1,user.id,p.id,i)} />
                        <Input 
                          id={`product-cart-${i}`}
                          name={`product-cart-${i}`} 
                          value={this.state.cartProducts[i].quantita} 
                          onChange={(e)=>this.handleChangeQty(e,user.id,p.id,i)}
                        />
                          {/* {p.quantita} */}
                        <PlusIcon onClick={()=>this.handleCounterQty(i,this.state.cartProducts[i].quantita+1,user.id,p.id,i)} />
                      </div>
                      {/* <IconButton onClick={()=>setCounterItem({userId:user.id,product: {counter:1,id:p.id}})} >
                      </IconButton> */}
                    </Grid>
                    <Grid item xs={2} align="center">
                      <p className={classes.cartPriceItem}>€ {(Math.round( ( p.quantita * p.prezzo ) * 1e2 ) / 1e2).toFixed(2)} </p>
                    </Grid>
                    <Grid item xs={1} align="center">
                      <IconButton onClick={()=> deleteItemCart({userId:user.id,product:{id: p.id}}).then((resp)=>{
                        if(resp.data.length === 0) {toggleCart(false)}
                      })} ><DeleteIcon color="error"/></IconButton>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </div>
            <Grid container alignItems="center" className={classes.cartTotal} >
              <Grid item xs={12} >
                <div className={classes.cartSubtotal}>
                  <span> Imponibile </span><span className={this.state.loading ? "animated-background" : ''}> € {cart.totalNetPrice.toFixed(2)} </span>
                </div>
                {
                  Object.keys(cart.taxes).reverse().map((key,i)=>(
                    cart.taxes[key].quantita > 0 && <div className={classes.cartSubtotal} key={i}>
                      <span> IVA ({key}%) </span><span className={this.state.loading ? "animated-background" : ''}> € {cart.taxes[key].prezzo.toFixed(2)} </span>
                    </div>
                  ))
                }
              </Grid>
              <Grid item xs={8}></Grid>
              <Grid item xs={4}>
                <div className={classes.field}>
                  <p className={classes.label}>Totale carrello</p>
                  <p className={classes.parag}><span className={this.state.loading ? "animated-background" : ''}>€ {cart.totalGrossPrice.toFixed(2)}</span></p>
                </div>
              </Grid>
              <Grid item xs={12} className={classes.confirmCart}>
                <Button variant="contained" color="default" size="large" onClick={()=>this.emptyCart(user.id)}>Svuota carrello</Button>
                <Button variant="contained" size="large" onClick={()=>this.checkout()}>Checkout</Button>
              </Grid>
            </Grid>
          </div>
        </Drawer>
      </div>
    );
  }
}

MenuAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  toggleCart: PropTypes.func.isRequired,
  getCart: PropTypes.func.isRequired,
  setCounterItem: PropTypes.func.isRequired,
  deleteItemCart: PropTypes.func.isRequired,
  deleteAllItemsCart: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  restoreUser: PropTypes.func.isRequired,
};

//export default withStyles(styles)(MenuAppBar);
const mapStateToProps = (state, props) => ({
  user: state.user,
  cart: state.cart
});

export default connect(mapStateToProps, {
  logout,
  toggleCart,
  getCart,
  setCounterItem,
  deleteItemCart,
  deleteAllItemsCart,
  setUser,
  restoreUser,
  setSnack
})(withRouter(withStyles(styles)(MenuAppBar)));
