export default {
  data:[{
    id: 0,
    title: 'Dati personali',
    fields: [
      {
        element: 'input',
        name: 'denominazione',
        value: '',
        label: 'Ragione sociale',
        required: true,
      },
      {
        element: 'select',
        name: 'affiliato',
        options: 'affiliates',
        value: '',
        label: 'Affiliato',
        required: true,
      },
      {
        element: 'input',
        name: 'rea',
        value: '',
        label: 'Rea'
      },
      {
        element: 'input',
        name: 'partita_iva',
        value: '',
        label: 'Partita iva',
        required: true
      },
      {
        element: 'input',
        name: 'codice_fiscale',
        value: '',
        label: 'Codice fiscale referente',
        required: true
      },
      {
        element: 'address',
        name: 'sl_address',
        fields: {
          nazione: {
            name: 'sl_nazione',
            label: 'Sede legale Nazione',
            placeholder: 'Seleziona una nazione',
            options: 'allNations',
            required: true
          },
          provincia: {
            name: 'sl_provincia',
            label: 'Sede legale Provincia',
            placeholder: 'Seleziona una provincia',
            options: 'allProvinces',
            callback: (context, event) => {
              //context.formClienti.current.setFieldValue('client.citta', '')
              return context.getCitta(event.target.value,'sl_citta')
            },
            required: true
          },
          citta: {
            name: 'sl_citta',
            label: 'Sede legale Città',
            options: 'citta',
            placeholder: 'Seleziona una città',
            required: true
          },
          indirizzo: {
            name: 'sl_indirizzo',
            label: 'Sede legale Indirizzo',
            required: true
          },
          cap: {
            name: 'sl_cap',
            label: 'Sede legale Cap',
            required: true
          }
        }
      },
      {
        element: 'input',
        name: 'email',
        value: '',
        label: 'E-mail',
        required: true
      },
      {
        element: 'input',
        name: 'pac',
        value: '',
        label: 'PEC',
        //required: true
        condition: {
          compare: 0, // -2:< ; -1:<=; 0:=; 1:>=; 2:>
          field: 'sl_nazione',
          value: 'Italia'
        }
      },
      {
        element: 'input',
        name: 'sdi',
        value: '',
        label: 'SDI',
        //required: true
        condition: {
          compare: 0, // -2:< ; -1:<=; 0:=; 1:>=; 2:>
          field: 'sl_nazione',
          value: 'Italia'
        }
      },
      {
        element: 'input',
        name: 'telefono',
        value: '',
        label: 'Telefono',
        required: true
      },
      {
        element: 'input',
        name: 'fax',
        value: '',
        label: 'Fax',
        //required: true
      },
      {
        element: 'input',
        name: 'skype',
        value: '',
        label: 'Skype',
      },
      {
        element: 'input',
        name: 'sito_web',
        value: '',
        label: 'Sito web',
        //required: true
      },
      {
        element: 'checkbox',
        name: 'sede_coincide',
        value: 'same',
        label: 'La sede operativa coincide con la sede legale',
        callback: (context,value) => context.handleVisibilitySection('so_address',!value)
      },
      {
        element: 'address',
        name: 'so_address',
        show: {
          condition: (values) => {
            return values['sede_coincide'] === false 
          }
        },          
        fields: {
          nazione: {
            name: 'so_nazione',
            label: 'Sede operativa Nazione',
            placeholder: 'Seleziona una nazione',
            options: 'allNations',
            required: true
          },
          provincia: {
            name: 'so_provincia',
            label: 'Sede operativa Provincia',
            placeholder: 'Seleziona una provincia',
            options: 'province',
            callback: (context, event) => {
              // context.formAgenti.current.setFieldValue('shipper.provincia', '')
              return context.getCitta(event.target.value,'so_citta')
            },
            required: true
          },
          citta: {
            name: 'so_citta',
            label: 'Sede operativa Città',
            options: 'citta',
            placeholder: 'Seleziona una città',
            required: true
          },
          indirizzo: {
            name: 'so_indirizzo',
            label: 'Sede operativa Indirizzo',
            required: true
          },
          cap: {
            name: 'so_cap',
            label: 'Sede operativa Cap',
            required: true
          }
        }
      },
    ]
  },
  {
    id: 1,
    title: 'Referente',
    fields: [
      {
        element: 'input',
        name: 'contatto_nome',
        value: '',
        label: 'Nome referente',
        required: true,
      },
      {
        element: 'input',
        name: 'contatto_cognome',
        value: '',
        label: 'Cogome referente',
        required: true,
      },
      {
        element: 'input',
        name: 'contatto_ruolo',
        value: '',
        label: 'Ruolo referente',
        required: true,
      },
      {
        element: 'input',
        name: 'contatto_cellulare',
        value: '',
        label: 'Telefono referente',
        //required: true,
      },
      {
        element: 'input',
        name: 'contatto_email',
        value: '',
        label: 'E-mail referente',
        //required: true,
      },
    ]
  },
  {
    id: 2,
    title: 'Dati Commerciali',
    fields: [
      {
        element: 'input',
        name: 'sconti',
        value: '',
        label: 'Sconti',
        //required: true,
      },
      {
        element: 'input',
        name: 'fido',
        value: '',
        label: 'Fido',
        //required: true,
      },
      {
        element: 'input',
        name: 'pagamento',
        value: '',
        label: 'Pagamento',
        //required: true,
      },
      {
        element: 'input',
        name: 'banca',
        value: '',
        label: 'Banca',
        //required: true,
      },
      {
        element: 'input',
        name: 'coordinate_bancarie',
        value: '',
        label: 'IBAN',
        //required: true,
      },
      {
        element: 'input',
        name: 'swift',
        value: '',
        label: 'SWIFT',
        //required: true,
      },
      {
        element: 'select',
        name: 'port',
        value: '',
        label: 'Porto',
        options: 'ports'
        //required: true,
      },
    ]
  },
  {
    id: 3,
    title: 'Dati agente',
    fields: [
      {
        element: 'autoselect',
        name: 'agent',
        value: '',
        label: 'Agente di riferimento',
        placeholder: 'Seleziona un agente',
        options: 'allAgents',
        fullValue: true,
      },
    ]
  }
  ],
  user: {
    id: 1,
    title: 'Dati di accesso',
    slug: 'client',
    fields: [
      {
        element: 'input',
        name: 'name',
        label: 'Nome completo',
        required: true,
      },
      {
        element: 'input',
        name: 'email',
        label: 'E-mail',
        required: true,
      },
      {
        element: 'input',
        name: 'password',
        label: 'Password',
        required: true,
        type: 'password'
      }
    ]
  }
}