import ajax from 'axios';
import { API_URL } from '../config'
import queryString from 'query-string'
export const LOADING_CATEGORIES = 'LOADING_CATEGORIES';
export const RETURN_CATEGORIES = 'RETURN_CATEGORIES';
export const GET_CATEGORY_DETAILS = 'GET_CATEGORY_DETAILS';
export const POST_CATEGORY = 'POST_CATEGORY';

function returnCategories(categories) {
  return {
    type: RETURN_CATEGORIES,
    categories,
  };
}

export function getAllCategories({
  perPage=100, 
  page=1,
  orderBy='full_name',
  order='asc',
  search=''
}) {
  
  return (dispatch, getState) => {
    const query = {
      perPage,
      page,
      orderBy,
      order,
      search
    }
    dispatch({ type: LOADING_CATEGORIES })
    return ajax
      .get(`${API_URL}/categories?${queryString.stringify(query)}`)
      .then(response => {
        dispatch(returnCategories(response.data.data));
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error);
      });
  };
}

function getCategoryDetails(category) {
  return {
    type: GET_CATEGORY_DETAILS,
    category,
  };
}
export function getCategoryById(id) {
  return (dispatch, getState) => {
    return ajax
      .get(API_URL+'/admin/categories/'+id)
      .then(function(response) {
        dispatch(getCategoryDetails(response.data.data));
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error.response.data.error);
      });
  };
}


function putCategory(category) {
  return {
    type: POST_CATEGORY,
    category,
  };
}
export function putCategoryById(id,data) {
  return (dispatch, getState) => {
    return ajax({
      method: id === 'create' ? 'POST' : 'PUT',
      url: API_URL+'/admin/categories/'+id,
      data: data
    })
      .then(function(response) {
        dispatch(putCategory(response.data.data));
        return response
      })
      .catch(function(error) {
        return error.response
      });
  };
}
