import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';

import { 
  getShipperById, 
  putShipperById, 
  getAllProvince, 
  getAllCitiesByProvince, 
  setSnack 
} from '../actions';

import {
  Grid,
  Button
} from '@material-ui/core';

import {
  ArrowBack
} from '@material-ui/icons';

import ErrorFocus from '../components/ErrorFocus' 
import FormFields from '../components/Form/Fields'
import LoadingPage from '../components/LoadingPage'

import Spedizioniere from '../data/FormTemplates/Spedizioniere'


class ShipperEdizione extends React.Component {

  constructor(props) {
    super(props)
    this.state= {
      notifica: true,
      province: [],
      citta_byProvince: {
        citta: []
      },
      loading_citta: false,
      shipper: {
        denominazione: '',
        partita_iva: '',
        nazione: '',
        provincia: '',
        citta: '',
        indirizzo: '',
        cap: '',
        email: '',
        cellulare: '',
        nome: '',
        cognome: '',
        user: {
          name: '',
          email: '',
          password: ''
        },
      },
      formTemplate: Spedizioniere
      //allNations: allNations
    }
  }

  componentDidMount() {
    const { getShipperById, getAllProvince, match } = this.props;

    if(match.params.id) {
      getShipperById(match.params.id).then( (response) => {
        this.setState({
          shipper: {
            denominazione: response.denominazione || '' ,
            partita_iva: response.partita_iva || '' ,
            email: response.email || '' ,
            cellulare: response.cellulare || '' ,
            nazione: {id: response.nazione, label: response.nazione }|| '' ,
            provincia: response.provincia || '' ,
            citta: response.citta || '' ,
            indirizzo: response.indirizzo || '' ,
            cap: response.cap || '' ,
            nome: response.nome || '' ,
            cognome: response.cognome || '' ,
            user: {
              name: response.user.name || '',
              email: response.user.email || '',
              password: ''
            }
          }
        })

        if(response.provincia) {
          this.getCitta(response.provincia, 'citta')
        }
      }).catch((error)=>{})
    }

    getAllProvince().then((response)=>{
      let options = []
      response.map((s,i) => {
        return options = options.concat({
          value: s.nome,
          label: s.nome
        })
      })
      this.setState({
        province: options
      })
    }).catch((error)=>{})
  }


  getCitta = (province, tagCitta) => {
    this.setState({
      ['loading_'+tagCitta]: true
    })
    getAllCitiesByProvince(province).then((response)=>{
      let options = []
      response.map((s,i) => {
        return options = options.concat({
          value: s.nome,
          label: s.nome
        })
      })
      this.setState({
          citta_byProvince: {
            ...this.state.citta_byProvince,
            [tagCitta]: options
          },
          ['loading_'+tagCitta]: false
      })
    }).catch((error)=>{})
  }

  handleNotifica = (name,value) => {
    this.setState({ [name]: value });
  };
  
  render() {
    const copy = (o) => {
      let output, v, key;
      output = Array.isArray(o) ? [] : {};
      for (key in o) {
          v = o[key];
          output[key] = (typeof v === "object") ? copy(v) : v;
      }
      return output;
   }
    const { classes, match, history, loading } = this.props
    const { shipper, formTemplate } = this.state
    return (
      <React.Fragment>
        {loading && <LoadingPage /> }
          <div className={classes.navigationBar}>
            <div className={classes.backForm}>
              <Button 
                size="small" 
                color="primary" 
                className={classes.button} 
                onClick={()=>history.push('/shippers')}
              >
                <ArrowBack className={classes.leftIcon} /> 
                Torna alla lista
              </Button>
            </div>
            <div className={classes.titleForm} ><span>Scheda spedizioniere</span></div>
            <div className={classes.breadCrumbsForm}>
              <span>W-SHOP > SHIPPERS > SCHEDA SPEDIZIONIERE</span>
            </div>
          </div>
          <Formik
            initialValues={{ shipper: shipper, errors: {} }}
            enableReinitialize={true} 
            validate={(value) => {

              let fields = { user: {} };
              let scrollToID = []

              if(value.shipper.denominazione === '') {
                fields.denominazione = 'Campo obbligatorio'
                scrollToID.push('input-denominazione')
              }
              if(value.shipper.partita_iva === '') {
                fields.partita_iva = 'Campo obbligatorio'
                scrollToID.push('input-partita_iva')
              }
              if(value.shipper.nome === '') {
                fields.nome = 'Campo obbligatorio'
                scrollToID.push('input-nome')
              }
              if(value.shipper.cognome === '') {
                fields.cognome = 'Campo obbligatorio'
                scrollToID.push('input-cognome')
              }                
              if(value.shipper.email === '') {
                fields.email = 'Campo obbligatorio'
                scrollToID.push('input-email')
              }
              if(value.shipper.cellulare === '') {
                fields.cellulare = 'Campo obbligatorio'
                scrollToID.push('input-cellulare')
              }
              
              if(value.shipper.nazione === '') {
                fields.nazione = 'Campo obbligatorio'
                scrollToID.push('input-nazione')
              }
              if(value.shipper.provincia === '') {
                fields.provincia = 'Campo obbligatorio'
                scrollToID.push('input-provincia')
              }
              if(value.shipper.citta === '') {
                fields.citta = 'Campo obbligatorio'
                scrollToID.push('input-citta')
              }
              if(value.shipper.indirizzo === '') {
                fields.indirizzo = 'Campo obbligatorio'
                scrollToID.push('input-indirizzo')
              }
              if(value.shipper.cap === '') {
                fields.cap = 'Campo obbligatorio'
                scrollToID.push('input-cap')
              }
              
              if (!value.shipper.user.email) {
                fields.user.email = 'Campo obbligatorio';
                scrollToID.push('input-user-email')
              } 
              else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.shipper.user.email)) {
                fields.user.email = 'Indirizzo email non valido';
                scrollToID.push('input-user-email')
              }
              
              if(value.shipper.user.name === '') {
                fields.user.name = 'Campo obbligatorio'
                scrollToID.push('input-user-name')
              }
              
              if(!match.params.id && value.shipper.user.password === '') {
                fields.user.password = 'Campo obbligatorio'
                scrollToID.push('input-user-password')
              }
              
              if(Object.keys(fields.user).length === 0) {
                delete fields.user
              }
              
              const errors = {}
                if(Object.keys(fields).length > 0) errors.fields = fields
                if(scrollToID[0]) errors.first = scrollToID[0]
                
              return errors
              
            }}
            onSubmit={(values, actions) => {
              const id = this.props.match.params.id ? match.params.id : 'create'

              const allFields = copy(values.shipper)
              
              if(allFields.user.password === '') {
                delete allFields.user.password
              }
              //allFields.nazione = allFields.nazione.label
              allFields.notifica = this.state.notifica
              allFields.cap = values.shipper.cap.toString()

              this.props.putShipperById(id, allFields).then((response) => {
                if(response.status >= 200 && response.status < 300) {
                  this.props.setSnack(true,'Shipper creato con successo','success')
                  this.props.history.push(`/shipper/${response.data.data.id}`);
                }
                else {
                  this.props.setSnack(true,'Form non compilato correttamente, verifica i dati inseriti','warning')
                  Object.keys(response.data.errors).map(function(key, index) {
                    return actions.setFieldError(key,response.data.errors[key])
                  })
                }
              }).catch((error)=>{})
            }}
            render={(props) => {
              const { touched, errors } = props
              return (
                <form onSubmit={props.handleSubmit}>
                  <ErrorFocus firstItem={errors.first} />
                  <div className={classes.actionsForm} >
                  </div>
                  {
                    <FormFields 
                      classes={classes}
                      slug='shipper'
                      template={formTemplate}
                      onChange={props.handleChange} 
                      onBlur={props.handleBlur}
                      setFieldValue={props.setFieldValue}
                      setFieldTouched={props.setFieldTouched}
                      error={errors.fields}
                      touched={touched.shipper}
                      values={props.values.shipper} 
                      options={
                        {
                          citta_byProvince: this.state.citta_byProvince
                        }
                      }
                      checked={{
                        notifica: this.state.notifica
                      }} 
                      context={this}
                      hasUser='shipper'
                    />
                  }
                  <Grid container justify="center">
                    <Button type="submit" variant="contained" color="primary" className={this.props.classes.button}>Salva</Button>
                  </Grid>
                </form>
              )
            }
            }
          />
        </React.Fragment>
    );
  }
}

ShipperEdizione.propTypes = {
  shipper: PropTypes.object,
  getShipperById: PropTypes.func,
  putShipperById: PropTypes.func,
  getAllProvince: PropTypes.func,
  getAllCitiesByProvince: PropTypes.func,
  setSnack: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  shipper: state.shippers.selected,
  user: state.user,
  loading: state.info.loading
});
const mapDispatchToProps = {
  getShipperById,
  putShipperById,
  getAllProvince,
  getAllCitiesByProvince,
  setSnack
};

export default withRouter(
  connect(mapStateToProps,mapDispatchToProps)(ShipperEdizione)
)
