import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { checkout, setSnack } from '../actions';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import imgPlaceholder from '../assets/img/imgPlaceholder.png'
import {IMG_URL} from '../config';
import classNames from 'classnames';

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    margin: theme.spacing(1)
  },
  label: {
    color: '#0000008a',
    padding: 0,
    fontSize: theme.spacing(1.5),
    fontWeight: 700,
    lineHeight: 1
  },
  parag: {
    margin: theme.spacing(1),
    display: 'inline-block',
  },
  oldValue: {
    textDecoration: 'line-through',
    fontWeight: 400
  },
  formContainer: {
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    margin: 0+'!important'
  },
  actionBarContainer: {
    justifyContent: 'center',
    padding: 20
  },
  // field: {
  //   height: 'fit-content!important'
  // },
  fieldMsg: {
    textAlign: 'center',
    height: 'fit-content!important'
  },
  button: {
    margin: theme.spacing(1),
    boxShadow: 'none'
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  navigationBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 16
  },
  pageTitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  breadCrumbs: {
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#05c1f0',
    textAlign: 'center'
  },
  listContainer: {
    width: 1000,
    margin: '0 auto'
  },
  productContainer: {
    display: 'flex',
    justifyContent: 'start',
    borderBottom: '1px solid lightgrey',
    padding: '24px 0',
    '&:last-child': {
      borderBottom: 'none',
    }
  },
  productDetails:{
    width: '100%',
    paddingLeft: theme.spacing(3),
    paddingRigth: theme.spacing(3),
  },
  deliverySummary: {
    paddingTop: 30,
    paddingBottom: 30,
    borderBottom: '1px solid lightgrey',
    '& p': {
      margin: 0
    }
  },
  pricesSummary: {
    display: 'flex',
    flexDirection: 'column',
    //borderBottom: '1px solid lightgrey',
    marginTop: 30,
    '& p': {
      margin: '0 0 16px 0'
    }
  },
  cartSubtotal: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 13,
    paddingBottom: 8,
    '& :last-child': {
      fontWeight: 'bold'
    }
  },
  totalField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '16px 0',
  },
  totalLabel: {
    color: '#0000008a',
    margin: 4,
    textTransform: 'uppercase',
    fontWeight: 700
  },
  totalParag: {
    margin: 0,
    fontSize: 24,
    padding: '8px 16px 8px 8px',
    background: '#f0f0f0',
    fontWeight: 700,
    borderRadius: 5,
  },
  price: {
    fontWeight: 700
  },
  emptyContainer: {
    textAlign: 'center',
    width: '100%',
    padding: theme.spacing(3)
  }
});

const ShowField = props => {
  return (
    <div>
      <label className={props.classes.label}>{props.label}: </label>
      <p className={classNames(props.classes.parag, {
         [props.classes.price]: props.className,
       })}>
        <span className={props.classes.oldValue}>{props.oldValue !== undefined ? props.oldValue : ''}</span>
        <span> {props.value ? props.value : '-'}</span>
        </p>
    </div>
  )
}
const TextField = withStyles(styles)(ShowField)


class Checkout extends React.Component {

  
  preCheckout() {
    let cart = []
    this.props.cart.list.map((product,i)=>{
      return cart.push({
        id: product.id,
        quantita: product.quantita,
        prezzo: product.prezzo
      })
    })
    this.props.checkout({
      products: cart,
      userId: this.props.user.id
    }).then((resp)=>{
      if(resp.status === 402) {
        this.props.setSnack(true,'Alcuni valori sono stati aggiornati, conferire le informazioni nuovamente','warning')
        return false
      }
      this.props.history.push('/riepilogo-ordine/'+resp.data.id)
    }).catch((error)=>{})
  }

  
  render() {
    const { classes, cart } = this.props
    // const { cart } = this.state

    return (
      <React.Fragment>
        {/* <MenuAppBar logout={logout} /> */}
        {Object.keys(cart).length === 0 ? 'Caricamento in corso...' : 
        <div>
          <div className={classes.navigationBar}>
            <div className={classes.title} ><span>Checkout</span></div>
            <div className={classes.breadCrumbs}>
              <span>W-SHOP > CHECKOUT</span>
            </div>
          </div>
          <Paper className={classes.root} elevation={2}>          
            <Button size="small" color="primary" className={classes.button} onClick={()=>this.props.history.push('/prodotti')}>
              <ArrowBackIcon className={classes.leftIcon} />
              Torna alla lista
            </Button>
            <div className={classes.titleProduct} style={{textAling: 'center'}}>Completa il tuo acquisto</div>
              
              <Grid container className={classes.listContainer} spacing={5}>
              {cart.list.length>0 ? 
                <React.Fragment>
                  <Grid item xs={8} >
                    {cart.list.map((p,i)=> (
                      <div className={classes.productContainer} key={i}>
                        {p.immagine && p.immagine !== null ? 
                          <img src={IMG_URL+p.immagine} className={classes.imgProdotto} width={150} height={150} alt="preview img prodotto"/> :
                          <img src={imgPlaceholder} className={classes.imgProdotto} width={150} height={150} alt="placeholder prodotto"/>
                        }
                        <div className={classes.productDetails}>
                          <TextField label="Prodotto" value={p.titolo}/>
                          <TextField label="Variante" value={p.slug}/>
                          <TextField label="Quantita" value={p.quantita} oldValue={p.quantita_old && p.quantita_old}/>
                          <TextField label="Prezzo" value={`€${p.prezzo}`} oldValue={p.prezzo_old && `€${p.prezzo_old}`} className={classes.price} /> 
                        </div>
                      </div>
                    ))}
                  </Grid>
                  <Grid item xs={4}>
                    {/* <div className={classes.deliverySummary}>
                      <p><strong>Riepilogo dati di consegna: </strong></p>
                      <p> Studio grafico Domenico Cimbalo </p>
                      <p> 88900 Crotone </p>
                      <p> Via Alberto Brasili 8/10 </p>
                      <p> P.IVA 123456789 </p>
                    </div> */}
                    <div className={classes.pricesSummary}>
                      <p><strong>Riepilogo costi: </strong></p>
                      <div className={classes.cartSubtotal}>
                        <span> Imponibile </span><span> € {cart.totalNetPrice.toFixed(2)} </span>
                      </div>
                      {
                        Object.keys(cart.taxes).reverse().map((key,i)=>(
                          cart.taxes[key].quantita > 0 && <div className={classes.cartSubtotal} key={i}>
                            <span> IVA ({key}%) </span><span> € {cart.taxes[key].prezzo.toFixed(2)} </span>
                          </div>
                        ))
                      }
                      
                      <div className={classes.totalField}>
                        <p className={classes.totalLabel}>Totale carrello</p>
                        <p className={classes.totalParag}>€ {cart.totalGrossPrice.toFixed(2)}</p>
                      </div>
                    </div>
                  </Grid>
                  </React.Fragment> : 
                  <p className={classes.emptyContainer}> Nessun prodotto nel carrello</p>
                }
              </Grid> 
          </Paper>
          {cart.list.length>0 && 
            <Grid container className={classes.actionBarContainer}>
              <Grid item >
              <Button color="primary" className={classes.button} onClick={()=>this.props.history.push('/prodotti')}>
                  Continua gli acquisti
                </Button>
                <Button variant="contained" color="primary" className={classes.button} onClick={()=>this.preCheckout()}>
                  Conferma acquisto
                </Button>
              </Grid>
            </Grid>
          }
        </div>}
      </React.Fragment>
    );
  }
}

Checkout.propTypes = {
  user: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired
};
const mapStateToProps = (state, props) => ({
  cart: state.cart,
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, { 
    checkout,
    setSnack
  })(Checkout)
);
