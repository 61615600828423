import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import FormFields from '../components/Form/Fields'
import Operatore from '../data/FormTemplates/Operatore'

import { 
  getOperatorById, 
  putOperatorById, 
  setSnack 
} from '../actions';

import {
  Grid,
  Button
} from '@material-ui/core';

import { 
  ArrowBack, 
  Visibility as ViewIcon
} from '@material-ui/icons';

import ErrorFocus from '../components/ErrorFocus'
import LoadingPage from '../components/LoadingPage'

class OperatoreEdizione extends React.Component {

  constructor(props) {
    super(props)
    this.state= {
      notifica: true,
      operator : {
        name: '',
        email: '',
        password: ''
      },
      formTemplate: Operatore
    }
  }

  componentDidMount() {
    const { getOperatorById, match } = this.props;

    if(match.params.id) {
      getOperatorById(match.params.id).then( (response) => {
        
        this.setState({
          operator : {
            name: response.name || '' ,
            email: response.email || '',
            password: ''
          }
        })
      }).catch((error)=>{})
    }

  }

  handleNotifica = name => event => {
    this.setState({ [name]: event.target.checked });
  };
  
  render() {
    const copy = (o) => {
      let output, v, key;
      output = Array.isArray(o) ? [] : {};
      for (key in o) {
          v = o[key];
          output[key] = (typeof v === "object") ? copy(v) : v;
      }
      return output;
   }
    const { classes, match, history, user, loading } = this.props
    const { operator, formTemplate } = this.state
    return (
      <React.Fragment>
        {loading && <LoadingPage /> }
          <div className={classes.navigationBar}>
            <div className={classes.backForm}>
              { user.level === 'admin' && 
                <Button size="small" color="primary" className={classes.button} onClick={()=>history.push('/operatori')}>
                  <ArrowBack className={classes.leftIcon} />
                  Torna alla lista
                </Button>
              }
            </div>
            <div className={classes.titleForm} ><span>Scheda operatore</span></div>
            <div className={classes.breadCrumbsForm}>
              <span>W-SHOP > OPERATORI > SCHEDA OPERATORE</span>
            </div>
          </div>
          <Formik
            initialValues={{ operator: operator, errors: {} }}
            enableReinitialize={true} 
            validate={(value) => {
              let fields = { }
              let scrollToID = []

              if(value.operator.name === '') {
                fields.name = 'Campo obbligatorio'
                scrollToID.push('input-name')
              }
              if (!value.operator.email) {
                fields.email = 'Campo obbligatorio';
                scrollToID.push('input-email')
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.operator.email)) {
                fields.email = 'Indirizzo email non valido';
                scrollToID.push('input-email')
              }
              if(!match.params.id && value.operator.password === '') {
                fields.password = 'Campo obbligatorio'
                scrollToID.push('input-password')
              }

              const errors = {}
              if(Object.keys(fields).length > 0) errors.fields = fields
              if(scrollToID[0]) errors.first = scrollToID[0]
              return errors
            }}
            onSubmit={(values, actions) => {
              const id = this.props.match.params.id ? match.params.id : 'create'

              this.props.putOperatorById(id, values.operator).then((response) => {
                if(response.status >= 200 && response.status < 300) {
                  this.props.setSnack(true,'Operatore creato con successo','success')
                  user.level === 'operator' ? this.props.history.push(`/operatore/${response.data.data.id}`) : this.props.history.push(`/operatori`);
                }
                else {
                  this.props.setSnack(true,'Form non compilato correttamente, verifica i dati inseriti','warning')
                  Object.keys(response.data.errors).map(function(key, index) {
                    return actions.setFieldError(key,response.data.errors[key])
                  })
                }
              }).catch((error)=>{})
            }}
            render={(props) => {
              const { touched, errors } = props

              return (
                <form onSubmit={props.handleSubmit}>
                  <ErrorFocus firstItem={errors.first} />
                  {/* <Paper className={classes.root} elevation={2}> */}
                  <div className={classes.actionsForm} >  
                    { this.props.match.params.id &&
                      <Button 
                        size="small" 
                        color="primary" 
                        className={this.props.classes.buttonRight} 
                        onClick={()=> this.props.history.push(`/operatore/${this.props.match.params.id}`)}
                      >
                        <ViewIcon className={this.props.classes.leftIcon} />
                        Visualizza
                      </Button>
                    }
                  </div>
                  {
                    <FormFields 
                      classes={classes}
                      slug='operator'
                      template={formTemplate}
                      onChange={props.handleChange} 
                      onBlur={props.handleBlur}
                      error={errors.fields}
                      touched={touched.operator}
                      values={props.values.operator} 
                    />
                  }
                  
                  <Grid container justify="center">
                    <Button type="submit" variant="contained" color="primary" className={this.props.classes.button}>Salva</Button>
                  </Grid>
                </form>
              )
            }
            }
          />
        </React.Fragment>
    );
  }
}

OperatoreEdizione.propTypes = {
  operator: PropTypes.object,
  getOperatorById: PropTypes.func,
  putOperatorById: PropTypes.func,
  setSnack: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  operator: state.operators.selected,
  user: state.user,
  loading: state.info.loading
});
const mapDispatchToProps = {
  getOperatorById,
  putOperatorById,
  setSnack
};

export default withRouter(
  connect(mapStateToProps,mapDispatchToProps)(OperatoreEdizione)
)
