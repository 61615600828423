export default {
  data: [{
    id: 0,
    title: 'Dati personali',
    fields: [
      {
        element: 'input',
        name: 'denominazione',
        value: '',
        label: 'Ragione sociale',
        required: true,
      },
      {
        element: 'input',
        name: 'partita_iva',
        value: '',
        label: 'Partita iva',
        required: true,
      },
      // {
      //   element: 'input',
      //   name: 'codice_fiscale',
      //   value: '',
      //   label: 'Codice fiscale'
      // },
      {
        element: 'input',
        name: 'nome',
        value: '',
        label: 'Nome',
        required: true
      },
      {
        element: 'input',
        name: 'cognome',
        value: '',
        label: 'Cognome',
        required: true
      },
      {
        element: 'input',
        name: 'email',
        value: '',
        label: 'E-mail',
        required: true
      },
      {
        element: 'input',
        name: 'cellulare',
        value: '',
        label: 'Telefono',
        required: true
      },
      {
        element: 'address',
        name: 'address',
        fields: {
          nazione: {
            name: 'nazione',
            label: 'Nazione',
            placeholder: 'Seleziona una nazione',
            options: 'allNations',
            required: true
          },
          provincia: {
            name: 'provincia',
            label: 'Provincia',
            placeholder: 'Seleziona una provincia',
            options: 'province',
            callback: (context, event) => {
              // context.formAgenti.current.setFieldValue('shipper.provincia', '')
              return context.getCitta(event.target.value,'citta')
            },
            required: true
          },
          citta: {
            name: 'citta',
            label: 'Città',
            options: 'citta',
            placeholder: 'Seleziona una città',
            required: true
          },
          indirizzo: {
            name: 'indirizzo',
            label: 'Indirizzo',
            required: true
          },
          cap: {
            name: 'cap',
            label: 'Cap',
            required: true
          }
        }
      },
      {
        element: 'checkbox',
        name: 'notifica',
        label: 'Abilita notifica ordini',
        callback: (context,value) => context.handleNotifica('notifica',value)
      }
    ]
  }],
  user: {
    id: 1,
    title: 'Dati di accesso',
    slug: 'shipper',
    fields: [
      {
        element: 'input',
        name: 'name',
        value: '',
        label: 'Nome completo',
        required: true,
      },
      {
        element: 'input',
        name: 'email',
        value: '',
        label: 'E-mail',
        required: true,
      },
      {
        element: 'input',
        name: 'password',
        value: '',
        label: 'Password',
        required: true,
        type: 'password'
      }
    ]
  }
}