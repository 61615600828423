import React from 'react';
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import {setSnack, getUserInfo} from './actions';
import { withStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
//import Layout from './Layout'

const styles = theme => ({
  success: {
    backgroundColor: green[600],
    color: 'white'
  },
  warning: {
    backgroundColor: theme.palette.error.dark,
    color: 'white'
  }
});

class App extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Snackbar
          autoHideDuration={6000}
          open={this.props.info.snack.show}
          onClose={()=>this.props.setSnack(false)}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
        >
          <SnackbarContent 
            className={this.props.classes[this.props.info.snack.variant]} 
            message={<span id="message-id">{this.props.info.snack.message}</span>}/>
        </Snackbar>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  info: state.info,
});

export default connect(
  mapStateToProps,
  { setSnack, getUserInfo }
)(withStyles(styles)(App))