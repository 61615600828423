import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { USER_LST } from '../config'

const PrivateRoute = ({ component: Component, props, ...rest }) => (
  localStorage.getItem(USER_LST) ? (
    <Route
      {...rest}
      render={ () => <Component {...props} />}
    />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: props.location },
      }}
    />
  )
);

export default PrivateRoute