import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getUserInfo } from '../actions';

const Home = props => {
  useEffect(() => {
    props.getUserInfo()
    //.then((resp)=>{ console.log(resp) })
    //.catch(err => console.log(err))

  })

  return (
    <div>
      Dashboard
    </div>
  )
}

export default connect(null, { getUserInfo })(Home)
