import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import SearchIcon from '@material-ui/icons/Search';
import CleanIcon from '@material-ui/icons/HighlightOff';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CopyIcon from '@material-ui/icons/FileCopy';
import AddCartIcon from '@material-ui/icons/AddShoppingCart';
import ViewIcon from '@material-ui/icons/Visibility';
import AddIcon from '@material-ui/icons/Add';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import { FormattedNumber } from 'react-intl';
import imgPlaceholder from '../assets/img/imgPlaceholder.png'
import {IMG_URL} from '../config';
import RSelect from './ReactSelect';

const TableHeadStyles = theme => ({
  fieldTh: {
    textTransform: 'uppercase',
    fontSize: 11,
    fontWeight: 700,
    color: '#666'
  },
  unactive: {
    pointerEvents: 'none'
  }
});
class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { 
      onSelectAllClick, 
      order, 
      orderBy, 
      numSelected, 
      rowCount, 
      columnData, 
      classes, 
      deletable, 
      userLevel
    } = this.props;
    return (
      <TableHead>
        <TableRow>
          {deletable && 
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>}
          {columnData.map((column,key) => {
            if( column.hideForLevel && column.hideForLevel.indexOf(userLevel) > -1 ) return false
            return (
              <TableCell
                key={key}
                align={column.align || 'left'}
                padding={column.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === column.value ? order : false}
              >
                <Tooltip
                  title="Ordina"
                  placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === column.value}
                    direction={order}
                    className={classNames(classes.fieldTh, {
                      [classes.unactive]: column.unsortable,
                    })}
                    //className={classes.fieldTh}
                    onClick={this.createSortHandler(column.value)}
                  >
                    {column.label ? column.label : column.value}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
          <TableCell padding="checkbox"></TableCell>
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columnData: PropTypes.array.isRequired,
  deletable: PropTypes.bool.isRequired,
};
EnhancedTableHead = withStyles(TableHeadStyles)(EnhancedTableHead);

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    display: 'flex',
    flex: '1 1 100%',
  },
  actions: {
    color: theme.palette.text.secondary,
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    flex: '0 0 auto',
    //marginRight: theme.spacing(3),
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  button: {
    //margin: theme.spacing(1),
    boxShadow: 'none',
    '& span': {
      alignItems: 'flex-start'
    }
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  searchBox: {
    display: 'inline-block',
    border: '1px solid grey',
    borderRadius: 20,
    padding: '4px 16px',
    '& input': {
      border: 0,
      outline: 'none',
    },
    '& svg': {
      cursor: 'pointer',
      verticalAlign: 'text-bottom'
    }
  },
  breadCrumbs: {
    display: 'inline-block',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  filterSelect: {
    width: 350,
    padding: '0 24px',
    display: 'inline-block'
  },
  filterButton: {
    marginRight: 16,
  },
  filterRangeActive: {
    textAlign: 'center',
    minWidth: 150,
    padding: 8
  }
});

class EnhancedTableToolbar extends React.Component {
  
  state = {
    text:''
  }

  getBreadCrumbs = (path) => {
    const parts = path.split('/')
    parts.shift()
    return parts.map((p)=>{
      return `> ${p}`
    })
  }
  
  resetSearch = () => {
    this.setState({
      text: ''
    })
    this.props.handleSearch('')
  }
  render() {
    const { 
      location,
      numSelected, 
      classes, 
      title,
      filters,
      filtersModal,
      removeItems, 
      handleSearch, 
      goTo, 
      userLevel,
      canAdd,
      slug,
      customAdd
    } = this.props;
    return (
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        <div className={classes.title}>
          {numSelected > 0 && (
            <Typography color="inherit" variant="subtitle1">
              {numSelected} element{numSelected > 1 ? 'i' : 'o'} selezionat{numSelected > 1 ? 'i' : 'o'}
            </Typography>
          ) 
          // : (
          //   null
          //     // <span>{title}</span>
          // )
          }
        </div>
        <div className={classes.spacer} >
          {numSelected > 0 ? (
            <Tooltip title="Rimuovi">
              <IconButton aria-label="Delete" onClick={removeItems}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          ) : (
            canAdd.includes(userLevel) && (
              <Button 
                variant="contained" 
                size="small" 
                color="primary" 
                className={classes.button} 
                onClick={
                  () => customAdd ? customAdd() : goTo('add')
                }
              >
                <AddIcon className={classes.leftIcon} fontSize="small" />
                Aggiungi nuovo
              </Button>
            )   
          )}
        </div>
        <div className={classes.actions}>
          {filters && filters.length > 0 ?
            filters.map((filter)=>(
              <div className={classes.filterSelect} key={filter.name}>
                <RSelect
                  id={`input-filterby-${filter.name}`}
                  name={filter.name} 
                  options={filter.options}
                  //value={props.values.product.supplier}
                  setChange={(event) => filter.callback(event && event.value || '')}
                  placeholder={filter.placeholder}
                />
              </div>
            )) : null
          }
          {
            filtersModal && filtersModal.length > 0 ? 
              filtersModal.map((filter,i)=>(
                <div className={classes.filterButton} key={i}>
                   {
                    filter.active && filter.activeComponent ?  
                    <div className={classes.filterRangeActive}>
                      <filter.activeComponent />
                    </div> :
                      <Button 
                        color="primary" 
                        onClick={filter.callback}>
                        { filter.label }
                      </Button>
                   }
                </div>
              ))
            : null
          }
          {(numSelected > 0 || slug === 'operators' || slug === 'shippers' || slug === 'royalties' || slug === 'inventory') ? null :
            <div className={classes.searchBox}>
              <input type="text"
                    placeholder="Cerca"
                    value={this.state.text} 
                    onChange={(e)=>{this.setState({text: e.target.value})}} 
                    onKeyDown={(e)=>{e.keyCode===13 && handleSearch(e.target.value)}} />
                {this.state.text.length === 0 ?
                <SearchIcon fontSize="small" /> :
                <CleanIcon fontSize="small" onClick={this.resetSearch} /> }
            </div>
          }
          {/* {location && <div className={classes.breadCrumbs}>
            <span>W-SHOP {this.getBreadCrumbs(location.pathname)}</span>
          </div>} */}
        </div>
      </Toolbar>
    );
  }
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string,
  filters: PropTypes.array,
  filtersModal: PropTypes.array,
  removeItems: PropTypes.func,
  handleSearch: PropTypes.func,
  goTo: PropTypes.func,
  userLevel: PropTypes.string,
  canAdd: PropTypes.array,
  customAdd: PropTypes.func
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: '100%',
    //...theme.mixins.gutters(),
    //marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  paginationSelect: {
    paddingRight: theme.spacing(4),
    border: '1px solid lightgrey'
  },
  hoverRow: {
    //cursor: 'pointer',
    height: 50
  },
  chip: {
    margin: theme.spacing(1) / 2,
  },
  status: {
    paddingLeft: 24,
    position: 'relative',
    textTransform: 'capitalize',
    '&:before':{
      content: '""',
      width: 10,
      height: 10,
      position: 'absolute',
      top: 3,
      left: 5,
      borderRadius: 100,
    },
    '&.green': {
      '&:before':{
        backgroundColor: 'green',
      }
    },
    '&.orange': {
      '&:before':{
        backgroundColor: 'orange',
      }
    },
    '&.grey': {
      '&:before':{
        backgroundColor: 'grey',
      }
    },
    '&.red': {
      '&:before':{
        backgroundColor: 'red',
      }
    }
  }
});

class EnhancedTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: props.pagination.order || 'asc',
      orderBy: props.pagination.orderBy || 'id',
      selected: [],
      page: props.pagination.current_page - 1 || 0,
      perPage: props.pagination.per_page || 100,
      openMenu: false
    }
    this.anchorEl = null
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'asc';
    if (this.state.orderBy === property && this.state.order === 'asc') {
      order = 'desc';
    }
    this.props.paginate({
      perPage: this.state.perPage,
      page:1,
      orderBy,
      order
    }).then(()=>{
        this.setState({
          page:0, 
          order, 
          orderBy
        })
    }).catch((error)=>{})
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState(state => ({ selected: this.props.choises.slice(0,this.state.perPage).map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleSelect = (event, id) => {
    event.stopPropagation()
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.props.paginate({
      perPage:this.state.perPage,
      page:page+1})
      .then(()=>{
        this.setState({ page, selected: [] });
      }).catch((error)=>{})
  };

  handleChangeRowsPerPage = event => {
    this.props.paginate({
      perPage:event.target.value,
      page:1})
      .then(()=>{
        this.setState({ 
          perPage: event.target.value, 
          page: 0,
          selected: [] 
        });
      }).catch((error)=>{})
  };

  handleRemove = () => {
    this.props.removeItems(this.state.selected);
    this.setState({
      selected: []
    })
  }

  handleSearch = (data) => {
    this.props.paginate({
      orderBy:'id',
      order:'asc',
      search:data})
      .then(()=>{
        this.setState(state =>{
          return {
            perPage: state.perPage, 
            page: 0,
            order: 'asc',
            orderBy: 'id',
            selected: []
          }
        })
      }).catch((error)=>{})
  } 

  getStatus(status) {
    switch(status) {
      case 'disponibile':
        return 'green'
      case 'in arrivo':
        return 'orange'
      case 'esaurito':
        return 'red'
    }
  }

  printValue = (choise, val) => {
    switch(val.type) {
      case 'bool':
        return  eval(`choise.${val.value}`) ? 'Si' : 'No'
      case 'img': 
        let img = ''
        if(val.param && eval(`choise.${val.value}`)) {
          const myVal = val.value+'.'+val.param
          img = eval(`choise.${myVal}`)
        }
        else {
          img = eval(`choise.${val.value}`)
        }
        const url = img !== null && IMG_URL + img || imgPlaceholder 
        
        return <img width={65} height={65} src={url} alt="" />
      case 'status': 
        return <span className={`${this.props.classes.status} ${this.getStatus(eval(`choise.${val.value}`))} `}>{eval(`choise.${val.value}`)}</span>
      case 'currency': 
        return <FormattedNumber value={eval(`choise.${val.value}`)} style="currency" currency="EUR" />
      default: {
        if(val.param) {
          if(eval(`choise.${val.value}`)) {
            const myVal = val.value+'.'+val.param
            return eval(`choise.${myVal}`) || '---'
          }
          else 
            return '---'
        }
        else {
          return eval(`choise.${val.value}`) || '---'
        }
      }
    }
  }

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  handleOpenMenu = (val) => {
    this.setState({
      openMenu: true
    })
    this.anchorEl = val
  }
  handleCloseMenu = () => {
    this.setState({
      openMenu: false
    })
    this.anchorEl= null
  }

  render() {
    const { 
        classes, 
        location, 
        tableTitle, 
        slug, 
        choises, 
        pagination, 
        labels, 
        filters,
        filtersModal,
        goToPage, 
        addToCart, 
        userLevel, 
        canAdd,
        canEdit,
        hasView,
        hasEdit,
        canRemove,
        customAdd,
        customEdit
      } = this.props;
    const { order, orderBy, selected, perPage, page } = this.state;
    
    return (
      <Paper className={classes.root}>
        <EnhancedTableToolbar 
          slug={slug}
          title={tableTitle} 
          location={location}
          filters={filters}
          filtersModal={filtersModal}
          numSelected={selected.length} 
          removeItems={this.handleRemove} 
          handleSearch={this.handleSearch} 
          goTo={goToPage}
          userLevel={userLevel}
          canAdd={canAdd}
          canEdit={canEdit}
          customAdd={customAdd}
        />
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={choises ? choises.slice(0,this.state.rowsPerPage).length : 0}
              columnData={labels}
              deletable={(userLevel==='admin' || userLevel==='operator') || canRemove}
              userLevel={userLevel}
            />
            <TableBody>
              {choises && choises
                //.sort(getSorting(order, orderBy))
                //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((n) => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      //onClick={event => !this.state.openMenu && goToPage(n.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                      className={classes.hoverRow}
                    >
                      {((userLevel==='admin' || userLevel==='operator') || canRemove) &&
                      <TableCell padding="checkbox">
                        <Checkbox checked={isSelected} onClick={event => this.handleSelect(event, n.id)}/>
                      </TableCell>}
                      {labels.map((l,i)=> {
                        if( l.hideForLevel && l.hideForLevel.indexOf(userLevel) > -1 ) return false
                        return (
                          <TableCell key={i} align={l.align} style={{width: l.width}}>
                            {l.array ? 
                              n[l.value].map(data => (
                                <Chip
                                  key={data.id}
                                  label={data.name}
                                  className={classes.chip}
                                />
                              )) :
                              this.printValue(n,l)
                              }
                          </TableCell>
                        )
                      }
                      )}
                      <TableCell align="right" style={{width: '10%'}}>
                        {userLevel==='pos' && n.varianti === null && 
                          <IconButton 
                            aria-label="Aggiungi al carrello" 
                            color="primary" 
                            onClick={
                              (event)=>{
                                event.stopPropagation(); 
                                addToCart(n)
                                }
                              }
                            >
                            <AddCartIcon />
                          </IconButton>
                        }
                        {canEdit.includes(userLevel) && hasEdit &&
                          <React.Fragment>
                            <Tooltip title="Modifica">
                              <IconButton 
                                aria-label="Modifica" 
                                color="primary" 
                                onClick={
                                  event => {
                                    event.stopPropagation(); 
                                    customEdit ? customEdit(n.id) : goToPage('edit/'+n.id)
                                  }
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            </Tooltip>

                            {slug === 'prodotti' &&
                              <Tooltip title="Duplica">
                                <IconButton aria-label="Duplica" color="primary" onClick={event => {event.stopPropagation(); goToPage('copy/'+n.id)}}>
                                  <CopyIcon />
                                </IconButton>
                              </Tooltip>
                            }
                            {/* {slug === 'agenti' && 
                              <Tooltip title="Commissioni">
                                <IconButton aria-label="Commissioni" color="primary" onClick={event => {event.stopPropagation(); goToPage(n.id+'/commissioni')}}>
                                  <RoyaltiesIcon />
                                </IconButton>
                              </Tooltip>
                            } */}
                          </React.Fragment>
                        }
                        {
                          hasView &&
                            <Tooltip title="Visualizza">
                              <IconButton aria-label="Visualizza" color="primary" onClick={event => { goToPage(n.id) }}>
                                <ViewIcon />
                              </IconButton>
                            </Tooltip>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              {pagination.total === 0 && (
                <TableRow style={{ height: 49 }}>
                  <TableCell colSpan={12}>Nessun risultato incontrato</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          component="div"
          count={pagination.total || 0}
          labelRowsPerPage="Numero di righe"
          rowsPerPage={perPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Pagina precedente',
          }}
          nextIconButtonProps={{
            'aria-label': 'Pagina successiva',
          }}
          labelDisplayedRows= {({ from, to, count }) => `${from}-${to} di ${count}`}
          rowsPerPageOptions={[100,150,200]}
          classes={{ select: classes.paginationSelect }}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

EnhancedTable.defaultProps = {
  hasView: true,
  hasEdit: true,
  canRemove: false,
  customEdit: null,
  goToPage: null,
  choises: [],
  canAdd: ['admin', 'operator'],
  canEdit: ['admin', 'operator']
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object,
  tableTitle: PropTypes.string,
  choises: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  labels:PropTypes.array.isRequired,
  filters:PropTypes.array,
  paginate: PropTypes.func.isRequired,
  removeItems: PropTypes.func,//.isRequired,
  slug: PropTypes.string.isRequired,
  goToPage: PropTypes.func,
  addToCart: PropTypes.func,
  userLevel: PropTypes.string,
  canAdd: PropTypes.array,
  canEdit: PropTypes.array,
  hasView: PropTypes.bool,
  hasEdit: PropTypes.bool,
  canRemove: PropTypes.bool,
  customAdd: PropTypes.func,
  customEdit: PropTypes.func
};

export default withStyles(styles)(EnhancedTable);