import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getOrderById, getOrderPdfById, uploadPaymentConfirmation, setSnack } from '../actions';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {CheckCircle} from '@material-ui/icons';
import Input from '@material-ui/core/Input';
import { STATUS_PYM } from '../config'



class Riepilogo extends React.Component {

  componentDidMount() {
    setTimeout(()=>{
      const { getOrderById, match, user } = this.props;
      getOrderById({userId: user.id, orderId:match.params.id})
    },1000)
  }

  uploadPaymentMethod({userId, orderId, document}) {
    this.props.uploadPaymentConfirmation({userId, orderId, document})
    .then((response)=>{
      this.props.setSnack(true,'Upload eseguito con successo','success')
    })
  }
  
  render() {
    const { classes, getOrderPdfById, user, order} = this.props

    return (
      <React.Fragment>
        {/* <MenuAppBar logout={logout} /> */}
        {/* {Object.keys(cart).length === 0 ? 'Caricamento in corso...' :  */}
        <div>
          <div className={classes.navigationBar}>
            <div className={classes.title} ><span>Riepilogo</span></div>
            {/* <div className={classes.breadCrumbs}>
              <span>W-SHOP > CHECKOUT</span>
            </div> */}
          </div>
          <Paper className={classes.root} elevation={2}>          
            {/* <Button size="small" color="primary" className={classes.button} onClick={()=>this.props.history.push('/prodotti')}>
              <ArrowBackIcon className={classes.leftIcon} />
              Torna alla lista
            </Button> */}
            <div className={classes.titleBox} >
              <CheckCircle style={{ fontSize: 96 }}/>
              <h2>CONGRATULAZIONI</h2>
              <p>Il tuo ordine è stato effettuato con successo!</p>
              <p><strong>Riepilogo ordine</strong></p>
            </div>
              
            <Grid container className={classes.listContainer} spacing={5}>
            {order.variants && order.variants.length>0 ? 
              <React.Fragment>
                <Grid item xs={12} >
                  <div className={classes.productsTableContainer}>
                    <div className={classes.productTableRow}>
                      <p>Prodotto</p>
                      <p>Variante</p>
                      <p>Quantità</p>
                      <p>Prezzo</p>
                    </div>
                    {order.variants.map((o,i)=> (
                      <div className={classes.productTableRow} key={i}>
                        <p>{o.product.nome}</p>
                        <p>{o.slug || '-'}</p>
                        <p>{o.pivot.quantita}</p>
                        <p><strong>€ {o.pivot.prezzo_totale_con_iva.toFixed(2)}</strong></p>
                      </div>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div className={classes.credentialsSummary}>
                    <p><strong>Credenziali pagamento:</strong></p>
                    <p><label>Beneficiario:</label> <strong>Working Company s.r.l.</strong></p>
                    <p><label>Banca:</label> <strong>Banco di Intesa San Paolo S.p.a.</strong></p>
                    <p><label>IBAN:</label> <strong>IT95S0306979234100000004901</strong></p>
                    <p><label>SWIFT:</label> <strong>BSCITITMMXXX</strong></p>
                    { order.status === STATUS_PYM['waiting'] &&
                      <p className={classes.warningParag}>Avviso con nota: L'ordine verrà processato solo ad avvenuto pagamento della merce e upload della contabile bancaria sul gestionale.</p>
                    }
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className={classes.pricesSummary}>
                    <p><strong>Riepilogo costi: </strong></p>
                    <div className={classes.cartSubtotal}>
                      <span> Imponibile </span><span> € {order.prezzo_totale.toFixed(2)} </span>
                    </div>
                    {
                      Object.keys(order.prezzi_iva).reverse().map((key,i)=>(
                        order.prezzi_iva[key] > 0 && <div className={classes.cartSubtotal} key={i}>
                          <span> IVA ({key.split('-')[1]}%) </span><span> € {order.prezzi_iva[key].toFixed(2)} </span>
                        </div>
                      ))
                    }
                    
                    <div className={classes.totalField}>
                      <p className={classes.totalLabel}>Totale carrello</p>
                      <p className={classes.totalParag}>€ {order.prezzo_con_iva.toFixed(2)}</p>
                    </div>
                  </div>
                </Grid>
                </React.Fragment> : 
                <p className={classes.emptyContainer}> Nessun prodotto in questo ordine</p>
              }
            </Grid> 
          </Paper>
          <Grid container className={classes.actionBarContainer}>
            <Grid item >
            { order.status === STATUS_PYM['waiting'] && 
              <form noValidate encType="multipart/form-data" className={classes.inline}>
                <Input
                  accept="*"
                  //name="product.immagine"
                  //valeu={props.values.product.immagine}
                  onChange={(event) => {
                    this.uploadPaymentMethod({userId: user.id, orderId: order.id, document: event.currentTarget.files[0]})
                  }}
                  className={classes.inputHidden}
                  id={`ordine_file_${order['id']}`}
                  type="file"
                />
                <label htmlFor={`ordine_file_${order['id']}`}>
                  <Button 
                    className={classes.button}
                    component="span"
                    variant="contained"
                    //size="small" 
                    onClick={(e)=>{ 
                      e.stopPropagation(); 
                      //this.props.uploadPaymentConfirmation({userId: o.pos_id, orderId: o.id})
                    }}
                  >
                    carica conferma pagamento
                  </Button>
                </label>
              </form>
            }
              <Button variant="contained" color="default" className={classes.button} onClick={()=>getOrderPdfById({userId: user.id, orderId: order.id})}>
                Download ordine
              </Button>
              <Button variant="contained" color="primary" className={classes.button} onClick={()=>this.props.history.push('/ordini')}>
                Vai ai miei ordini
              </Button>
            </Grid>
          </Grid>
        </div>
        {/* } */}
      </React.Fragment>
    );
  }
}

Riepilogo.propTypes = {
  user: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired
};
const mapStateToProps = (state, props) => ({
  order: state.orders.selected,
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, { 
    getOrderById,
    getOrderPdfById,
    uploadPaymentConfirmation,
    setSnack
  })(Riepilogo)
);
