import ajax from 'axios';
import { API_URL } from '../config'
import queryString from 'query-string'
export const RETURN_SHIPPERS = 'RETURN_SHIPPERS';
export const GET_SHIPPER_DETAILS = 'GET_SHIPPER_DETAILS';
export const POST_SHIPPER = 'POST_SHIPPER';

function returnShippers(shippers) {
  return {
    type: RETURN_SHIPPERS,
    shippers,
  };
}

export function getAllShippers({perPage=100, page=1, orderBy='id',order='asc',search=''}) {
  return (dispatch, getState) => {
    const query = {
      perPage,
      page,
      orderBy,
      order,
      search
    }
    const user = {level: 'admin'}//getState().user 
    return ajax
      .get(`${API_URL}/${user.level}/shippers?${queryString.stringify(query)}`)
      .then(response => {
        dispatch(returnShippers(response.data));
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error);
      });
  };
}

function getShipperDetails(shipper) {
  return {
    type: GET_SHIPPER_DETAILS,
    shipper,
  };
}
export function getShipperById(id) {
  return (dispatch, getState) => {
    const user = getState().user
    const urlPath = user.level === 'admin' ? '/admin/shippers/'+id : '/user/'+id+'/shipper'
    return ajax
      .get(API_URL+urlPath)//'/admin/shippers/'+id)
      .then(function(response) {
        dispatch(getShipperDetails(response.data.data));
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error.response.data.error);
      });
  };
}


function putShipper(shipper) {
  return {
    type: POST_SHIPPER,
    shipper,
  };
}
export function putShipperById(id,data) {
  return (dispatch, getState) => {
    return ajax({
      method: id === 'create' ? 'POST' : 'PUT',
      url: API_URL+'/admin/shippers/'+id,
      data: data
    })
      .then(function(response) {
        dispatch(putShipper(response.data.data));
        return response
      })
      .catch(function(error) {
        return error.response
        //console.log(error.response.data.error);
      });
  };
}
