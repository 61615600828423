import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { 
  getShipperById, 
  setClientPsw, 
  setSnack 
} from '../actions';

import {
  Paper,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl
} from '@material-ui/core';

import {
  ArrowBack as ArrowBackIcon,
  VpnKey as KeyIcon,
  Edit as EditIcon
} from '@material-ui/icons';

import LoadingPage from '../components/LoadingPage'

const ShowField = props => {
  return (
    <div>
      <label className={props.classes.label}>{props.label}</label>
      <p className={props.classes.parag}>{props.value ? props.value : '---'}</p>
    </div>
  )
}

class ShipperDettaglio extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      showDialog: false,
      showError: ''
    }
    this.inputPsw = React.createRef();
    this.submitPsw = this.submitPsw.bind(this);

    // const TextField = (props) => <ShowField classes={props.classes} {...props} />
  }
  
  componentDidMount() {
    const { getShipperById, match } = this.props;
    getShipperById(match.params.id)
  }
  
  submitPsw(e) {
    e.preventDefault()
    if(this.inputPsw.current.value === '') {
      this.setState({showError: 'Campo obbligatorio'})
      return false
    }
    if(this.inputPsw.current.value.length <= 3) {
      this.setState({showError: 'La password deve contentere minimo 3 caratteri'})
      return false
    }
    this.props.setClientPsw(this.inputPsw.current.value, this.props.user.id).then((response) => {
      this.setState({
        showDialog: false,
        showError: ''
      })
      this.props.setSnack(true,'Password salvata con successo!','success')
    }).catch((error)=>{})
  }

  render() {
    const { shipper, classes, user, loading } = this.props
    const TextField = (props) => <ShowField classes={classes} {...props} />
    return (
      <React.Fragment>
        {
          loading ? <LoadingPage /> :
          //Object.keys(shipper).length === 0 ? 'Caricando informazioni dello shipper' : 
          <div>
            <div className={classes.navigationBar}>
              <div className={classes.backForm}>
                <Button size="small" color="primary" className={classes.button} onClick={()=>this.props.history.push('/shippers')}>
                  <ArrowBackIcon className={classes.leftIcon} />
                  Torna alla lista
                </Button>
              </div>
              <div className={classes.titleForm} >
                <span>{ user.level === 'admin' ? 'Scheda shipper': 'Profilo'}</span>
              </div>
              { user.level === 'admin' ? 
                <div className={classes.breadCrumbsForm}>
                  <span>W-SHOP > SHIPPERS > SCHEDA SHIPPER</span>
                </div> :
                <Button size="small" color="primary" className={classes.buttonRight} onClick={()=>this.setState({showDialog: true})}>
                  <KeyIcon className={classes.leftIcon} />
                  Modifica password
                </Button>
              }
            </div>
            <div className={classes.actionsForm}>
              { user.level === 'admin' &&
                <React.Fragment>
                  <Button 
                    size="small" 
                    color="primary" 
                    className={classes.buttonRight} 
                    onClick={()=>this.props.history.push(`/shipper/edit/${shipper['id']}`)}
                  >
                    <EditIcon className={classes.leftIcon} />
                    Modifica
                  </Button> 
                </React.Fragment>
              }
            </div>
            <Paper className={classes.root} elevation={2}>
            
              <Grid container className={classes.formContainer}>
                <div className={classes.sectionTitle}>Dati Personali</div>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Ragione sociale" value={shipper.denominazione} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Partita iva" value={shipper.partita_iva} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Contatto Nome" value={shipper.nome}/></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Contatto Cognome" value={shipper.cognome}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Contatto E-mail" value={shipper.email}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Telefono" value={shipper.cellulare} /></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede legale Nazione" value={shipper.nazione}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede legale Provincia" value={shipper.provincia}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede legale Città" value={shipper.citta}/></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede operativa Indirizzo" value={shipper.indirizzo}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede legale Cap" value={shipper.cap}/></Grid>
                </Grid>                
              </Grid>
            </Paper>
            <Paper className={classes.root} elevation={2}>
              <Grid container className={classes.formContainer}>
                <div className={classes.sectionTitle}>Dati di accesso</div>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Username" value={shipper.user && shipper.user.name}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="E-mail utente" value={shipper.user && shipper.user.email}/></Grid>
                </Grid>
              </Grid>
            </Paper>
          </div>
        }
        <Dialog
          open={this.state.showDialog}
          onClose={this.handleCancel}
          aria-labelledby="Cambia password"
          aria-describedby="cambia password di accesso"
        > 
          <form onSubmit={this.submitPsw}>
            <DialogTitle id="change-access-password">Cambia password</DialogTitle>
            <DialogContent>
              { loading && <LoadingPage light absolute /> }
              <DialogContentText>
                Imposta la nuova password di accesso: 
              </DialogContentText>
              <FormControl className={classes.row}>
                <TextField
                  type="password"
                  inputRef={this.inputPsw}
                  label="Password"
                  className={classes.textFieldContainerInput}
                  error={this.state.showError !== ''}
                  helperText={this.state.showError}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({showDialog: false})} color="primary">
                Cancella
              </Button>
              <Button type="submit" color="primary" autoFocus>
                Continua
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    );
  }
}

ShipperDettaglio.propTypes = {
  shipper: PropTypes.object.isRequired,
  getShipperById: PropTypes.func.isRequired,
  setClientPsw: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  shipper: state.shippers.selected,
  user: state.user,
  loading: state.info.loading
});

export default withRouter(
  connect(mapStateToProps, {
    getShipperById,
    setClientPsw,
    setSnack
  })(ShipperDettaglio)
);
