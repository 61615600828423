import { LOGGED_IN, LOGGED_OUT, SET_USER } from '../actions';

const initialState = {
  message: '',
  token: '',
  id: '',
  level: '',
  name: '',
  impersonificate: {}
}

export default function user(state = initialState, action) {
  switch (action.type) {
    case LOGGED_IN:
      return {
        ...state,
        id: action.data.id,
        level: action.data.level,
        is_agent: action.data.is_agent,
        message: action.data.msg,
        token: action.data.token,
        name: action.data.name,
        impersonificate: action.data.impersonificate || {}, 
      };
    // case LOGIN_DENIED:
    //   return {
    //     ...state,
    //     logged: action.data.user,
    //     denied: true,
    //     message: action.data.msg,
    //     token: action.data.token,
    //   };
    case LOGGED_OUT:
      return {
        ...state,
        id: '',
        level: '',
        is_agent: '',
        message: '',
        token: '',
        name: '',
        impersonificate: {}
      };
    case SET_USER:
      return {
        ...state,
        id: action.user.id,
        level: action.user.level,
        is_agent: action.user.is_agent,
        message: '',
        token: action.user.token,
        name: action.user.name,
        impersonificate: action.user.impersonificate || {}
      };
    default:
      return state;
  }
}
