import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { 
  getInventoryList, 
  getProductVariantList, 
  editInventory,
  removeItems,
  setSnack
} from '../actions';

import { IMG_URL } from '../config';

import Table from '../components/Table';
import RSelect from '../components/ReactSelect';
import LoadingPage from '../components/LoadingPage';
import PageTitle from '../components/PageTitle';

import {
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField
} from '@material-ui/core';

import {
  Image as ImageIcon,
  Add as PlusIcon,
  Remove as MinusIcon
} from '@material-ui/icons';


class InventarioLista extends React.Component {

  constructor(props) {
    super(props)
    this.loading = true;
    this.state = {
      openDialog: false,
      action: '',
      variants: [],
      selected: {
        id: 0,
        variant: null,
        quantity: 0
      }
    };
    this.labels = [
      {
        value: "id",
        //numeric: true
      },
      {
        value: "product_image",
        label: "Foto",
        type: "img",
        unsortable: true
        //param: "immagine"
      },
      {
        value: "product_name",
        label: "Titolo",
        //param: "nome"
      },
      {
        value: "slug",
        label: "variante",
        unsortable: true
      },
      {
        value: "quantity",
        label: "Quantità",
        //hideForLevel: ['agent', 'pos']
      }
    ]
  }

  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    }
  }

  componentDidMount() {
    const { getInventoryList, getProductVariantList, pagination } = this.props;
    getInventoryList({perPage:pagination.per_page, page: pagination.current_page}).then(()=>{
      return this.loading = false
    }).catch(()=>{})

    getProductVariantList()
      .then((response)=>{ 
        let options = []
        
        response.map((s,i) => {
          options = options.concat({
            value: s.id,
            label: s.product.nome + `${s.slug !== '' ? ' - ' + s.slug : ''}`,
            immagine: s.product.immagine
          })
        })
        this.setState({
          variants: options
        })
      })
      .catch(()=>{
        console.log('error 500')
      })
  }
  
  handleCustomAdd() {
    this.setState({ 
      openDialog: true,
      action: 'add',
      selected: {
        id: 0,
        variant: null,
        quantity: 1
      } 
    })
  }

  handleCustomEdit(id) {
    let selected = this.props.inventory.data.filter((variant)=> {
      return variant.id === id 
    })

    this.setState({ 
      openDialog: true,
      action: 'edit',
      selected: {
        id: selected[0].id,
        variant: {
          id: selected[0].product_variant_id,
          label: selected[0].product_name,//product.nome + `${selected[0].product_variant.slug !== '' ? ' - ' + selected[0].product_variant.slug : ''}`,
          immagine: selected[0].product_image//variant.product.immagine
        },
        quantity: selected[0].quantity
      }
    })
  }

  handleCancelDialog() {
    this.setState({ 
      openDialog: false,
      //action: ''
    })
  }

  handleConfirmDialog() {
    if(this.state.selected.id || this.state.selected.variant) {
      let cb
      if(this.state.action === 'add') {
        // create
        cb = this.props.editInventory(this.state.selected.variant.id, this.state.selected.quantity, true)
      }
      else {
        //  edit
        cb = this.props.editInventory(this.state.selected.id, this.state.selected.quantity)
      }
      
      cb.then(() => {
        this.props.getInventoryList({perPage: this.props.pagination.per_page, page: this.props.pagination.current_page})
        .then((response)=>{
          this.setState({ 
            openDialog: false
          })
          this.props.setSnack(true,'Prodotto aggiunto con successo', 'success')
        }).catch(()=> {})
      }).catch(()=> { 
        this.setState({ 
          openDialog: false
        })
        this.props.setSnack(true,'Non è stato possibile aggiungere il prodotto','warning') 
      })
    }
  }

  handleChangeQty(e) {
    const key = e.target.name.split('-')
    //console.log(e.target.value, parseInt(e.target.value))
    //if(parseInt(e.target.value) > 0) {
      const val = parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : ''
      this.setState(state => {
        return {
          ...state,
          selected: {
            ...state.selected,
            quantity: val
          }
        }
      })
    //}
  }

  handleCounterQty(val) {
    if(val >= 0) {
      this.setState(state => {
        return {
          ...state,
          selected: {
            ...state.selected,
            quantity: val
          }
        }
      })
    }
  }

  handleRemove(data) {
    this.props.removeItems(data,'inventory').then(response => {
      if(response.status === 200) {
        this.props.setSnack(true,'Prodotto rimosso con successo', 'success')
        this.props.getInventoryList({})
      }
    }).catch((error)=>{
      this.props.setSnack(true,'Non è stato possibile rimuovere il prodotto','warning') 
    })
  }
  
  render() {
    const { classes, loading } = this.props
    const { selected } = this.state

    return (
      <React.Fragment>
        { loading && <LoadingPage /> }
        <PageTitle 
          title="Magazzino"
          breadcrumbs={['W-SHOP','MAGAZZINO']}
          //handleBackClick={()=>history.push('/prodotti')}
          classes={classes}
        />
        <Paper className={this.props.classes.root} elevation={2}>
          <Table 
            slug="inventory"
            tableTitle="Magazzino"
            location={this.props.location}
            choises={this.props.inventory.data}
            pagination={this.props.pagination}
            labels={this.labels} 
            paginate={({perPage,page,orderBy,order,search}) => this.props.getInventoryList({perPage,page,orderBy,order,search})}
            userLevel={this.props.user.level}
            canAdd={['admin','operator','pos']}
            canEdit={['admin','operator','pos']}
            hasView={false}
            canRemove={true}
            removeItems={(data) => this.handleRemove(data)}
            customAdd={()=>this.handleCustomAdd()}
            customEdit={(id)=>this.handleCustomEdit(id)}
          />
        </Paper>
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCancel}
          aria-labelledby="conferma rimozione ordine"
          aria-describedby="sei sicuro di voler rimuovere questo ordine"
          maxWidth={'lg'}
          fullWidth={true}
        >
          <DialogTitle id="confirm-remove-order">
            { this.state.action === 'add' && 'Aggiungi prodotto' }
            { this.state.action === 'edit' && 'Modifica quantità' }
          </DialogTitle>
          <DialogContent>
          { loading && <LoadingPage light absolute /> }
            <Grid container >
              <Grid item xs={4} >
              <div className={this.props.classes.previewContainer}>
                  {
                    selected.variant && selected.variant.immagine !== '' && 
                    <img id="preview_image" src={IMG_URL +selected.variant.immagine} width='100%' alt="preview img prodotto"/> ||
                    <React.Fragment>
                      <ImageIcon />
                      <span>Foto prodotto</span>
                    </React.Fragment>
                  }
                </div>
              </Grid>
              <Grid item xs={8} >
                <Grid container >
                  <Grid item xs={12} >
                    <RSelect
                      id="input-product-inventory"
                      name="product" 
                      options={this.state.variants}
                      value={selected.variant}
                      disabled={this.state.action === 'edit'}
                      setChange={(event) => {
                        const variant = event ? 
                          {
                            id: event && event.value,
                            label: event && event.label,
                            immagine: event && event.immagine
                          } : null
                        this.setState(
                          (state) => ({
                            ...state,
                            selected: {
                              ...state.selected,
                              variant: variant
                            }
                          })
                        )
                      }}
                      label="Prodotti"
                      //error={props.errors.supplier !== undefined}
                      placeholder="Seleziona un prodotto"
                    />
                  </Grid>
                  <Grid item xs={12} style={{position: 'relative', marginTop: '40px'}}>
                    <div className={classes.cartQtnBox}>
                      <MinusIcon className={selected.quantita === 1 ? classes.disable: ''} 
                                onClick={()=>this.handleCounterQty(selected.quantity-1)} />
                        <TextField
                          type="text"
                          label="Quantità" 
                          error={selected.quantity < 0}
                          value={selected.quantity} 
                          onChange={(e)=>this.handleChangeQty(e)}
                        />
                        <PlusIcon onClick={()=>this.handleCounterQty(selected.quantity+1)} />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleCancelDialog()} color="primary">
              Cancella
            </Button>
            <Button 
              onClick={() => this.handleConfirmDialog()} 
              color="primary" 
              autoFocus
              disabled={selected.quantity < 0 || selected.variant === null}
            >
              Continua
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

InventarioLista.propTypes = {
  classes: PropTypes.object.isRequired,
  inventory: PropTypes.object.isRequired,
  getInventoryList: PropTypes.func.isRequired,
  getProductVariantList: PropTypes.func.isRequired,
  editInventory: PropTypes.func.isRequired,
  setSnack: PropTypes.func,
  removeItems: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  inventory: state.inventory.list,
  user: state.user,
  pagination: state.info.pagination,
  loading: state.info.loading
});

export default withRouter(
  connect(mapStateToProps, {
    getInventoryList,
    getProductVariantList,
    editInventory,
    removeItems,
    setSnack
  })(InventarioLista)
);
