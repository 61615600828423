import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setUser, logout } from './actions';
import MenuAppBar from './components/MenuAppBar';
import Footer from './components/Footer';
import { withStyles } from '@material-ui/core/styles';
import { routes } from './routes'
import layoutStyle from './layoutStyle'
import PrivateRoute from './components/PrivateRoute'

const styles = theme => (layoutStyle(theme));

class Layout extends React.Component {

  
  render() {
    return (
      <>
        {
          this.props.user && this.props.user.token !== '' && 
          <MenuAppBar logout={this.props.logout} />
        }
        <Switch>
          {
            routes.map( (route,key) => {
              const { component: Component, path, exact, isPublic} = route;
              
              return (
                isPublic ? 
                  <Route
                    exact={exact}
                    path={path}
                    key={key}
                    render={ () => <Component classes={this.props.classes} />}
                  /> 
                  : 
                  <PrivateRoute
                    exact={exact}
                    path={path}
                    key={key}
                    component={Component}
                    props={{classes: this.props.classes}}
                  />
                )
              }
            )
          }
        </Switch>
        {
          this.props.user && this.props.user.token !== '' && 
          <Footer />
        }
      </>
    )
  }
}

const mapStateToProps = (state, props) => ({
  user: state.user,
});

export default withRouter(
  connect(mapStateToProps, {
    setUser,
    logout
  })(withStyles(styles)(Layout))
)