import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import update from 'react-addons-update';
import { FormattedNumber, FormattedDate } from 'react-intl';

import { 
  getAllOrders, 
  getClientsByOrders, 
  putOrderById, 
  removeItems, 
  getOrderPdfById, 
  uploadPaymentConfirmation, 
  rejectPaymentOrder,
  setSnack, 
} from '../actions';

import { STATUS_PYM } from '../config'

import {
  Paper,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  ExpansionPanelActions,
  Grid,
  GridList,
  GridListTile,
  IconButton,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Divider,
  FormHelperText,
  FormControlLabel,
  Switch,
  Input,
  TableSortLabel,
  TablePagination
} from '@material-ui/core';

import {
  Delete as DeleteIcon,
  Clear as ClearIcon,
  Add as PlusIcon,
  Remove as MinusIcon,
  ExpandMore as ExpandMoreIcon,
  ArrowUpward as ArrowUpIcon,
  ArrowDownward as ArrowDownIcon
} from '@material-ui/icons';
//import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DatePickerUI from '../components/DatePickerUI'
import RSelect from '../components/ReactSelect';
import LoadingPage from '../components/LoadingPage'

class OrdiniLista extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      orderNames: [],
      orderOld: {
        id: -1,
        data: {}
      },
      sortBy: '-id',
      openDialog: false,
      dialogDelivery: false,
      dialogFilterByDate: false,
      itemSelected: {},
      filterByPosId: '',
      filterByStatus: '',
      filterByDate: {
        startDate: '',
        endDate: ''
      },
      filterByDateTemp: {
        startDate: '',
        endDate: ''
      },
      showCancelled: false,
      expandedPanel: -1
    }
  }

  componentDidMount() {
    //setTimeout(()=>{
      const { getAllOrders, getClientsByOrders, user } = this.props
      const { filterByDate, filterByPosId, filterByStatus, sortBy, sortOrder } = this.state
      getAllOrders({
        id: user.level, 
        status: filterByStatus.value, 
        posId: filterByPosId.value, 
        rangeDate: filterByDate.rangeDate,
        orderBy: sortBy
      })
      .then((response) => {
        this.setState({
          orders: response
        })
        if(user.level === 'admin') {
          getClientsByOrders().then((response) => {
            let options = []
            response.map((s,i) => {
              options = options.concat({
                value: s.id,
                label: s.denominazione//,
                //sub_label: s.full_name
              })
            })
            this.setState({
              orderNames: options,
            })
          }).catch((error)=>{})
        }
      })
      .catch(() => {})
      
    //},1000)
  }

  handleSort(sortBy) {
    const oldOrderBy = this.state.sortBy
    let orderBy = sortBy
    
    if( oldOrderBy.replace(/\-/g, "") === sortBy && !oldOrderBy.startsWith('-') ) {
      orderBy = '-'+orderBy
    }
    

    this.props.getAllOrders({
      id: this.props.user.level, 
      status: this.state.filterByStatus.value, 
      posId: this.state.filterByPosId.value, 
      rangeDate: this.state.filterByDate.rangeDate,
      orderBy: orderBy
    })
    .then((response) => {
      this.setState({
        orders: response.orders,
        sortBy: orderBy
      })
    })
    .catch(() => {})
  }
  
  setPanel(key) {
    this.setState(state =>{
      if(state.expandedPanel === key) {
        key = -1
      }
      return {
        ...state,
        expandedPanel: key
      }
    })
  }
  isExpanded (key) {    
    if(this.state.expandedPanel === key) 
      return true
    else 
      return false
  }
  handleRemove() {
    const { filterByDate, filterByPosId, filterByStatus } = this.state
    this.props.removeItems({},'orders',this.state.itemSelected.id).then(response => {
      if(response.status === 200) {
        //this.props.getAllOrders({id:'user/'+this.props.user.id}).then((response)=>{
        this.props.setSnack(true,'Prodotto rimosso con successo','success')
        this.setState({
          openDialog: false,
          itemSelected: {},
          expandedPanel: -1
        })
        this.props.getAllOrders({
          id: this.props.user.level, 
          status: filterByStatus ? filterByStatus.value : '', 
          posId: filterByPosId ? filterByPosId.value : '', 
          rangeDate: filterByDate.rangeDate
        })
        .then((response)=>{
          this.setState({
            orders: response
          })
        })
        .catch((error)=>{})
      }
    }).catch((error)=>{})
  }

  handleEdit({event, key, order}) {
    event && event.stopPropagation()
    const newData = update(this.state.orders[key], {});
    this.setState({
      orderOld: {
        id: key,
        data: newData
      }
    })
  }

  handleReset({event, key, dialog}) {
    event && event.stopPropagation()
    let newOrders
    this.setState(state => {
      if(state.orderOld.id !== -1) {
        newOrders = update(state.orders, {
          [key]: {$set: state.orderOld.data}
        });
      }

      return {
        orders: newOrders || state.orders ,
        orderOld: {
          id: -1,
          data: {},
        },
        itemSelected: {},
        [dialog]: false
      }
    })
  }

  uploadPaymentMethod({userId, orderId, document}) {
    this.props.uploadPaymentConfirmation({userId, orderId, document})
    .then((response)=>{
      this.setState(state => {
        this.props.setSnack(true,'Upload eseguito con successo!','success')
        let newOrders = []
        state.orders.map(el => {
          newOrders = newOrders.concat(el.id === orderId ? response : el)
        })
        
        return {
          orders: newOrders
        }
      })
    })
  }

  setCounter({event,key,productKey,counter}) {
    event && event.stopPropagation()
    this.setState(state => {
      const oldQtyTot = state.orders[key].quantita
      const oldQtyVar = state.orders[key].variants[productKey].pivot.quantita
      
      const newOrders = update(state.orders, {
        [key]: {
          quantita: {$set: oldQtyTot + counter}, 
          variants: {
            [productKey]:{
              pivot:{
                quantita:{$set: oldQtyVar + counter}
              }
            }
          }
        },
      });
      return {
        orders: newOrders
      }
    })
  }
  handleRemoveProduct() {
    this.setState(state => {
      const newOrders = update(state.orders, {
        [state.itemSelected.key]: {
          variants: {$splice: [[state.itemSelected.productKey,1]]}
        }
      }); 
      
      return {
        orders: newOrders,
        openDialog: false,
        itemSelected: ''
      }
    })
  }

  handleSave({event, key, dialog}) {
    event && event.stopPropagation()
    let order = this.state.orders[key]
    let variants = []
    order.variants.map((o,i)=>{
      variants.push({
        id: o.id,
        quantita: o.pivot.quantita
      })
    })
    const data = {
      metodo_pagamento: order.metodo_pagamento,
      status: order.status,
      prodotti: variants,
      data_consegna: this.state.itemSelected.data_consegna
    }
    this.props.putOrderById({id: order.id,data: data }).then((response)=>{
      
      if(response.status === 200) {
        this.setState(state => {
          const newOrders = update(state.orders, {
            [key]: {$set:response.data.data}
          });
          return {
            orders: newOrders,
            orderOld: {
              id: -1,
              data: {}
            },
            [dialog]: false
          }
        })
        this.props.setSnack(true,'Ordine aggiornato con successo','success')
      }
    }).catch((err) => {
      this.props.setSnack(true,"Errore nell'aggiornare l'ordine",'warning')
    })
  }

  handleOpenDialog({event,item, id, key, productKey,dialog='openDialog',cb}) {
    event && event.stopPropagation()
    this.setState({
      [dialog]: true,
      itemSelected: {
        item: item,
        id: id,
        key: key,
        productKey: productKey
      }
    })
    cb && cb()
  }

  handleCancel({dialog='openDialog'}) {
    this.setState({
      [dialog]: false,
      itemSelected: {}
    })
  }
  
  handleConfirm() {
    let promise
    if(this.state.itemSelected.item === 'ordine') {
      promise = this.handleRemove()
    }
    else {
      promise = this.handleRemoveProduct()
    }
  }

  getStatus(status) {
    switch(status) {
      case STATUS_PYM['waiting']:
        return 'red'
      case STATUS_PYM['processing']:
        return 'green'
      case STATUS_PYM['preparing']:
        return 'orange'
      case STATUS_PYM['completed']:
        return 'grey';
    }
  }

  setOrderStatus = (e,id,key,value) => {
    if(value === STATUS_PYM['completed']) {
      this.handleOpenDialog({
        event: e,
        id: id, 
        key: key, 
        item: 'ordine',
        dialog:'dialogDelivery',
      })
      return false
    }
    
    this.setState(state => {
      const newOrders = update(state.orders, {
        [key]: {
          status: {$set:value}
        }
      });
      return {
        orders: newOrders
      }
    })
  }

  handleDate = (date) => {
    if(date) {
      const d = date.split('/')
      return new Date(d[2],d[1]-1,d[0])
    }
    else return null
  }

  handleSubmitDelivery(key) {
    if(this.state.itemSelected.data_consegna) {
      if(this.state.orderOld.data.is_editable) {
        this.setState(state => {
          const newOrders = update(state.orders, {
            [key]: {
              status: {$set:'Completato'}
            }
          });
          return {
            orders: newOrders,
            ['dialogDelivery']: false
          }
        })
      }
      else {
        this.handleSave({event:null,key:key,dialog:'dialogDelivery'})
      }
      
    } 
    else {
      this.setState((state)=>{
        return {
          itemSelected: {
            ...state.itemSelected,
            error: 'Campo obbligatorio'
          }
        }
      })
    }
  }

  cleanFilterByDate() {
    const { filterByPosId, filterByStatus } = this.state

    
    this.props.getAllOrders({
      id: this.props.user.level, 
      status: filterByStatus ? filterByStatus.value : '', 
      posId: filterByPosId ? filterByPosId.value : '', 
      rangeDate: ''
    })
    .then((response) => {      
      this.setState({
        orders: response,
        filterByDate: {
          startDate: '',
          endDate: '',
          rangeDate: ''
        }
      })
    })
  }

  getRangeDateFromObj(dateObj) {
    const splitStartDate = dateObj.startDate !== '' ? dateObj.startDate.split('/') : ['','','']
    const splitEndDate = dateObj.endDate !== '' ? dateObj.endDate.split('/') : ['','','']
    
    return `${splitStartDate[2]}-${splitStartDate[1]}-${splitStartDate[0]},${splitEndDate[2]}-${splitEndDate[1]}-${splitEndDate[0]}`
  }

  handleSubmitFilterByDate() {
    const { filterByPosId, filterByStatus } = this.state
    const rangeDate = this.getRangeDateFromObj(this.state.filterByDateTemp)
    this.props.getAllOrders({
      id: this.props.user.level, 
      rangeDate, 
      posId: filterByPosId ? filterByPosId.value : '', 
      status: filterByStatus ? filterByStatus.value : ''
    })
    .then((response)=>{
      this.setState((state) =>{
        return {
          orders: response,
          filterByDate: state.filterByDateTemp,
          ['dialogFilterByDate']: false
        }
      })
    })
    .catch((error)=>{})
    
  }

  handleChangePage = (event, page) => {
    this.props.getAllOrders({
      id: this.props.user.level, 
      status: this.state.filterByStatus.value, 
      posId: this.state.filterByPosId.value, 
      rangeDate: this.state.filterByDate.rangeDate,
      orderBy: this.state.sortBy,
      page: page+1
    })
    .then((response) => {
      this.setState({
        orders: response,
        sortBy: this.state.sortBy
      })
    })
    .catch(() => {})

    // this.props.paginate({
    //   perPage:this.state.perPage,
    //   page:page+1})
    //   .then(()=>{
    //     this.setState({ page, selected: [] });
    //   }).catch((error)=>{})
  };


  render() {
    const { classes, user, getAllOrders, pagination, loading } = this.props
    const { 
      orders, 
      orderOld, 
      filterByDate, 
      filterByPosId, 
      filterByStatus, 
      showCancelled,
      sortBy
    } = this.state

    return (
      <React.Fragment>
        {
          loading ? <LoadingPage /> : 
          <div>
            <div className={classes.navigationBar}>
              <div className={classes.title} ><span>Ordini</span></div>
              <div className={classes.actions}>
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showCancelled}
                        onChange={ (e, checked) => {
                          this.setState({
                            showCancelled: checked,
                          })
                          getAllOrders({
                            id: user.level, 
                            status: filterByStatus.value, 
                            posId: filterByPosId.value, 
                            rangeDate: filterByDate.rangeDate, 
                            trashed: checked,
                          })
                          .then((response)=>{
                            this.setState({
                              orders: response,
                              showCancelled: checked
                            })
                          })
                          .catch((error)=>{})
                        }}
                        value="cancelled"
                        color="primary"
                      />
                    }
                    label="Visualizza ordini cancellati"
                  />
                </div>
                {user.level === 'admin' && 
                  <React.Fragment>
                    <div className={classes.filterSelect}>
                      <RSelect
                        id="input-order_name"
                        name="Nome" 
                        options={this.state.orderNames}
                        value={this.state.filterByPosId}
                        setChange={(event) => {
                          const posId = event && event.value || ''
                          getAllOrders({
                            posId, 
                            id: user.level, 
                            status: filterByStatus ? filterByStatus.value : '', 
                            rangeDate: filterByDate.rangeDate,
                            //page: page+1
                          })
                          .then((response)=>{
                            this.setState({
                              orders: response,
                              filterByPosId: event || ""
                            })
                          })
                          .catch((error)=>{})
                        }}
                        //label="Filtra nome*"
                        //error={props.errors.supplier !== undefined}
                        placeholder="Filtra per Nome"
                      />
                    </div>
                    <div className={classes.filterSelect}>
                      <RSelect
                        //native
                        options={[
                          { value: STATUS_PYM['completed'], label: 'Completato' },
                          { value: STATUS_PYM['preparing'], label: 'In elaborazione' },
                          { value: STATUS_PYM['processing'], label: 'In processamento' }
                        ]}
                        placeholder="Filtra per Stato"
                        value={filterByStatus}
                        setChange={(event) => {
                          const status = event && event.value || ''
                          getAllOrders({
                            id: user.level, 
                            status: status, 
                            rangeDate: filterByDate.rangeDate, 
                            posId: filterByPosId ? filterByPosId.value : ''
                          })
                          .then((response)=>{
                            this.setState({
                              orders: response,
                              filterByStatus: event || ""
                            })
                          })
                          .catch((error)=>{})
                        }}
                      />
                    </div>
                    <div className={classes.filterFlex}>
                      <Button 
                        color="primary" 
                        onClick={()=> this.setState({'dialogFilterByDate': true}) }>Filtra data 
                      </Button>
                        {this.state.filterByDate.startDate && this.state.filterByDate.endDate 
                        ? <span className={classes.filterRangeDate}>{` da: ${this.state.filterByDate.startDate} a ${this.state.filterByDate.endDate}`} <ClearIcon color="error" style={{ fontSize: 21 }} onClick={()=>this.cleanFilterByDate()} /></span>
                        : ''}
                    </div>
                  </React.Fragment>
                }
                <div className={classes.breadCrumbs}>
                  <span>W-SHOP > ORDINI</span>
                </div>
              </div>
            </div>
            <Paper className={classes.root} elevation={2}>
              {orders.length > 0 && 
                <React.Fragment>
                  <div className={classes.gridHeader}>
                    <GridList spacing={2} cols={10}>
                      <GridListTile className={classes.fieldTh} cols={1} >
                        <TableSortLabel
                          active={sortBy.replace(/\-/g, "") === 'id'}
                          direction={sortBy.startsWith('-') ? 'desc' : 'asc'}
                          onClick={() => this.handleSort('id')}
                        >
                          <span>Ordine</span>
                        </TableSortLabel>
                      </GridListTile>
                      <GridListTile className={classes.fieldTh} cols={2} >
                        <TableSortLabel
                          active={sortBy.replace(/\-/g, "") === 'pos'}
                          direction={sortBy.startsWith('-') ? 'desc' : 'asc'}
                          onClick={() => this.handleSort('pos')}
                        >
                          <span>Nome Cliente</span>
                        </TableSortLabel>
                      </GridListTile>
                      <GridListTile className={classes.fieldTh} cols={1} >
                        <TableSortLabel
                          active={sortBy.replace(/\-/g, "") === 'prezzo_totale'}
                          direction={sortBy.startsWith('-') ? 'desc' : 'asc'}
                          onClick={() => this.handleSort('prezzo_totale')}
                        >
                          <span>Totale</span>
                        </TableSortLabel>
                        <span></span>
                      </GridListTile>
                      <GridListTile className={classes.fieldTh} cols={1} >
                        <TableSortLabel
                          active={sortBy.replace(/\-/g, "") === 'quantita'}
                          direction={sortBy.startsWith('-') ? 'desc' : 'asc'}
                          onClick={() => this.handleSort('quantita')}
                        >
                          <span>Quantità</span>
                        </TableSortLabel>
                      </GridListTile>
                      <GridListTile className={classes.fieldTh} cols={2} >
                        <TableSortLabel
                          active={sortBy.replace(/\-/g, "") === 'status'}
                          direction={sortBy.startsWith('-') ? 'desc' : 'asc'}
                          onClick={() => this.handleSort('status')}
                        >
                          <span>Stato</span>
                        </TableSortLabel>
                      </GridListTile>
                      <GridListTile className={classes.fieldTh} cols={1} >
                        <TableSortLabel
                          active={sortBy.replace(/\-/g, "") === 'created_at'}
                          direction={sortBy.startsWith('-') ? 'desc' : 'asc'}
                          onClick={() => this.handleSort('created_at')}
                        >
                          <span>Creazione</span>
                        </TableSortLabel>
                      </GridListTile>
                      <GridListTile className={classes.fieldTh} cols={1} >
                        <TableSortLabel
                          active={sortBy.replace(/\-/g, "") === 'updated_at'}
                          direction={sortBy.startsWith('-') ? 'desc' : 'asc'}
                          onClick={() => this.handleSort('updated_at')}
                        >
                          <span>Aggiornato</span>
                        </TableSortLabel>
                      </GridListTile>
                      <GridListTile className={classes.fieldTh} cols={1} >
                        <TableSortLabel
                          active={sortBy.replace(/\-/g, "") === 'data_consegna'}
                          direction={sortBy.startsWith('-') ? 'desc' : 'asc'}
                          onClick={() => this.handleSort('data_consegna')}
                        >
                          <span>Consegna</span>
                        </TableSortLabel>
                      </GridListTile>
                    </GridList>
                  </div>
                  {orders.map((o,i)=> (
                    <ExpansionPanel 
                      key={i} 
                      expanded={this.isExpanded(i)} 
                      onClick={()=>{this.setPanel(i)}}
                      //className={o.status === 'In attesa di pagamento' ? classes.warningDiv : ''}
                    >
                      <ExpansionPanelSummary 
                        classes={{content: classes.marginX20}} 
                        expandIcon={<ExpandMoreIcon />}
                        className={o.status === STATUS_PYM['waiting'] ? classes.warningBG : ''}
                      >
                        <GridList className={classes.gridOrder} cols={10}>
                          <GridListTile className={classes.field} cols={1} ><span>{o.id}</span></GridListTile>
                          <GridListTile className={classes.field} cols={2} >
                            <span>
                              {user.level === 'admin' ? <Link to={`/cliente/${o.pos_id}`}>{o.pos_denominazione}</Link> : o.pos_denominazione }
                            </span>
                          </GridListTile>
                          <GridListTile className={classes.field} cols={1} > <FormattedNumber value={o.prezzo_con_iva} style="currency" currency="EUR" /></GridListTile>
                          <GridListTile className={classes.field} cols={1} ><span>{o.quantita}</span></GridListTile>
                          <GridListTile className={classes.field} cols={2} >
                            {o.is_editable.status && orderOld.id === i &&
                              <Select
                                native
                                value={o.status}
                                onClick={(event)=>{
                                  event.stopPropagation()
                                }}
                                onChange={(event) => {
                                  this.setOrderStatus(event, o.id,i,event.target.value)
                                }}
                              >
                                <option value="" hidden />
                                <option value={STATUS_PYM['preparing']}>In elaborazione</option>
                                <option value={STATUS_PYM['processing']}>In processamento</option>
                                <option value={STATUS_PYM['completed']}>Completato</option>
                              </Select> ||
                              <span className={`${classes.status} ${this.getStatus(o.status)} `}>{o.status}</span> 
                            }
                          </GridListTile>
                          <GridListTile className={classes.field} cols={1} >
                            <span>{o.created_at}</span>
                          </GridListTile>
                          <GridListTile className={classes.field} cols={1} >
                            <span>
                              <FormattedDate
                                value={new Date(o.updated_at)}
                                day="2-digit"
                                month="2-digit"
                                year="numeric" />
                            </span>
                          </GridListTile>
                          <GridListTile className={classes.field} cols={1} >
                            <span>{o.data_consegna ||
                              this.state.itemSelected.id === o.id && this.state.itemSelected.data_consegna && 
                              <span className={classes.previewValue}> {this.state.itemSelected.data_consegna} </span> 
                              || '--' }
                            </span>
                          </GridListTile>
                        </GridList>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails className={classes.gridOrderDetails}>
                        <Grid container>
                          <Grid item xs={3} className={classes.fieldTh} ><span>Nome prodotto</span></Grid>
                          <Grid item xs={2} className={classes.fieldTh} ><span>Variante</span></Grid>
                          <Grid item xs={2} className={classes.fieldTh} ><span>Categoria</span></Grid>
                          <Grid item xs={2} className={classes.fieldTh} ><span>Prezzo</span></Grid>
                          <Grid item xs={2} className={classes.fieldTh} ><span>Quantità</span></Grid>
                          <Grid item xs={1} className={classes.fieldTh} ></Grid>
                        </Grid>
                        {o.variants.map((p,y)=>(
                          <Grid container className={classes.containerTr} key={y}>
                            <Grid item xs={3} className={classes.fieldTr} ><span>{p.product.nome}</span></Grid>
                            <Grid item xs={2} className={classes.fieldTr} ><span>{p.slug ? p.slug : '-'}</span></Grid>
                            <Grid item xs={2} className={classes.fieldTr} ><span>{p.product.category.name}</span></Grid>
                            <Grid item xs={2} className={classes.fieldTr} ><span><FormattedNumber value={p.pivot.prezzo_totale_con_iva} style="currency" currency="EUR" /></span></Grid>
                            <Grid item xs={2} className={classes.fieldTr} >
                              {o.is_editable.quantity && orderOld.id === i && 
                                <IconButton classes={{root: classes.minusButton }} className={p.pivot.quantita === 1 ? classes.disable : ''} onClick={(e)=>this.setCounter({event:e, key:i,productKey:y,counter:-1})} ><MinusIcon /></IconButton>
                              }
                              <span>{p.pivot.quantita}</span>
                              {o.is_editable.quantity && orderOld.id === i && 
                                <IconButton classes={{root: classes.plusButton}} onClick={(e)=>this.setCounter({event:e, key:i,productKey:y,counter:1})} ><PlusIcon /></IconButton>
                              }
                            </Grid>
                            <Grid item xs={1} align="right">
                              {o.is_editable.item && orderOld.id === i && <IconButton onClick={(e)=>this.handleOpenDialog({event:e, key:i, productKey: y,item: 'prodotto'})} ><DeleteIcon /></IconButton>}
                            </Grid>
                          </Grid>
                        ))}
                      </ExpansionPanelDetails>
                      <Divider />
                      <ExpansionPanelActions>
                        { o.is_editable.date && orderOld.id !== i && 
                          <Button 
                            size="small" 
                            color="primary" 
                            variant="contained" 
                            onClick={(e)=>this.handleOpenDialog({
                              event: e,
                              id: o.id, 
                              key: i, 
                              item: 'ordine',
                              dialog:'dialogDelivery',
                              // cb: this.handleEdit({
                              //   event:e,
                              //   key:i
                              // })
                            })
                          }>Scegli data consegna</Button>}
                        {orderOld.id !== i && orders[i].status === STATUS_PYM['waiting'] && 
                        <form noValidate encType="multipart/form-data">
                          <Input
                            accept="*"
                            //name="product.immagine"
                            //valeu={props.values.product.immagine}
                            onChange={(event) => {
                              this.uploadPaymentMethod({userId: o.pos_id, orderId: o.id, document: event.currentTarget.files[0]})
                            }}
                            className={this.props.classes.inputHidden}
                            id={`ordine_file_${i}`}
                            type="file"
                          />
                          <label htmlFor={`ordine_file_${i}`}>
                            <Button 
                              component="span"
                              size="small" 
                              onClick={(e)=>{ 
                                e.stopPropagation(); 
                                //this.props.uploadPaymentConfirmation({userId: o.pos_id, orderId: o.id})
                              }}
                            >
                              carica conferma pagamento
                            </Button>
                          </label>
                          </form>
                        }
                        {orderOld.id !== i && orders[i].document && 
                          <Button 
                            size="small" 
                            onClick={(e)=>{ e.stopPropagation(); this.props.getOrderPdfById({userId: o.pos_id, orderId: o.id, doc: true, fileName: o.document})} }
                          >
                            visualizza pagamento
                          </Button>
                        }
                        {orderOld.id !== i && orders[i].document && orders[i].status === 'In elaborazione' && (user.level === 'admin' || user.level === 'operator') &&
                          <Button 
                            size="small" 
                            onClick={(e)=>{ 
                              //e.stopPropagation(); 
                              this.props.rejectPaymentOrder({orderId: o.id})
                                .then(()=>{
                                  this.props.getAllOrders({
                                    id: this.props.user.level, 
                                    status: filterByStatus ? filterByStatus.value : '', 
                                    posId: filterByPosId ? filterByPosId.value : '', 
                                    rangeDate: filterByDate.rangeDate
                                  })
                                  .then((response)=>{
                                    this.setState({
                                      orders: response
                                    })
                                    this.props.setSnack(true,'Conferma pagamento rifiutato con successo','success')
                                  })
                                  .catch((error)=>{})
                                })
                            }}
                          >
                            rifiuta conferma pagamento
                          </Button>
                        }
                        {orderOld.id !== i && 
                          <Button size="small" onClick={(e)=>{ e.stopPropagation(); this.props.getOrderPdfById({userId: o.pos_id, orderId: o.id})} }>
                            scarica ordine
                          </Button>
                        }
                        {o.is_editable.enabled && orderOld.id !== i && <Button size="small" onClick={(e)=>this.handleEdit({event:e,key:i,order:o})}>Modifica</Button>}
                        {o.deletable && orderOld.id !== i && <Button size="small" onClick={(e)=>this.handleOpenDialog({event: e,id: o.id, item: 'ordine'})}>Rimuovi</Button>}
                        {orderOld.id === i && <Button size="small" onClick={(e)=>this.handleReset({event:e,key:i})}>Cancella</Button>}
                        {orderOld.id === i && <Button size="small" color="primary" variant="contained" onClick={(e)=>this.handleSave({event:e,key:i})}>Salva</Button>}
                      </ExpansionPanelActions>
                    </ExpansionPanel>
                  ))}
                  <TablePagination
                    component="div"
                    count={pagination.total || 0}
                    labelRowsPerPage="Numero di righe"
                    //labelRowsPerPage=''
                    rowsPerPageOptions={[10]}
                    rowsPerPage={pagination.per_page}
                    page={pagination.current_page-1}
                    backIconButtonProps={{
                      'aria-label': 'Pagina precedente',
                    }}
                    nextIconButtonProps={{
                      'aria-label': 'Pagina successiva',
                    }}
                    labelDisplayedRows= {({ from, to, count }) => `Pagina ${pagination.current_page} di ${pagination.last_page}`}
                    // rowsPerPageOptions={[100,150,200]}
                    // classes={{ select: classes.paginationSelect }}
                    onChangePage={this.handleChangePage}
                    // onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </React.Fragment> || 
                <p className={classes.emptyContainer}>Nessun ordine</p>
              }
            </Paper>
          </div>
        }
        <Dialog
          open={this.state.openDialog}
          onClose={this.handleCancel}
          aria-labelledby="conferma rimozione ordine"
          aria-describedby="sei sicuro di voler rimuovere questo ordine"
        >
          <DialogTitle id="confirm-remove-order">{`Rimozione ${this.state.itemSelected.item}`}</DialogTitle>
          <DialogContent>
            { loading && <LoadingPage light absolute /> }
            <DialogContentText id="confirm-remove-order-description">
              {`Sei sicuro di voler rimuove questo ${this.state.itemSelected.item}?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleCancel({})} color="primary">
              Cancella
            </Button>
            <Button onClick={() => this.handleConfirm()} color="primary" autoFocus>
              Continua
            </Button>
          </DialogActions>
        </Dialog>
        
        <Dialog
          open={this.state.dialogDelivery}
          //onClose={()=>this.handleCancel('dialogDelivery')}
          aria-labelledby="Seleziona la data presunta di consegna"
          // aria-describedby="sei sicuro di voler rimuovere questo ordine"
        >
          <DialogTitle id="choose-delivery-date">Data di consegna</DialogTitle>
          <DialogContent>
            { loading && <LoadingPage light absolute /> }
            <DialogContentText>
              Scegli la data presunta di consegna dell'ordine:
            </DialogContentText>
            <DatePickerUI
              label="Scegli data"
              format="dd/MM/yyyy"
              classes={ this.props.classes.formControl }
              value={this.handleDate(this.state.itemSelected.data_consegna)}
              //error={this.state.itemSelected.error}
              onChange={(value) => {
                this.setState(state => {
                  return {
                    itemSelected: {
                      ...state.itemSelected,
                      data_consegna: value,
                      error: undefined
                    }
                  }
                })
              }}
            />
            {this.state.itemSelected.error && <FormHelperText error margin='dense'>{this.state.itemSelected.error}</FormHelperText>}
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={() => this.handleReset({event:null, key:this.state.itemSelected.key, dialog:'dialogDelivery'})} color="primary"> */}
            <Button onClick={() => this.handleCancel({dialog:'dialogDelivery'})} color="primary">
              Cancella
            </Button>
            <Button onClick={() => this.handleSubmitDelivery(this.state.itemSelected.key)} color="primary" autoFocus>
              Continua
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogFilterByDate}
          //onClose={()=>this.handleCancel('dialogDelivery')}
          aria-labelledby="Seleziona un range di date da filtrare"
          // aria-describedby="sei sicuro di voler rimuovere questo ordine"
        >
          <DialogTitle>Filtro per data</DialogTitle>
          <DialogContent>
            { loading && <LoadingPage light absolute /> }
            <DialogContentText>Seleziona un range di date da filtrare:</DialogContentText>
            <DatePickerUI
              label="Data iniziale"
              format="dd/MM/yyyy"
              classes={ this.props.classes.formControl }
              value={this.handleDate(this.state.filterByDateTemp.startDate)}
              disablePast={false}
              onChange={(value) => {
                this.setState(state => {
                  return {
                    filterByDateTemp: {
                      ...state.filterByDateTemp,
                      startDate: value,
                      rangeDate: this.getRangeDateFromObj({startDate: value, endDate: state.filterByDateTemp.endDate})
                    }
                  }
                })
              }}
            />
            <DatePickerUI
              label="Data finale"
              format="dd/MM/yyyy"
              classes={ this.props.classes.formControl }
              value={this.handleDate(this.state.filterByDateTemp.endDate)}
              disablePast={false}
              onChange={(value) => {
                this.setState(state => {
                  return {
                    filterByDateTemp: {
                      ...state.filterByDateTemp,
                      endDate: value,
                      rangeDate: this.getRangeDateFromObj({startDate: state.filterByDateTemp.startDate, endDate: value})
                    }
                  }
                })
              }}
            />
            {/* {this.state.itemSelected.error && <FormHelperText error margin='dense'>{this.state.itemSelected.error}</FormHelperText>} */}
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={() => this.handleReset({event:null, key:this.state.itemSelected.key, dialog:'dialogDelivery'})} color="primary"> */}
            <Button onClick={() => { this.handleCancel({ dialog:'dialogFilterByDate' }) } } color="primary">
              Cancella
            </Button>
            <Button onClick={() => this.handleSubmitFilterByDate()} color="primary" autoFocus>
              Continua
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

OrdiniLista.propTypes = {
  orders: PropTypes.object.isRequired,
  getAllOrders: PropTypes.func.isRequired,
  getClientsByOrders: PropTypes.func,
  putOrderById: PropTypes.func.isRequired,
  removeItems: PropTypes.func.isRequired,
  getOrderPdfById: PropTypes.func.isRequired,
  uploadPaymentConfirmation: PropTypes.func.isRequired,
  rejectPaymentOrder: PropTypes.func.isRequired,
  setSnack: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state, props) => ({
  orders: state.orders.list,
  user: state.user,
  pagination: state.info.pagination,
  loading: state.info.loading
});

export default withRouter(
  connect(mapStateToProps, {
    getAllOrders,
    getClientsByOrders,
    putOrderById,
    removeItems,
    getOrderPdfById,
    uploadPaymentConfirmation,
    rejectPaymentOrder,
    setSnack,
  })(OrdiniLista)
);
