import { useEffect } from 'react';
import { connect } from 'formik';

const ErrorFocus = (props) => {
  
  useEffect(() => {
    const { firstItem } = props
    const { isSubmitting, isValidating } = props.formik;

    if(firstItem && isSubmitting && !isValidating) {
      document.getElementById(firstItem) && 
      document.getElementById(firstItem).scrollIntoView({behavior: 'smooth', block: 'center'});
    }

  })

  return null
}

export default connect(ErrorFocus);