import { RETURN_PRODUCTS, GET_PRODUCT_DETAILS, POST_PRODUCT, DELETE_IMAGE, DELETE_PRODUCT } from '../actions';

const initialState = {
  list: {},
  selected: null,
}

export default function products(state = initialState, action) {
  switch (action.type) {
    case RETURN_PRODUCTS:
      return {
        ...state,
        list: action.products,
        selected: null,
      };
    case GET_PRODUCT_DETAILS:
    case POST_PRODUCT: 
      return {
        ...state,
        selected: action.product
      };
    case DELETE_IMAGE:
      return {
        ...state,
        selected: action.product
      }
    default:
    case DELETE_PRODUCT:
      return state;
  }
}
