import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getClientById, loginAs, setClientPsw, setSnack } from '../actions';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FaceIcon from '@material-ui/icons/Face';
import EditIcon from '@material-ui/icons/Edit';
import KeyIcon from '@material-ui/icons/VpnKey';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';

import LoadingPage from '../components/LoadingPage'

const ShowField = props => {
  return (
    <div>
      <label className={props.classes.label}>{props.label}</label>
      <p className={props.classes.parag}>{props.value ? props.value : '---'}</p>
    </div>
  )
}


class ClienteDettaglio extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      showDialog: false,
      showError: ''
    }
    this.inputPsw = React.createRef();
    this.submitPsw = this.submitPsw.bind(this);

  }
  
  componentDidMount() {
    const { getClientById, match, user } = this.props;
    getClientById({id: match.params.id, user: user.level })
  }


  loginAs(id) {
    this.props.loginAs(id).then((response)=> {
      this.props.setSnack(true, response.name +' loggato con successo','success')
      this.props.history.push('/prodotti')
    }).catch((error)=>{})
  }

  submitPsw(e) {
    e.preventDefault()
    if(this.inputPsw.current.value === '') {
      this.setState({showError: 'Campo obbligatorio'})
      return false
    }
    if(this.inputPsw.current.value.length <= 3) {
      this.setState({showError: 'La password deve contentere minimo 3 caratteri'})
      return false
    }
    this.props.setClientPsw(this.inputPsw.current.value, this.props.user.id).then((response) => {
      this.setState({
        showDialog: false,
        showError: ''
      })
      this.props.setSnack(true,'Password salvata con successo!','success')
    }).catch((error)=>{})
  }

  render() {
    const { client, classes, user, loading } = this.props
    const TextField = (props) => <ShowField classes={classes} {...props} />

    return (
      <React.Fragment>
        {
          loading ? <LoadingPage /> :
          //Object.keys(client).length === 0 ? 'Caricando informazioni del cliente' : 
          <div>
            <div className={classes.navigationBar}>
              <div className={classes.backForm}>
              { 
                (user.level === 'admin' || user.level === 'agent') &&
                  <Button 
                    size="small" 
                    color="primary" 
                    className={classes.button} 
                    onClick={()=>this.props.history.push('/clienti')}
                  >
                    <ArrowBackIcon className={classes.leftIcon} />
                    Torna alla lista
                  </Button>
              }
              </div>
              <div className={classes.titleForm} >
                <span>{ user.level === 'admin' ? 'Scheda cliente': 'Profilo'}</span>
              </div>
              { user.level === 'pos' ? 
                <Button size="small" color="primary" className={classes.buttonRight} onClick={()=>this.setState({showDialog: true})}>
                  <KeyIcon className={classes.leftIcon} />
                  Modifica password
                </Button>
                :
                <div className={classes.breadCrumbsForm}>
                  <span>W-SHOP > CLIENTI > SCHEDA</span>
                </div> 
              }
            </div>
            <div className={classes.actionsForm} >
              { user.level === 'admin' || user.level === 'agent' ?
                <React.Fragment>
                  <Button 
                    size="small" 
                    color="primary" 
                    className={classes.buttonRight} 
                    onClick={()=>this.props.history.push(`/cliente/edit/${client['id']}`)}
                  >
                    <EditIcon className={classes.leftIcon} />
                    Modifica
                  </Button>
                  <Button 
                    size="small" 
                    color="primary" 
                    className={classes.buttonRight} 
                    onClick={()=>this.loginAs(client.id)}
                  >
                    <FaceIcon className={classes.leftIcon} />
                    Impersonifica
                  </Button>
                </React.Fragment> : null
              }
            </div>
            <Paper className={classes.root} elevation={2}>            
              <Grid container className={classes.formContainer}>
                <div className={classes.sectionTitle}>Dati Aziendali</div>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Ragione sociale" value={client.denominazione} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Affiliato" value={client.affiliato} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Rea" value={client.rea} /></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Partita iva" value={client.partita_iva} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Codice fiscale" value={client.codice_fiscale}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="SDI" value={client.sdi} /></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede legale Nazione" value={client.sl_nazione}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede legale Provincia" value={client.sl_provincia}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede legale Città" value={client.sl_citta}/></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede operativa Indirizzo" value={client.sl_indirizzo}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede legale Cap" value={client.sl_cap}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="E-mail" value={client.email} /></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="PEC" value={client.pec} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Telefono" value={client.telefono} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Fax" value={client.fax} /></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Skype" value={client.skype} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Sito web" value={client.sito_web} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede operativa Nazione" value={client.so_nazione}/></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede operativa Provincia" value={client.so_provincia}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede operativa Città" value={client.so_citta}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede operativa Indirizzo" value={client.so_indirizzo}/></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede operativa Cap" value={client.so_cap}/></Grid>
                </Grid>
              </Grid>
              {/* <Grid container className={classes.row}>
                <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede operativa Cap" value={client.so_cap}/></Grid>
              </Grid> */}
            </Paper>
            <Paper className={classes.root} elevation={2}>
              <Grid container className={classes.formContainer}>
                <div className={classes.sectionTitle}>Referente</div>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Contatto Nome" value={client.contatto_nome}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Contatto Cognome" value={client.contatto_cognome}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Contatto Ruolo" value={client.contatto_ruolo}/></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Contatto Cellulare" value={client.contatto_cellulare}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Contatto E-mail" value={client.contatto_email}/></Grid>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.root} elevation={2}>
              <Grid container className={classes.formContainer}>
                <div className={classes.sectionTitle}>Dati Commerciali</div>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sconti" value={client.sconti}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Fido" value={client.fido}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Pagamento" value={client.pagamento}/></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Ns Banca" value={client.ns_banca}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="IBAN" value={client.coordinate_bancarie}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="SWIFT" value={client.swift}/></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Porto" value={client.porto}/></Grid>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.root} elevation={2}>
              <Grid container className={classes.formContainer}>
                <div className={classes.sectionTitle}>Dati di accesso</div>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Username" value={client.user && client.user.name}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="E-mail utente" value={client.user && client.user.email}/></Grid>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.root} elevation={2}>
              <Grid container className={classes.formContainer}>
                <div className={classes.sectionTitle}>Dati agente</div>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Nome" value={client.agent ? client.agent.nome : '---'}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Cognome" value={client.agent ? client.agent.cognome : '---'}/></Grid>
                </Grid>
              </Grid>
            </Paper>
            {
              client.warehouses && client.warehouses.length > 0 &&
              <Paper className={classes.root} elevation={2}>
                <Grid container className={classes.formContainer}>
                  <div className={classes.sectionTitle}>Lista Depositi</div>
                  { client.warehouses && client.warehouses.map((warehouse,i)=>(
                    <div key={i}>
                      <h3 className={classes.subtitle}>Deposito {i+1}</h3>
                      <Grid container className={classes.row}>
                        <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Fornitore" value={warehouse.supplier ? warehouse.supplier.denominazione : '---'}/></Grid>
                        <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Denominazione" value={warehouse.denominazione}/></Grid>
                        <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Indirizzo" value={warehouse.indirizzo}/></Grid>
                      </Grid>
                      <Grid container className={classes.row}>
                        <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="E-mail" value={warehouse.email}/></Grid>
                        <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Telefono" value={warehouse.telefono}/></Grid>
                        <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Referente" value={warehouse.referente}/></Grid>
                      </Grid>
                    </div>
                  )) ||
                  <Grid container className={classes.row}> Non sono presenti depositi </Grid>
                  }
                </Grid>
              </Paper>
            }
          </div>
        }
        <Dialog
          open={this.state.showDialog}
          onClose={this.handleCancel}
          aria-labelledby="Cambia password"
          aria-describedby="cambia password di accesso"
        > 
          <form onSubmit={this.submitPsw}>
            <DialogTitle id="change-access-password">Cambia password</DialogTitle>
            <DialogContent>
              { loading && <LoadingPage light absolute /> }
              <DialogContentText>
                Imposta la nuova password di accesso: 
              </DialogContentText>
              <FormControl className={classes.row}>
                <TextField
                  type="password"
                  inputRef={this.inputPsw}
                  label="Password"
                  className={classes.textFieldContainerInput}
                  error={this.state.showError !== ''}
                  helperText={this.state.showError}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({showDialog: false})} color="primary">
                Cancella
              </Button>
              <Button type="submit" color="primary" autoFocus>
                Continua
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    );
  }
}

ClienteDettaglio.propTypes = {
  client: PropTypes.object.isRequired,
  getClientById: PropTypes.func.isRequired,
  loginAs: PropTypes.func.isRequired,
  setClientPsw: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  client: state.clients.selected,
  user: state.user,
  loading: state.info.loading
});

export default withRouter(
  connect(mapStateToProps, {
    getClientById,
    loginAs,
    setClientPsw,
    setSnack
  })(ClienteDettaglio)
);
