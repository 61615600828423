import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import { 
  getProductById, 
  putItemCart, 
} from '../actions';

import {
  Typography, 
  Modal,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Select,
  InputLabel,
  FormControl,
  MenuItem,
  Button  
} from '@material-ui/core';


import {
  AddShoppingCart as AddCartIcon, 
  ArrowBack as ArrowBackIcon,
  History as HistoryIcon,
  Edit as EditIcon
} from '@material-ui/icons';

import {IMG_URL} from '../config';
import imgPlaceholder from '../assets/img/imgPlaceholder.png'
import LoadingPage from '../components/LoadingPage'

const ShowField = props => {
  return (
    <div className={props.classes.field}>
      <label className={props.classes.label}>{props.label}: </label>
      {props.format === 'money' ? 
      <NumberFormat className={props.classes.paragInline} 
                    value={props.value ? props.value : '-'} 
                    displayType={'text'} 
                    thousandSeparator="." 
                    decimalSeparator="," 
                    prefix={'€'} 
                    renderText={value => <p className={props.classes.paragInline}>{value}</p>}/> : 
      <p className={props.classes.paragInline}>{props.value ? props.value : '-'}</p>}
    </div>
  )
}


class ProdottoDettaglio extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      selectedVariant: 0,
      slugsSelected: [],
      modalLogs: []
    }
  }
  componentWillUnmount() {
    this.setState = (state,callback)=>{
      return;
    }
  }

  componentDidMount() {
    const { getProductById, match } = this.props;
    getProductById(match.params.id)
      .then((data)=> {
        let slugs = []
        data.varianti && data.varianti.map((v,i)=>{ return slugs.push(v.values[0].slug) })

        this.setState({
          slugsSelected: slugs,
        })
        
      }).catch((error)=>{})
  }
  
  handleChangeVariante(data,index) {
    this.setState((state)=>{
      
      const slugsSelected = Object.assign([...state.slugsSelected], {[index]: data})
      const slug = slugsSelected.join('-')
      const selectedVariant = this.props.product.variants.findIndex((v)=>{
        return v.slug === slug
      })

      return {
        ...state,
        slugsSelected,
        selectedVariant
      }
    })
  }
  addToCart() {
    const product = {
      id: this.props.product.variants[`${this.state.selectedVariant}`].id,
      titolo: this.props.product.nome,
      quantita: 1,
      prezzo: this.props.product.variants[`${this.state.selectedVariant}`].prezzo_utente,
      slug: this.props.product.variants[`${this.state.selectedVariant}`].slug
    }
    this.props.putItemCart({product,userId:this.props.user.id})
  }

  showLogs(logs) {
    this.setState({
      modalLogs: logs
    })
  }

  
  render() {
    const { user, product, classes, loading } = this.props
    const { selectedVariant, slugsSelected } = this.state
    const TextField = (props) => <ShowField classes={classes} {...props} />
    
    return (
      <React.Fragment>
          {
            (loading || product === null) ? <LoadingPage /> :
            //Object.keys(product).length === 0 ? 'Caricamento informazioni in corso...' : 
          <div>
            <div className={classes.navigationBar}>
              <div>
                <Button size="small" color="primary" className={classes.button} onClick={()=>this.props.history.push('/prodotti')}>
                  <ArrowBackIcon className={classes.leftIcon} />
                  Torna alla lista
                </Button>
              </div>
              {/* <div className={classes.title} > */}
                <Typography variant="h1" className={classes.title}>Specifiche prodotto</Typography>
              {/* </div> */}
              <div className={classes.breadCrumbs}>
                <span>W-SHOP > PRODOTTI > SPECIFICHE</span>
              </div>
            </div>
            <div className={classes.actionsForm}>
              { user.level === 'admin' && product.changes && product.changes.length > 0 && 
                <>
                  <Button size="small" color="primary" className={classes.buttonRight} onClick={()=>this.showLogs(product.changes)}>
                    <HistoryIcon className={classes.leftIcon} />
                    Visualizza Logs
                  </Button>
                  <Button 
                    size="small" 
                    color="primary" 
                    className={classes.buttonRight} 
                    onClick={()=>this.props.history.push(`/prodotti/edit/${product['id']}`)}
                  >
                    <EditIcon className={classes.leftIcon} />
                    Modifica
                  </Button> 
                </>
              }
            </div>
            <Paper className={classes.root} elevation={2}>
              <Grid container className={classes.root} spacing={5}>
                <Grid item xs={4} >
                  <Grid container>
                    <div className={classes.containerColumn}>
                      {
                        (product.immagine && product.immagine !== null) 
                        ? <img src={IMG_URL+product.immagine} width="100%" alt="preview img prodotto"/> 
                        : <img src={imgPlaceholder} width="100%" alt="placeholder prodotto"/>
                      }
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="h2" color="primary" className={classes.title} gutterBottom>{product.nome}</Typography>
                  <Grid container>
                    <Grid item xs={6}>
                      <Grid container>
                        <div className={classes.containerMainColumn}>
                          {/* <Typography variant="h6" color="primary" className={classes.title} gutterBottom>{product.nome}</Typography> */}
                          <p className={classes.description}>{product.descrizione}</p>
                          {product.variants && product.variants.length>0 && user.level === 'admin' && <TextField label="Quantità" value={product.variants[`${selectedVariant}`].quantita}/>}
                          {product.variants && product.variants.length>0 && user.level === 'admin' && <TextField label="Quantità impegnata" value={product.variants[`${selectedVariant}`].quantita_impegnata}/>}
                          {product.variants && product.variants.length>0 && user.level === 'admin' && <TextField label="Prezzo Acqusito" format="money" value={product.variants[`${selectedVariant}`].prezzo_acquisto} />}
                          {product.variants && product.variants.length>0 && <TextField label="Prezzo Vendita" format="money" value={product.variants[`${selectedVariant}`].prezzo_utente}/>}
                          {product.variants && product.variants.length>0 && user.level === 'admin' && <TextField label="Prezzo Vendita Corner" format="money" value={product.variants[`${selectedVariant}`].prezzo_vendita_corner} />}
                          {product.variants && <TextField label="Stato" value={product.variants[`${selectedVariant}`].stato_da_stampare}/>}
                          {slugsSelected.length>0 && 
                            <div className={classes.flexContainer}>
                              <div className={classes.labelContainer}><span className={classes.label}>Varianti: </span></div>
                              <div>
                                {product.varianti.map((v,i) => (

                                  <FormControl className={classes.formControl} key={i}>
                                    <InputLabel className={classes.labelBottom} htmlFor={`variante-${v.name}`}>{v.name}</InputLabel>
                                    <Select
                                      value={slugsSelected[`${i}`]}
                                      className={classes.inputSelect}
                                      onChange={(e,data)=>this.handleChangeVariante(data.props.value,i)}
                                    >
                                      {v.values.map((item,y) => <MenuItem key={y} value={item.slug}>{item.name}</MenuItem>)}
                                    </Select>
                                  </FormControl>))
                                }
                              </div>
                            </div>
                          }
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                        <div className={classes.containerColumn} style={{borderLeft:'1px solid grey'}}>
                          <div><TextField label="Categoria" value={product.category && product.category.name}/></div>
                          <div><TextField label="Codice prodotto" value={product.variants && product.variants[`${selectedVariant}`].codice}/></div>
                          <div><TextField label="Fornitore" value={product.supplier && product.supplier.denominazione}/></div>
                          <div><TextField label="Unità di misura" value={product.unita_misura}/></div>
                          <div><TextField label="Aliquota iva" value={product.aliquota_iva}/></div>
                          <div><TextField label="Scadenza" value={product.scadenza}/></div>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            {user.level === 'pos' && product.variants[this.state.selectedVariant].stato === 'Disponibile' && <Grid container className={classes.actionBarContainer}>
              <Grid item >
                <Button variant="contained" color="primary" className={classes.button} onClick={()=>this.addToCart()}>
                  <AddCartIcon className={classes.leftIcon} />
                  Aggiungi al carrello
                </Button>
              </Grid>
            </Grid>}
          </div>}
          <Modal
            aria-labelledby="modal-visualizza-log"
            autodetectwindowheight='true'
            open={this.state.modalLogs.length > 0}
            onClose={()=>this.showLogs([])}
          >
            <div className={classes.modalContent}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">Data</TableCell>
                  <TableCell padding="checkbox">Utente</TableCell>
                  <TableCell padding="checkbox">Azione</TableCell>
                  <TableCell padding="checkbox">Dato</TableCell>
                  <TableCell padding="checkbox">Variante</TableCell>
                  <TableCell padding="checkbox">Vecchio valore</TableCell>
                  <TableCell padding="checkbox">Nuovo valore</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.modalLogs.map(row => (
                  <TableRow key={row.id}>
                    <TableCell padding="checkbox">{row.created_at}</TableCell>
                    <TableCell padding="checkbox">{row.user.name}</TableCell>
                    <TableCell padding="checkbox">{row.type}</TableCell>
                    <TableCell padding="checkbox">{row.changes.length > 0 && row.changes[0].name || '--'}</TableCell>
                    <TableCell padding="checkbox">{row.variant && row.variant.slug || '--'}</TableCell>
                    <TableCell padding="checkbox">{row.changes.length > 0 && row.changes[0].old || '--'}</TableCell>
                    <TableCell padding="checkbox">{row.changes.length > 0 && row.changes[0].new || '--'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            </div>
          </Modal>
        </React.Fragment>
    );
  }
}
ProdottoDettaglio.defaultProps = {
  classes: {}
}

ProdottoDettaglio.propTypes = {
  product: PropTypes.object,
  getProductById: PropTypes.func.isRequired,
  putItemCart: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  product: state.products.selected,
  user: state.user,
  loading: state.info.loading
});

export default withRouter(
  connect(mapStateToProps, {
    getProductById,
    putItemCart
  })(ProdottoDettaglio)
);
