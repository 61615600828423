import { RETURN_SHIPPERS, GET_SHIPPER_DETAILS, POST_SHIPPER } from '../actions';

const initialState = {
  list: {},
  selected: {},
  pagination: {}
}

export default function users(state = initialState, action) {
  switch (action.type) {
    case RETURN_SHIPPERS:
      return {
        ...state,
        list: action.shippers,
        selected: {},
      };
    case GET_SHIPPER_DETAILS:
    case POST_SHIPPER: 
      return {
        ...state,
        selected: action.shipper
      };
    default:
      return state;
  }
}
