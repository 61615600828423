import ajax from 'axios';
import { API_URL } from '../config'
import queryString from 'query-string'
export const RETURN_OPERATORS = 'RETURN_OPERATORS';
export const GET_OPERATOR_DETAILS = 'GET_OPERATOR_DETAILS';
export const POST_OPERATOR = 'POST_OPERATOR';

function returnOperators(operators) {
  return {
    type: RETURN_OPERATORS,
    operators,
  };
}

export function getAllOperators({perPage=100, page=1, orderBy='id',order='asc',search=''}) {
  return (dispatch, getState) => {
    const query = {
      perPage,
      page,
      orderBy,
      order,
      search
    }

    return ajax
      .get(`${API_URL}/admin/operators?${queryString.stringify(query)}`)
      //.get(`${API_URL}/admin/operators`)
      .then(response => {
        dispatch(returnOperators(response.data, order));
        return response.data
      })
      .catch(function(error) {
        //console.log(error);
      });
  };
}

function getOperatorDetails(operator) {
  return {
    type: GET_OPERATOR_DETAILS,
    operator,
  };
}
export function getOperatorById(id) {
  return (dispatch, getState) => {
    const user = getState().user
    const urlPath = user.level === 'admin' ? '/admin/operators/'+id : '/user/'+id+'/operator'
    return ajax
      .get(API_URL+urlPath)//'/admin/operators/'+id)
      .then(function(response) {
        dispatch(getOperatorDetails(response.data.data));
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error.response.data.error);
      });
  };
}


function putOperator(operator) {
  return {
    type: POST_OPERATOR,
    operator,
  };
}
export function putOperatorById(id,data) {
  return (dispatch, getState) => {
    const user = getState().user
    return ajax({
      method: id === 'create' ? 'POST' : 'PUT',
      url: API_URL+`/${user.level}/operators/`+id,
      data: data
    })
      .then(function(response) {
        dispatch(putOperator(response.data.data));
        return response
      })
      .catch(function(error) {
        return error.response
        //console.log(error.response.data.error);
      });
  };
}
