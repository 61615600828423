import ajax from 'axios';
import { API_URL } from '../config'

export const GET_CART = 'GET_CART';
export const TOGGLE_CART = 'TOGGLE_CART';
export const PUT_ITEM_CART = 'PUT_ITEM_CART';
export const DELETE_ITEM_CART = 'DELETE_ITEM_CART';
export const SET_COUNTER_ITEM = 'SET_COUNTER_ITEM';
export const CHECKOUT = 'CHECKOUT';

function floatSum(data) {
  const total = data.reduce((a,b)=>{
    return Math.round( (a+b) * 1e2 ) / 1e2
  })
  return total
}

export function updateCart(data) {
  let arrProducts = []
  let objTaxes = { 22: {prezzo: 0, quantita:0 },10: {prezzo: 0, quantita:0 },5: {prezzo: 0, quantita:0 },4: {prezzo: 0, quantita:0 },0: {prezzo: 0, quantita:0 } }
  let totalTaxes = 0
  let totalNetPrice = 0
  let totalGrossPrice = 0

  data.map(item => {
    arrProducts.push(item.quantita)
    
    objTaxes[item.aliquota_iva].prezzo = floatSum([objTaxes[item.aliquota_iva].prezzo, ( item.prezzo*item.quantita * item.aliquota_iva/100)])

    objTaxes[item.aliquota_iva].quantita = objTaxes[item.aliquota_iva].quantita + item.quantita 
    
    totalTaxes = floatSum([totalTaxes, (item.prezzo*item.quantita*item.aliquota_iva)/100])

    totalNetPrice = floatSum([totalNetPrice, item.prezzo * item.quantita])

    totalGrossPrice = floatSum([totalTaxes, totalNetPrice])

    return true
  })

  return {
    list: data,
    taxes: objTaxes,
    net: totalNetPrice,
    vat: totalTaxes,
    gross: totalGrossPrice
  }
}

export function toggleCart(open) {
  return {
    type: TOGGLE_CART,
    open
  };
}

function returnCart({list, taxes, net,vat, gross},open=false) {
  return {
    type: GET_CART,
    open,
    list,
    taxes,
    net,
    vat,
    gross
  };
}
export function getCart(userId) {
  return (dispatch, getState) => {
    return ajax({
      method: 'GET',
      url: API_URL+'/user/'+userId+'/cart'
    })
      .then(function(response) {
        dispatch(returnCart(updateCart(response.data.data)))
        return response.data.data
      })
      .catch(function(error) {
        return error.response
        //console.log(error.response.data.error);
      });
  };
}

export function putItemCart({userId,product}) {
  return (dispatch, getState) => {
    return ajax({
      method: 'POST',
      url: API_URL+'/user/'+userId+'/cart/add',
      data: product
    })
      .then(function(response) {
        dispatch(returnCart(updateCart(response.data.data),true))
        //dispatch(returnCart(response.data.data));
        return response
      })
      .catch(function(error) {
        return error.response
        //console.log(error.response.data.error);
      });
  };
}

export function deleteItemCart({userId,product}) {
  return (dispatch, getState) => {
    return ajax({
      method: 'POST',
      url: API_URL+'/user/'+userId+'/cart/remove',
      data: product
    })
      .then(function(response) {
        dispatch(returnCart(updateCart(response.data.data), true))
        return response.data
      })
      .catch(function(error) {
        return error.response
        //console.log(error.response.data.error);
      });
  };
}

export function deleteAllItemsCart({userId}) {
  return (dispatch, getState) => {
    return ajax({
      method: 'GET',
      url: API_URL+'/user/'+userId+'/cart/empty'
    })
      .then(function(response) {
        dispatch(returnCart(updateCart(response.data.data)))
        //dispatch(removeItemCart(response.data.data));
        return response
      })
      .catch(function(error) {
        return error.response
        //console.log(error.response.data.error);
      });
  }
}

export function setCounterItem({userId,product}) {
  return (dispatch, getState) => {
    return ajax({
      method: 'POST',
      url: API_URL+'/user/'+userId+'/cart/set',
      data: product
    })
      .then(function(response) {
        dispatch(returnCart(updateCart(response.data.data),getState().cart.open))
        return response.data
      })
      .catch(function(error) {
        //console.log(error)
        return error.response
      });
  };
}

function setCheckout() {
  return {
    type: CHECKOUT
  };
}
export function checkout({userId, payment='contanti',products, signal}) {
  return (dispatch, getState) => {
    return ajax({
      method: 'POST',
      url: API_URL+'/user/'+userId+'/cart/checkout',
      data: {
        'metodo_pagamento': payment,
        'prodotti': products
      }
    },{ cancelToken: signal })
      .then(function(response) {
        dispatch(setCheckout());
        return response.data
      })
      .catch(function(error) {
        return error.response
      });
  };
}
