import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';

import { 
  getClientById, 
  putClientById, 
  setSnack, 
  getAllProvince, 
  getAgentsListAll, 
  getAllCitiesByProvince,
  getSupplierByClient,
} from '../actions';

import { 
  Paper, 
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Button
} from '@material-ui/core';

import { 
  ArrowBack, 
  Visibility as ViewIcon
} from '@material-ui/icons';

import ErrorFocus from '../components/ErrorFocus' 
import { allNations } from '../data/nations';
import Cliente from '../data/FormTemplates/Cliente'
import FormFields from '../components/Form/Fields'
import LoadingPage from '../components/LoadingPage' 

class ClienteEdizione extends React.Component {

  constructor(props) {
    super(props)
    this.formClienti = React.createRef();
    this.state= {
      province: [],
      citta_byProvince: {
        sl_citta: [],
        so_citta: []
      },
      loading_sl_citta: false,
      loading_so_citta: false,
      client: {
        sede_coincide: false,
        agent: null,
        denominazione: '',
        affiliato: '',
        contatto_nome: '',
        contatto_cognome: '',
        contatto_ruolo: '',
        contatto_cellulare: '',
        contatto_email: '',
        sito_web: '',
        email: '',
        pec: '',
        sdi: '',
        telefono: '',
        skype: '',
        fax: '',
        rea: '',
        partita_iva: '',
        codice_fiscale: '',
        sl_indirizzo: '',
        sl_cap: '',
        sl_citta: '',
        sl_provincia: '',
        sl_nazione: '',
        so_indirizzo: '',
        so_cap: '',
        so_citta: '',
        so_provincia: '',
        so_nazione: '',
        sconti: '',
        fido: '',
        pagamento: '',
        coordinate_bancarie: '',
        ns_banca: '',
        swift: '',
        porto: '',
        user: {
          name: '',
          email: '',
          password: ''
        },
        suppliers: [],
        warehouses: []
      },
      allNations: allNations,
      allAgents: [],
      allSuppliers: [],
      formTemplate: Cliente,
      sectionsToHide: []
    }
  }

  componentDidMount() {
    const { getClientById, getAllProvince, getAgentsListAll, getSupplierByClient, match } = this.props;

    getSupplierByClient().then((responseSuppliers) => {

      if(match.params.id) {
        getClientById({id:match.params.id}).then( (response) => {
          this.setState({
            allSuppliers: responseSuppliers,
            warehousesIds: response.warehouses || [],
            sectionsToHide: response.sede_coincide ? ['so_address'] : [],
            client: {
              sede_coincide: response.sede_coincide || false,
              agent: response.agent && {id: response.agent.id, label: response.agent.nome_completo } || null ,
              denominazione: response.denominazione || '',
              affiliato: response.affiliato || '',
              contatto_nome: response.contatto_nome || '',
              contatto_cognome: response.contatto_cognome || '',
              contatto_cellulare: response.contatto_cellulare || '',
              contatto_email: response.contatto_email || '',
              contatto_ruolo: response.contatto_ruolo || '',
              sito_web: response.sito_web || '',
              email: response.email || '',
              pec: response.pec || '',
              sdi: response.sdi || '',
              telefono: response.telefono || '',
              skype: response.skype || '',
              fax: response.fax || '',
              rea: response.rea || '',
              partita_iva: response.partita_iva || '',
              codice_fiscale: response.codice_fiscale || '',
              sl_indirizzo: response.sl_indirizzo || '',
              sl_cap: response.sl_cap || '',
              sl_citta: response.sl_citta || '',
              sl_provincia: response.sl_provincia || '',
              sl_nazione: response.sl_nazione || '', //{label:response.sl_nazione, id:response.sl_nazione},
              so_indirizzo: response.so_indirizzo || '',
              so_cap: response.so_cap || '',
              so_citta: response.so_citta || '',
              so_provincia: response.so_provincia || '',
              so_nazione: response.so_nazione || '',//{label:response.so_nazione, id:response.so_nazione},
              sconti: response.sconti || '',
              fido: response.fido || '',
              pagamento: response.pagamento || '',
              coordinate_bancarie: response.coordinate_bancarie || '',
              ns_banca: response.ns_banca || '',
              swift: response.swift || '',
              porto: response.porto || '',
              user: {
                name: response.user.name || '',
                email: response.user.email || '',
                password: ''
              },
              suppliers: response.suppliers || [],
              warehouses: []
            }
          })
          if(response.sl_provincia) {
            this.getCitta(response.sl_provincia, 'sl_citta')
          }
          if(response.so_provincia) {
            this.getCitta(response.so_provincia, 'so_citta')
          }
          if(response.warehouses.length > 0) {
            this.getWarehouses()
          }
        }).catch((error)=>{})
        
      }
      
    })


    getAllProvince().then((response)=>{
      let options = []
      response.map((s,i) => {
        options = options.concat({
          value: s.nome,
          label: s.nome
        })
      })
      this.setState({
        province: options
      })
    }).catch((error)=>{})

    getAgentsListAll().then((response)=>{
      let options = []
      response.map((s,i) => {
        options = options.concat({
          value: s.id,
          label: s.nome +' '+s.cognome,
          nome: s.nome,
          cognome: s.cognome
        })
      })
      this.setState({
        allAgents: options
      })
    }).catch((error)=>{})
  }

  getCitta = (province, tagCitta) => {
    this.setState({
      ['loading_'+tagCitta]: true
    })
    getAllCitiesByProvince(province)
      .then((response)=>{
        let options = []
        response.map((s,i) => {
          options = options.concat({
            value: s.nome,
            label: s.nome
          })
        })
        this.setState({
            citta_byProvince: {
              ...this.state.citta_byProvince,
              [tagCitta]: options
            },
            ['loading_'+tagCitta]: false
        })
      }).catch((error)=>{})
  }


  getWarehouses = () => {
    let temp_warehouses = []
    this.state.allSuppliers.map( (supplier,i) => {
      temp_warehouses.push(0)
      supplier.warehouses.map( warehouse => {
        this.state.warehousesIds.map( clientWH => {
          if(warehouse.id === clientWH.id) {
            temp_warehouses[i] = clientWH.id
          }
        })
      })
      //props.setFieldValue(`client.warehouses[${i}]`, event.target.value);
    })
    this.setState(state => {
      return {
        ...state,
        client: {
          ...state.client,
          warehouses: temp_warehouses
        }
      }
    })
  }


  handleVisibilitySection = (section, show) => {
    let newSectionsToHide = this.state.sectionsToHide
    const position = this.state.sectionsToHide.indexOf(section)
    
    if(position === -1) {
      // section is already showed and I want to hide
      !show && newSectionsToHide.push(section)
    }
    else {
      show && newSectionsToHide.splice(position, 1)
    }

    this.setState({
      sectionsToHide: newSectionsToHide
    })
  }
  
  render() {
    const copy = (o) => {
      let output, v, key;
      output = Array.isArray(o) ? [] : {};
      for (key in o) {
          v = o[key];
          output[key] = (typeof v === "object") ? copy(v) : v;
      }
      return output;
   }
    const { classes, match, history, loading } = this.props
    const { client, allSuppliers, user, formTemplate } = this.state
    return (
      <React.Fragment>
        {loading && <LoadingPage /> }
          <div>
            <div className={classes.navigationBar}>
              <div className={classes.backForm}>
                <Button size="small" color="primary" className={classes.button} onClick={()=>history.push('/clienti')}>
                  <ArrowBack className={classes.leftIcon} />
                  Torna alla lista
                </Button>
              </div>
              <div className={classes.titleForm} ><span>Formulario cliente</span></div>
              <div className={classes.breadCrumbsForm}>
                <span>W-SHOP > CLIENTI > FORMULARIO</span>
              </div>
            </div>
              <Formik
                ref={this.formClienti}
                initialValues={{ client: client, errors: {} }}
                enableReinitialize={true}
                //validateOnBlur={false}
                validate={(value) => {
                  
                  let fields = { user: {} };
                  let scrollToID = []

                  if(value.client.denominazione === '') {
                    fields.denominazione = 'Campo obbligatorio'
                    scrollToID.push('input-denominazione')
                  }
                  if(value.client.affiliato === '') {
                    fields.affiliato = 'Campo obbligatorio'
                    scrollToID.push('input-affiliato')
                  }
                  if(value.client.partita_iva === '') {
                    fields.partita_iva = 'Campo obbligatorio'
                    scrollToID.push('input-partita_iva')
                  }
                  
                  if(value.client.codice_fiscale === '') {
                    fields.codice_fiscale = 'Campo obbligatorio'
                    scrollToID.push('input-codice_fiscale')
                  }
                  if(value.client.sl_nazione === '') {
                    fields.sl_nazione = 'Campo obbligatorio'
                    scrollToID.push('input-sl_nazione')
                  }
                  if(value.client.sl_provincia === '') {
                    fields.sl_provincia = 'Campo obbligatorio'
                    scrollToID.push('input-sl_provincia')
                  }
                  if(value.client.sl_citta === '') {
                    fields.sl_citta = 'Campo obbligatorio'
                    scrollToID.push('input-sl_citta')
                  }
                  if(value.client.sl_indirizzo === '') {
                    fields.sl_indirizzo = 'Campo obbligatorio'
                    scrollToID.push('input-sl_indirizzo')
                  }
                  if(value.client.sl_cap === '') {
                    fields.sl_cap = 'Campo obbligatorio'
                    scrollToID.push('input-sl_cap')
                  }
                  if(value.client.email === '') {
                    fields.sl_cap = 'Campo obbligatorio'
                    scrollToID.push('input-email')
                  }
                  if(value.client.sl_nazione.label === 'Italia') {
                    if(value.client.pec === '') {
                      fields.pec = 'Campo obbligatorio'
                      scrollToID.push('input-pec')
                    }
                    if(value.client.sdi === '') {
                      fields.sdi = 'Campo obbligatorio'
                      scrollToID.push('input-sdi')
                    }
                  }
                  if(value.client.telefono === '') {
                    fields.sl_cap = 'Campo obbligatorio'
                    scrollToID.push('input-telefono')
                  }

                  if(!value.client.sede_coincide) {

                    if(value.client.so_nazione === '') {
                      fields.so_nazione = 'Campo obbligatorio'
                      scrollToID.push('input-so_nazione')
                    }
                    if(value.client.so_provincia === '') {
                      fields.so_provincia = 'Campo obbligatorio'
                      scrollToID.push('input-so_provincia')
                    }
                    if(value.client.so_citta === '') {
                      fields.so_citta = 'Campo obbligatorio'
                      scrollToID.push('input-so_citta')
                    }
                    if(value.client.so_cap === '') {
                      fields.so_cap = 'Campo obbligatorio'
                      scrollToID.push('input-so_cap')
                    }
                    if(value.client.so_indirizzo === '') {
                      fields.so_indirizzo = 'Campo obbligatorio'
                      scrollToID.push('input-so_indirizzo')
                    }
                  }

                  if(value.client.contatto_nome === '') {
                    fields.contatto_nome = 'Campo obbligatorio'
                    scrollToID.push('input-contatto_nome')
                  }
                  if(value.client.contatto_cognome === '') {
                    fields.contatto_cognome = 'Campo obbligatorio'
                    scrollToID.push('input-contatto_cognome')
                  }
                  if(value.client.contatto_ruolo === '') {
                    fields.contatto_ruolo = 'Campo obbligatorio'
                    scrollToID.push('input-contatto_ruolo')
                  }
                  
                  // if(value.client.contato_email === '') {
                  //   fields.email = 'Campo obbligatorio'
                  //   scrollToID.push('input-contatto_email')
                  // }

                  // if(value.client.telefono === '') {
                  //   fields.telefono = 'Campo obbligatorio'
                  //   scrollToID.push('input-contatto_telefono')
                  // }

                  
                  
                  
                  if(value.client.user.name === '') {
                    fields.user.name = 'Campo obbligatorio'
                    scrollToID.push('input-user-name')
                  }
                  
                  if (!value.client.user.email) {
                    fields.user.email = 'Campo obbligatorio';
                  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.client.user.email)) {
                    fields.user.email = 'Indirizzo email non valido';
                    scrollToID.push('input-user-email')
                  }
                
                  if(!match.params.id && value.client.user.password === '') {
                    fields.user.password = 'Campo obbligatorio'
                    scrollToID.push('input-user-password')
                  }
                  
                  if(Object.keys(fields.user).length === 0) {
                    delete fields.user
                  }
                  
                  const errors = {}
                  if(Object.keys(fields).length > 0) errors.fields = fields
                  if(scrollToID[0]) errors.first = scrollToID[0]
                  
                  return errors
                }}
                onSubmit={(values, actions) => {
                  const id = match.params.id ? match.params.id : 'create'
                  const allFields = copy(values.client)
                  
                  if(allFields.user.password === '') {
                    delete allFields.user.password
                  }

                  if(allFields.sede_coincide) {                  
                    allFields.so_nazione = values.client.sl_nazione
                    allFields.so_provincia = values.client.sl_provincia
                    allFields.so_citta = values.client.sl_citta
                    allFields.so_indirizzo = values.client.sl_indirizzo
                    allFields.so_cap = values.client.sl_cap
                  }

                  // allFields.sl_nazione = allFields.sl_nazione.label
                  // allFields.so_nazione = allFields.so_nazione.label
                  allFields.agent_id = allFields.agent.value || null 

                  allFields.warehouses = allFields.warehouses.filter(function(e){return e>0}); 
                  this.props.putClientById(id, allFields).then((response) => {
                    if(response.status >= 200 && response.status < 300) {
                      this.props.setSnack(true,'Cliente creato con successo','success')
                      history.push(`/cliente/${response.data.data.id}`);
                    }
                    else {
                      this.props.setSnack(true,'Form non compilato correttamente, verifica i dati inseriti','warning')
                      Object.keys(response.data.errors).map(function(key, index) {
                        actions.setFieldError(key,response.data.errors[key])
                      })
                    }
                  }).catch((error)=>{})
                }}
                render={props => {
                  //const { touched, errors } = props
                  const { 
                    touched, 
                    errors,
                    values,
                    handleBlur,
                    handleChange,
                    setFieldValue,
                    setFieldTouched
                  } = props
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <ErrorFocus firstItem={errors.first} />
                      <div className={classes.actionsForm}>
                        {
                          match.params.id &&
                          <Button 
                            size="small" 
                            color="primary" 
                            className={classes.buttonRight} 
                            onClick={()=> this.props.history.push(`/cliente/${match.params.id}`)}
                          >
                            <ViewIcon className={classes.leftIcon} />
                            Visualizza scheda
                          </Button>
                        }
                      </div>
                      {
                        <FormFields 
                          classes={classes}
                          slug='client'
                          template={formTemplate}
                          onChange={handleChange} 
                          onBlur={handleBlur}
                          setFieldValue={setFieldValue}
                          setFieldTouched={setFieldTouched}
                          error={errors.fields}
                          touched={touched.client}
                          values={values.client} 
                          options={
                            {
                              citta_byProvince: this.state.citta_byProvince,
                              affiliates: [
                                {
                                  value: 'point', 
                                  label: 'Point'
                                },
                                {
                                  value: 'coffee', 
                                  label: 'Coffee'
                                },
                                {
                                  value: 'corner', 
                                  label: 'Corner'
                                }
                              ],
                              ports: [
                                {
                                  label: 'Franco',
                                  value: 'franco'
                                },
                                {
                                  label: 'Assegnato',
                                  value: 'assegnato'
                                }
                              ],
                              allAgents: this.state.allAgents
                            }
                          }
                          checked={{
                            sede_coincide: values.client.sede_coincide
                          }}
                          sectionsToHide={this.state.sectionsToHide}
                          context={this}
                          hasUser='client'
                        />
                      }
                      {
                        allSuppliers.length > 0 &&
                          <Paper className={classes.root} elevation={2}>
                            <Grid container className={classes.formContainer}>
                              <div className={classes.sectionTitle}>Dati di deposito</div>
                              {
                                allSuppliers.map((supplier,i)=>(
                                  <Grid container key={supplier.id}>
                                    <Grid item className={classes.textFieldContainer} xs={6}>
                                      <FormControl className={this.props.classes.formControl}>
                                        <InputLabel htmlFor={`input-warehouses-${i}-name`}>Deposito {supplier.denominazione}</InputLabel>
                                        <Select
                                          id={`input-warehouses-${i}-name`}
                                          displayEmpty
                                          value={props.values.client.warehouses[i]}
                                          onChange={(event) => {
                                            props.setFieldValue(`client.warehouses[${i}]`, event.target.value);
                                          }}
                                        >
                                          <MenuItem value={0}></MenuItem>
                                          {
                                            supplier.warehouses.map((warehouse,l)=>(
                                              <MenuItem key={warehouse.id} value={warehouse.id}>{warehouse.denominazione}</MenuItem>
                                            ))
                                          }
                                        </Select>
                                      </FormControl>
                                    </Grid>   
                                  </Grid>
                                ))
                              }
                            </Grid>
                          </Paper>
                      }
                      <Grid container justify="center">
                        <Button type="submit" variant="contained" color="primary" className={classes.button}>Salva</Button>
                      </Grid>
                    </form>
                  )
                }}
              />
          </div>
        </React.Fragment>
    );
  }
}

ClienteEdizione.propTypes = {
  client: PropTypes.object,
  getClientById: PropTypes.func,
  putClientById: PropTypes.func,
  setSnack: PropTypes.func.isRequired,
  getAllProvince: PropTypes.func.isRequired,
  getAllCitiesByProvince: PropTypes.func.isRequired,
  getSupplierByClient: PropTypes.func.isRequired,
  getAgentsListAll: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  client: state.clients.selected,
  user: state.user,
  loading: state.info.loading
});
const mapDispatchToProps = {
  getClientById,
  putClientById,
  getAllProvince,
  getAllCitiesByProvince,
  getAgentsListAll,
  getSupplierByClient,
  setSnack
};

export default withRouter(
  connect(mapStateToProps,mapDispatchToProps)(ClienteEdizione)
)
