import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAllOperators, removeItems } from '../actions';
import Table from '../components/Table';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import LoadingPage from '../components/LoadingPage'
import PageTitle from '../components/PageTitle';

class OperatoriLista extends React.Component {
  componentDidMount() {
    const { getAllOperators, pagination } = this.props;
    getAllOperators({perPage:pagination.per_page, page: pagination.current_page});
  }
  
  handleRemove(data) {
    this.props.removeItems(data,'operators').then(response => {
      if(response.status === 200) {
        this.props.getAllOperators({})
      }
    }).catch((error)=>{})
  }
  
  goToPage(page) {
    this.props.history.push('operatore/'+page)
  }
  render() {
    const { 
      loading,
      classes,
      location,
      operators,
      pagination,
      getAllOperators,
      user
    } = this.props

    return (
      <React.Fragment>
        { loading && <LoadingPage /> }
        <PageTitle 
          title="Operatori"
          breadcrumbs={['W-SHOP','OPERATORI']}
          //handleBackClick={()=>history.push('/prodotti')}
          classes={classes}
        />
        <Paper className={classes.root} elevation={2}>
          <Table 
            slug="operators"
            tableTitle="Operatori" 
            location={location}
            choises={operators.data}
            pagination={pagination}
            labels={[
              {
                value: "id",
                //numeric: true
              },
              {
                value: "name",
                label: "Nome",
              },
              {
                value: "email",
                label: "E-mail",
              }
            ]}
            paginate={({perPage,page,orderBy,order,search}) => getAllOperators({perPage,page,orderBy,order,search})}
            removeItems={(data) => this.handleRemove(data)}
            goToPage={(page) => this.goToPage(page)}
            hasView={false}
            userLevel={user.level}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

OperatoriLista.propTypes = {
  operators: PropTypes.object.isRequired,
  getAllOperators: PropTypes.func.isRequired,
  removeItems: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  operators: state.operators.list,
  user: state.user,
  pagination: state.info.pagination,
  loading: state.info.loading
});

export default withRouter(
  connect(mapStateToProps, {
    getAllOperators,
    removeItems
  })(OperatoriLista)
);