import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Paper,
  Button,
  Grid,
  Input,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import {IMG_URL} from '../config';

import ListDraggable from '../components/ListDraggable';
import LoadingPage from '../components/LoadingPage';
import PageTitle from '../components/PageTitle';
import { getAllProducts, saveProductOrder } from '../actions'

import PlusIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import CleanIcon from '@material-ui/icons/HighlightOff';
import imgPlaceholder from '../assets/img/imgPlaceholder.png'


function ProdottiOrdinamento({
  loading,
  classes,
  history,
  getAllProducts,
  saveProductOrder
}) {

  const [items, setItems] = useState([])
  const [ids, setIds] = useState([])
  
  const [text, setText] = useState('')
  const [products, setProducts] = useState([])
  const [alertDialog, setAlertDialog] = useState(false)

  useEffect(() => {

    getAllProducts({ only_ordered: true })
      .then(response => {
        setItems(response.data)
        getIds(response.data)
      })
      .catch(error => {})
  },[])

  const getIds = (list) => {
    const newList = list.map(item => item.id)
    setIds(newList)
  }

  const handleSave = () => {
    saveProductOrder(ids)
  }

  const handleSearch = (t) => {
    if(t !== '') {
      getAllProducts({
        perPage:1000,
        search: t,
        excluded: ids.join()
      })
        .then(response => {
          setProducts(response.data)
        })
        .catch(()=>{})
    }
  }

  const addItem = (product) => {
    if(items.length < 20) {
      const newProducts = products.filter(prod => prod.id !== product.id)
      setProducts(newProducts)
      setItems(items.concat(product))
      setIds(ids.concat(product.id))
    }
    else{
      setAlertDialog(true)
    }
  }

  const removeItem = (item) => {
    const newItems = items.filter(itm => itm.id !== item.id)
    const newIds = ids.filter(id => id !== item.id)
    setItems(newItems)
    setIds(newIds)
  }

  return (
    <>
      { loading && <LoadingPage /> }
      <PageTitle 
        title="Ordina prodotti"
        breadcrumbs={['W-SHOP','PRODOTTI','ORDINA PRODOTTI']}
        handleBackClick={()=>history.push('/prodotti')}
        classes={classes}
      />
      <Paper className={classes.root} elevation={2}> 
        <Grid container className={classes.formContainer}>
          <Grid container className={classes.row}>
            <Grid 
              item 
              xs={6}
            >
              <div style={{
                backgroundColor: '#f3f3f3',
                padding: 8,
                marginRight: 16,
                marginLeft: 16
              }}>
                <div style={{ display: 'flex', flexDirection: 'column' , paddingBottom: 24, minHeight: 140 }}>
                  <h2 style={{fontSize: 16, textTransform:'uppercase'}}>Prodotti ordinati ({items.length})</h2>
                  <p style={{margin: 0}}>
                    In questa lista è possibile ordinare, tramite <i>Drag and Drop</i>, i primi 20 elementi dell' e-commerce.
                  </p>
                  <p style={{margin: 0}}>
                    Rimuovendo il prodotto dalla lista non verrà rimosso dall'e-commerce ma semplicemente dall'ordinamento.
                  </p>
                </div>
                {
                  items.length > 0 && 
                    <ListDraggable 
                      droppableId='droppable'
                      items={items}
                      onOrderChange={getIds}
                      removeItem={removeItem}
                    />
                }
              </div>
            </Grid>
            <Grid 
              item 
              xs={6}
            >
              <div 
                style={{
                  backgroundColor: '#f3f3f3',
                  padding: 8,
                  marginRight: 16,
                  marginLeft: 16
                }}
              >
              <div style={{ display: 'flex', flexDirection:'column', paddingBottom: 24, minHeight: 140 }}>
                <div style={{ display: 'flex', alignItems: 'center'}}>
                  <h2 style={{fontSize: 16, textTransform:'uppercase', flex:1}}>Cerca prodotti non ordinati</h2>
                  <div className={classes.searchBox}>
                    <input 
                      type="text"
                      name="search"
                      placeholder="Cerca"
                      autoComplete="off"
                      value={text} 
                      onChange={e => setText(e.target.value)} 
                      onKeyDown={e => { e.keyCode === 13 && handleSearch(e.target.value) }} 
                    />
                      {
                        text.length === 0 
                        ? <SearchIcon fontSize="small" /> 
                        : <CleanIcon fontSize="small" onClick={() => setText('')} /> 
                      }
                  </div>
                </div>
                <p style={{margin: 0}}>
                  Cerca i prodotti dell'e-commerce che ancora non sono stati ordinati e aggiungili alla lista ordinata tramite click sulla icona col +
                </p>
              </div>
              {
                products.map(product => (
                  <div key={product.id} style={{
                    padding: 8,
                    marginBottom: 8,
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <img width={40} height={40} src={product.immagine !== null ? IMG_URL+product.immagine : imgPlaceholder} alt="" style={{marginRight: 8}} />
                    <span style={{flex:2}}>{product.nome}</span>
                    <span onClick={()=>addItem(product)} style={{cursor: 'pointer'}}><PlusIcon /></span>
                  </div>
                ))
              }
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Grid container justify="center">
        <Button 
          type="submit" 
          onClick={handleSave}
          variant="contained" 
          color="primary" 
          className={classes.button}
        >
          Salva
        </Button>
      </Grid>
      <Dialog open={alertDialog}>
        <DialogTitle>Attenzione</DialogTitle>
        <DialogContent>
          <DialogContentText>Non è possibile ordinare più di 20 elementi</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setAlertDialog(false) } } color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
ProdottiOrdinamento.propTypes = {
  getAllProducts: PropTypes.func,
  saveProductOrder: PropTypes.func,
};
const mapStateToProps = (state, props) => ({
  loading: state.info.loading
});

export default withRouter(
  connect(
    mapStateToProps, 
    {
      getAllProducts,
      saveProductOrder
    }
  )(ProdottiOrdinamento)
)
