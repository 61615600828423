import React from 'react';
import PropTypes from 'prop-types';
//import { DatePicker } from 'material-ui-pickers';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import 'date-fns';
import format from 'date-fns/format';
import DateFnsUtils from '@date-io/date-fns';
//import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import itLocale from 'date-fns/locale/it'

class DatePickerUI extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      selectedDate: this.props.value,
    };
  }
  
  handleDateChange = date => {
    if(date !== null) {
      this.setState({ selectedDate: date });
      this.props.onChange(format(date,this.props.format));
    }
    else {
      this.setState({ selectedDate: null });
      this.props.onChange(null);
    }
  }

  componentDidUpdate(prevProps,prevState) {
    if(prevState.selectedDate !== this.props.value) {
      this.setState({
        selectedDate: this.props.value
      })
    }
  }

  

  render() {
    const { classes, label, format, error, disablePast } = this.props;
    const { selectedDate } = this.state;
    
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
        <DatePicker
          margin="normal"
          label={label}
          helperText={error}
          classes={{root: classes}}
          format={format}
          clearable
          clearLabel="rimuovi"
          cancelLabel="annulla"
          invalidLabel="Data non valida"
          value={selectedDate}
          onChange={this.handleDateChange}
          disablePast={disablePast}
        />
      </MuiPickersUtilsProvider>
    );
  }
}
DatePickerUI.defaultProps = {
  disablePast: true
}

DatePickerUI.propTypes = {
  classes: PropTypes.string,
  disablePast: PropTypes.bool,
  label: PropTypes.string.isRequired,
  error: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  format: PropTypes.string,
};

export default DatePickerUI;