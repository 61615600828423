import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getSupplierById } from '../actions';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import LoadingPage from '../components/LoadingPage'

const ShowField = props => {
  return (
    <div>
      <label className={props.classes.label}>{props.label}</label>
      <p className={props.classes.parag}>{props.value ? props.value : '---'}</p>
    </div>
  )
}

class FornitoreDettaglio extends React.Component {
  
  componentDidMount() {
    const { getSupplierById, match } = this.props;
    getSupplierById(match.params.id)
  }

  
  render() {
    const { supplier, classes, loading } = this.props
    const TextField = (props) => <ShowField classes={classes} {...props} />

    return (
      <React.Fragment>
        { loading ? <LoadingPage /> :

        <div>
          <div className={classes.navigationBar}>
            <div className={classes.backForm}>
              <Button size="small" color="primary" className={classes.button} onClick={()=>this.props.history.push('/fornitori')}>
                <ArrowBackIcon className={classes.leftIcon} />
                Torna alla lista
              </Button>
            </div>
            <div className={classes.titleForm} ><span>Scheda fornitore</span></div>
            <div className={classes.breadCrumbsForm}>
              <span>W-SHOP > FORNITORI > SCHEDA FORNITORE</span>
            </div>
          </div>
          <div className={classes.actionsForm} >
            <Button 
              size="small" 
              color="primary" 
              className={classes.buttonRight} 
              onClick={()=>this.props.history.push(`/fornitore/edit/${supplier['id']}`)}
            >
              <EditIcon className={classes.leftIcon} />
              Modifica scheda
            </Button>
          </div>
          <Paper className={classes.root} elevation={2}>
            
            <Grid container className={classes.formContainer}>
              <div className={classes.sectionTitle}>Dati Aziendali</div>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Ragione sociale" value={supplier.denominazione} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Rea" value={supplier.rea} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Partita iva" value={supplier.partita_iva} /></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="SDI" value={supplier.sdi}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede legale Nazione" value={supplier.sl_nazione}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede legale Provincia" value={supplier.sl_provincia}/></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede legale Città" value={supplier.sl_citta}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede legale Indirizzo" value={supplier.sl_indirizzo}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede legale Cap" value={supplier.sl_cap}/></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="E-mail" value={supplier.email} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Pec" value={supplier.pec} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Telefono" value={supplier.telefono} /></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Fax" value={supplier.fax} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Skype" value={supplier.skype} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Sito web" value={supplier.sito_web} /></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede operativa Nazione" value={supplier.so_nazione}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede operativa Provincia" value={supplier.so_provincia}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sede operativa Città" value={supplier.so_citta}/></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} >
                    <TextField label="Sede operativa Indirizzo" value={supplier.so_indirizzo}/>
                  </Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} >
                    <TextField label="Sede operativa Cap" value={supplier.so_cap}/>
                  </Grid>
                </Grid>
                
              </Grid>
            </Paper>
            <Paper className={classes.root} elevation={2}>
              <Grid container className={classes.formContainer}>
                <div className={classes.sectionTitle}>Referente</div>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Contatto Nome" value={supplier.contatto_nome}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Contatto Cognome" value={supplier.contatto_cognome}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Contatto Ruolo" value={supplier.contatto_ruolo}/></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Contatto Telefono" value={supplier.contatto_cellulare}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Contatto E-mail" value={supplier.contatto_email}/></Grid>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.root} elevation={2}>
              <Grid container className={classes.formContainer}>
                <div className={classes.sectionTitle}>Dati Commerciali</div>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Sconti" value={supplier.sconti}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Fido" value={supplier.fido}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Pagamento" value={supplier.pagamento}/></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Ns Banca" value={supplier.ns_banca}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="IBAN" value={supplier.coordinate_bancarie}/></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="SWIFT" value={supplier.swift}/></Grid>
                </Grid>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Porto" value={supplier.porto}/></Grid>
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.root} elevation={2}>
              <Grid container className={classes.formContainer}>
                <div className={classes.sectionTitle}>Lista Depositi</div>
                { supplier.warehouses && supplier.warehouses.map((warehouse,i)=>(
                  <div key={i}>
                    <h3 className={classes.subtitle}>Deposito {i+1}</h3>
                    <Grid container className={classes.row}>
                      <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Denominazione" value={warehouse.denominazione}/></Grid>
                      <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Referente" value={warehouse.referente}/></Grid>
                      <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="E-mail" value={warehouse.email}/></Grid>
                    </Grid>
                    <Grid container className={classes.row}>
                      <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Telefono" value={warehouse.telefono}/></Grid>
                      <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Nazione" value={warehouse.nazione}/></Grid>
                      <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Indirizzo" value={warehouse.indirizzo}/></Grid>
                    </Grid>
                    <Grid container className={classes.row}>
                      <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Città" value={warehouse.citta}/></Grid>
                      <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Provincia" value={warehouse.provincia}/></Grid>
                      <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Cap" value={warehouse.cap}/></Grid>
                    </Grid>
                  </div>
                )) }
              </Grid>
            </Paper>
          </div>
        }
      </React.Fragment>
    );
  }
}

FornitoreDettaglio.propTypes = {
  supplier: PropTypes.object.isRequired,
  getSupplierById: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  supplier: state.suppliers.selected,
  user: state.user,
  loading: state.info.loading
});

export default withRouter(
  connect(mapStateToProps, {
    getSupplierById
  })(FornitoreDettaglio)
);
