import React from 'react';
import PropTypes from 'prop-types';
import { withFormik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import logo from '../assets/img/logo_WSHOP.svg'
import Recaptcha from 'react-recaptcha'

const styles = theme => ({
  root: {
    width: 400,
    padding: theme.spacing(5),
    background: '#fff'
  },
  logoContainer: {
    textAlign: 'center',
    marginBottom: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(2),
    '& h2': {
      textTransform: 'uppercase',
      fontWeight: 700,
      margin: 0,
      fontSize: 14
    }
  },
  row: {
    width: '100%'
  },
  field: {
    marginBottom: theme.spacing(3)
  },
  fieldError: {
    width: '100%',
    marginBottom: theme.spacing(2),
    color: '#f44336',
    fontSize: '0.75rem'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(4),
    '& button': {
      boxShadow: 'none'
    },
    '& button:first-child': {
      marginRight: theme.spacing(2)
    }
  }
})

const InnerForm = ({
  externalFunc,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  isSubmitting,
  setFieldValue,
  classes
}) => (
  <form onSubmit={handleSubmit} className={classes.root}>
    <div className={classes.logoContainer}><img src={logo} alt="logo" width="150px" /></div>
    <div className={classes.title}>
      <h2>Recupero password</h2>
      <span>Inserisci l'indirizzo e-mail</span>
    </div>
    <FormControl className={classes.row}>
      <TextField
        type="text"
        name="email"
        label="Indirizzo e-mail"
        fullWidth={true}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values.email}
        className={classes.field}
        //variant="outlined"
        error={touched.email && errors.email === 'Campo obbligatorio'}
        helperText={touched.email && errors.email && errors.email}
      />
      {/* {touched.email && errors.email && <div className={classes.fieldError}>{errors.email}</div>} */}
    </FormControl>
    <FormControl className={classes.row}>
      <Recaptcha
        name="reCaptcha"
        sitekey="6LcHwqIUAAAAADlcZhorQCRhxO34agMxdN_MFGFv"
        render="explicit"
        //theme="dark"
        verifyCallback={(val) => {
          setFieldValue('reCaptcha',val)
        }}
        //onloadCallback={()=>{console.log('ok')}}
      />
      {touched.reCaptcha && errors.reCaptcha && <div className={classes.fieldError}>{errors.reCaptcha}</div>}
    </FormControl>
    <div className={classes.buttonContainer}>
      <Button variant="contained" size="large" fullWidth color="default" onClick={() => externalFunc(0)}>Annulla</Button>
      <Button variant="contained" size="large" fullWidth color="primary" type="submit">Prosegui</Button>
    </div>
  </form>
);

// Wrap our form with the using withFormik HoC
const ForgetPswForm = withFormik({
  // Transform outer props into form values
  mapPropsToValues: props => ({ email: '', reCaptcha: '' }),
  // Add a custom validation function (this can be async too!)
  validate: (values, props) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Campo obbligatorio';
    }
    if (!values.reCaptcha) {
      errors.reCaptcha = 'Campo obbligatorio';
    }
    return errors;
  },
  // Submission handler
  handleSubmit: (
    values,
    {
      props,
      setSubmitting,
      setErrors /* setValues, setStatus, and other goodies */,
    }
  ) => {
    props.forgetPsw(values.email).then((response) => {
      if(response && response.status > 400) {
        props.setSnack(true,'Indizzo e-mail non incontrato','warning')
      } else {
        props.setSnack(true,'Controlla la tua casella di posta!','success')
        props.externalFunc(0)
      }
    }).catch((error)=>{})
  },
})(withStyles(styles)(InnerForm))

ForgetPswForm.propTypes = {
  forgetPsw: PropTypes.func.isRequired,
  setSnack: PropTypes.func.isRequired,
  externalFunc: PropTypes.func
};

export default ForgetPswForm
