import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  Button
} from '@material-ui/core';

const PageTitle = ({
  title,
  breadcrumbs,
  backPage,
  handleBackClick,
  actionAdd,
  goTo,
  classes
}) => {
  return (
    <div className={classes.navigationBar}>
      <div className={classes.backForm}>
      {
        handleBackClick && (
          <Button size="small" color="primary" className={classes.button} onClick={handleBackClick}>
            <ArrowBackIcon className={classes.leftIcon} />
            Torna alla lista
          </Button>
        )
      }
      {
        goTo && (
          <Button size="small" color="primary" className={classes.button} onClick={goTo.action}>
            {goTo.label}
          </Button>
        )

      }
      </div>
      <div className={classes.titleForm} ><span>{title}</span></div>
      <div className={classes.breadCrumbsForm}>
        <span>
          {
            breadcrumbs.map( (crumb,i) => (
              crumb + (i < breadcrumbs.length-1  ? ' > ' : '')
            ))
          }
        </span>
      </div>
    </div>
  )
}

export default PageTitle;