import React from 'react'

import {
  Grid
} from '@material-ui/core';

import Input from './Input'
import AutoSelect from './AutoSelect'
import NativeSelect from './NativeSelect'
import Address from './Address'
import Checkbox from './Checkbox'

export default (props) => {
  const { field, index } = props
  let id, name 
  
  if(props.prefix) {
    id = props.prefix+'-'+field.name 
    name = props.slug+'.'+props.prefix+'.'+field.name 
  }
  else {
    id = field.name 
    name = props.slug+'.'+field.name 
  }

  //onsole.log('FIELD MAP > > >',field.name, props.values)

  const fieldMap = {
    input: (
      field.element === 'input' &&
      <Grid item className={props.classes.textFieldContainer} xs={6} key={field.name}>
        <Input 
          key={field.name}
          id={id}
          classnameInput={props.classes.formControl} 
          type={field.type}
          name={name} 
          label={field.label}
          //value={typeof props.values[field.name] === 'string' && props.values[field.name] || ''}
          value={props.values[field.name]}
          onChange={props.onChange} 
          onBlur={props.onBlur}
          error={props.error}
          touched={props.touched}
          errorName={field.name}
          required={field.required || (props.error && props.error[field.name] === 'Campo obbligatorio')}
        />
      </Grid>
    ),
    autoselect: (
      field.element === 'autoselect' &&
      <Grid item className={props.classes.textFieldContainer} xs={6} key={field.name}>
        <AutoSelect 
          key={field.name}
          id={field.name}
          classnameInput={props.classes.formControl} 
          name={name} 
          label={field.label}
          placeholder={field.placeholder}
          //value={typeof props.values[field.name] === 'string' && props.values[field.name] || ''}
          value={props.values[field.name]}
          options={props.options && field.options && props.options[field.options]} 
          onChange={props.onChange && field.callback} 
          onBlur={props.onBlur}
          setFieldValue={props.setFieldValue}
          setFieldTouched={props.setFieldTouched}
          error={props.error}
          touched={props.touched}
          errorName={field.name}
          isMulti={field.isMulti}
          fullValue={field.fullValue}
          required={field.required}
          context={props.context}
        />
      </Grid>
    ),
    select: (
      field.element === 'select' &&
      <Grid item className={props.classes.textFieldContainer} xs={6} key={field.name}>
        <NativeSelect 
          key={field.name}
          id={field.name}
          classnameInput={props.classes.formControl} 
          name={name} 
          label={field.label}
          placeholder={field.placeholder}
          value={props.values[field.name]}
          //value={typeof props.values[field.name] === 'string' && props.values[field.name] || ''}
          options={props.options && props.options[field.options]} 
          onChange={props.onChange && field.callback} 
          onBlur={props.onBlur}
          setFieldValue={props.setFieldValue}
          setFieldTouched={props.setFieldTouched}
          error={props.error}
          touched={props.touched}
          errorName={field.name}
          isMulti={field.isMulti}
          required={field.required}
          context={props.context}
        />
      </Grid>
    ),
    address: (
      field.element === 'address' && 
      <Address 
        key={`address-${index}`}
        slug={props.slug}
        classnameInput={props.classes.formControl} 
        classnameGrid={props.classes.textFieldContainer}
        fields={field.fields}
        values={props.values} 
        options={props.options && props.options['citta_byProvince']} 
        onChange={props.onChange} 
        onBlur={props.onBlur}
        setFieldValue={props.setFieldValue}
        setFieldTouched={props.setFieldTouched}
        error={props.error}
        touched={props.touched}
        context={props.context}
      />
    ),
    checkbox: (
      field.element === 'checkbox' &&
      <Checkbox
        key={field.name} 
        name={field.name} 
        classnameInput={props.classes.formControl} 
        label={field.label}
        value={field.value}//props.values[field.name]} 
        color={field.color}
        setFieldValue={props.setFieldValue}
        setFieldTouched={props.setFieldTouched}
        checked={props.checked && props.checked[field.name]}
        onChange={props.onChange && ( () => {
          props.setFieldValue(`${props.slug}.${field.name}`, !props.checked[field.name])
          field.callback(props.context, !props.checked[field.name])
        })} 
        context={props.context}
      />
    )
  }

  return fieldMap[field.element]
}