export default {
  data: [
    {
      id: 0,
      title: 'Dati aziendali',
      fields: [
        {
          element: 'input',
          name: 'denominazione',
          label: 'Ragione sociale',
          required: true,
        },
        {
          element: 'input',
          name: 'rea',
          label: 'Rea',
          required: false,
        },
        {
          element: 'input',
          name: 'partita_iva',
          label: 'Partita iva',
          required: false,
        },
        {
          element: 'address',
          name: 'sl_address',
          fields: {
            nazione: {
              name: 'sl_nazione',
              label: 'Nazione',
              placeholder: 'Seleziona una nazione',
              options: 'allNations',
              required: true
            },
            provincia: {
              name: 'sl_provincia',
              label: 'Provincia',
              placeholder: 'Seleziona una provincia',
              options: 'province',
              callback: (context, event) => {
                // context.formAgenti.current.setFieldValue('shipper.provincia', '')
                return context.getCitta(event.target.value,'sl_citta')
              },
              required: true
            },
            citta: {
              name: 'sl_citta',
              label: 'Città',
              options: 'citta',
              placeholder: 'Seleziona una città',
              required: true
            },
            indirizzo: {
              name: 'sl_indirizzo',
              label: 'Indirizzo',
              required: true
            },
            cap: {
              name: 'sl_cap',
              label: 'Cap',
              required: true
            }
          }
        },
        {
          element: 'input',
          name: 'email',
          label: 'E-mail',
          required: true
        },
        {
          element: 'input',
          name: 'pec',
          label: 'Pec',
          //required: true,
          condition: {
            compare: 0, // -2:< ; -1:<=; 0:=; 1:>=; 2:>
            field: 'sl_nazione',
            value: 'Italia'
          }
        },
        {
          element: 'input',
          name: 'sdi',
          label: 'SDI',
          //required: true,
          condition: {
            compare: 0, // -2:< ; -1:<=; 0:=; 1:>=; 2:>
            field: 'sl_nazione',
            value: 'Italia'
          }
        },
        {
          element: 'input',
          name: 'telefono',
          value: '',
          label: 'Telefono',
          required: true
        },
        {
          element: 'checkbox',
          name: 'sede_coincide',
          value: 'same',
          label: 'La sede operativa coincide con la sede legale',
          callback: (context,value) => context.handleVisibilitySection('so_address',!value)
        },
        {
          element: 'address',
          name: 'so_address',
          show: {
            condition: (values) => {
              return values['sede_coincide'] === false 
            }
          },          
          fields: {
            nazione: {
              name: 'so_nazione',
              label: 'Nazione',
              placeholder: 'Seleziona una nazione',
              options: 'allNations',
              required: true
            },
            provincia: {
              name: 'so_provincia',
              label: 'Provincia',
              placeholder: 'Seleziona una provincia',
              options: 'province',
              callback: (context, event) => {
                // context.formAgenti.current.setFieldValue('shipper.provincia', '')
                return context.getCitta(event.target.value,'so_citta')
              },
              required: true
            },
            citta: {
              name: 'so_citta',
              label: 'Città',
              options: 'citta',
              placeholder: 'Seleziona una città',
              required: true
            },
            indirizzo: {
              name: 'so_indirizzo',
              label: 'Indirizzo',
              required: true
            },
            cap: {
              name: 'so_cap',
              label: 'Cap',
              required: true
            }
          }
        }
      ]
    },
    {
      id: 1,
      title: 'Referente',
      fields: [
        {
          element: 'input',
          name: 'contatto_nome',
          label: 'Nome referente',
          required: true,  
        },
        {
          element: 'input',
          name: 'contatto_cognome',
          label: 'Cognome referente',
          required: true,  
        },
        {
          element: 'input',
          name: 'contatto_ruolo',
          label: 'Ruolo referente',
          required: true,  
        },
        {
          element: 'input',
          name: 'contatto_cellulare',
          label: 'Telefono referente',
          required: false,  
        },
        {
          element: 'input',
          name: 'contatto_email',
          label: 'E-mail referente',
          required: false,  
        }
      ]
    },
    {
      id: 2,
      title: 'Dati commerciali',
      fields: [
        {
          element: 'input',
          name: 'sconti',
          label: 'Sconti',
          required: false,  
        },
        {
          element: 'input',
          name: 'fido',
          label: 'Fido',
          required: false,  
        },
        {
          element: 'input',
          name: 'pagamento',
          label: 'Pagamento',
          required: false,  
        },
        {
          element: 'input',
          name: 'ns_banca',
          label: 'Banca',
          required: false,  
        },
        {
          element: 'input',
          name: 'coordinate_bancarie',
          label: 'IBAN',
          required: false,  
        },
        {
          element: 'input',
          name: 'swift',
          label: 'SWIFT',
          required: false,  
        },
        // {
        //   element: '`',
        //   name: 'porto',
        //   label: 'Porto',
        //   options: [
        //     {
        //       label:'Franco',
        //       value: 'franco'
        //     },
        //     {
        //       label:'Assegnato',
        //       value: 'assegnato'
        //     }
        //   ],
        //   required: false
        // }
      ]
    }
  ]
}