import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';

import { 
  getSupplierById, 
  putSupplierById, 
  getAllProvince, 
  getAllCitiesByProvince, 
  postAllCitiesByProvinces,
  setSnack 
} from '../actions';

import {
  Paper, 
  Grid,
  Input,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormHelperText,
  FormControl,
  FormControlLabel,
  Button
} from '@material-ui/core';

import { 
  ArrowBack, 
  Visibility as ViewIcon
} from '@material-ui/icons';

import RSelect from '../components/ReactSelect';
import ErrorFocus from '../components/ErrorFocus' 
import { allNations } from '../data/nations'
import Fornitore from '../data/FormTemplates/Fornitore'
import FormFields from '../components/Form/Fields'
import LoadingPage from '../components/LoadingPage'

import InputCustom from '../components/Form/Input'
import AutoSelect from '../components/Form/AutoSelect'
import NativeSelect from '../components/Form/NativeSelect'

class FornitoreEdizione extends React.Component {

  constructor(props) {
    super(props)
    this.formFornitori = React.createRef();

    this.state= {
      province: [],
      citta_byProvince: {
        sl_citta: [],
        so_citta: [],
        warehouses: []
      },
      loading_sl_citta: false,
      loading_so_citta: false,
      supplier: {
        sede_coincide: false,
        denominazione: '',
        rea: '',
        partita_iva: '',
        sdi: '',
        sl_nazione: '',
        sl_provincia: '',
        sl_citta: '',
        sl_indirizzo: '',
        sl_cap: '',
        email: '',
        pec: '',
        telefono: '',
        skype: '',
        fax: '',
        sito_web: '',
        so_nazione: '',
        so_provincia: '',
        so_citta: '',
        so_indirizzo: '',
        so_cap: '',
        contatto_nome: '',
        contatto_cognome: '',
        contatto_cellulare: '',
        contatto_ruolo: '',
        contatto_email: '',
        sconti: '',
        fido: '',
        pagamento: '',
        ns_banca: '',
        coordinate_bancarie: '',
        swift: '',
        porto: '',
        warehouses: [{
          id: '',
          denominazione: '',
          email: '',
          telefono: '',
          referente: '',
          nazione: '',
          provincia: '',
          citta: '',
          indirizzo: '',
          cap: ''
          //regione: ''
        }]
      },
      allNations: allNations,
      formTemplate: Fornitore,
      sectionsToHide: []
    }
  }

  componentDidMount() {
    const { getSupplierById, getAllProvince, match } = this.props;

    if(match.params.id) {
      getSupplierById(match.params.id).then( (response) => {
        this.setState({
          sectionsToHide: response.sede_coincide ? ['so_address'] : [],
          supplier: {
            sede_coincide: response.sede_coincide || false,
            denominazione: response.denominazione || '' ,
            rea: response.rea || '' ,
            partita_iva: response.partita_iva || '' ,
            sdi: response.sdi || '' ,
            email: response.email || '' ,
            pec: response.pec || '' ,
            skype: response.skype || '' ,
            telefono: response.telefono || '' ,
            fax: response.fax || '' ,
            sito_web: response.sito_web || '' ,
            sl_nazione: response.sl_nazione || '',//{id: response.sl_nazione, label: response.sl_nazione},
            sl_provincia: response.sl_provincia || '' ,
            sl_citta: response.sl_citta || '' ,
            sl_indirizzo: response.sl_indirizzo || '' ,
            sl_cap: response.sl_cap || '' ,
            so_nazione: response.so_nazione || '' ,//{id: response.so_nazione, label: response.so_nazione},
            so_provincia: response.so_provincia || '' ,
            so_citta: response.so_citta || '' ,
            so_indirizzo: response.so_indirizzo || '' ,
            so_cap: response.so_cap || '' ,
            contatto_nome: response.contatto_nome || '' ,
            contatto_cognome: response.contatto_cognome || '' ,
            contatto_cellulare: response.contatto_cellulare || '' ,
            contatto_ruolo: response.contatto_ruolo || '' ,
            contatto_email: response.contatto_email || '' ,
            sconti: response.sconti || '' ,
            fido: response.fido || '' ,
            pagamento: response.pagamento || '' ,
            ns_banca: response.ns_banca || '' ,
            coordinate_bancarie: response.coordinate_bancarie || '' ,
            swift: response.swift || '' ,
            porto: response.porto || '',
            warehouses: response.warehouses.length > 0 ? response.warehouses : [{
              id: '',
              denominazione: '',
              email: '',
              telefono: '',
              referente: '',
              nazione: '',
              provincia: '',
              citta: '',
              indirizzo: '',
              cap: ''
            }]
          }
        })

        if(response.sl_provincia) {
          this.getCitta(response.sl_provincia, 'sl_citta')
        }
        if(response.so_provincia) {
          this.getCitta(response.so_provincia, 'so_citta')
        }
        if(response.warehouses.length > 0) {
          let provinces = []
          response.warehouses.map( (warehouse)=>{
            provinces.push(warehouse.provincia)
          })
          this.getAllCitiesWarehouses(provinces)
        }
      }).catch((error)=>{})
    }

    getAllProvince().then((response)=>{
      let options = []
      response.map((s,i) => {
        options = options.concat({
          value: s.nome,
          label: s.nome
        })
      })
      this.setState({
        province: options
      })
    }).catch((error)=>{})
  }

  getCitta = (province, tagCitta) => {
    this.setState({
      ['loading_'+tagCitta]: true
    })
    getAllCitiesByProvince(province).then((response)=>{
      let options = []
      response.map((s,i) => {
        options = options.concat({
          value: s.nome,
          label: s.nome
        })
      })
      this.setState({
        citta_byProvince: {
          ...this.state.citta_byProvince,
          [tagCitta]: options
        },
        ['loading_'+tagCitta]: false
      })
    }).catch((error)=>{})
  }

  getCittaWarehouse = (province, i) => {
    let warehousesProvinces = this.state.citta_byProvince.warehouses
    
    if(warehousesProvinces[i]) warehousesProvinces[i].loading = true

    getAllCitiesByProvince(province,i).then((response)=>{
      let options = []
      response.map((s,i) => {
        options = options.concat({
          value: s.nome,
          label: s.nome
        })
      })
      warehousesProvinces.splice(i,0,{
        options,
        loading: false
      })
      // warehousesProvinces[i].options = options
      // warehousesProvinces[i].loading = false
      this.setState({
        citta_byProvince: {
          ...this.state.citta_byProvince,
          warehouses: warehousesProvinces
        }
      })
    }).catch((error)=>{})
  }

  getAllCitiesWarehouses = (provinces) => {
    postAllCitiesByProvinces(provinces).then((response) => {
      let warehousesOptions = []
      response.map((r)=>{
        let options = []
        r.map((s)=>{
          options = options.concat({
            value: s.nome,
            label: s.nome
          })
        })
        warehousesOptions.push({
          options,
          loading: false
        })
      })
      this.setState({
        citta_byProvince: {
          ...this.state.citta_byProvince,
          warehouses: warehousesOptions
        }
      })
      //console.log('warehousesOptions > > >',warehousesOptions)
    }).catch((error)=>{})
  }

  addWarehouse = () => {
    let newWarehouses = this.state.supplier.warehouses
    let newWarehousesOptions = this.state.citta_byProvince.warehouses
    const index = newWarehouses.length

    
    this.setState((state)=>{
      return {
        ...state,
        supplier: {
          ...state.supplier,
          warehouses: newWarehouses
        }
      }
    },
    () => {
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${index}].id`, '');
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${index}].denominazione`, '');
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${index}].nazione`, '');
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${index}].provincia`, '');
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${index}].citta`, '');
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${index}].indirizzo`, '');
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${index}].cap`, '');
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${index}].email`, '');
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${index}].telefono`, '');
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${index}].referente`, '');
    })
  }

  removeWarehouse = (values,i) => {
    const newValues = [...values]
    newValues.splice(i,1)
    this.formFornitori.current.setFieldValue(`supplier.warehouses`, []);
    newValues.map((v,x)=>{
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${x}].id`, v.id);
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${x}].denominazione`, v.denominazione);
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${x}].nazione`, v.nazione);
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${x}].provincia`, v.provincia);
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${x}].citta`, v.citta);
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${x}].indirizzo`, v.indirizzo);
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${x}].cap`, v.cap);
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${x}].email`, v.email);
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${x}].telefono`, v.telefono);
      this.formFornitori.current.setFieldValue(`supplier.warehouses[${x}].referente`, v.referente);
    })
  }

  handleVisibilitySection = (section, show) => {
    let newSectionsToHide = this.state.sectionsToHide
    const position = this.state.sectionsToHide.indexOf(section)
    
    if(position === -1) {
      // section is already showed and I want to hide
      !show && newSectionsToHide.push(section)
    }
    else {
      show && newSectionsToHide.splice(position, 1)
    }

    this.setState({
      sectionsToHide: newSectionsToHide
    })
  }
  
  render() {
    const copy = (o) => {
      let output, v, key;
      output = Array.isArray(o) ? [] : {};
      for (key in o) {
          v = o[key];
          output[key] = (typeof v === "object") ? copy(v) : v;
      }
      return output;
   }
    const { classes, match, history, loading } = this.props
    const { supplier, formTemplate } = this.state
    
    return (
      <React.Fragment>
        {loading && <LoadingPage /> }
          <div className={classes.navigationBar}>
            <div className={classes.backForm}>
              <Button size="small" color="primary" className={classes.button} onClick={()=>history.push('/fornitori')}>
                <ArrowBack className={classes.leftIcon} /> Torna alla lista
              </Button>
            </div>
            <div className={classes.titleForm} ><span>Formulario fornitore</span></div>
            <div className={classes.breadCrumbsForm}>
              <span>W-SHOP > FORNITORI > FORMULARIO</span>
            </div>
          </div>
          <Formik
            ref={this.formFornitori}
            initialValues={{ supplier: supplier, errors: {} }}
            enableReinitialize={true} 
            validateOnChange={false}
            validate={(value) => {
              let fields = {warehouses: [{}]};
              let scrollToID = []
              let hasErrorWarehouses= false

              if(value.supplier.denominazione === '') {
                fields.denominazione = 'Campo obbligatorio'
                scrollToID.push('input-denominazione')
              }
              if(value.supplier.partita_iva === '') {
                fields.partita_iva = 'Campo obbligatorio'
                scrollToID.push('input-partita_iva')
              }
              
              if(value.supplier.sl_nazione === '') {
                fields.sl_nazione = 'Campo obbligatorio'
                scrollToID.push('input-sl_nazione')
              }
              if(value.supplier.sl_provincia === '') {
                fields.sl_provincia = 'Campo obbligatorio'
                scrollToID.push('input-sl_provincia')
              }
              if(value.supplier.sl_citta === '') {
                fields.sl_citta = 'Campo obbligatorio'
                scrollToID.push('input-sl_citta')
              }
              if(value.supplier.sl_indirizzo === '') {
                fields.sl_indirizzo = 'Campo obbligatorio'
                scrollToID.push('input-sl_indirizzo')
              }
              if(value.supplier.sl_cap === '') {
                fields.sl_cap = 'Campo obbligatorio'
                scrollToID.push('input-sl_cap')
              }

              if(value.supplier.email === '') {
                fields.email = 'Campo obbligatorio'
                scrollToID.push('input-email')
              }
              if(value.supplier.sl_nazione.label === 'Italia') {
                if(value.supplier.pec === '') {
                  fields.pec = 'Campo obbligatorio'
                  scrollToID.push('input-pec')
                }
                if(value.supplier.sdi === '') {
                  fields.sdi = 'Campo obbligatorio'
                  scrollToID.push('input-sdi')
                }
              }              
              if(value.supplier.telefono === '') {
                fields.telefono = 'Campo obbligatorio'
                scrollToID.push('input-telefono')
              }

              if(value.supplier.sede_coincide === false) {
                if(value.supplier.so_nazione === '') {
                  fields.so_nazione = 'Campo obbligatorio'
                  scrollToID.push('input-so_nazione')
                }
                if(value.supplier.so_provincia === '') {
                  fields.so_provincia = 'Campo obbligatorio'
                  scrollToID.push('input-so_provincia')
                }
                if(value.supplier.so_citta === '') {
                  fields.so_citta = 'Campo obbligatorio'
                  scrollToID.push('input-so_citta')
                }
                if(value.supplier.so_indirizzo === '') {
                  fields.so_indirizzo = 'Campo obbligatorio'
                  scrollToID.push('input-so_indirizzo')
                }
                if(value.supplier.so_cap === '') {
                  fields.so_cap = 'Campo obbligatorio'
                  scrollToID.push('input-so_cap')
                }
              }

              if(value.supplier.contatto_nome === '') {
                fields.contatto_nome = 'Campo obbligatorio'
                scrollToID.push('input-contatto_nome')
              }
              if(value.supplier.contatto_cognome === '') {
                fields.contatto_cognome = 'Campo obbligatorio'
                scrollToID.push('input-contatto_cognome')
              }
              if(value.supplier.contatto_ruolo === '') {
                fields.contatto_ruolo = 'Campo obbligatorio'
                scrollToID.push('input-contatto_ruolo')
              }

              value.supplier.warehouses.map((warehouse,i)=> {
                if(warehouse.denominazione === '') {
                  fields.warehouses[i].denominazione = 'Campo obbligatorio'
                  hasErrorWarehouses= true
                  scrollToID.push(`input-warehouse-${i}-denominazione`)
                }
                if(warehouse.email === '') {
                  fields.warehouses[i].email = 'Campo obbligatorio'
                  hasErrorWarehouses= true
                  scrollToID.push(`input-warehouse-${i}-email`)
                }
                if(warehouse.nazione === '') {
                  fields.warehouses[i].nazione = 'Campo obbligatorio'
                  hasErrorWarehouses= true
                  scrollToID.push(`input-warehouse-${i}-nazione`)
                }
                if(warehouse.provincia === '') {
                  fields.warehouses[i].provincia = 'Campo obbligatorio'
                  hasErrorWarehouses= true
                  scrollToID.push(`input-warehouse-${i}-provincia`)
                }
                if(warehouse.citta === '') {
                  fields.warehouses[i].citta = 'Campo obbligatorio'
                  hasErrorWarehouses= true
                  scrollToID.push(`input-warehouse-${i}-citta`)
                }
                if(warehouse.indirizzo === '') {
                  fields.warehouses[i].indirizzo = 'Campo obbligatorio'
                  hasErrorWarehouses= true
                  scrollToID.push(`input-warehouse-${i}-indirizzo`)
                }
                if(warehouse.cap === '') {
                  fields.warehouses[i].cap = 'Campo obbligatorio'
                  hasErrorWarehouses= true
                  scrollToID.push(`input-warehouse-${i}-cap`)
                }
                if(warehouse.email === '') {
                  fields.warehouses[i].email = 'Campo obbligatorio'
                  hasErrorWarehouses= true
                  scrollToID.push(`input-warehouse-${i}-email`)
                }
                if(warehouse.telefono === '') {
                  fields.warehouses[i].telefono = 'Campo obbligatorio'
                  hasErrorWarehouses= true
                  scrollToID.push(`input-warehouse-${i}-telefono`)
                }
              })

              if(hasErrorWarehouses === false) {
                delete fields.warehouses
              }

              const errors = {}
              if(Object.keys(fields).length > 0) errors.fields = fields
              if(scrollToID[0]) errors.first = scrollToID[0]

              return errors
              
            }}
            onSubmit={(values, actions, saveAndView) => {
              let id = 'create'
              let action = 'creato'
              if(this.props.match.params.id) {
                id = match.params.id 
                action = 'aggiornato'
              } 
              const allFields = copy(values.supplier)
              
              if(allFields.sede_coincide) {  
                allFields.so_nazione = values.supplier.sl_nazione
                allFields.so_provincia = values.supplier.sl_provincia
                allFields.so_citta = values.supplier.sl_citta
                allFields.so_indirizzo = values.supplier.sl_indirizzo
                allFields.so_cap = values.supplier.sl_cap
              }
              // allFields.sl_nazione = allFields.sl_nazione.label
              // allFields.so_nazione = allFields.so_nazione.label

              // allFields.warehouses.map((warehouse) => {
              //   warehouse.nazione = warehouse.nazione.label
              // })
              
              this.props.putSupplierById(id, allFields).then((response) => {
                if(response.status >= 200 && response.status < 300) {
                  this.props.setSnack(true,`Fornitore ${action} con successo`,'success')
                  if(saveAndView) {
                    this.props.history.push(`/fornitore/${response.data.data.id}`);
                  }
                  else {
                    this.props.history.push(`/fornitore/edit/${response.data.data.id}`);
                  }
                }
                else {
                  this.props.setSnack(true,'Form non compilato correttamente, verifica i dati inseriti','warning')
                  Object.keys(response.data.errors).map(function(key, index) {
                    actions.setFieldError(key,response.data.errors[key])
                  })
                }
              }).catch((error)=>{})
            }}
            render={(props) => {
              const { 
                touched, 
                errors,
                values,
                handleBlur,
                handleChange,
                setFieldValue,
                setFieldTouched
              } = props
              return (
                <form> 
                  <ErrorFocus firstItem={errors.first} />
                  <div className={classes.actionsForm} >
                    { this.props.match.params.id &&
                      <Button 
                        size="small" 
                        color="primary" 
                        className={classes.buttonRight} 
                        onClick={()=> this.props.history.push(`/fornitore/${match.params.id}`)}
                      >
                        <ViewIcon className={classes.leftIcon} />
                        Visualizza scheda
                      </Button>
                    }
                  </div>
                  {
                    <FormFields 
                      classes={classes}
                      slug='supplier'
                      template={formTemplate}
                      onChange={handleChange} 
                      onBlur={handleBlur}
                      setFieldValue={setFieldValue}
                      setFieldTouched={setFieldTouched}
                      error={errors.fields}
                      touched={touched.supplier}
                      values={values.supplier} 
                      options={
                        {
                          citta_byProvince: this.state.citta_byProvince
                        }
                      }
                      checked={{
                        sede_coincide: values.supplier.sede_coincide
                      }}
                      sectionsToHide={this.state.sectionsToHide}
                      context={this}
                    />
                  }
                  <Paper className={classes.root} elevation={2}>
                    <Grid container className={classes.formContainer}>
                    <div className={classes.sectionTitle}>Lista depositi</div>
                      {props.values.supplier.warehouses.map((warehouse,i)=>(
                        <Grid container key={i}>
                          <div className={classes.subtitleBox}>
                            <h3 className={classes.subtitle}>Deposito {i+1}</h3>
                            { i === 0 && props.values.supplier.warehouses.length === 1 ? 
                              null
                              : <Button onClick={()=>this.removeWarehouse(props.values.supplier.warehouses,i)} color="primary" className={classes.button}>Rimuovi deposito</Button>
                            }
                          </div>
                          <Grid item className={classes.textFieldContainer} xs={6}>
                            <FormControl 
                              className={classes.formControl} 
                              error={
                                errors.fields &&
                                errors.fields.warehouses && 
                                errors.fields.warehouses[i] && 
                                errors.fields.warehouses[i].denominazione !== undefined &&
                                touched.supplier && 
                                touched.supplier.warehouses &&
                                touched.supplier.warehouses[i] &&
                                touched.supplier.warehouses[i].denominazione
                              }>
                              <InputLabel htmlFor={`input-warehouses-${i}-denominazione`}>Denominazione*</InputLabel>
                              <Input 
                                id={`input-warehouses-${i}-denominazione`} 
                                name={`supplier.warehouses[${i}].denominazione`} 
                                value={warehouse.denominazione} 
                                onChange={props.handleChange} 
                                onBlur={props.handleBlur}
                              />
                              {
                                errors.fields &&
                                errors.fields.warehouses && 
                                errors.fields.warehouses[i] && 
                                errors.fields.warehouses[i].denominazione && 
                                touched.supplier && 
                                touched.supplier.warehouses &&
                                touched.supplier.warehouses[i] &&
                                touched.supplier.warehouses[i].denominazione &&
                                <FormHelperText>{errors.fields.warehouses[i].denominazione}</FormHelperText>
                              }
                            </FormControl>
                          </Grid>
                          
                          <Grid item className={classes.textFieldContainer} xs={6}>
                            <AutoSelect 
                              //key={fields.nazione.name}
                              id={`input-warehouses-${i}-nazione`}
                              classnameInput={classes.formControl} 
                              name={`supplier.warehouses[${i}].nazione`}
                              label="Nazione"
                              placeholder="Seleziona una nazione"
                              value={ warehouse.nazione }
                              options={this.state.allNations}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              setFieldValue={setFieldValue}
                              setFieldTouched={setFieldTouched}
                              error={
                                  errors.fields && 
                                  errors.fields.warehouses &&
                                  errors.fields.warehouses[i]
                                }
                              touched={
                                touched.supplier && 
                                touched.supplier.warehouses && touched.supplier.warehouses[i]
                              }
                              errorName={`nazione`}
                              required={true}
                              //context={props.context}
                            />
                          </Grid>
                          <Grid item className={classes.textFieldContainer} xs={6}>
                            { 
                              //warehouse.nazione && 
                              warehouse.nazione === 'Italia' 
                              ?
                              <NativeSelect
                                id={`input-warehouses-${i}-provincia`}
                                classnameInput={classes.formControl} 
                                name={`supplier.warehouses[${i}].provincia`}
                                label="Provincia"
                                value={ warehouse.provincia }
                                options={this.state.province} 
                                onChange={(context, event) => {
                                  this.getCittaWarehouse(event.target.value, i)
                                }} 
                                onBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                error={
                                  errors.fields && 
                                  errors.fields.warehouses &&
                                  errors.fields.warehouses[i]
                                }
                                touched={
                                  touched.supplier && 
                                  touched.supplier.warehouses && touched.supplier.warehouses[i]
                                }
                                errorName={`provincia`}
                                required={true}
                                context={this}
                              />
                              :
                              <InputCustom
                                id={`input-warehouses-${i}-provincia`}
                                classnameInput={classes.formControl} 
                                name={`supplier.warehouses[${i}].provincia`}
                                label="Provincia"
                                value={ warehouse.provincia }
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                error={
                                  errors.fields && 
                                  errors.fields.warehouses &&
                                  errors.fields.warehouses[i]
                                }
                                touched={
                                  touched.supplier && 
                                  touched.supplier.warehouses && touched.supplier.warehouses[i]
                                }
                                errorName={`provincia`}
                                required={true}
                              />
                            }
                          </Grid>
                          <Grid item className={classes.textFieldContainer} xs={6}>
                            { 
                              warehouse.nazione === 'Italia' 
                              ?
                              <NativeSelect
                                id={`input-warehouses-${i}-citta`}
                                classnameInput={classes.formControl} 
                                name={`supplier.warehouses[${i}].citta`}
                                label="Città"
                                value={ warehouse.citta }
                                options={this.state.citta_byProvince.warehouses[i] && this.state.citta_byProvince.warehouses[i].options} 
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                setFieldTouched={setFieldTouched}
                                error={
                                  errors.fields && 
                                  errors.fields.warehouses &&
                                  errors.fields.warehouses[i]
                                }
                                touched={
                                  touched.supplier && 
                                  touched.supplier.warehouses && touched.supplier.warehouses[i]
                                }
                                errorName={`citta`}
                                required={true}
                                context={this}
                              />
                              :
                              <InputCustom
                                id={`input-warehouses-${i}-citta`}
                                classnameInput={classes.formControl} 
                                name={`supplier.warehouses[${i}].citta`}
                                label="Città"
                                value={ warehouse.citta }
                                onChange={handleChange} 
                                onBlur={handleBlur}
                                error={
                                  errors.fields && 
                                  errors.fields.warehouses &&
                                  errors.fields.warehouses[i]
                                }
                                touched={
                                  touched.supplier && 
                                  touched.supplier.warehouses && touched.supplier.warehouses[i]
                                }
                                errorName={`citta`}
                                required={true}
                              />
                            }
                          </Grid>

                          <Grid item className={classes.textFieldContainer} xs={6}>
                            <InputCustom 
                              id={`input-warehouses-${i}-indirizzo`}
                              classnameInput={classes.formControl} 
                              name={`supplier.warehouses[${i}].indirizzo`}
                              label="Indirizzo"
                              value={ warehouse.indirizzo }
                              onChange={handleChange} 
                              onBlur={handleBlur}
                              error={
                                errors.fields && 
                                errors.fields.warehouses &&
                                errors.fields.warehouses[i]
                              }
                              touched={
                                touched.supplier && 
                                touched.supplier.warehouses && touched.supplier.warehouses[i]
                              }
                              errorName={`indirizzo`}
                              required={true}
                            />
                          </Grid>
                          <Grid item className={classes.textFieldContainer} xs={6}>
                            <InputCustom 
                              id={`input-warehouses-${i}-cap`}
                              classnameInput={classes.formControl} 
                              name={`supplier.warehouses[${i}].cap`}
                              label="Cap"
                              value={ warehouse.cap }
                              onChange={handleChange} 
                              onBlur={handleBlur}
                              error={
                                errors.fields && 
                                errors.fields.warehouses &&
                                errors.fields.warehouses[i]
                              }
                              touched={
                                touched.supplier && 
                                touched.supplier.warehouses && touched.supplier.warehouses[i]
                              }
                              errorName={`cap`}
                              required={true}
                            />
                          </Grid>
                          <Grid item className={classes.textFieldContainer} xs={6}>
                            <FormControl 
                              className={classes.formControl} 
                              error={
                                errors.fields &&
                                errors.fields.warehouses && 
                                errors.fields.warehouses[i] && 
                                errors.fields.warehouses[i].email !== undefined &&
                                touched.supplier && 
                                touched.supplier.warehouses &&
                                touched.supplier.warehouses[i] &&
                                touched.supplier.warehouses[i].email 
                              }
                            >
                              <InputLabel htmlFor={`input-warehouses-${i}-email`}>E-mail*</InputLabel>
                              <Input 
                                id={`input-warehouses-${i}-email`} 
                                name={`supplier.warehouses[${i}].email`} 
                                value={warehouse.email} 
                                onChange={props.handleChange} 
                                onBlur={props.handleBlur} 
                              />
                              {
                                errors.fields &&
                                errors.fields.warehouses && 
                                errors.fields.warehouses[i] && 
                                errors.fields.warehouses[i].email &&
                                touched.supplier && 
                                touched.supplier.warehouses &&
                                touched.supplier.warehouses[i] &&
                                touched.supplier.warehouses[i].email &&  
                                <FormHelperText>{errors.fields.warehouses[i].email}</FormHelperText>
                              }
                            </FormControl>
                          </Grid>
                          <Grid item className={classes.textFieldContainer} xs={6}>
                            <FormControl 
                              className={classes.formControl} 
                              error={
                                errors.fields &&
                                errors.fields.warehouses && 
                                errors.fields.warehouses[i] && 
                                errors.fields.warehouses[i].telefono !== undefined &&
                                touched.supplier && 
                                touched.supplier.warehouses &&
                                touched.supplier.warehouses[i] &&
                                touched.supplier.warehouses[i].telefono 
                              }
                            >
                              <InputLabel htmlFor={`input-warehouses-${i}-telefono`}>Telefono*</InputLabel>
                              <Input 
                                id={`input-warehouses-${i}-telefono`} 
                                name={`supplier.warehouses[${i}].telefono`} 
                                value={warehouse.telefono} 
                                onChange={props.handleChange} 
                                onBlur={props.handleBlur} 
                              />
                              {
                                errors.fields &&
                                errors.fields.warehouses && 
                                errors.fields.warehouses[i] && 
                                errors.fields.warehouses[i].telefono &&
                                touched.supplier && 
                                touched.supplier.warehouses &&
                                touched.supplier.warehouses[i] &&
                                touched.supplier.warehouses[i].telefono &&  
                                <FormHelperText>{errors.fields.warehouses[i].telefono}</FormHelperText>
                              }
                            </FormControl>
                          </Grid>
                          <Grid item className={classes.textFieldContainer} xs={6}>
                            <FormControl className={classes.formControl} >
                              <InputLabel htmlFor={`input-warehouses-${i}-referente`}>Referente</InputLabel>
                              <Input 
                                id={`input-warehouses-${i}-referente`} 
                                name={`supplier.warehouses[${i}].referente`} 
                                value={warehouse.referente} 
                                onChange={props.handleChange} 
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      ))
                    }
                    </Grid>
                  </Paper>             
                  <Grid container justify="center">
                    {
                      props.values.supplier.warehouses.length > 0  && 
                      <Button 
                        onClick={()=>this.addWarehouse()} 
                        //variant="contained" 
                        color="primary" 
                        className={classes.button}
                      >
                      + Nuovo deposito
                      </Button> 
                    }
                    <Button 
                      type="submit" 
                      onClick={(values,actions) => props.handleSubmit(values, actions,false) }
                      variant="contained" 
                      color="primary" 
                      className={this.props.classes.button}
                    >
                      Salva e aggiorna
                    </Button>
                    <Button 
                      type="submit" 
                      onClick={(values,actions) => props.handleSubmit(values, actions,true) }
                      variant="contained" 
                      color="primary" 
                      className={this.props.classes.button}
                    >
                      Salva e visualizza
                    </Button>
                  </Grid>
                </form>
              )
            }}
          />
        </React.Fragment>
    );
  }
}

FornitoreEdizione.propTypes = {
  supplier: PropTypes.object,
  getSupplierById: PropTypes.func,
  putSupplierById: PropTypes.func,
  getAllProvince: PropTypes.func,
  getAllCitiesByProvince: PropTypes.func,
  setSnack: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  supplier: state.suppliers.selected,
  user: state.user,
  loading: state.info.loading
});
const mapDispatchToProps = {
  getSupplierById,
  putSupplierById,
  getAllProvince,
  getAllCitiesByProvince,
  postAllCitiesByProvinces,
  setSnack
};

export default withRouter(
  connect(mapStateToProps,mapDispatchToProps)(FornitoreEdizione)
)
