import { 
  RETURN_OPERATORS, 
  GET_OPERATOR_DETAILS, 
  POST_OPERATOR 
} from '../actions';

const initialState = {
  list: {},
  selected: {},
  pagination: {}
}

export default function users(state = initialState, action) {
  switch (action.type) {
    case RETURN_OPERATORS:
      return {
        ...state,
        list: action.operators,
        selected: {},
      };
    case GET_OPERATOR_DETAILS:
    case POST_OPERATOR: 
      return {
        ...state,
        selected: action.operator
      };
    default:
      return state;
  }
}
