import ajax from 'axios';
import { history } from '../store'
import { API_URL, USER_LST, USER_LST_TMP } from '../config'
import { updateCart } from './actionsCart'
export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const SET_USER = 'SET_USER';

function loggedIn(data, cart) {
  return {
    type: LOGGED_IN,
    data,
    cart
  };
}

function loggedOut(data) {
  return {
    type: LOGGED_OUT,
  };
}

export function setUser(user) {
  return {
    type: SET_USER,
    user
  };
}

export function submitLogin(user) {
  return (dispatch, getState) => {
    let url = API_URL+'/auth/login';
    
    return ajax
      .post(url, user)
      .then(response => {
        
        if (response.status === 200) {
          localStorage.setItem(USER_LST, JSON.stringify(response.data.data))
          const cart = updateCart(response.data.data.cart.products)
          return dispatch(loggedIn(response.data.data,cart));
        }
      })
      .catch(function(error) {
        return error.response
      });
  };
}

export function loginAs(id) {
  return (dispatch, getState) => {
    const user = getState().user
    const urlPath = user.level === 'admin' ?  'admin/users' : `${user.level}/pos`

    //let url = API_URL+'/admin/users/'+userId+'/impersonate';

    return ajax
      .get(`${API_URL}/${urlPath}/${id}/impersonate`)
      //.get(url)
      .then(response => {
        if (response.status === 200) {
          const user_lst = localStorage.getItem(USER_LST)
          localStorage.setItem(USER_LST_TMP, user_lst)
          localStorage.setItem(USER_LST, JSON.stringify(response.data.data))
          
          const newData = Object.assign({},response.data.data)
          newData.impersonificate = JSON.parse(user_lst)
          
          dispatch(loggedIn(newData,updateCart(response.data.data.cart.products)));
          return  newData
        }
      })
      .catch(function(error) {
        //console.log(error);
      });
  };
}

export function restoreUser(user) {
  return (dispatch, getState) => {
    const url = API_URL+'/auth/logout';

    return ajax
      .get(url)
      .then(response => {
        //if (response.status >= 200 && response.status < 300) {
        dispatch(dispatch({
          type: SET_USER,
          user
        }));
        localStorage.setItem(USER_LST, JSON.stringify(user))
        localStorage.removeItem(USER_LST_TMP)
        return true//response
        //}
      })
      .catch(function(error) {
        //console.log(error);
      });
  }
}
export function getUserInfo() {
  return (dispatch, getState) => {
    const url = API_URL+'/auth/info';

    return ajax
      .get(url)
      .then(response => {
        return response
      //   if (response.status >= 200 && response.status < 300) {
      //     localStorage.removeItem(USER_LST)
      //     localStorage.removeItem(USER_LST_TMP)
      //     dispatch(loggedOut());
      //     return history.push('/login')
      //   }
      })
      .catch(function(error) {
        //console.log(error);
      });
  }
}

export function logout() {
  
  return (dispatch, getState) => {
    const url = API_URL+'/auth/logout';

    return ajax
      .get(url)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          localStorage.removeItem(USER_LST)
          localStorage.removeItem(USER_LST_TMP)
          dispatch(loggedOut());
          return history.push('/login')
        }
      })
      .catch(function(error) {
        //console.log(error);
      });
  };

}

export function submitForget(email) {
  return (dispatch, getState) => {
    let url = API_URL+'/auth/password/create';
    
    return ajax
      .post(url,{
        email: email
      })
      .then(response => {
        return response.data
        // if (response.status === 200) {
        //   localStorage.setItem(USER_LST, JSON.stringify(response.data.data))
        //   const cart = updateCart(response.data.data.cart.products)
        //   return dispatch(loggedIn(response.data.data,cart));
        // }
      })
      .catch(function(error) {
        return error.response
      });
  };
}


export function submitReset({token,password}) {
  return (dispatch, getState) => {
    let url = API_URL+'/auth/password/reset';
    
    return ajax
      .post(url,{
        password: password,
        token: token
      })
      .then(response => {
        
        if (response.status === 200) {
          localStorage.setItem(USER_LST, JSON.stringify(response.data.data))
          const cart = updateCart(response.data.data.cart.products)
          return dispatch(loggedIn(response.data.data,cart));
        }
      })
      .catch(function(error) {
        return error.response
      });
  };
}

export function setClientPsw(psw,userId) {
  return (dispatch, getState) => {
    let url = API_URL+'/user/'+ userId +'/password';
    
    return ajax
      .post(url,{
        password: psw
      })
      .then(response => {
        return response.data
        // if (response.status === 200) {
        //   localStorage.setItem(USER_LST, JSON.stringify(response.data.data))
        //   const cart = updateCart(response.data.data.cart.products)
        //   return dispatch(loggedIn(response.data.data,cart));
        // }
      })
      .catch(function(error) {
        return error.response
      });
  };
}