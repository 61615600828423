import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { 
  getOperatorById, 
  setClientPsw, 
  setSnack 
} from '../actions';

import { 
  Paper,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl
} from '@material-ui/core';

import {
  ArrowBack as ArrowBackIcon, 
  Edit as EditIcon,
  VpnKey as KeyIcon
} from '@material-ui/icons';

import LoadingPage from '../components/LoadingPage'

const ShowField = props => {
  return (
    <div>
      <label className={props.classes.label}>{props.label}</label>
      <p className={props.classes.parag}>{props.value ? props.value : '---'}</p>
    </div>
  )
}


class OperatoreDettaglio extends React.Component {
  
  constructor(props) {
    super(props)
    this.state = {
      showDialog: false,
      showError: '',
    }
    this.inputPsw = React.createRef();
    this.submitPsw = this.submitPsw.bind(this);
  }
  
  componentDidMount() {
    const { getOperatorById, match, user } = this.props;
    getOperatorById(match.params.id, user.level )
  }

  submitPsw(e) {
    e.preventDefault()
    if(this.inputPsw.current.value === '') {
      this.setState({showError: 'Campo obbligatorio'})
      return false
    }
    if(this.inputPsw.current.value.length <= 3) {
      this.setState({showError: 'La password deve contentere minimo 3 caratteri'})
      return false
    }
    this.props.setClientPsw(this.inputPsw.current.value, this.props.user.id).then((response) => {
      this.setState({
        showDialog: false,
        showError: ''
      })
      this.props.setSnack(true,'Password salvata con successo!','success')
    }).catch((error)=>{})
  }


  render() {
    const { operator, classes, match, user, loading } = this.props
    const TextField = (props) => <ShowField classes={classes} {...props} />

    return (
      <React.Fragment>
        { loading ? <LoadingPage /> : //Object.keys(operator).length === 0 ? "Caricando informazioni dell'operatore" : 
          <div>
            <div className={classes.navigationBar}>
              <div className={classes.backForm}>
                <Button size="small" color="primary" className={classes.button} onClick={()=>this.props.history.push('/operatori')}>
                  <ArrowBackIcon className={classes.leftIcon} />
                  Torna alla lista
                </Button>
              </div>
              <div className={classes.titleForm} >
                <span>{ user.level === 'admin' ? 'Scheda operatore': 'Profilo'}</span>
              </div>
              <div className={classes.breadCrumbsForm}>
                <span>W-SHOP > OPERATORI > SCHEDA OPERATORE</span>
              </div>
            </div>
            <div className={classes.actionsForm}>
              { 
                user.id === match.params.id &&
                  <Button size="small" color="primary" className={classes.buttonRight} onClick={()=>this.setState({showDialog: true})}>
                    <KeyIcon className={classes.leftIcon} />
                    Modifica password
                  </Button>
              }
              {
                user.level === 'admin' &&
                <Button 
                  size="small" 
                  color="primary" 
                  className={classes.buttonRight} 
                  onClick={()=>this.props.history.push(`/operatore/edit/${operator['id']}`)}
                >
                  <EditIcon className={classes.leftIcon} />
                  Modifica
                </Button>
              }              
            </div>
            <Paper className={classes.root} elevation={2}>
              <Grid container className={classes.formContainer}>
                <div className={classes.sectionTitle}>Dati di accesso</div>
                <Grid container className={classes.row}>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Nome" value={operator.name} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4}><TextField label="Cognome" value={operator.cognome} /></Grid>
                  <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="E-mail" value={operator.email}/></Grid>
                  {/* <Grid item className={classes.textFieldContainer} xs={4} ><TextField label="Password" value={operator.password}/></Grid> */}
                </Grid>
              </Grid>
            </Paper>
          </div>
        }
        <Dialog
          open={this.state.showDialog}
          onClose={this.handleCancel}
          aria-labelledby="Cambia password"
          aria-describedby="cambia password di accesso"
        > 
          <form onSubmit={this.submitPsw}>
            <DialogTitle id="change-access-password">Cambia password</DialogTitle>
            <DialogContent>
              { loading && <LoadingPage light absolute /> }
              <DialogContentText>
                Imposta la nuova password di accesso: 
              </DialogContentText>
              <FormControl className={classes.row}>
                <TextField
                  type="password"
                  inputRef={this.inputPsw}
                  label="Password"
                  className={classes.textFieldContainerInput}
                  error={this.state.showError !== ''}
                  helperText={this.state.showError}
                />
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.setState({showDialog: false})} color="primary">
                Cancella
              </Button>
              <Button type="submit" color="primary" autoFocus>
                Continua
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </React.Fragment>
    );
  }
}

OperatoreDettaglio.propTypes = {
  operator: PropTypes.object.isRequired,
  getOperatorById: PropTypes.func.isRequired,
  setClientPsw: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  operator: state.operators.selected,
  user: state.user,
  loading: state.info.loading
});

export default withRouter(
  connect(mapStateToProps, {
    getOperatorById,
    setClientPsw,
    setSnack
  })(OperatoreDettaglio)
);