import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import thunk from 'redux-thunk'
import  { createBrowserHistory } from 'history'
import rootReducer from './modules'
import { USER_LST, USER_LST_TMP } from './config'
import { getUserInfo } from './actions';

export const history = createBrowserHistory()

const user = localStorage.getItem(USER_LST)
const userJson = JSON.parse(user)
const impersonificate = localStorage.getItem(USER_LST_TMP) || '{}'
if(userJson) 
  userJson.impersonificate = JSON.parse(impersonificate)

const initialState = {
  user: userJson
}
const enhancers = []
const middleware = [thunk, routerMiddleware(history)]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension())
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers
)

export default createStore(
  //connectRouter(history)(rootReducer),
  rootReducer,
  initialState,
  composedEnhancers
)
