import ajax from 'axios';
import { API_URL } from '../config'
import queryString from 'query-string'
export const RETURN_INVENTORY = 'RETURN_INVENTORY';

function returnInventory(inventory, order) {
  return {
    type: RETURN_INVENTORY,
    inventory,
    order
  };
}

export function getInventoryList({perPage=100, page=1, orderBy='id',order='asc',search=''}) {
  return (dispatch, getState) => {
    const query = {
      perPage,
      page,
      orderBy,
      order,
      search
    }
    
    return ajax
      .get(`${API_URL}/inventory?${queryString.stringify(query)}`)
      //.get(API_URL+'/Inventary')
      .then(response => {
        dispatch(returnInventory(response.data, order));
        return response.data
      })
      .catch(function(error) {
        //console.log(error)
        dispatch({
          type: 'SET_LOADING',
          loading: false
        });        
        return error
      });
  };
}

export function getProductVariantList() {
  return (dispatch, getState) => {
    
    return ajax
      .get(`${API_URL}/inventory/productVariantList`)
      //.get(API_URL+'/Inventary')
      .then(response => {
        //dispatch(returnInventory(response.data.data));
        return response.data.data
      })
      .catch(function(error) {
        dispatch({
          type: 'SET_LOADING',
          loading: false
        }); 
        //console.log(error)
      });
  };
}


export function editInventory(id,quantity,create) {
  let url, data;

  if(create) {
    url = 'create';
    data = { product_variant_id: id, quantity }
  }
  else{
    url = id
    data = { quantity }
  }

  return (dispatch, getState) => {
    return ajax({
      method: 'POST',
      url: API_URL+ '/inventory/'+ url,
      data
    })
      .then(function(response) {
        //dispatch(putClient(response.data.data));
        return response.data.data
      })
      .catch(function(error) {
        dispatch({
          type: 'SET_LOADING',
          loading: false
        }); 
        //console.log('error edit',error.response);
        throw error.response
      });
  };
}


export function editQuantityProductToInventory(product_variant_id,quantity) {
  return (dispatch, getState) => {
    return ajax({
      method: 'POST',
      url: API_URL+'/inventory/create',
      data: {
        product_variant_id,
        quantity
      }
    })
      .then(function(response) {
        //dispatch(putClient(response.data.data));
        return response.data.data
      })
      .catch(function(error) {
        dispatch({
          type: 'SET_LOADING',
          loading: false
        }); 
        return error.response
        //console.log(error.response.data.error);
      });
  };
}