import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { 
  getAllCategories, 
  putCategoryById, 
  removeItems, 
  setSnack 
} from '../actions';


import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  FormHelperText,
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  TableSortLabel
} from '@material-ui/core';

import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon
} from '@material-ui/icons';

import LoadingPage from '../components/LoadingPage'


class CategorieLista extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: '',
      newCategory: { name: '', parent_id: null},
      type: '',
      sortBy: 'full_name',
      sortOrder: 'asc'
    }
  }

  componentDidMount() {
    const { getAllCategories } = this.props;
    getAllCategories({});
  }
  
  handleSort(sortBy, sortOrder) {
    this.props.getAllCategories({
      orderBy: sortBy, 
      order: sortOrder
    })
    .then(() => {
      this.setState({
        sortBy: sortBy,
        sortOrder: sortOrder
      })
    }) 
  }
  
  handleRemove({data={},id}) {
    this.props.removeItems(data,'categories',id).then(response => {
      if(response.status === 200) {
        this.props.getAllCategories({})
      }
    }).catch((error)=>{})
  }

  handleChangeNewCategory(name) {
    this.setState((state)=>{ 
      return {
        newCategory: {
          ...state.newCategory,
          name: name
        }
      }
    })
  }

  handleClickOpen({id, type}) {
    this.setState(state => {
      return { 
        open: type, 
        newCategory: {
          parent_id: id ? id :null,
          name: type !== 'create' ? this.props.categories.find(x => x.id === id).name :'',
          type: type
        }
      }
    });
  }

  handleClose(data) {
    if(data) {
      this.props.putCategoryById(data,this.state.newCategory).then((response)=>{
        if(response.status === 422) {
          this.setState(state => {
            return {
              ...state,
              newCategory: {
                ...state.newCategory,
                error: response.data.errors.name
              }
            }
          })
        }
        else {
          this.props.getAllCategories({})
          this.setState({ open: '', newCategory: { name: '', parent_id: null} });
        }
      }).catch((error)=>{})
    }
    else {
      this.setState(state => {
        return {
          open: '',
          newCategory: {
            name: '',
            parent_id: null,
            status: ''
          }
        }

      })
    }
  }

  handleRemoveConfirm() {
    this.props.removeItems({},'categories',this.state.newCategory.parent_id).then((response)=> {
      if(response.status === 402) {
        this.props.setSnack(true,'Impossibile rimuovere una categoria in uso','warning')
      }
      this.setState({
        open: ''
      })
      //if(response.status === 200) {
        this.props.getAllCategories({})
      //}
    }).catch((error)=>{})
  }

  render() {
    const { sortBy, sortOrder } = this.state
    const { classes, categories, loading } = this.props

    return (
      <React.Fragment>
        {/* <MenuAppBar logout={logout} /> */}
        {
          loading ? <LoadingPage /> : 
          <div>
            <div className={classes.navigationBar}>
              <div className={classes.title} >
                <span>Categorie</span>
              </div>
              <div className={classes.spacer}>
                <Button variant="contained" size="small" color="primary" className={classes.button} onClick={() => this.handleClickOpen({type:'create'})}>
                  <AddIcon className={this.props.classes.leftIcon} fontSize="small" />
                  Aggiungi nuova
                </Button>
              </div>
              <div className={classes.actions} >
                <div className={classes.breadCrumbs}>
                  <span>W-SHOP > CATEGORIE </span>
                </div>
              </div>
            </div>
            <Paper className={classes.root} elevation={2}>
              <Table>
                <TableHead>
                  <TableRow style={{height: 56}}>
                    <TableCell >
                      <TableSortLabel
                        active={sortBy === 'full_name'}
                        direction={sortOrder}
                        onClick={() => this.handleSort('full_name', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        <span className={classes.fieldTh}>Percorso</span>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell >
                      <TableSortLabel
                        active={sortBy === 'name'}
                        direction={sortOrder}
                        onClick={() => this.handleSort('name', sortOrder === 'asc' ? 'desc' : 'asc')}
                      >
                        <span className={classes.fieldTh}>Nome</span>
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={this.props.classes.checkbox}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categories.length > 0 ? categories.map((c,i)=>{
                    return (
                      <TableRow key={i}>
                        <TableCell >{c.full_name}</TableCell>
                        <TableCell >{c.name} </TableCell>
                        <TableCell align="right" >
                          <Tooltip title="Aggiungi categoria" placement="top">
                            <IconButton 
                              aria-label="Aggiungi categoria" 
                              onClick={()=>this.handleClickOpen({type:'create',id:c.id})}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Modifica categoria" placement="top">
                            <IconButton 
                              aria-label="Modifica categoria" 
                              onClick={()=>this.handleClickOpen({type:'edit',id:c.id})}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Rimuovi categoria" placement="top">
                            <IconButton 
                              aria-label="Rimuovi categoria" 
                              onClick={()=>this.handleClickOpen({type:'remove',id: c.id})}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          {/* <IconButton aria-label="Nuovo" onClick={()=>this.handleClickOpen({type:'create',id:c.id})}>
                            <AddIcon />
                          </IconButton>
                          <IconButton aria-label="Modifica" onClick={()=>this.handleClickOpen({type:'edit',id:c.id})}>
                            <EditIcon />
                          </IconButton>
                          <IconButton aria-label="Delete" onClick={()=>this.handleClickOpen({type:'remove',id: c.id})}>
                            <DeleteIcon />
                          </IconButton> */}
                        </TableCell>
                      </TableRow>
                    )
                  }) :
                  <TableRow>
                    <TableCell padding="checkbox" colSpan={3} align="center">Nessuna categoria presente</TableCell>
                  </TableRow>
                  }
                </TableBody>
              </Table>
            </Paper>
          </div>
        }
        <Dialog
          open={this.state.open === 'edit' || this.state.open === 'create' }
          onClose={() => this.handleClose()}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">{this.state.newCategory.type === 'edit' ? 'Modifica': 'Nuova'} Categoria</DialogTitle>
          <DialogContent>
            { loading && <LoadingPage light absolute /> }
            <DialogContentText>
            {this.state.newCategory.type === 'edit' ? 
              'Modifica il nome della categoria' :
              'Inserisci il nome della categoria da creare:'}
            </DialogContentText>
            <TextField
                autoFocus
                margin="dense"
                id="nuova_categoria"
                label="Nome categoria"
                type="text"
                value={this.state.newCategory.name}
                onChange={(e)=>this.handleChangeNewCategory(e.target.value)}
                fullWidth
              />
              {this.state.newCategory.error && <FormHelperText className={this.props.classes.error}>{this.state.newCategory.error}</FormHelperText>}
          </DialogContent>
          <DialogActions>
            <Button onClick={()=>this.handleClose()} color="primary">
              Cancella
            </Button>
            {this.state.newCategory.type === 'edit' ? 
              <Button onClick={()=>this.handleClose(this.state.newCategory.parent_id)} color="primary">
                Modifica
              </Button> : 
              <Button onClick={()=>this.handleClose('create')} color="primary">
                Aggiungi
              </Button>
            }
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.state.open === 'remove'}
          onClose={this.handleCancel}
          aria-labelledby="conferma rimozione categoria"
          aria-describedby="sei sicuro di voler rimuovere questa categoria"
        >
          <DialogTitle >{`Rimozione categoria`}</DialogTitle>
          <DialogContent>
            { loading && <LoadingPage light absolute /> }
            <DialogContentText>
              {`Sei sicuro di voler rimuove la categoria "${this.state.newCategory.name}"?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose()} color="primary">
              Cancella
            </Button>
            <Button onClick={() => this.handleRemoveConfirm()} color="primary" autoFocus>
              Continua
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

CategorieLista.propTypes = {
  categories: PropTypes.array.isRequired,
  getAllCategories: PropTypes.func.isRequired,
  putCategoryById: PropTypes.func.isRequired,
  removeItems: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state, props) => ({
  categories: state.categories.list,
  user: state.user,
  loading: state.info.loading
});

export default withRouter(
  connect(mapStateToProps, {
    getAllCategories,
    putCategoryById,
    removeItems,
    setSnack
  })(CategorieLista)
);
