import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';

import { getAllAgents, removeItems } from '../actions';
import Table from '../components/Table';
import LoadingPage from '../components/LoadingPage'
import PageTitle from '../components/PageTitle';

class AgentiLista extends React.Component {
  componentDidMount() {
    const { getAllAgents, pagination } = this.props;
    getAllAgents({perPage:pagination.per_page, page: pagination.current_page});
  }
  
  handleRemove(data) {
    this.props.removeItems(data,'agents').then(response => {
      if(response.status === 200) {
        this.props.getAllAgents({})
      }
    }).catch((error)=>{})
  }
  
  goToPage(page) {
    this.props.history.push('agente/'+page)
  }
  render() {
    const { 
      loading, 
      agents, 
      classes, 
      location, 
      pagination,
      getAllAgents,
      user 
    } = this.props

    return (
      <React.Fragment>
        { loading && <LoadingPage /> }
        <PageTitle 
          title="Agenti"
          breadcrumbs={['W-SHOP','AGENTI']}
          //handleBackClick={()=>history.push('/prodotti')}
          classes={classes}
        />
        <Paper className={classes.root} elevation={2}>
          <Table 
            slug="agenti"
            tableTitle="Agenti" 
            location={location}
            choises={agents.data}
            pagination={pagination}
            labels={[
              {
                value: "id",
                //numeric: true
              },
              {
                value: "nome",
                label: "Nome",
              },
              {
                value: "cognome",
                label: "Cognome",
              },
              {
                value: "email",
                label: "E-mail",
              },
              {
                value: "parent",
                param: "nome_completo",
                label: "Agente di riferimento",
                unsortable: true,
              },
              // {
              //   value: "commissioni",
              //   label: "Totale commisioni",
              //   type: "currency"
              // }
            ]}
            paginate={({perPage,page,orderBy,order,search}) => getAllAgents({perPage,page,orderBy,order,search})}
            removeItems={(data) => this.handleRemove(data)}
            goToPage={(page) => this.goToPage(page)}
            userLevel={user.level}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

AgentiLista.propTypes = {
  agents: PropTypes.object.isRequired,
  getAllAgents: PropTypes.func.isRequired,
  removeItems: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  agents: state.agents.list,
  user: state.user,
  pagination: state.info.pagination,
  loading: state.info.loading
});

export default withRouter(
  connect(mapStateToProps, {
    getAllAgents,
    removeItems
  })(AgentiLista)
);