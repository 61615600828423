import { LOADING_CATEGORIES, RETURN_CATEGORIES, GET_CATEGORY_DETAILS, POST_CATEGORY, DELETE_CATEGORY } from '../actions';

const initialState = {
  list: [],
  selected: {},
  loading: false
}

export default function categories(state = initialState, action) {
  switch (action.type) {
    case LOADING_CATEGORIES:
      return {
        ...state,
        loading: true
      }
    case RETURN_CATEGORIES:
      return {
        ...state,
        list: action.categories,
        selected: {},
        loading: false
      };
    case GET_CATEGORY_DETAILS:
    case POST_CATEGORY: 
      return {
        ...state,
        selected: action.category
      };
    default:
    case DELETE_CATEGORY:
      return state;
  }
}
