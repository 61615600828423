export const API_URL = 'https://working-api.lavoraconworking.it/v1'
export const IMG_URL = 'https://working-api.lavoraconworking.it/storage/' 
export const USER_LST = 'erp_kr_usr';
export const USER_LST_TMP = 'erp_kr_usr_tmp';
export const STATUS_PYM = {
  'waiting': 'In attesa di pagamento',
  'processing': 'In processamento',
  'preparing': 'In elaborazione',
  'completed': 'Completato'  
}

export const URL_LEVEL = {
  'EN': {
    'admin': 'admin',
    'pos': 'user',
    'shipper': 'shipper',
    'operator': 'operator',
    'agent': 'agent'
  },
  'IT': {
    'admin': 'admin',
    'pos': 'cliente',
    'shipper': 'shipper',
    'operator': 'operatore',
    'agent': 'agente'
  }
}
