import ajax from 'axios';
import { API_URL } from '../config'
import queryString from 'query-string'
export const RETURN_CLIENTS = 'RETURN_CLIENTS';
export const GET_CLIENT_DETAILS = 'GET_CLIENT_DETAILS';
export const POST_CLIENT = 'POST_CLIENT';

function returnClients(clients) {
  return {
    type: RETURN_CLIENTS,
    clients,
  };
}
export function getAllClients({perPage=100, page=1, orderBy='id',order='asc',search=''}) {
  return (dispatch, getState) => {
    const query = {
      perPage,
      page,
      orderBy,
      order,
      search
    }
    const user = getState().user
    return ajax
      .get(`${API_URL}/${user.level}/pos?${queryString.stringify(query)}`)
      .then(response => {
        dispatch(returnClients(response.data));
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error);
      });
  };
}

function getClientDetails(client) {
  return {
    type: GET_CLIENT_DETAILS,
    client,
  };
}
export function getClientById({id, user='admin'}) {
  const url = user === 'admin' ? '/admin/pos/'+id : '/user/'+id+'/pos'
  return (dispatch, getState) => {
    return ajax
      .get(API_URL + url)
      .then(function(response) {
        dispatch(getClientDetails(response.data.data));
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error.response.data.error);
      });
  };
}


function putClient(client) {
  return {
    type: POST_CLIENT,
    client,
  };
}
export function putClientById(id,data) {
  return (dispatch, getState) => {
    return ajax({
      method: id === 'create' ? 'POST' : 'PUT',
      url: API_URL+'/admin/pos/'+id,
      data: data
    })
      .then(function(response) {
        dispatch(putClient(response.data.data));
        return response
      })
      .catch(function(error) {
        return error.response
        //console.log(error.response.data.error);
      });
  };
}

export function getSupplierByClient() {
  return (dispatch, getState) => {
    return ajax
      .get(API_URL+'/admin/suppliers/withWarehouses')
      .then(function(response) {
        //dispatch(putClient(response.data.data));
        return response.data.data
      })
      .catch(function(error) {
        return error.response
        //console.log(error.response.data.error);
      });
  };
}
