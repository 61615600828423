export const allNations = [
  { id:"Afghanistan", label:"Afghanistan" },
  { id:"Albania", label:"Albania" },
  { id:"Algeria", label:"Algeria" },
  { id:"Andorra", label:"Andorra" },
  { id:"Angola", label:"Angola" },
  { id:"Antartico", label:"Antartico" },
  { id:"Antigua And Barbuda", label:"Antigua E Barbuda" },
  { id:"Arabia Saudita", label:"Arabia Saudita" },
  { id:"Argentina", label:"Argentina" },
  { id:"Armenia", label:"Armenia" },
  { id:"Australia", label:"Australia" },
  { id:"Austria", label:"Austria" },
  { id:"Azerbaijan", label:"Azerbaijan" },
  { id:"Bahamas", label:"Bahamas" },
  { id:"Bahrain", label:"Bahrain" },
  { id:"Bangladesh", label:"Bangladesh" },
  { id:"Barbados", label:"Barbados" },
  { id:"Belgium", label:"Belgio" },
  { id:"Belize", label:"Belize" },
  { id:"Benin", label:"Benin" },
  { id:"Bhutan", label:"Bhutan" },
  { id:"Bielorussia", label:"Bielorussia" },
  { id:"Bolivia", label:"Bolivia" },
  { id:"Bosnia Erzegovina", label:"Bosnia Erzegovina" },
  { id:"Botswana", label:"Botswana" },
  { id:"Brasile", label:"Brasile" },
  { id:"Brunei", label:"Brunei" },
  { id:"Bulgaria", label:"Bulgaria" },
  { id:"Burkina Faso", label:"Burkina Faso" },
  { id:"Burundi", label:"Burundi" },
  { id:"Cambodia", label:"Cambogia" },
  { id:"Camerun", label:"Camerun" },
  { id:"Canada", label:"Canada" },
  { id:"Cape Verde", label:"Capo Verde" },
  { id:"Chad", label:"Ciad" },
  { id:"Ciile", label:"Cile" },
  { id:"Cina", label:"Cina" },
  { id:"Cina - Hong Kong", label:"Cina - Hong Kong" },
  { id:"Cina - Macao", label:"Cina - Macao" },
  { id:"Cipro", label:"Cipro" },
  { id:"Citta' Del Vaticano", label:"Citta' Del Vaticano" },
  { id:"Colombia", label:"Colombia" },
  { id:"Comore", label:"Comore" },
  { id:"Corea Del Nord", label:"Corea Del Nord" },
  { id:"Corea Del Sud", label:"Corea Del Sud" },
  { id:"Costa D'avorio", label:"Costa D'avorio" },
  { id:"Costa Rica", label:"Costa Rica" },
  { id:"Croazia", label:"Croazia" },
  { id:"Cuba", label:"Cuba" },
  { id:"Danimarca", label:"Danimarca" },
  { id:"Danimarca - Isole Faroe", label:"Danimarca - Isole Faroe" },
  { id:"Dominica", label:"Dominica" },
  { id:"Ecuador", label:"Ecuador" },
  { id:"Egitto", label:"Egitto" },
  { id:"El Salvador", label:"El Salvador" },
  { id:"Emirati Arabi Uniti", label:"Emirati Arabi Uniti" },
  { id:"Eritrea", label:"Eritrea" },
  { id:"Estonia", label:"Estonia" },
  { id:"Etiopia", label:"Etiopia" },
  { id:"Figi", label:"Figi" },
  { id:"Filippine", label:"Filippine" },
  { id:"Finlandia", label:"Finlandia" },
  { id:"France", label:"Francia" },
  { id:"Gabon", label:"Gabon" },
  { id:"Gambia", label:"Gambia" },
  { id:"Georgia", label:"Georgia" },
  { id:"Germany", label:"Germania" },
  { id:"Ghana", label:"Ghana" },
  { id:"Jamaica", label:"Giamaica" },
  { id:"Giappone", label:"Giappone" },
  { id:"Jordan", label:"Giordania" },
  { id:"Grecia", label:"Grecia" },
  { id:"Grenada", label:"Grenada" },
  { id:"Groenlandia", label:"Groenlandia" },
  { id:"Guatemala", label:"Guatemala" },
  { id:"Guinea", label:"Guinea" },
  { id:"Guinea Equatoriale", label:"Guinea Equatoriale" },
  { id:"Guinea-bissau", label:"Guinea-bissau" },
  { id:"Guyana", label:"Guyana" },
  { id:"Haiti", label:"Haiti" },
  { id:"Honduras", label:"Honduras" },
  { id:"India", label:"India" },
  { id:"Indonesia", label:"Indonesia" },
  { id:"Iran", label:"Iran" },
  { id:"Iraq", label:"Iraq" },
  { id:"Ireland", label:"Irlanda" },
  { id:"Islanda", label:"Islanda" },
  { id:"Isole Marshall", label:"Isole Marshall" },
  { id:"Isole Salomone", label:"Isole Salomone" },
  { id:"Israel", label:"Israele" },
  { id:"Italia", label:"Italia" },
  { id:"Kazakistan", label:"Kazakistan" },
  { id:"Kenya", label:"Kenya" },
  { id:"Kirghizistan", label:"Kirghizistan" },
  { id:"Kiribati", label:"Kiribati" },
  { id:"Kuwait", label:"Kuwait" },
  { id:"Laos", label:"Laos" },
  { id:"Lesotho", label:"Lesotho" },
  { id:"Lettonia", label:"Lettonia" },
  { id:"Libano", label:"Libano" },
  { id:"Liberia", label:"Liberia" },
  { id:"Libia", label:"Libia" },
  { id:"Liechtenstein", label:"Liechtenstein" },
  { id:"Lituania", label:"Lituania" },
  { id:"Lussemburgo", label:"Lussemburgo" },
  { id:"Macedonia", label:"Macedonia" },
  { id:"Madagascar", label:"Madagascar" },
  { id:"Malawi", label:"Malawi" },
  { id:"Maldive", label:"Maldive" },
  { id:"Malesia", label:"Malesia" },
  { id:"Mali", label:"Mali" },
  { id:"Malta", label:"Malta" },
  { id:"Marocco", label:"Marocco" },
  { id:"Mauritania", label:"Mauritania" },
  { id:"Mauritius", label:"Mauritius" },
  { id:"Messico", label:"Messico" },
  { id:"Micronesia", label:"Micronesia" },
  { id:"Moldavia", label:"Moldavia" },
  { id:"Mongolia", label:"Mongolia" },
  { id:"Montenegro", label:"Montenegro" },
  { id:"Mozambico", label:"Mozambico" },
  { id:"Myanmar", label:"Myanmar" },
  { id:"Namibia", label:"Namibia" },
  { id:"Nauru", label:"Nauru" },
  { id:"Nepal", label:"Nepal" },
  { id:"Nicaragua", label:"Nicaragua" },
  { id:"Niger", label:"Niger" },
  { id:"Nigeria", label:"Nigeria" },
  { id:"Norvegia", label:"Norvegia" },
  { id:"Nuova Zelanda", label:"Nuova Zelanda" },
  { id:"Olanda", label:"Olanda" },
  { id:"Oman", label:"Oman" },
  { id:"Pakistan", label:"Pakistan" },
  { id:"Palau", label:"Palau" },
  { id:"Palestina", label:"Palestina" },
  { id:"Panama", label:"Panama" },
  { id:"Papua Nuova Guinea", label:"Papua Nuova Guinea" },
  { id:"Paraguay", label:"Paraguay" },
  { id:"Peru", label:"Peru'" },
  { id:"Polonia", label:"Polonia" },
  { id:"Porto Rico", label:"Porto Rico" },
  { id:"Portogallo", label:"Portogallo" },
  { id:"Principato Di Monaco", label:"Principato Di Monaco" },
  { id:"Qatar", label:"Qatar" },
  { id:"Regno Unito", label:"Regno Unito" },
  { id:"Repubblica Ceca", label:"Repubblica Ceca" },
  { id:"Repubblica Centrafricana", label:"Repubblica Centrafricana" },
  { id:"Repubblica Del Congo", label:"Repubblica Del Congo" },
  { id:"Repubblica Democratica Del Congo (Ex Zaire)", label:"Repubblica Democratica Del Congo (Ex Zaire)" },
  { id:"Repubblica Di San Marino", label:"Repubblica Di San Marino" },
  { id:"Repubblica Dominicana", label:"Repubblica Dominicana" },
  { id:"Romania", label:"Romania" },
  { id:"Ruanda", label:"Ruanda" },
  { id:"Russia", label:"Russia" },
  { id:"Sahara Occidentale", label:"Sahara Occidentale" },
  { id:"Saint Vincent E Grenadines", label:"Saint Vincent E Grenadines" },
  { id:"Samoa", label:"Samoa" },
  { id:"San Kitts E Nevis", label:"San Kitts E Nevis" },
  { id:"Santa Lucia", label:"Santa Lucia" },
  { id:"Sao Tome E Principe", label:"Sao Tome E Principe" },
  { id:"Senegal", label:"Senegal" },
  { id:"Serbia", label:"Serbia" },
  { id:"Seyschelles", label:"Seychelles" },
  { id:"Sierra Leone", label:"Sierra Leone" },
  { id:"Singapore", label:"Singapore" },
  { id:"Siria", label:"Siria" },
  { id:"Slovacchia", label:"Slovacchia" },
  { id:"Slovenia", label:"Slovenia" },
  { id:"Somalia", label:"Somalia" },
  { id:"Spagna", label:"Spagna" },
  { id:"Sri Lanka", label:"Sri Lanka" },
  { id:"Stati Uniti D'america", label:"Stati Uniti D'america" },
  { id:"Stati Uniti D'america - Guam", label:"Stati Uniti D'america - Guam" },
  { id:"Stati Uniti D'america - Isole Marianne Settentrionali", label:"Stati Uniti D'america - Isole Marianne Settentrionali" },
  { id:"Stati Uniti D'america - Isole Periferiche Secondarie", label:"Stati Uniti D'america - Isole Periferiche Secondarie" },
  { id:"Stati Uniti D'america - Isole Vergini Statunitensi", label:"Stati Uniti D'america - Isole Vergini Statunitensi" },
  { id:"Stati Uniti D'america - Samoa Americane", label:"Stati Uniti D'america - Samoa Americane" },
  { id:"Sud Africa", label:"Sud Africa" },
  { id:"Sudan", label:"Sudan" },
  { id:"Suriname", label:"Suriname" },
  { id:"Svezia", label:"Svezia" },
  { id:"Svizzera", label:"Svizzera" },
  { id:"Swaziland", label:"Swaziland" },
  { id:"Tagikistan", label:"Tagikistan" },
  { id:"Tailandia", label:"Tailandia" },
  { id:"Taiwan", label:"Taiwan" },
  { id:"Tanzania", label:"Tanzania" },
  { id:"Timor Dell'est", label:"Timor Dell'est" },
  { id:"Togo", label:"Togo" },
  { id:"Tonga", label:"Tonga" },
  { id:"Trinidad And Tobago", label:"Trinidad E Tobago" },
  { id:"Tunisia", label:"Tunisia" },
  { id:"Turchia", label:"Turchia" },
  { id:"Turkmenistan", label:"Turkmenistan" },
  { id:"Tuvalu", label:"Tuvalu" },
  { id:"Ucraina", label:"Ucraina" },
  { id:"Uganda", label:"Uganda" },
  { id:"Ungheria", label:"Ungheria" },
  { id:"Uruguay", label:"Uruguay" },
  { id:"Uzbekistan", label:"Uzbekistan" },
  { id:"Vanuatu", label:"Vanuatu" },
  { id:"Venezuela", label:"Venezuela" },
  { id:"Vietnam", label:"Vietnam" },
  { id:"Yemen", label:"Yemen" },
  { id:"Zambia", label:"Zambia" },
  { id:"Zimbabwe", label:"Zimbabwe" }
]