import React from 'react'
import PropTypes from 'prop-types';

import {
  Paper,
  Grid,
} from '@material-ui/core';

import UserFields from './UserFields'
import FieldsMap from './FieldsMap'

const FormFields = (props) => {
  const { template, hasUser } = props
  
  return (
    <>
      {
        template.data.map(section => {
          return (
            <Paper className={props.classes.root} elevation={2} key={section.id}>
              <Grid container className={props.classes.formContainer}>
                <div className={props.classes.sectionTitle}>{section.title}</div>
                <Grid container >
                {
                  section.fields.map( (field,i) =>{
                    return (
                      props.sectionsToHide.indexOf(field.name) === -1 &&
                      <FieldsMap 
                        key={`field-${field.name}`} 
                        field={field} 
                        index={i} 
                        {...props} 
                      />
                    )
                  })
                }
                </Grid>
              </Grid>
            </Paper>
          )
        })
      }
      {
        hasUser && 
          <Paper className={props.classes.root} elevation={2} key={template.user.id}>
            <Grid container className={props.classes.formContainer}>
              <div className={props.classes.sectionTitle}>{template.user.title}</div>
              <Grid container>
                <UserFields 
                  slug={template.user.slug}
                  fields={template.user.fields}
                  classes={props.classes}
                  values={props.values && props.values.user}
                  error={props.error && props.error.user}
                  touched={props.touched && props.touched.user}
                  onChange={props.onChange}
                  onBlur={props.onBlur}
                />
              </Grid>
            </Grid>
          </Paper>
      }
    </>
  )
}

FormFields.defaultProps = {
  sectionsToHide: []
}

FormFields.propTypes = {
  classes: PropTypes.object,
  slug: PropTypes.string,
  template: PropTypes.object,
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  values: PropTypes.object,
  options: PropTypes.object,
  checked: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  error: PropTypes.object,
  touched: PropTypes.object,
  errorName: PropTypes.string,
  hasUser: PropTypes.string,
  sectionsToHide: PropTypes.array
}

export default FormFields