export default {
  data:[{
    id: 0,
    title: 'Dati personali',
    fields: [
      {
        element: 'input',
        name: 'denominazione',
        value: '',
        label: 'Ragione sociale',
        required: true,
      },
      {
        element: 'input',
        name: 'partita_iva',
        value: '',
        label: 'Partita iva',
        required: true,
      },
      {
        element: 'input',
        name: 'codice_fiscale',
        value: '',
        label: 'Codice fiscale'
      },
      {
        element: 'input',
        name: 'nome',
        value: '',
        label: 'Nome',
        required: true
      },
      {
        element: 'input',
        name: 'cognome',
        value: '',
        label: 'Cognome',
        required: true
      },
      {
        element: 'input',
        name: 'email',
        value: '',
        label: 'E-mail',
        required: true
      },
      {
        element: 'input',
        name: 'cellulare',
        value: '',
        label: 'Telefono',
        required: true
      },
      {
        element: 'address',
        fields: {
          nazione: {
            name: 'nazione',
            label: 'Nazione',
            placeholder: 'Seleziona una nazione',
            options: 'allNations',
            required: true
          },
          provincia: {
            name: 'provincia',
            label: 'Provincia',
            placeholder: 'Seleziona una provincia',
            options: 'allProvinces',
            callback: (context, event) => {
              context.formAgenti.current.setFieldValue('agent.citta', '')
              return context.getCitta(event.target.value,'citta')
            },
            required: true
          },
          citta: {
            name: 'citta',
            label: 'Città',
            options: 'citta',
            placeholder: 'Seleziona una città',
            required: true
          },
          indirizzo: {
            name: 'indirizzo',
            label: 'Indirizzo',
            required: true
          },
          cap: {
            name: 'cap',
            label: 'Cap',
            required: true
          }
        }
      },
      {
        element: 'autoselect',
        name: 'agent',
        value: '',
        label: 'Agente di riferimento',
        placeholder: 'Seleziona un agente',
        options: 'agents',
        required: false
      },
      {
        element: 'autoselect',
        name: 'regioni',
        value: '',
        label: 'Regioni attività',
        placeholder: 'Seleziona una regione',
        options: 'allRegions',
        callback: (context, event) => {
          let regions = []
          if(event) {
            regions = event.map((a)=>{return a.value})
          }
          return context.getProvincesByRegions(regions)
        },
        isMulti: true,
        required: false
      },
      {
        element: 'autoselect',
        name: 'province',
        value: '',
        label: 'Province attività',
        placeholder: 'Seleziona una provincia',
        options: 'allProvinces',
        isMulti: true,
        required: false
      }
    ]
  }],
  user: {
    id: 1,
    title: 'Dati di accesso',
    slug: 'agent',
    fields: [
      {
        element: 'input',
        name: 'name',
        label: 'Nome completo',
        required: true,
      },
      {
        element: 'input',
        name: 'email',
        label: 'E-mail',
        required: true,
      },
      {
        element: 'input',
        name: 'password',
        label: 'Password',
        required: true,
        type: 'password'
      }
    ]
  }
}