import { 
  RETURN_ORDERS, 
  UPDATE_ORDER_BY_ID,
  RETURN_ORDER_BY_ID, 
  DELETE_ORDER 
} from '../actions';

const initialState = {
  list: {},
  selected: {},
}

export default function orders(state = initialState, action) {
  switch (action.type) {
    case RETURN_ORDERS:
      return {
        ...state,
        list: action.orders,
        selected: {},
      };
    case UPDATE_ORDER_BY_ID:
      const { list } = state
      let newList = []
      list.map(el => {
        return newList = newList.concat(el.id === action.id ? action.order : el)
      })
      
      return {
        ...state,
        list: newList,
        selected: action.order,
      };
    case RETURN_ORDER_BY_ID:
      return {
        ...state,
        selected: action.order,
      };
    default:
    case DELETE_ORDER:
      return state;
  }
}
