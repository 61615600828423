import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';

import { 
  getAgentById, 
  putAgentById, 
  getAgentsList, 
  getAllProvince, 
  getAllCitiesByProvince, 
  setSnack 
} from '../actions';

import {
  Grid,
  Button
} from '@material-ui/core';

import { 
  ArrowBack, 
  Visibility as ViewIcon
} from '@material-ui/icons';

//import { allNations } from '../data/nations'
import { allRegions } from '../data/regions'
import { allProvinces } from '../data/provinces'
import Agente from '../data/FormTemplates/Agente'

import ErrorFocus from '../components/ErrorFocus' 
import FormFields from '../components/Form/Fields'
import LoadingPage from '../components/LoadingPage'

class AgenteEdizione extends React.Component {

  constructor(props) {
    super(props)
    this.formAgenti = React.createRef();
    this.state= {
      province: [],
      agents: [],
      citta_byProvince: {
        citta: []
      },
      loading_citta: false,
      agent: {
        denominazione: '',
        partita_iva: '',
        codice_fiscale: '',
        nazione: '',
        provincia: '',
        citta: '',
        indirizzo: '',
        cap: '',
        nome: '',
        cognome: '',
        email: '',
        cellulare: '',
        agent: null,
        regioni: null,
        province: null,
        user: {
          name: '',
          email: '',
          password: ''
        }
      },
      formTemplate: Agente,
      //allNations: allNations,
      allRegions: allRegions,
      allProvinces: []
    }
  }

  componentDidMount() {
    const { getAgentById, getAgentsList, getAllProvince, match } = this.props;

    if(match.params.id) {
      getAgentById(match.params.id).then( (response) => {
        this.setState({
          agent: {
            denominazione: response.denominazione || '' ,
            partita_iva: response.partita_iva || '' ,
            codice_fiscale: response.codice_fiscale || '' ,
            nazione: response.nazione || '',//{id: response.nazione || '', label: response.nazione || '' } ,
            provincia: response.provincia || '' ,
            citta: response.citta || '' ,
            indirizzo: response.indirizzo || '' ,
            cap: response.cap || '' ,
            nome: response.nome || '' ,
            cognome: response.cognome || '' ,
            email: response.email || '' ,
            cellulare: response.cellulare || '' ,
            agent: response.parent && {id: response.parent.id, label: response.parent.nome_completo } || null ,
            regioni: response.regioni ? this.initArray(response.regioni) : null,
            province: response.province ? this.initArray(response.province) : null,
            user: {
              name: response.user && response.user.name || '',
              email: response.user && response.user.email || '',
              password: ''
            }
          }
        })

        response.regioni && this.getProvincesByRegions(response.regioni, response.province)

        if(response.provincia) {
          this.getCitta(response.provincia, 'citta')
        }
      }).catch((error)=>{})
    }

    getAgentsList(match.params.id).then((response)=>{
      let options = []
      response.map((s,i) => {
        options = options.concat({
          value: s.id,
          label: s.nome + ' ' + s.cognome,
          sub_label: s.nome + ' ' + s.cognome
        })
      })
      this.setState({
        agents: options
      })
    }).catch((error)=>{})

    getAllProvince().then((response)=>{
      let options = []
      response.map((s,i) => {
        options = options.concat({
          value: s.nome,
          label: s.nome
        })
      })
      this.setState({
        province: options
      })
    }).catch((error)=>{})
  }

  getCitta = (province, tagCitta) => {
    this.setState({
      ['loading_'+tagCitta]: true
    })
    getAllCitiesByProvince(province)
      .then((response)=>{
        let options = []
        response.map((s,i) => {
          options = options.concat({
            value: s.nome,
            label: s.nome
          })
        })
        this.setState({
            citta_byProvince: {
              ...this.state.citta_byProvince,
              [tagCitta]: options
            },
            ['loading_'+tagCitta]: false
        })
        //this.formAgenti.current.setFieldValue(`agent[${tagCitta}]`, '');
      })
      .catch((error)=>{})
  }

  initArray = (arr) => {
    let options = []
    arr.map((s,i) => {
      options = options.concat({
        value: s,
        label: s
      })
    })
    return options
  }

  unsetArray = (arr) => {
    if(arr) {
      return arr.map((a)=>{return a.value})
    }
    return []
  }

  getProvincesByRegions = (regions, provinces) => {
    const cleanedRegions = regions
    let options = []
    let provinceCleaned = []
    const provSelected = provinces || this.formAgenti.current.state.values.agent.province
    let provincesSelected = provinces ? 
      provinces : 
      (
        provSelected 
        && provSelected.map((p)=>{ return p.value }) 
        || []
      )
    if(cleanedRegions.length > 0) {
      options = allProvinces.filter((prov,i) => {
        let flagRegion = cleanedRegions.indexOf(prov.regione)
        let flagProvince = provincesSelected.indexOf(prov.value)
    
        if(flagProvince > -1 && flagRegion > -1) {
          provinceCleaned.push(prov)
        }
        return flagRegion > -1
      })
    }
    
    this.setState({
      allProvinces: options
    })
    if(provSelected) {
      this.formAgenti.current.setFieldValue('agent.province', provinceCleaned);
    }
  }
  
  render() {
    const copy = (o) => {
      let output, v, key;
      output = Array.isArray(o) ? [] : {};
      for (key in o) {
          v = o[key];
          output[key] = (typeof v === "object") ? copy(v) : v;
      }
      return output;
   }
    const { classes, match, history, loading } = this.props
    const { agent, formTemplate } = this.state

    return (
      <React.Fragment>
        {loading && <LoadingPage /> }
          <div className={classes.navigationBar}>
            <div className={classes.backForm}>
              <Button 
                size="small" 
                color="primary" 
                className={classes.button} 
                onClick={()=>history.push('/agenti')}
              >
                <ArrowBack className={classes.leftIcon} /> Torna alla lista
              </Button>
            </div>
            <div className={classes.titleForm} ><span>Scheda agente</span></div>
            <div className={classes.breadCrumbsForm}>
              <span>W-SHOP > AGENTI > SCHEDA AGENTE</span>
            </div>
          </div>
            <Formik
              ref={this.formAgenti}
              initialValues={{ agent: agent, errors: {} }}
              enableReinitialize={true} 
              validate={(value) => {
                let fields = { user: {} }
                let scrollToID = []

                if(value.agent.denominazione === '') {
                  fields.denominazione = 'Campo obbligatorio'
                  scrollToID.push('input-denominazione')
                }
                if(value.agent.partita_iva === '') {
                  fields.partita_iva = 'Campo obbligatorio'
                  scrollToID.push('input-partita_iva')
                }
                if(value.agent.nome === '') {
                  fields.nome = 'Campo obbligatorio'
                  scrollToID.push('input-nome')
                }
                if(value.agent.cognome === '') {
                  fields.cognome = 'Campo obbligatorio'
                  scrollToID.push('input-cognome')
                }                
                if(value.agent.email === '') {
                  fields.email = 'Campo obbligatorio'
                  scrollToID.push('input-email')
                }
                if(value.agent.cellulare === '') {
                  fields.cellulare = 'Campo obbligatorio'
                  scrollToID.push('input-cellulare')
                }
                
                if(value.agent.nazione === '') {
                  fields.nazione = 'Campo obbligatorio'
                  scrollToID.push('input-nazione')
                }
                if(value.agent.provincia === '') {
                  fields.provincia = 'Campo obbligatorio'
                  scrollToID.push('input-provincia')
                }
                if(value.agent.citta === '') {
                  fields.citta = 'Campo obbligatorio'
                  scrollToID.push('input-citta')
                }
                if(value.agent.indirizzo === '') {
                  fields.indirizzo = 'Campo obbligatorio'
                  scrollToID.push('input-indirizzo')
                }
                if(value.agent.cap === '') {
                  fields.cap = 'Campo obbligatorio'
                  scrollToID.push('input-cap')
                }

                if(value.agent.user.name === '') {
                  fields.user.name = 'Campo obbligatorio'
                  scrollToID.push('input-user-name')
                }
                
                if(value.agent.user.email === '') {
                  fields.user.email = 'Campo obbligatorio'
                  scrollToID.push('input-user-email')
                } 
                else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.agent.user.email)) {
                  fields.user.email = 'Indirizzo email non valido';
                  scrollToID.push('input-user-email')
                }
                
                if(!match.params.id && value.agent.user.password === '') {
                  fields.user.password = 'Campo obbligatorio'
                  scrollToID.push('input-user-password')
                }
                if(Object.keys(fields.user).length === 0) {
                  delete fields.user
                }
                                
                const errors = {}
                if(Object.keys(fields).length > 0) errors.fields = fields
                if(scrollToID[0]) errors.first = scrollToID[0]
                return errors
                
              }}
              onSubmit={(values, actions) => {
                const id = this.props.match.params.id ? match.params.id : 'create'

                const allFields = copy(values.agent)
                
                if(allFields.user.password === '') {
                  delete allFields.userpassword
                }
                //allFields.nazione = allFields.nazione.label
                allFields.regioni = this.unsetArray(allFields.regioni)
                allFields.province = this.unsetArray(allFields.province)
                
                allFields.cap = values.agent.cap.toString()
                
                allFields.agent_id = allFields.agent ? allFields.agent.value : undefined 
                //delete allFields.agent
                allFields.percent = 10

                // const { user_name, user_email, user_password } = allFields
                // allFields.user = {
                //   name: user_name,
                //   email: user_email,
                //   password: user_password
                // }

                this.props.putAgentById(id, allFields).then((response) => {
                  if(response.status >= 200 && response.status < 300) {
                    this.props.setSnack(true,'Agente creato con successo','success')
                    history.push(`/agente/${response.data.data.id}`);
                  }
                  else {
                    this.props.setSnack(true,'Form non compilato correttamente, verifica i dati inseriti','warning')
                    Object.keys(response.data.errors).map(function(key, index) {
                      actions.setFieldError(key,response.data.errors[key])
                    })
                  }
                }).catch((error)=>{})
              }}
              render={(props) => {
                const { touched, errors } = props
                return (
                  <form onSubmit={props.handleSubmit}>
                    <ErrorFocus firstItem={errors.first} />
                    {/* <Paper className={classes.root} elevation={2}> */}
                    <div className={classes.actionsForm} >
                      { this.props.match.params.id &&
                        <Button 
                          size="small" 
                          color="primary" 
                          className={this.props.classes.buttonRight} 
                          onClick={()=> this.props.history.push(`/agente/${this.props.match.params.id}`)}
                        >
                          <ViewIcon className={this.props.classes.leftIcon} />
                          Visualizza
                        </Button>
                      }
                    </div>
                    {
                      <FormFields 
                        classes={classes}
                        slug='agent'
                        template={formTemplate}
                        onChange={props.handleChange} 
                        onBlur={props.handleBlur}
                        setFieldValue={props.setFieldValue}
                        setFieldTouched={props.setFieldTouched}
                        error={errors.fields}
                        touched={touched.agent}
                        values={props.values.agent} 
                        options={
                          {
                            agents: this.state.agents,
                            allRegions: this.state.allRegions,
                            allProvinces: this.state.allProvinces,
                            citta_byProvince: this.state.citta_byProvince
                          }
                        }
                        context={this}
                        hasUser='agent'
                      />
                    }
                    {/* </Paper> */}
                    {/* <Paper className={classes.root} elevation={2}>
                      <Grid container className={classes.formContainer}>
                        <div className={classes.sectionTitle}>Dati di accesso</div>
                        <Grid container >
                          <Grid item className={classes.textFieldContainer} xs={6}>
                            <FormControl className={this.props.classes.formControl} error={props.errors.user && props.errors.user_email !== undefined}>
                              <InputLabel htmlFor="input-user_email">E-mail*</InputLabel>
                              <Input id="input-user_email"  name="agent.user_email" value={props.values.agent.user_email} onChange={props.handleChange} />
                              {props.errors.user && props.errors.user_email && <FormHelperText>{props.errors.user_email}</FormHelperText>}
                            </FormControl>
                          </Grid>
                          <Grid item className={classes.textFieldContainer} xs={6}>
                            <FormControl className={this.props.classes.formControl} error={props.errors.user && props.errors.user_name !== undefined}>
                              <InputLabel htmlFor="input-user_name">Username*</InputLabel>
                              <Input id="input-user_name"  name="agent.user_name" value={props.values.agent.user_name} onChange={props.handleChange} />
                              {props.errors.user && props.errors.user_name && <FormHelperText>{props.errors.user_name}</FormHelperText>}
                            </FormControl>
                          </Grid>
                          <Grid item className={classes.textFieldContainer} xs={6}>
                            <FormControl className={this.props.classes.formControl} error={props.errors.user && props.errors.user_password !== undefined}>
                              <InputLabel htmlFor="input-user_password">Password*</InputLabel>
                              <Input id="input-user_password" type="password"  name="agent.user_password" value={props.values.agent.user_password} onChange={props.handleChange} />
                              {props.errors.user && props.errors.user_password && <FormHelperText>{props.errors.user_password}</FormHelperText>}
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Paper> */}
                    
                    <Grid container justify="center">
                      <Button 
                        type="submit" 
                        variant="contained" 
                        color="primary" 
                        className={classes.button}>
                        Salva
                      </Button>
                    </Grid>
                  </form>
                )
              }}
            />
        </React.Fragment>
    );
  }
}

AgenteEdizione.propTypes = {
  agent: PropTypes.object,
  getAgentById: PropTypes.func,
  putAgentById: PropTypes.func,
  getAllProvince: PropTypes.func,
  getAllCitiesByProvince: PropTypes.func,
  setSnack: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  agent: state.agents.selected,
  user: state.user,
  loading: state.info.loading
});
const mapDispatchToProps = {
  getAgentById,
  putAgentById,
  getAgentsList,
  getAllProvince,
  getAllCitiesByProvince,
  setSnack
};

export default withRouter(
  connect(mapStateToProps,mapDispatchToProps)(AgenteEdizione)
)
