import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
//import { ConnectedRouter } from 'connected-react-router'
import { BrowserRouter } from 'react-router-dom';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { IntlProvider } from 'react-intl';
import store, { history } from './store'
//import Layout from './Layout'
import App from './App'
import Layout from './Layout'
import it from 'react-intl/locale-data/it.js';
import { addLocaleData } from 'react-intl';
import 'sanitize.css/sanitize.css'
import './index.css'

addLocaleData([...it]);


const target = document.querySelector('#root')
const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#68f4ff',
      main: '#0b6bb5',
      dark: '#0090bd',
      contrastText: '#fff',
    }
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: '0 12px',
        minWidth: 65
      }
    },
    MuiInputBase: {
      input: {
        minHeight: 30,
        padding: 0,
        alignItems: 'center',
        height: 'auto'
      }
    },
    MuiSelect: {
      selectMenu: {
        display: 'flex',
        minHeight: 30
      }
    }
  }
})

render(
  <MuiThemeProvider theme={theme}>
    <IntlProvider locale="it">
      <Provider store={store}>
        {/* <ConnectedRouter history={history}> */}
          <BrowserRouter>
            <>
              <App />
              <Layout />
            </>
          </BrowserRouter>
        {/* </ConnectedRouter> */}
      </Provider>
    </IntlProvider>
  </MuiThemeProvider>,
  target
)
