export const allRegions = [
  {value: "Abruzzo", label: "Abruzzo"},
  {value: "Basilicata", label: "Basilicata"},
  {value: "Calabria", label: "Calabria"},
  {value: "Campania", label: "Campania"},
  {value: "Emilia-Romagna", label: "Emilia-Romagna"},
  {value: "Friuli-Venezia Giulia", label: "Friuli-Venezia Giulia"},
  {value: "Lazio", label: "Lazio"},
  {value: "Liguria", label: "Liguria"},
  {value: "Lombardia", label: "Lombardia"},
  {value: "Marche", label: "Marche"},
  {value: "Molise", label: "Molise"},
  {value: "Piemonte", label: "Piemonte"},
  {value: "Puglia", label: "Puglia"},
  {value: "Sardegna", label: "Sardegna"},
  {value: "Sicilia", label: "Sicilia"},
  {value: "Toscana", label: "Toscana"},
  {value: "Trentino-Alto Adige/Südtirol", label: "Trentino-Alto Adige/Südtirol"},
  {value: "Umbria", label: "Umbria"},
  {value: "Valle d'Aosta/Vallée d'Aoste", label: "Valle d'Aosta/Vallée d'Aoste"},
  {value: "Veneto", label: "Veneto"}
]