import React from 'react'
import PropTypes from 'prop-types';

import {
  Input,
  InputLabel,
  FormHelperText,
  FormControl
} from '@material-ui/core';

const FormInput = ({
  classnameInput,
  id,
  type,
  label,
  name,
  value,
  onChange,
  onBlur,
  error,
  errorName,
  touched,
  required
}) => {
  //console.log('FORM INPUT > > >',name, value)
  return (
    <FormControl 
      className={classnameInput} 
      error={
        error &&
        error[errorName] !== undefined &&  
        touched && 
        touched[errorName]
      }
    >
      <InputLabel htmlFor={`input-${id}`}>{label}{required && '*'}</InputLabel>
      <Input 
        id={`input-${id}`} 
        name={name}
        value={value} 
        onChange={onChange} 
        onBlur={onBlur}
        type={type}
      />
      {
        error &&
        error[errorName] !== undefined &&  
        touched && 
        touched[errorName] && 
        <FormHelperText>{error[errorName]}</FormHelperText>
      }
    </FormControl>
  )
}

FormInput.defaultProps = {
  type: 'text',
  value: ''
}

FormInput.propTypes = {
  classnameInput: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.object,
  errorName: PropTypes.string,
  required: PropTypes.bool
}

export default FormInput