import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getProductById, getAllProducts, getAllCategories, removeItems, putItemCart } from '../actions';
import Table from '../components/Table';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Button
} from '@material-ui/core';
import LoadingPage from '../components/LoadingPage'
import RangeSlider from '../components/RangeSlider'
import PageTitle from '../components/PageTitle';

import {
  Clear as ClearIcon,
} from '@material-ui/icons';
import { FormattedNumber } from 'react-intl';

class ProdottiLista extends React.Component {

  constructor(props) {
    super(props)

    this.labels = [
      {
        value: "id",
        //width: '5%'
        //numeric: true
      },
      {
        value: "immagine",
        label: "Foto",
        type: "img",
        unsortable: true,
        //width: '5%'
      },
      {
        value: "nome",
        label: "Titolo",
        //width: '30%'
      },
      {
        value: "category_name",
        //param: "name",
        label: "Categoria",
        //unsortable: true,
        //width: '20%'
      },
      {
        value: "prezzo_stampabile",
        label: "Prezzo",
        unsortable: true,
        //width: '10%'
      },
      {
        value: "only_inventory",
        label: "Solo magazzino",
        hideForLevel: ['agent', 'pos', 'shipper'],
        type: 'bool',
        //width: '10%'
      }
    ]

    this.state = {
      categories: [],
      dialogFilterByPrice: false,
      filterPrice: [0, 100],
      filtersActive: {
        price: false
      },
      maxPrice: 100
    }
  }


  componentDidMount() {
    const { getAllProducts, getAllCategories, pagination } = this.props;
    getAllProducts({perPage:pagination.per_page, page: pagination.current_page}).then(response => {
      if(response){
        this.setState({
          maxPrice: response.max_price,
          filterPrice: [0, response.max_price]
        })
      }
    }).catch((error)=>{})
    
    getAllCategories({perPage:1000}).then((response)=>{
      let options = []
      response.map((s,i) => {
        options = options.concat({
          value: s.id,
          label: s.full_name,
          sub_label: s.full_name
        })
      })
      this.setState({
        categories: options
      })
    }).catch((error)=>{})
  }
  
  handleRemove(data) {
    this.props.removeItems(data,'products').then(response => {
      if(response.status === 200) {
        this.props.getAllProducts({})
      }
    }).catch((error)=>{})
  }

  goToPage(page) {
    const url = '/prodotti/'+page
    this.props.history.push(url)
  }

  addToCart(p) {
    this.props.getProductById(p.id).then((data)=> {
      const product = {
        id: data.variants[0].id,
        titolo: data.nome,
        quantita: 1,
        prezzo: data.variants[0].prezzo_utente,
        slug: data.variants[0].slug
      }
      this.props.putItemCart({product,userId:this.props.user.id})
    }).catch((error)=>{})
  }

  handleCancel({dialog='openDialog'}) {
    this.setState({
      [dialog]: false,
      filterPrice: [0,this.state.maxPrice]
    })
  }

  handleSubmitFilterByPrice() {
    const { filterPrice } = this.state
    
    this.props.getAllProducts({minPrice: filterPrice[0], maxPrice: filterPrice[1]}).then(response => {
        this.setState((state) =>{
          return {
            filtersActive: {
              price: true
            },
            ['dialogFilterByPrice']: false
          }
        })
      }).catch((error)=>{})
    //}).catch((error)=>{})
    
  }

  activeFilterPrice() {
    return (
      <div>
        <p style={{
            margin: 0, 
            color: '#0b6bb5', 
            textTransform: 'uppercase', 
            letterSpacing: 0.1, 
            fontWeight: 600
          }}
        >Filtro prezzo attivo</p>
        <p style={{
            margin: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <span style={{
            padding: '0 8px',
            backgroundColor: '#ececec',
            fontWeight: 600
          }}>
          <FormattedNumber value={this.state.filterPrice[0]} style="currency" currency="EUR" /> - <FormattedNumber value={this.state.filterPrice[1]} style="currency" currency="EUR" />
          </span>
          <ClearIcon 
            color="error" 
            style={{ fontSize: 20, cursor: 'pointer' }} 
            onClick={()=>this.resetFilter('price')}
          />
        </p>
      </div>
    )
  }

  resetFilter(name) {
    this.props.getAllProducts({perPage:this.props.pagination.per_page, page: this.props.pagination.current_page}).then(response => {
      if(response){
        this.setState({
          maxPrice: response.max_price,
          filterPrice: [0, response.max_price],
          filtersActive: {
            [name]: false
          }
        })
      }
    }).catch((error)=>{})
    // this.setState({
    //   filterPrice: [0, this.state.maxPrice],
    //   filtersActive: {
    //     [name]: false
    //   }
    // })
  }

  render() {
    const {
      loading,
      classes,
      location,
      products,
      pagination,
      getAllProducts,
      user,
      history,
    } = this.props

    const {
      filtersActive,
      filterPrice,
      dialogFilterByPrice,
      maxPrice
    } = this.state

    return (
      <React.Fragment>
        { loading && <LoadingPage /> }
        <PageTitle 
          title="E-commerce"
          breadcrumbs={['W-SHOP','PRODOTTI']}
          goTo={
            (user.level === 'admin' || user.level === 'operator') && {
            label: 'Ordina prodotti',
            action: ()=>history.push('/prodotti/ordinamento')
          }}
          //handleBackClick={()=>history.push('/prodotti')}
          classes={classes}
        />
        <Paper elevation={2} className={classes.root}>
          <Table 
            slug="e-commerce"
            tableTitle="E-commerce"
            location={location}
            choises={products.data}
            pagination={pagination}
            labels={this.labels} 
            paginate={({perPage,page,orderBy,order,search}) => getAllProducts({perPage,page,orderBy,order,search})}
            removeItems={(data) => this.handleRemove(data)}
            goToPage={(page) => this.goToPage(page)}
            addToCart={(product) => this.addToCart(product)}
            userLevel={user.level}
            filters={[
              {
                name: 'category',
                placeholder: 'Filtra per categoria',
                options: this.state.categories,
                callback: (value) => this.props.getAllProducts({ categoryId: value })
              }
            ]}
            filtersModal={[
              {
                label: 'Filtra prezzo',
                callback: () => {
                  this.setState({'dialogFilterByPrice': true})
                },
                active: filtersActive.price,
                activeComponent: () => this.activeFilterPrice()
              }
            ]}
          />
        </Paper>
        <Dialog
          open={dialogFilterByPrice}
          aria-labelledby="Seleziona un range di prezzo da filtrare"
        >
          <DialogTitle>Filtra per prezzo</DialogTitle>
          <DialogContent>
            { loading && <LoadingPage light absolute /> }
            <DialogContentText>Seleziona un range di prezzo da filtrare:</DialogContentText>
            <RangeSlider 
              max={maxPrice}
              callback={(e,value) => this.setState({ filterPrice: value })
              } 
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { this.handleCancel({ dialog: 'dialogFilterByPrice' }) } } color="primary">
              Cancella
            </Button>
            <Button 
              onClick={() => this.handleSubmitFilterByPrice()} 
              color="primary"
              //variant="contained"
            >
              Filtra
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ProdottiLista.propTypes = {
  products: PropTypes.object.isRequired,
  getProductById: PropTypes.func.isRequired,
  getAllProducts: PropTypes.func.isRequired,
  getAllCategories: PropTypes.func.isRequired,
  putItemCart: PropTypes.func.isRequired,
  removeItems: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  products: state.products.list,
  user: state.user,
  pagination: state.info.pagination,
  loading: state.info.loading
});

export default withRouter(
  connect(mapStateToProps, {
    getProductById,
    getAllProducts,
    getAllCategories,
    putItemCart,
    removeItems
  })(ProdottiLista)
);
