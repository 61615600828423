import { RETURN_SUPPLIERS, GET_SUPPLIER_DETAILS, POST_SUPPLIER, DELETE_SUPPLIER } from '../actions';

const initialState = {
  list: {},
  selected: {},
  pagination: {}
}

export default function suppliers(state = initialState, action) {
  switch (action.type) {
    case RETURN_SUPPLIERS:
      return {
        ...state,
        list: action.suppliers,
        selected: {},
      };
    case GET_SUPPLIER_DETAILS:
    case POST_SUPPLIER: 
      return {
        ...state,
        selected: action.supplier
      };
    default:
    case DELETE_SUPPLIER:
      return state;
  }
}
