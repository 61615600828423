import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {IMG_URL} from '../config';
import imgPlaceholder from '../assets/img/imgPlaceholder.png'

import MinusIcon from '@material-ui/icons/Remove';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const ListDraggable = ({
  droppableId = 'droppable',
  items = [],
  onOrderChange,
  removeItem
}) => {

  const [state, setState] = useState(items)

  useEffect(()=>{
    setState(items)
  },[items.length])

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      state,
      result.source.index,
      result.destination.index
    );

    setState(items);
    onOrderChange(items)
  }

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 8,
    marginBottom: 8,
    display: 'flex',
    alignItems: 'center',
    // change background colour if dragging
    //borderWidth: 2,
    //borderColor: isDragging ? "black" : "white",
    //borderStyle: 'solid',
    background: isDragging ? "lightgoldenrodyellow" : "white",
    // styles we need to apply on draggables
    ...draggableStyle
  });
  
  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "#e3e3e3" : "",
    // padding: grid,
    // width: 250
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
          {state.map((item, index) => (
            <Draggable key={item.id} draggableId={String(item.id)} index={index}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={getItemStyle(
                    snapshot.isDragging,
                    provided.draggableProps.style
                  )}
                >
                  <img width={40} height={40} src={item.immagine !== null ? IMG_URL+item.immagine : imgPlaceholder} alt={item.nome} style={{marginRight: 8}} />
                  <span style={{flex:2}}>{item.nome}</span>
                  <span onClick={()=>removeItem(item)} style={{cursor: 'pointer'}}><MinusIcon /></span>
                </div>
              )}
            </Draggable>
            ))}
            {/* {provided.placeholder} */}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}

export default ListDraggable;