import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik, FieldArray } from 'formik';
import NumberFormat from 'react-number-format';

import { 
  getProductById, 
  putProductById, 
  setSnack, 
  getAllSuppliers, 
  getAllCategories, 
  removeImage 
} from '../actions';

import {
  Paper, 
  Input,
  InputLabel,
  FormControlLabel,
  Checkbox,
  TextField,
  FormHelperText,
  Select,
  FormControl,
  Button,
  IconButton,
  Grid,
  Stepper,
  Step,
  StepLabel,
  StepConnector
} from '@material-ui/core';

import {
  Add as PlusIcon,
  ArrowBack as ArrowBackIcon,
  Visibility as ViewIcon,
  Close as CloseIcon,
  Add as AddIcon
} from '@material-ui/icons';

import ChipInput from 'material-ui-chip-input';

import RSelect from '../components/ReactSelect';
import DatePickerUI from '../components/DatePickerUI'
import LoadingPage from '../components/LoadingPage'
import AutoSelect from '../components/Form/AutoSelect'
import NativeSelect from '../components/Form/NativeSelect'

import { IMG_URL } from '../config';

function getSteps() {
  return ['Seleziona il tipo di prodotto', 'Seleziona le varianti', 'Crea il prodotto'];
}


class ProdottoEdizione extends React.Component {

  constructor(props) {
    super(props)
    this.formVarianti = React.createRef();
    this.formProduct = React.createRef();
    this.state= {
      type: 'semplice',
      activeStep: props.match.params.id ? 2 : 0,
      completed: {},
      variants: true,
      suppliers: [],
      categories: [],
      product: {
        only_inventory: 0,
        supplier: null,
        category: null,
        nome: '',
        descrizione: '',
        codice_a_barre: '',
        unita_misura: '',
        scadenza: null,
        aliquota_iva: 22,
        varianti: [],
        variants: [
          //{
          // prezzo_acquisto: 0,
          // prezzo_vendita: 0,
          // prezzo_vendita_corner: 0
        //}
        ],
        immagine: ''
      }
    }
  }

  componentDidMount() {
    const { getProductById, getAllSuppliers, getAllCategories, match } = this.props;

    if(match.params.id) {
      getProductById(match.params.id).then( (response) => {
        if(response) {
          this.setState({
            type: response.varianti && response.varianti.length > 0 ? 'varianti' : 'semplice',
            product: {
              only_inventory: response.only_inventory || 0,
              supplier: {value: response.supplier.id, label: response.supplier.id + ' - ' + response.supplier.denominazione},
              category: {value: response.category.id, label: response.category.full_name},
              nome: response.nome,
              descrizione: response.descrizione,
              codice_a_barre: response.codice_a_barre || '',
              unita_misura: response.unita_misura || '',
              scadenza: response.scadenza || null,
              aliquota_iva: response.aliquota_iva || 22,
              varianti: response.varianti || [],
              variants: response.variants || [],
              immagine: response.immagine
            }
          })
        }
      }).catch((error)=>{})
    }
    getAllSuppliers({perPage:1000})
      .then((response)=>{
        let options = []
        
        response.map((s,i) => {
          options = options.concat({
            value: s.id,
            label: s.id + ' - ' + s.denominazione
          })
        })
        this.setState({
          suppliers: options
        })
      }).catch((error)=>{})
    getAllCategories({perPage:1000}).then((response)=>{
      let options = []
      response.map((s,i) => {
        options = options.concat({
          value: s.id,
          label: s.full_name,
          sub_label: s.full_name
        })
      })
      this.setState({
        categories: options
      })
    }).catch((error)=>{})
  }

  handleStep = (step) => {
    let variante = []
    if(this.state.activeStep === 0 && this.state.type === 'semplice') {
      ++step
      this.handleVariants(false)
    }
    else if(this.state.activeStep === 0 && this.state.type === 'varianti') {
      if(this.state.product.varianti === null || this.state.product.varianti.length === 0) {
        variante.push({
          name: '',
          values: []
        })
      }
    }
    // else if(this.state.activeStep === 1 && this.state.type === 'varianti') {
    //   this.handleVariants()
    // }
    else if(this.state.activeStep === 2 && this.state.type === 'semplice') {
      --step
    }
    else if(this.state.activeStep === 2 && this.state.type === 'varianti') {
      alert('Attenzione')
    }

    this.setState((state) => {
      return {
        activeStep: step,
        product: {
          ...state.product,
          varianti: variante.length > 0 ? variante : state.product.varianti
        }
      }
    });
  };

  handleVariants = async (ricalcola=true) => {
    if(this.state.type === 'semplice') {
      if(this.state.product.variants.length === 0) {
        this.setState((state) => {
          return {
            ...state,
            product: {
              ...state.product,
              variants: [{
                quantita: 0, 
                prezzo_acquisto: 0, 
                prezzo_vendita: 0, 
                prezzo_vendita_corner: 0, 
                stato: 'Disponibile'
              }]
            }
          }
        })
      }
    }
    else {
      let counter = 1
      let newVariants = []
      let index_varianti = []
      
      if(ricalcola) {
        // setta gli index per prelevarne i nomi dei valori in ordine crescente
        this.state.product.varianti.map((variante, i) => (
          counter = counter * variante.values.length
          
        ))
        for(let i=0; i<counter; i++) {
          newVariants.push({
            label:'',
            slug:'',
            quantita: 0, 
            prezzo_acquisto: 0, 
            prezzo_vendita: 0, 
            prezzo_vendita_corner: 0, 
            stato: 'Disponibile'
          })
        }
      }
      else {
        newVariants = this.state.product.variants
      }
      
      // setta gli index per prelevarne i nomi dei valori in ordine crescente
      this.state.product.varianti.map((variante, i) => {
          const v = {
            attuale: 0,
            max:  variante.values.length - 1
          }
          index_varianti.push(v)
          //counter = counter * variante.values.length
      })

      // setta gli slug per ogni variante
      for(let i=0; i<newVariants.length; i++) {
          let slug = "", label= ""
          // ciclo per aggiungere lo slug
          for(let j=0; j<index_varianti.length; j++) {
            slug = slug.concat(this.state.product.varianti[j]['values'][index_varianti[j]['attuale']]['slug'])
            label = label.concat(this.state.product.varianti[j]['name']+": "+this.state.product.varianti[j]['values'][index_varianti[j]['attuale']]['name'])
            if(j < index_varianti.length - 1) {
              slug = slug.concat("-");
              label = label.concat(", ");
            }
          }
          // setto lo slug finito
          newVariants[i].label = label
          newVariants[i].slug = slug
          // prepara gli index per l'iterazione successiva
          for(let j=index_varianti.length-1; j >= 0; j--) {
              if(index_varianti[j]['attuale'] < index_varianti[j]['max']) {
                  index_varianti[j]['attuale']++;
                  break;
              }
              else {
                  index_varianti[j]['attuale'] = 0;
              }
          }
      }
      
      await this.setState((state) => {
        return {
          ...state,
          product: {
            ...state.product,
            slugs: counter,
            variants: newVariants
          }
        }
      })
    }
  }

  handleType = (tipo) => {
    this.setState({ type: tipo })
  };

  handleChips = (values,chip,field,index) => {
    if(index > -1) {
      //remove
      
      const x = values.filter((v,i) => {
        return v.slug !== chip
      })

      this.formVarianti.current.setFieldValue(field, x);
    }
    else {
      // add
      chip.slug = this.slugify(chip.slug)
      this.formVarianti.current.setFieldValue(field, values.concat(chip));
    }
  };


  addVariant = (i) => {
    this.formVarianti.current.setFieldValue(`product.varianti[${i+1}].name`, '');
    this.formVarianti.current.setFieldValue(`product.varianti[${i+1}].values`, []);
  }

  removeVariant = (values,i) => {
    const newValues = [...values]
    newValues.splice(i,1)
    this.formVarianti.current.setFieldValue(`product.varianti`, []);
    newValues.map((v,x)=>{
      this.formVarianti.current.setFieldValue(`product.varianti[${x}].name`, v.name);
      this.formVarianti.current.setFieldValue(`product.varianti[${x}].values`, v.values);
    })
  }
  
  slugify = (text) => {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '_')           // Replace spaces with _
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-+/g, '_')           // Replace - with _
      .replace(/\_\_+/g, '_')         // Replace multiple _ with single _
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  }
  
  handleDate = (date) => {
    if(date) {
      const d = date.split('/')
      //console.log(date, new Date(d[2],d[1],d[0]))
      return new Date(d[2],d[1]-1,d[0])
    }
    else return null
  }

  resetImg = () => {
    if(this.props.product.id) {
      this.props.removeImage(this.props.product.id).then(()=>{
        this.formProduct.current.setFieldValue("product.immagine", '')
      })
    }
    else {
      this.formProduct.current.setFieldValue("product.immagine", '')
    }
  }
  
  
  render() {
    const steps = getSteps();
    const { 
      loading,
      classes,
      match,
      history,
      location,
      setSnack,
      putProductById,
      user
    } = this.props
    const { activeStep, type, only_inventory } = this.state;
    const connector = (
      <StepConnector
        classes={{
          active: classes.connectorActive,
          completed: classes.connectorCompleted,
          disabled: classes.connectorDisabled,
          line: classes.connectorLine,
        }}
      />
    );
    return (
      loading ? <LoadingPage /> :
        <Paper elevation={2} className={classes.root}>
          <Button size="small" color="primary" className={classes.button} onClick={()=>history.push('/prodotti')}>
            <ArrowBackIcon className={classes.leftIcon} /> Torna alla lista
          </Button>
          { match.params.id &&
            <Button 
              size="small" 
              color="primary" 
              className={classes.buttonRight} 
              onClick={()=> history.push(`/prodotti/${match.params.id}`)}
            >
              <ViewIcon className={classes.leftIcon} />
              Visualizza
            </Button>
          }
          <p className={classes.titleProduct}>{this.state.product.nome}</p>
          { !match.params.id &&
            <Stepper alternativeLabel activeStep={activeStep} connector={connector}>
              {steps.map((label, index) => {
                return (
                  <Step key={label}>
                    <StepLabel>
                      {label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          }
          <div className={classes.formContainer}>
          {activeStep === 0 && (
            <Grid container justify="center" spacing={5} className={classes.formContainer} >
              <Grid container justify="center">
                <Button onClick={() => this.handleType('semplice')} 
                        color={type==='semplice' && "primary" || "default" } 
                        variant="contained" 
                        className={classes.button}>
                        Prodotto semplice 
                </Button>
                <Button onClick={() => this.handleType('varianti')} 
                        color={type==='varianti' && "primary" || "default" } 
                        variant="contained" 
                        className={classes.button}>
                        Prodotto con varianti 
                </Button>
              </Grid>
              <Grid container justify="center">
                <Button onClick={() => this.handleStep(parseInt(activeStep+1))} color="primary" variant="contained" className={classes.button}>Avanti </Button>
              </Grid>
            </Grid>
          )}
          {activeStep === 1 && 
            <Formik
              ref={this.formVarianti}
              initialValues={{ product: this.state.product }}
              enableReinitialize={true} 
              validate={(value) => {
                let errors = {varianti: [{}]};
                let hasError = false;
                if(value.product.varianti[0].name === '') {
                  errors.varianti[0].name = 'Campo obbligatorio'
                  hasError = true
                }
                if(value.product.varianti[0].values.length === 0) {
                  errors.varianti[0].values = 'Campo obbligatorio'
                  hasError = true
                }
                
                if(hasError === false) {
                  delete errors.varianti
                }
                return errors
              }}
              onSubmit={(values, actions) => {
                let ricalcola = false;
                
                if(this.state.product.varianti.length === values.product.varianti.length) {
                  //stessa dimensione degli array varianti vecchie e nuove
                  values.product.varianti.map((v,i)=>{
                    if(v.values.length !== this.state.product.varianti[i].values.length) {
                      //ricrea le variants
                      ricalcola = true
                    }
                  })
                }
                else {
                  //ricrea le variants
                  ricalcola = true
                }

                this.setState((state) => {
                  return {
                    ...state,
                    product: values.product
                  }
                })
                this.handleVariants(ricalcola).then((resp)=>{
                  //this.formProduct.current.setFieldValue(`product.variants`, this.state.product.variants);
                  this.handleStep(parseInt(activeStep+1))
                })
              }}
              render={props => {
                return (
                <form onSubmit={props.handleSubmit}>
                  <FieldArray
                    name="varianti"
                    render={arrayHelpers => (
                      props.values.product.varianti && props.values.product.varianti.length > 0 ? (
                        props.values.product.varianti.map((variante, i) =>
                          <Grid container spacing={5} key={i}>
                            <Grid item xs={5}>
                              <FormControl className={classes.formControl} error={props.errors.varianti && props.errors.varianti[i] && props.errors.varianti[i].name !== undefined}>
                                <InputLabel htmlFor={`input-variante-nome-${i}`}>Tipo variante (es:colore, taglia,ecc. )</InputLabel>
                                <Input 
                                  id={`input-variante-${i}`} 
                                  name={`product.varianti[${i}].name`} 
                                  value={props.values.product.varianti[i].name} 
                                  onChange={props.handleChange} />
                                {props.errors.varianti && props.errors.varianti[i] && <FormHelperText>{props.errors.varianti[i].name}</FormHelperText>}
                              </FormControl>
                            </Grid>
                            <Grid item xs={5}>
                              {/* <FormControl className={classes.formControl}>
                                <InputLabel htmlFor={`input-variante-valore-${i}`}>Valore</InputLabel>
                                <Input id={`input-variante-valore-${i}`} name={`product.varianti[${i}].values`} value={props.values.product.varianti[i].values} onChange={props.handleChange} />
                                {props.errors.varianti && <FormHelperText>{props.errors.varianti[i].name}</FormHelperText>}
                              </FormControl> */}
                              <ChipInput
                                defaultValue={props.values.product.varianti[i].values}
                                fullWidth
                                newChipKeyCodes={[13, 188]}
                                //chipRenderer={this.slugify}
                                dataSourceConfig={{ text: 'name', value: 'slug' }}
                                label='Variante (ex. rosso, giallo)'
                                name={`product.varianti[${i}].values`}
                                value={props.values.product.varianti[i].values} 
                                //onChange={(chips) => this.handleChips(chips,`product.varianti[${i}].values`)}
                                onAdd={(chip) => this.handleChips(props.values.product.varianti[i].values,chip,`product.varianti[${i}].values`)}
                                onDelete={(chip, index) => this.handleChips(props.values.product.varianti[i].values,chip,`product.varianti[${i}].values`,index)}
                                placeholder='Inserisci valori'
                                helperText={props.errors.varianti && props.errors.varianti[i] && props.errors.varianti[i].values}
                                error={props.errors.varianti && props.errors.varianti[i] && props.errors.varianti[i].values !== undefined}/>
                                {/* {props.errors.varianti && props.errors.varianti[i] &&  <FormHelperText>{props.errors.varianti[i].values}</FormHelperText>} */}
                            </Grid>
                            <Grid item xs={2}>
                              {
                                (props.values.product.varianti[i].name !== '' && props.values.product.varianti[i].values.length > 0 && props.values.product.varianti.length === i+1 ) ? 
                                <IconButton 
                                  aria-label="Aggiungi" 
                                  color="primary"
                                  variant="contained" 
                                  className={classes.button}
                                  onClick={()=>this.addVariant(i)}
                                >
                                  <AddIcon />
                                </IconButton>
                                // <Button onClick={()=>this.addVariant(i)} variant="contained" color="primary" className={classes.button}>Aggiungi</Button> 
                                : null
                              }
                              { i === 0 && props.values.product.varianti.length === 1 ? 
                                null
                                : 
                                <IconButton 
                                  aria-label="Rimuovi" 
                                  color="primary" 
                                  className={classes.button}
                                  onClick={()=>this.removeVariant(props.values.product.varianti,i)}
                                >
                                  <CloseIcon />
                                </IconButton>
                                // <Button onClick={()=>this.removeVariant(props.values.product.varianti,i)} color="primary" className={classes.button}>Rimuovi </Button>
                              }
                            </Grid>
                          </Grid>
                        )) : null
                    )}
                  />
                  <Grid container justify="center">
                    <Button onClick={() => this.handleStep(parseInt(activeStep-1))} color="primary" className={classes.button}>
                      Indietro
                    </Button>
                    <Button type="submit" color="primary" variant="contained" className={classes.button}>
                      Avanti
                    </Button>
                  </Grid>
                </form>
              )}}
            />
          } 
          {activeStep === 2 &&  
            <Formik
              ref={this.formProduct}
              initialValues={{ product: this.state.product, errors: {} }}
              enableReinitialize={true} 
              validateOnChange={false}
              //validateOnBlur={false}
              validate={(value) => {
                let errors = {};
                if(value.product.supplier === null) {
                  errors.supplier = 'Campo obbligatorio'
                }
                if(value.product.category === null) {
                  errors.category = 'Campo obbligatorio'
                }
                if(value.product.nome === '') {
                  errors.nome = 'Campo obbligatorio'
                }
                if(value.product.descrizione === '') {
                  errors.descrizione = 'Campo obbligatorio'
                }
                if(value.product.unita_misura === '') {
                  errors.unita_misura = 'Campo obbligatorio'
                }
                
                return errors
                
              }}
              onSubmit= { async (values, actions) => {
                //if(location.pathname.match(/(prodotti\/copy)/g))
                const id = match.params.id && location.pathname.match(/(prodotti\/edit)/g) ? match.params.id : 'create'
                let allValues = Object.assign({},values)
                console.log('allValues', allValues)
                
                allValues.product.variants.map((v,i) => {
                  delete v.label
                  delete v.quantita_impegnata
                  delete v.stato_da_stampare
                  delete v.prezzo_utente
                  delete v.deleted_at
                  //delete v.id
                })
                
                //console.log(blob)
                //return false
                
                if(typeof(allValues.product.immagine) === 'string' && !id === 'create'){
                  delete allValues.product.immagine
                }
                
                if(allValues.product.scadenza === null){
                  delete allValues.product.scadenza
                }
                if(allValues.product.immagine === null){
                  delete allValues.product.immagine
                }
                
                allValues.product.supplier_id = allValues.product.supplier.value
                delete values.product.supplier

                allValues.product.category_id = allValues.product.category.value
                delete values.product.category
                putProductById(id, allValues.product).then((response) => {
                  if(response.status >= 200 && response.status <= 299) {
                    setSnack(true,'Prodotto creato con successo','success')
                    history.push(`/prodotti/${response.data.data.id}`);
                  }
                  else {
                    setSnack(true,response.data.message,'warning')
                  }
                }).catch((error)=>{})
              }}
              render={props => (
                this.state.variants ?
                <form noValidate>
                  <Grid container >
                    <Grid item xs={4} >
                      <FormControl >
                        <Input
                          accept="image/jpeg, image/png"
                          name="product.immagine"
                          valeu={props.values.product.immagine}
                          onChange={(event) => {
                            let reader = new FileReader();
                            reader.onload = function() {
                              let output = document.getElementById('preview_image');
                              output.src = reader.result;
                            }
                            if(event.currentTarget.files[0]) {
                              reader.readAsDataURL(event.currentTarget.files[0]);
                              props.setFieldValue("product.immagine", event.currentTarget.files[0]);
                            }
                          }}
                          className={classes.inputHidden}
                          id="immagine_file"
                          type="file"
                        />
                        <label htmlFor="immagine_file">
                          <div className={classes.previewContainer}>
                            {
                              props.values.product.immagine !== '' && props.values.product.immagine !== null && 
                              <img id="preview_image" src={IMG_URL +props.values.product.immagine} width='100%' alt="preview img prodotto"/> ||
                              <React.Fragment>
                                <PlusIcon />
                                <span>Carica foto prodotto</span>
                              </React.Fragment>
                            }
                          </div>
                        </label>
                        { props.values.product.immagine !== '' && props.values.product.immagine !== null &&
                          <Button variant="contained" color="primary" className={classes.button} onClick={()=> this.resetImg()}>Rimuovi immagine</Button>
                        }
                      </FormControl>
                    </Grid>
                    <Grid item xs={8} >    
                      <Grid container spacing={5}>
                        <Grid item xs={6}>
                          <FormControl className={classes.formControl} error={props.errors.nome !== undefined}>
                            <InputLabel htmlFor="input-nome">Nome prodotto*</InputLabel>
                            <Input id="input-nome" onBlur={props.handleBlur} name="product.nome" value={props.values.product.nome} onChange={props.handleChange} />
                            {props.errors.nome && <FormHelperText>{props.errors.nome}</FormHelperText>}
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl className={classes.formControl} error={props.errors.descrizione !== undefined}>
                            <InputLabel htmlFor="input-descrizione">Descrizione prodotto*</InputLabel>
                            <Input id="input-descrizione" onBlur={props.handleBlur} multiline={true} name="product.descrizione" value={props.values.product.descrizione} onChange={props.handleChange} />
                            {props.errors.descrizione && <FormHelperText>{props.errors.descrizione}</FormHelperText>}
                          </FormControl>
                        </Grid>
                        <Grid item  xs={6}>
                          <FormControl className={classes.formControl} error={props.errors.supplier !== undefined}>
                            <AutoSelect 
                              //key={fields.nazione.name}
                              id="input-supplier_id"
                              name="product.supplier" 
                              options={this.state.suppliers}
                              value={props.values.product.supplier}
                              classnameInput={classes.formControl} 
                              label="Fornitore"
                              placeholder="Seleziona un fornitore*"

                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              
                              setFieldValue={props.setFieldValue}
                              setFieldTouched={props.setFieldTouched}
                              error={props.errors}
                              touched={props.touched && props.touched.product}
                              errorName={`supplier`}
                              required={true}
                              fullValue={true}
                              //context={props.context}
                            />
                            {/* <RSelect
                              id="input-supplier_id"
                              name="product.supplier" 
                              options={this.state.suppliers}
                              value={props.values.product.supplier}
                              setChange={(event) => {
                                props.setFieldValue("product.supplier", event);
                              }}
                              label="Fornitore*"
                              error={props.errors.supplier !== undefined}
                              placeholder="Seleziona un fornitore*"
                            />
                            {props.errors.supplier && <FormHelperText>{props.errors.supplier}</FormHelperText>} */}
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl className={classes.formControl} error={props.errors.category !== undefined}>
                           <AutoSelect 
                              //key={fields.nazione.name}
                              id="input-category_id"
                              name="product.category" 
                              options={this.state.categories}
                              value={props.values.product.category}
                              classnameInput={classes.formControl} 
                              label="Categoria"
                              placeholder="Seleziona una categoria*"

                              onChange={props.handleChange}
                              onBlur={props.handleBlur}
                              
                              setFieldValue={props.setFieldValue}
                              setFieldTouched={props.setFieldTouched}
                              error={props.errors}
                              touched={props.touched && props.touched.product}
                              errorName={`category`}
                              required={true}
                              fullValue={true}
                              //context={props.context}
                            />
                            
                            {/* <RSelect
                              id="input-category_id"
                              name="product.category" 
                              options={this.state.categories}
                              value={props.values.product.category}
                              setChange={(event) => {
                                props.setFieldValue("product.category", event);
                              }}
                              error={props.errors.category !== undefined}
                              label="Categoria*"
                              required={true}
                              placeholder="Seleziona una categoria*"
                            />
                            {props.errors.category && <FormHelperText>{props.errors.category}</FormHelperText>}  */}
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="input-codice_a_barre">Codice a barre</InputLabel>
                            <Input id="input-codice_a_barre"  name="product.codice_a_barre" value={props.values.product.codice_a_barre} onChange={props.handleChange} />
                            {props.errors.codice_a_barre && <FormHelperText>{props.errors.codice_a_barre}</FormHelperText>}
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl className={classes.formControl} error={props.errors.unita_misura !== undefined}>
                            <NativeSelect
                              id={`input-unita_misura`}
                              //classnameInput={classes.formControl} 
                              name={`product.unita_misura`}
                              label="Unità di misura"
                              value={props.values.product.unita_misura}
                              options={[
                                {value:"num", label:"Numero"},
                                {value:"gr", label:"Grammi"},
                                {value:"kg", label:"Kilogrammi"},
                                {value:"cl", label:"Centilitri"},
                                {value:"l", label:"Litri"},
                                {value:"cm", label:"Centimetri"},
                                {value:"m", label:"Metri"}
                              ]} 
                              // onChange={(context, event) => {
                              //   this.getCittaWarehouse(event.target.value, i)
                              // }} 
                              onBlur={props.handleBlur}
                              setFieldValue={props.setFieldValue}
                              setFieldTouched={props.setFieldTouched}
                              error={props.errors}
                              touched={props.touched && props.touched.product}
                              errorName={`unita_misura`}
                              required={true}
                              //context={this}
                            />
                            
                            {/* <InputLabel htmlFor="input-unita_misura">Unità di misura*</InputLabel>
                            <Select
                              native
                              value={props.values.product.unita_misura}
                              onChange={(event) => {
                                props.setFieldValue("product.unita_misura", event.target.value);
                              }}
                            >
                              <option value="" hidden />
                              <option value="num">Numero</option>
                              <option value="gr">Grammi</option>
                              <option value="kg">Kilogrammi</option>
                              <option value="cl">Centilitri</option>
                              <option value="l">Litri</option>
                              <option value="cm">Centimetri</option>
                              <option value="m">Metri</option>
                            </Select>
                            {props.errors.unita_misura && <FormHelperText>{props.errors.unita_misura}</FormHelperText>} */}
                          </FormControl>
                        </Grid>
                        
                        <Grid item xs={6}>
                          <DatePickerUI
                            label="Scadenza"
                            format="dd/MM/yyyy"
                            classes={ classes.formControl }
                            value={this.handleDate(props.values.product.scadenza)}
                            onChange={(value) => {
                              props.setFieldValue("product.scadenza", value);
                            }}
                          />
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl className={classes.formControl} error={props.errors.aliquota_iva !== undefined}>
                            <InputLabel htmlFor="input-aliquota_iva">Aliquota iva</InputLabel>
                            <Select
                              native
                              value={props.values.product.aliquota_iva}
                              onChange={(event) => {
                                props.setFieldValue("product.aliquota_iva", event.target.value);
                              }}
                            >
                              <option value={0}>0%</option>
                              <option value={4}>4%</option>
                              <option value={5}>5%</option>
                              <option value={10}>10%</option>
                              <option value={22}>22%</option>
                            </Select>
                            {props.errors.aliquota_iva && <FormHelperText>{props.errors.aliquota_iva}</FormHelperText>}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControlLabel 
                            className={classes.formControl}
                            control={
                              <Checkbox
                                checked={Boolean(props.values.product.only_inventory)}
                                onChange={(event) => {props.setFieldValue("product.only_inventory", props.values.product.only_inventory ? 0 : 1 )}}
                                //name="product.only_inventory"
                                value={JSON.stringify(props.values.product.only_inventory)}
                                color="primary"
                              />
                            }
                            label="Aggiungi prodotto solo in magazzino"
                          />
                        </Grid>


                        <Grid item xs={12}>
                          <FieldArray
                            name="variants"
                            render={arrayHelpers => (
                              props.values.product.variants && props.values.product.variants.length > 0 ? (
                                props.values.product.variants.map((variant, i) =>
                                  <React.Fragment key={i}>
                                    {variant.slug && <p className={classes.formRowTitle}>{variant.slug}</p>}
                                    <Grid container spacing={5}>
                                      <Grid item xs={6}>
                                        <FormControl className={classes.formControl}>
                                          <InputLabel htmlFor={`input-variants-${i}-codice`}>Codice</InputLabel>
                                          <Input id={`input-variants-${i}-codice`}
                                                  name={`product.variants[${i}].codice`} 
                                                  value={props.values.product.variants[i].codice}  
                                                  onChange={props.handleChange} />
                                        </FormControl>
                                      </Grid>

                                      <Grid item xs={6}>
                                        <FormControl className={classes.formControl}>
                                          <InputLabel htmlFor={`input-variants-${i}-quantita`}>Quantità</InputLabel>
                                          <Input id={`input-variants-${i}-quantita`} 
                                                name={`product.variants[${i}].quantita`} 
                                                value={props.values.product.variants[i].quantita} 
                                                onChange={props.handleChange} />
                                          {props.errors.variants && <FormHelperText>{props.errors.variants[i].quantita}</FormHelperText>}
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <FormControl className={classes.formControl}>
                                          <NumberFormat
                                            className={classes.formControlInput}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            label="Prezzo acquisto"
                                            value={props.values.product.variants[i].prezzo_acquisto} 
                                            onValueChange={values => {
                                              //onChange(values.floatValue);
                                              props.setFieldValue(`product.variants[${i}].prezzo_acquisto`, values.floatValue);
                                            }}
                                            customInput={TextField}
                                            id={`input-variants-${i}-prezzo-acquisto`}
                                            prefix="€"
                                          />
                                          {/* <TextField
                                            className={classes.formControlInput}
                                            label="Prezzo acquisto"
                                            type="number"
                                            value={props.values.product.variants[i].prezzo_acquisto} 
                                            onChange={(value) => {
                                              props.setFieldValue(`product.variants[${i}].prezzo_acquisto`, value);
                                            }}
                                            id={`input-variants-${i}-prezzo-acquisto`}
                                            //name={`product.variants[${i}].prezzo_acquisto`} 
                                            InputProps={{
                                              inputComponent: CurrencyInput,
                                            }}
                                          /> */}
                                        </FormControl>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <FormControl className={classes.formControl}>
                                          <NumberFormat
                                            className={classes.formControlInput}
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            label="Prezzo vendita"
                                            value={props.values.product.variants[i].prezzo_vendita} 
                                            onValueChange={values => {
                                              //onChange(values.floatValue);
                                              props.setFieldValue(`product.variants[${i}].prezzo_vendita`, values.floatValue);
                                            }}
                                            customInput={TextField}
                                            id={`input-variants-${i}-prezzo-vendita`}
                                            prefix="€"
                                          />
                                          {/* <TextField
                                            className={classes.formControlInput}
                                            label="Prezzo vendita"
                                            type="number"
                                            value={props.values.product.variants[i].prezzo_vendita} 
                                            onChange={(value) => {
                                              props.setFieldValue(`product.variants[${i}].prezzo_vendita`, value);
                                            }}
                                            id={`input-variants-${i}-prezzo-vendita`}
                                            //name={`product.variants[${i}].prezzo_acquisto`} 
                                            InputProps={{
                                              inputComponent: CurrencyInput,
                                            }}
                                          /> */}
                                        </FormControl>
                                      </Grid>
                                      { user.level === 'admin' &&
                                        <Grid item xs={6}>
                                          <FormControl className={classes.formControl}>
                                            <NumberFormat
                                              className={classes.formControlInput}
                                              thousandSeparator="."
                                              decimalSeparator=","
                                              label="Prezzo vendita corner"
                                              value={props.values.product.variants[i].prezzo_vendita_corner} 
                                              onValueChange={values => {
                                                //onChange(values.floatValue);
                                                props.setFieldValue(`product.variants[${i}].prezzo_vendita_corner`, values.floatValue);
                                              }}
                                              customInput={TextField}
                                              id={`input-variants-${i}-prezzo-vendita-corner`}
                                              prefix="€"
                                            />
                                            {/* <TextField
                                              className={classes.formControlInput}
                                              label="Prezzo vendita corner"
                                              type="number"
                                              value={props.values.product.variants[i].prezzo_vendita_corner} 
                                              onChange={(value) => {
                                                props.setFieldValue(`product.variants[${i}].prezzo_vendita_corner`, value);
                                              }}
                                              id={`input-variants-${i}-prezzo-vendita-corner`}
                                              //name={`product.variants[${i}].prezzo_acquisto`} 
                                              InputProps={{
                                                inputComponent: CurrencyInput,
                                              }}
                                            /> */}
                                          </FormControl>
                                        </Grid>
                                      }
                                      <Grid item xs={6}>
                                        <FormControl className={classes.formControl}>
                                        <InputLabel htmlFor={`input-stato-${i}`}>Stato</InputLabel>
                                          <Select
                                            native
                                            value={props.values.product.variants[i].stato} 
                                            onChange={(event) => {
                                              props.setFieldValue(`product.variants[${i}].stato`, event.target.value);
                                            }}
                                          >
                                            <option value="Disponibile">Disponibile</option>
                                            <option value="Non disponibile">Non disponibile</option>                                            
                                          </Select>
                                        </FormControl>
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                                )) : null
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container justify="center" className={classes.foot}>
                    { !match.params.id && 
                      <Button onClick={() => this.handleStep(parseInt(activeStep-1))} color="primary" className={classes.button}>Indietro </Button>
                    }
                    <Button onClick={props.handleSubmit} type="button" variant="contained" color="primary" className={classes.button}>Salva</Button>
                  </Grid>
                </form>
                : "Altro form"
              )}
            />
          }
          </div>
        </Paper>
    );
  }
}

ProdottoEdizione.propTypes = {
  product: PropTypes.object,
  getProductById: PropTypes.func,
  putProductById: PropTypes.func,
  setSnack: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  product: state.products.selected,
  user: state.user,
  loading: state.info.loading
});
const mapDispatchToProps = {
  getProductById,
  putProductById,
  getAllSuppliers,
  getAllCategories,
  removeImage,
  setSnack
};

export default withRouter(
  connect(mapStateToProps,mapDispatchToProps)(ProdottoEdizione)
)