import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAllClients, removeItems } from '../actions';
import Table from '../components/Table';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import LoadingPage from '../components/LoadingPage'
import PageTitle from '../components/PageTitle';

class ClientiLista extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      labels: [
        {
          value: "id",
          //numeric: true
        },
        {
          value: "denominazione",
          label: "ragione sociale",
        },
        {
          value: "contatto_cellulare",
          label: "telefono",
          unsortable: true
        },
        {
          value: "email",
          label: "E-mail",
        },
        {
          value: "last_order",
          param: "created_at",
          label: "Data ultimo ordine",
          type: "date"
        },
        {
          value: "partita_iva",
          label: "partita iva",
          unsortable: true
        },
        {
          value: "agent",
          param: "nome_completo",
          label: "Agente",
          unsortable: true,
        }
      ]
    }
  }

  componentDidMount() {
    const { getAllClients, pagination, user } = this.props;
    getAllClients({perPage:pagination.per_page, page: pagination.current_page})
  }
  
  handleRemove(data) {
    this.props.removeItems(data,'pos').then(response => {
      if(response.status === 200) {
        this.props.getAllClients({})
      }
    }).catch((error)=>{})
  }

  goToPage(page) {
    this.props.history.push('cliente/'+page)
  }
  render() {

    const {
      loading,
      clients,
      classes,
      pagination,
      location,
      getAllClients,
      user
    } = this.props

    return (
      <React.Fragment>
        { loading && <LoadingPage /> }
        <PageTitle 
          title="Clienti"
          breadcrumbs={['W-SHOP','CLIENTI']}
          //handleBackClick={()=>history.push('/prodotti')}
          classes={classes}
        />
        <Paper className={classes.root} elevation={2}> 
          <Table 
            slug="clienti"
            tableTitle="Clienti"
            location={location}
            choises={clients.data}
            pagination={pagination}
            labels={this.state.labels} 
            paginate={({perPage,page,orderBy,order,search}) => getAllClients({perPage,page,orderBy,order,search})}
            removeItems={(data) => this.handleRemove(data)}
            goToPage={(page) => this.goToPage(page)}
            userLevel={user.level}
          />
        </Paper>
      </React.Fragment>
    );
  }
}

ClientiLista.propTypes = {
  clients: PropTypes.object.isRequired,
  getAllClients: PropTypes.func.isRequired,
  removeItems: PropTypes.func.isRequired
};
const mapStateToProps = (state, props) => ({
  clients: state.clients.list,
  user: state.user,
  pagination: state.info.pagination,
  loading: state.info.loading
});

export default withRouter(
  connect(mapStateToProps, {
    getAllClients,
    removeItems
  })(ClientiLista)
);
