import React from 'react'
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import LoadingGif from '../assets/img/loading.svg'

const useStyles = makeStyles({
  container: {
    position: (props) => props.absolute ? 'absolute': 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 200,
    backgroundColor: (props) => props.light ? 'rgba(255,255,255,.5)' : 'rgba(0,0,0,.5)'
  }
})

// const styles = theme => ({
//   container: {
//     position: props.absolute ? 'absolute': 'fixed',
//     top: 0,
//     bottom: 0,
//     left: 0,
//     right: 0,
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     backgroundColor: 'rgba(0,0,0,.5)',
//     light
//   }
// })

const LoadingPage = (props) => {
  const classes = useStyles(props)
  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <img 
          src={LoadingGif} 
          className={classes.imgProdotto} 
          width={150} 
          height={150} 
          alt="loading"
        />
      </div>
    </div>
  )
}

LoadingPage.defaultProps = {
  light: false,
  absolute: false
}

LoadingPage.propTypes = {
  light: PropTypes.bool,
  absolute: PropTypes.bool
}

export default LoadingPage
//export default withStyles(styles)(LoadingPage)