import background from './assets/img/bgLogin.png'

const layoutStyle = theme => ({
  root: {
    //...theme.mixins.gutters(),
    padding: 0,
    margin: '8px auto',
    maxWidth: 1440
  },
  loginContainer: {
    flexGrow: 1,
    height: '100%',
    position: 'absolute',
    backgroundImage: `url(${background})`,
    backgroundSize: 'contain',
    backgroundRepeatX: 'repeat'
  },
  flexContainer: {
    display: 'flex'
  },
  label: {
    color: '#0000008a',
    padding: 0,
    fontSize: theme.spacing(1.5),
    //font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    lineHeight: 1
  },
  oldValue: {
    textDecoration: 'line-through',
    fontWeight: 400
  },
  inputHidden: {
    display: 'none'
  },
  inline: {
    display: 'inline-block'
  },
  inputSelect: {
    marginTop: '0!important',
    minWidth: 120,
    // padding: '0 8px',
    // border: '1px solid lightgrey'
  },
  labelBottom: {
    position: 'absolute',
    top: 'unset',
    bottom: -16,
    width: '100%',
    textAlign: 'center',
    transform: 'unset',
    fontSize: 12
  },
  parag: {
    margin: 0,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  paragInline: {
    display: 'inline-block',
    margin: 0,
    //paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1)
  },
  textFieldContainer: {
    padding: 8
  },
  navigationBar: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(2)}px 0`,
    alignItems: 'center',
    maxWidth: 1440,
    margin: '0 auto'
  },
  backForm: {
    flex: '1'
  },
  spacer: {
    display: 'flex',
    flex: '1 1 100%',
  },
  row: {
    width: '100%',
  },
  sectionTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    fontWeight: 700,
    textTransform: 'uppercase'
  },
  title: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 'bold',
    flex: '0 0 auto',
    marginRight: theme.spacing(3),
    fontFamily: 'inherit',
    letterSpacing: 'normal'
  },
  titleForm: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 'bold',
    flex: '1',
    textAlign: 'center'
  },
  subtitle: {
    paddingLeft: theme.spacing(1),
    fontSize: 14,
    marginBottom: 0
  },
  subtitleBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  description: {
    marginTop: 0
  },
  containerMax: {
    maxWidth: 1440,
    margin: '40px auto'
  },
  formContainer: {
    //minHeight: '300px',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-around',
    padding: theme.spacing(2)
  },
  actionBarContainer: {
    justifyContent: 'center',
    padding: 20
  },
  
  formRow: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  formRowTitle: {
    paddingTop: theme.spacing(6),
    textTransform: 'uppercase',
    fontWeight: 600,
    margin: 0,
    fontSize: 16,
    '&:first-child':{
      padding: 0
    }
  },
  formControl: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    // marginLeft: theme.spacing(3),
    // marginRight: theme.spacing(3),
    width: '100%',
  },
  formControlInput: {
    
  },
  field: {
    height: 'fit-content!important'
  },
  rootToolBar: {
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  gridHeader: {
    flexGrow: 1,
    margin: 0,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(7),
  },
  gridOrder: {
    flexGrow: 1
  },
  gridOrderDetails: {
    flexDirection: 'column',
    backgroundColor: '#0000000f',
    borderTop: '1px solid lightgrey'
  },
  containerTr: {
    '&:not(:last-child)':{
      borderBottom: '1px solid lightgrey'
    }
  },
  fieldTr: {
    height: 52+'px!important',
    paddingTop: 12,
    paddingBottom: 12 
  },
  fieldTh: {
    height: 'fit-content!important',
    color: '#666',
    fontSize: 14,
    fontWeight: 700,
    textTransform: 'uppercase',
    padding: '12px 0'
  },
  filterSelect: {
    width: 250,
    padding: '0 24px',
    display: 'inline-block'
  },
  filterFlex: {
    display: 'flex',
    alignItems: 'center'
  },
  filterRangeDate:{
    backgroundColor: 'lightgray',
    paddingRight: 4,
    '& svg':{
      cursor: 'pointer'
    }
  },
  searchBox: {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid grey',
    borderRadius: 20,
    padding: '4px 16px',
    backgroundColor: 'white',
    flex: 1,
    '& input': {
      border: 0,
      outline: 'none',
      flex: 2
    },
    '& svg': {
      cursor: 'pointer',
      verticalAlign: 'text-bottom'
    }
  },
  actions: {
    color: theme.palette.text.secondary,
    display: 'flex',
    alignItems: 'center',
    flex: '0 0 auto'
  },
  actionsForm: {
    margin: `${theme.spacing(1)}px auto`,
    width: 1440,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    margin: theme.spacing(1),
    boxShadow: 'none',
  },
  warning: {
    backgroundColor: theme.palette.error.dark,
    color: 'white'
  },
  group: {
    margin: `${theme.spacing(1)}px 0`,
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: theme.palette.secondary.main,
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: theme.palette.primary.main,
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: theme.palette.grey[100],
    },
  },
  connectorLine: {
    transition: theme.transitions.create('border-color'),
  },
  previewContainer: {
    cursor: 'pointer',
    width: 300,
    height: 300,
    backgroundColor: 'lightgrey',
    textTransform: 'uppercase',
    fontWeight: '700',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fontSize: 140,
      flex: 1
    },
    '& span': {
      marginBottom: theme.spacing(4)
    }
  },
  containerColumn: {
    marginTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  foot: {
    marginTop: 40
  },
  error: {
    color: 'red'
  },
  titleProduct: {
    color: '#05c1f0',
    textAlign: 'center',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    fontSize: 20,
    margin: '20px 0'
  },
  titleBox:{
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: '#05c1f0',
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    '& p:last-child': {
      color: '#000',
      textTransform: 'none',
      fontWeight: '400',
    }
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  buttonRight: {
    float: 'right',
    margin: theme.spacing(1),
  },
  breadCrumbs: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: '0 24px',
  },
  breadCrumbsForm: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    flex: '1',
    textAlign: 'right'
  },
  modalContent: {
    position: 'absolute',
    width: 1000,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: '32px 16px',
    outline: 'none',
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
    maxHeight: 600,
    overflowY: 'auto'
  },
  warningParag: {
    fontSize: 20,
    color: 'red'
  },
  cartQtnBox: {
    display: 'flex',
    alignItems: 'flex-end',
    '& input': {
      textAlign: 'center',
      width: 100
    },
    '& svg': {
      cursor: 'pointer',
      width: 30,
      height: 30
    },
  },
  listContainer: {
    width: 1000,
    margin: '0 auto'
  },
  productContainer: {
    display: 'flex',
    justifyContent: 'start',
    borderBottom: '1px solid lightgrey',
    padding: '24px 0',
    '&:last-child': {
      borderBottom: 'none',
    }
  },
  productDetails:{
    width: '100%',
    paddingLeft: theme.spacing(3),
    paddingRigth: theme.spacing(3),
  },
  deliverySummary: {
    paddingTop: 30,
    paddingBottom: 30,
    borderBottom: '1px solid lightgrey',
    '& p': {
      margin: 0
    }
  },
  pricesSummary: {
    display: 'flex',
    flexDirection: 'column',
    //borderBottom: '1px solid lightgrey',
    marginTop: 30,
    '& p': {
      margin: '0 0 16px 0'
    }
  },
  cartSubtotal: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 13,
    paddingBottom: 8,
    '& :last-child': {
      fontWeight: 'bold'
    }
  },
  totalField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '16px 0',
  },
  totalLabel: {
    color: '#0000008a',
    margin: 4,
    textTransform: 'uppercase',
    fontWeight: 700
  },
  totalParag: {
    margin: 0,
    fontSize: 24,
    padding: '8px 16px 8px 8px',
    background: '#f0f0f0',
    fontWeight: 700,
    borderRadius: 5,
  },
  price: {
    fontWeight: 700
  },
  emptyContainer: {
    textAlign: 'center',
    width: '100%',
    padding: theme.spacing(3)
  },
  productsTableContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 30,
    borderBottom: '2px solid #01c1f0',
    //marginBottom: 20
  },
  productTableRow: {
    display: 'flex',
    justifyContent: 'space-around',
    paddingLeft: 16,
    paddingRight: 16,
    borderBottom: '1px solid lightgrey',
    fontSize: 13,
    '&:first-child': {
      color: 'grey'
    },
    '&:last-child': {
      border: 0
    },
    '& p': {
      flexGrow: 1,
      flexBasis: 0,
      margin: '8px 0'
    },
    '& p:first-child': {
      flexGrow: 6
    },
  },
  credentialsSummary: {
    borderRadius: 10,
    border: '2px solid grey',
    backgroundColor: 'lightgrey',
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    width: 500,
    '& p': {
      margin: '4px 0'
    },
  },
  status: {
    paddingLeft: 24,
    '&:before':{
      content: '""',
      width: 10,
      height: 10,
      position: 'absolute',
      top: 8,
      left: 5,
      borderRadius: 100,
    },
    '&.red': {
      '&:before':{
        backgroundColor: 'red',
      }
    },
    '&.green': {
      '&:before':{
        backgroundColor: 'green',
      }
    },
    '&.orange': {
      '&:before':{
        backgroundColor: 'orange',
      }
    },
    '&.grey': {
      '&:before':{
        backgroundColor: 'grey',
      }
    }
  },
  marginX20: {
    marginTop: 20,
    marginBottom: 20
  },
  warningBG: {
    background: 'rgba(255,0,0,0.5)'
  }
})

export default layoutStyle