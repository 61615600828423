import React from 'react'
import PropTypes from 'prop-types';

import {
  FormHelperText,
  FormControl
} from '@material-ui/core';

import RSelect from '../ReactSelect';


const AutoSelect = ({
  classnameInput,
  id,
  label,
  placeholder,
  name,
  value,
  options,
  onChange,
  onBlur,
  setFieldValue,
  setFieldTouched,
  error,
  errorName,
  touched,
  isMulti,
  fullValue,
  required,
  context
}) => {
  return (
    <FormControl 
      className={classnameInput} 
      error={
        error &&
        error[errorName] !== undefined &&  
        touched && 
        touched[errorName]
      }
    >
      <RSelect
        id={`input-${id}`}
        name={name}
        options={options || []}
        value={(typeof value === 'object' || isMulti) ? value : {id: value, label: value} || ''}
        setChange={(event) => {
          let newValue = event && ( (isMulti || fullValue) ? event : event.label) || ''
          console.log('newValue',newValue)
          setFieldValue(name, newValue);
          onChange && onChange(context, newValue) 
        }}
        onBlur={ () => setFieldTouched(name, true) }
        label={label + (required ? '*': '')}
        error={
          error &&
          error[errorName] !== undefined &&  
          touched && 
          touched[errorName]
        }
        isMulti={isMulti}
        placeholder={placeholder}
      />
      {
        error &&
        error[errorName] &&  
        touched && 
        touched[errorName] &&
        <FormHelperText>{error[errorName]}</FormHelperText>
      }
    </FormControl>
  )
}

AutoSelect.defaultProps = {
  value: '',
  options: []
}

AutoSelect.propTypes = {
  classnameInput: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ]),
  options: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  error: PropTypes.object,
  errorName: PropTypes.string,
  isMulti: PropTypes.bool,
  required: PropTypes.bool,
  context: PropTypes.object
}

export default AutoSelect