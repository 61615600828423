import ajax from 'axios';
import { API_URL } from '../config'
import queryString from 'query-string'
export const RETURN_PRODUCTS = 'RETURN_PRODUCTS';
export const GET_PRODUCT_DETAILS = 'GET_PRODUCT_DETAILS';
export const POST_PRODUCT = 'POST_PRODUCT';
export const DELETE_IMAGE = 'DELETE_IMAGE';

function returnProducts(products) {
  return {
    type: RETURN_PRODUCTS,
    products,
  };
}

export function getAllProducts({
  perPage=100, 
  page=1, 
  orderBy='position',
  order='asc',
  search, 
  categoryId, 
  minPrice, 
  maxPrice, 
  only_ordered=false,
  excluded
}) {
  return (dispatch, getState) => {
    const query = {
      perPage,
      page,
      orderBy,
      order,
      search,
      excluded,
      'filter[category]': categoryId,
      'filter[min_price]': minPrice,
      'filter[max_price]': maxPrice,
      'filter[only_ordered]': only_ordered,
    }
    
    return ajax
      .get(`${API_URL}/products?${queryString.stringify(query)}`)
      //.get(API_URL+'/products')
      .then(response => {
        dispatch(returnProducts(response.data));
        return response.data
      })
      .catch(function(error) {
        //console.log(error)
      });
  };
}

function getProductDetails(product) {
  return {
    type: GET_PRODUCT_DETAILS,
    product,
  };
}
export function getProductById(id) {
  return (dispatch, getState) => {
    return ajax
      .get(API_URL+'/products/'+id)
      .then(function(response) {
        dispatch(getProductDetails(response.data.data));
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error.response.data.error);
        return error
      });
  };
}


function putProduct(product) {
  return {
    type: POST_PRODUCT,
    product,
  };
}
export function putProductById(id,data) {
  return (dispatch, getState) => {
    const formData = new FormData();
    Object.keys(data).forEach(keyX => { //product keyX=varianti
      if(Array.isArray(data[keyX])) { //dentro varianti
        data[keyX].map((d,y) => { // loop varianti d={0} y=indice varianti
          Object.keys(d).forEach(keyY => { //key=values 
            if(Array.isArray(d[keyY])) {
              d[keyY].map((v,z) => { //v={} di values
                Object.keys(v).forEach(keyZ => { //keyZ=slug e name
                  return formData.append(`${keyX}[${y}][${keyY}][${z}][${keyZ}]`, v[keyZ]) //varianti[0][values][0]['slug'] = valore
                })
              })
            }
            else{
              return formData.append(`${keyX}[${y}][${keyY}]`, d[keyY])
            }
          })
        })
      }
      else {
        return formData.append(keyX, data[keyX])
      }
    });
    
    if(id !== 'create') {
      formData.append('_method','PUT')
    }

    return ajax({
      method: 'POST',
      url: API_URL+'/admin/products/'+id,
      data: formData
    })
      .then(function(response) {
        dispatch(putProduct(response.data.data));
        return response
      })
      .catch(function(error) {
        return error.response
        //console.log(error.response.data.error);
      });
  }
}

export function removeImage(id) {
  return (dispatch, getState) => {
    return ajax
      .delete(API_URL+'/admin/products/'+id+'/image')
      .then(function(response) {
        dispatch({
          type: DELETE_IMAGE,
          product: response.data.data
        });
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error.response.data.error);
      });
  };
}


export function saveProductOrder(ids) {
  //ids: array di id(number) ordinati
  return (dispatch, getState) => {
    return ajax({
      method: 'POST',
      url: API_URL+'/admin/products/order',
      data: {ids}
    })
    .then(function(response) {
      dispatch({
        type: DELETE_IMAGE,
        product: response.data.data
      });
      return response.data.data
    })
    .catch(function(error) {
      //console.log(error.response.data.error);
    });
  };
}
