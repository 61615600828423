import ajax from 'axios';
import { API_URL } from '../config'
import queryString from 'query-string'
export const RETURN_AGENTS = 'RETURN_AGENTS';
export const GET_AGENT_DETAILS = 'GET_AGENT_DETAILS';
export const POST_AGENT = 'POST_AGENT';
export const RETURN_ROYALTIES = 'RETURN_ROYALTIES';

function returnAgents(agents) {
  return {
    type: RETURN_AGENTS,
    agents,
  };
}

export function getAllAgents({perPage=100, page=1, orderBy='id',order='asc',search=''}) {
  return (dispatch, getState) => {
    const query = {
      perPage,
      page,
      orderBy,
      order,
      search
    }
    const user = getState().user
    const agentPath = user.level === 'admin' ? 'agents' : 'sub_agents'
    return ajax
      .get(`${API_URL}/${user.level}/${agentPath}?${queryString.stringify(query)}`)
      .then(response => {
        dispatch(returnAgents(response.data));
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error);
      });
  };
}

export function getAgentsList(id) {
  return (dispatch, getState) => {
    return ajax
      .get(`${API_URL}/admin/agents/list${id ? '/'+id : ''}`)
      .then(response => {
        //dispatch(returnAgents(response.data));
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error);
      });
  };
}

export function getAgentsListAll(id) {
  return (dispatch, getState) => {
    return ajax
      .get(`${API_URL}/admin/agents/list_all`)
      .then(response => {
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error);
      });
  };
}

function getAgentDetails(agent) {
  return {
    type: GET_AGENT_DETAILS,
    agent,
  };
}
export function getAgentById(id) {
  return (dispatch, getState) => {
    const user = getState().user
    const urlPath = user.level === 'admin' ? '/admin/agents/'+id : '/user/'+id+'/agent'
    return ajax
      .get(API_URL+urlPath)//'/admin/agents/'+id)
      .then(function(response) {
        dispatch(getAgentDetails(response.data.data));
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error.response.data.error);
      });
  };
}


function putAgent(agent) {
  return {
    type: POST_AGENT,
    agent,
  };
}
export function putAgentById(id,data) {
  return (dispatch, getState) => {
    return ajax({
        method: id === 'create' ? 'POST' : 'PUT',
        url: API_URL+'/admin/agents/'+id,
        data: data
      })
      .then(function(response) {
        dispatch(putAgent (response.data.data));
        return response
      })
      .catch(function(error) {
        return error.response
        //console.log(error.response.data.error);
      });
  };
}

function returnRoyalties(royatlies) {
  return {
    type: RETURN_ROYALTIES,
    royatlies
  };
}
export function getAgentRoyalties({perPage=100, page=1, orderBy='id',order='asc',agent=''}) {
  return (dispatch, getState) => {
    const query = {
      perPage,
      page,
      orderBy,
      order,
      'filter[agent]': agent,
    }
    const user = getState().user
    const urlPath = user.level === 'admin' ? '/admin/agents' : '/agent'
    return ajax
      .get(`${API_URL+urlPath}/royalties/summary?${queryString.stringify(query)}`)
      //.get(API_URL+urlPath+'/royalties/summary')
      .then(function(response) {
        dispatch(returnRoyalties(response.data));
        return response.data.data
      })
      .catch(function(error) {
        //console.log(error.response.data.error);
      });
  };
}

export function getProvincesByRegions(regions) {
  //return (dispatch, getState) => {
    return ajax({
      method: 'POST',
      url: API_URL+'/regioni/province',
      data: { regioni: regions }
    })
    //.post(`${API_URL}/regioni/province`,)
    .then(function(response) {
      //dispatch(returnRoyalties(response.data));
      return response.data
    })
    .catch(function(error) {
      return error.response
    });
  //}
}