import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
//import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';


const styles = theme => ({
  input: {
    display: 'flex',
    //padding: 0,
  },
  valueContainer: {
    display: 'flex',
    //flexWrap: 'wrap',
    flex: 1,
    alignItems: 'flex-start',
    overflow: 'hidden',
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  singleValue: {
    fontSize: 16,
    whiteSpace: 'nowrap'
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  chip: {
    lineHeight: 1.5,
    height: 'auto',
    fontSize: 12,
    margin: '4px 8px 4px 0'
  },
  deleteIcon: {
    width: 14,
    height: 14
  }
});

const selectStyles = {
  indicatorsContainer: (provided) => ({
    ...provided,
    '& div': {
      padding: '0 8px'
    }
  })
}

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={
        classNames(props.selectProps.classes.chip, {
          [props.selectProps.classes.chipFocused]: props.isFocused,
        }
      )}
      onDelete={props.removeProps.onClick}
      deleteIcon={
        <CancelIcon 
          className={props.selectProps.classes.deleteIcon} 
          {...props.removeProps} 
        />
      }
    />
  );
}

// function Menu(props) {
//   return (
//     <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
//       {props.children}
//     </Paper>
//   );
// }

const components = {
  Control,
  //Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer
};

class IntegrationReactSelect extends React.Component {
  // state = {
  //   single: null,
  //   multi: null,
  // };

  // handleChange = name => value => {
  //   this.setState({
  //     [name]: value,
  //   });
  // };

  render() {
    const { 
      id,
      classes, 
      placeholder, 
      label, 
      value, 
      defaultValue,
      options, 
      setChange, 
      onBlur,
      error, 
      isMulti, 
      disabled 
    } = this.props;
    
    return (
      <Select
        id={id}
        classes={classes}
        styles={selectStyles}
        isMulti={isMulti || false}
        isClearable
        options={options}
        components={components}
        defaultValue={defaultValue}
        value={value}
        onChange={setChange}
        onBlur={onBlur}
        placeholder={placeholder}
        textFieldProps={{
          error: error,
          label: label,
          InputLabelProps: {
            shrink: true,
          },
        }}
        isDisabled={disabled}
      />
    );
  }
}

IntegrationReactSelect.defaultProps = {
  disabled: false
}


IntegrationReactSelect.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  placeolder: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  options: PropTypes.array.isRequired,
  setChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  defaultValue: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string
  ]),
};

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);