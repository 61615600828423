import { LOGGED_IN, TOGGLE_CART, GET_CART, PUT_ITEM_CART, DELETE_ITEM_CART, SET_COUNTER_ITEM, CHECKOUT } from '../actions';

const initialState = {
  open: false,
  list: [],
  taxes: {},
  totalNetPrice: 0,
  totalVatPrice: 0,
  totalGrossPrice: 0
}

export default function cart(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_CART:
      return {
        ...state,
        open: action.open
      };
    case LOGGED_IN:
      return {
        open: action.cart.open || false,
        list: action.cart.list,
        taxes: action.cart.taxes,
        totalNetPrice: action.cart.net,
        totalVatPrice: action.cart.vat,
        totalGrossPrice: action.cart.gross
      }
    case PUT_ITEM_CART:
    case SET_COUNTER_ITEM:
    case DELETE_ITEM_CART:
    case GET_CART:
      return {
        open: action.open || false,
        list: action.list,
        taxes: action.taxes,
        totalNetPrice: action.net,
        totalVatPrice: action.vat,
        totalGrossPrice: action.gross
      }
    case CHECKOUT: 
      return {
        ...state,
        list: []
      }
    default:
      return state;
  }
}
