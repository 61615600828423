import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    flexGrow: 1,
    borderTop: '1px solid lightgray',
    backgroundColor: '#0b6bb5',
    padding: theme.spacing(2),
    marginTop: theme.spacing(3),
    textAlign: 'center',
    fontSize: 11,
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'stretch',
    color: 'white'
  },
  date: {
    margin: 0,
    flex: 0.1
  },
  copyright: {
    margin: 0,
    flex: 0.8
  }
})

function Footer({ classes }) {

  const [date, setDate] = useState()

  useEffect(() => {
    const now = new Date().toLocaleDateString('it-IT',{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    setDate(now.replace(' ',', '))
  }, [])

  return (
    <div className={classes.container}>
      <p className={classes.date}>{date}</p>
      <p className={classes.copyright}>W-Shop è un prodotto di proprietà di Working Company SRL - Via F. Crispi 85, 72017 Ostuni (BR), P.IVA 02559230749 - www.lavoraconworking.it. <br />©2020 Working Company SRL. Tutti i diritti sono riservati.</p>
    </div>
  )
}

export default withStyles(styles)(Footer)