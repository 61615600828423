import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { submitLogin, submitForget, submitReset, setUser, setSnack } from '../actions';
import LoginForm from '../components/LoginForm';
import ForgetPswForm from '../components/ForgetPswForm';
import ResetPswForm from '../components/ResetPswForm';
import PropTypes from 'prop-types';
import { USER_LST } from '../config'
import Grid from '@material-ui/core/Grid';

class Login extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      activeStatus: 0
    }
    
    this.setActiveStatus = this.setActiveStatus.bind(this)
  }

  componentDidMount() {
    if (localStorage.getItem(USER_LST) && 
        this.props.user.token === '' && 
        this.props.user.level === '' &&
        this.props.user.id === '') {
      this.props.setUser(JSON.parse(localStorage.getItem(USER_LST)))
    } else {
      if(this.props.match.params.token) {
        this.setState({
          activeStatus: 2
        })
      }
    }
  }

  setActiveStatus (val) {
    this.setState({
      activeStatus: val
    })
  }

  setInitialPage() {
    switch(this.props.user.level) {
      case 'shipper':
        return '/ordini'
      case 'agent':
        return '/clienti'
      default:
        return '/prodotti'
    }
  }

  render() {
    const { classes } = this.props

    return (
      <>
        {this.props.user && this.props.user.token !== '' ? (
          <Redirect to={this.setInitialPage()} />
        ) : (
          <Grid container justify="center" alignItems="center" className={classes.loginContainer}>
            { 
              this.state.activeStatus === 0 && 
                <LoginForm 
                  login={this.props.submitLogin} 
                  setSnack={this.props.setSnack} 
                  externalFunc={this.setActiveStatus} 
                />
            }
            { 
              this.state.activeStatus === 1 && 
              <ForgetPswForm 
                forgetPsw={this.props.submitForget} 
                setSnack={this.props.setSnack} 
                externalFunc={this.setActiveStatus} 
              />
            }
            { 
              this.state.activeStatus === 2 && 
              <ResetPswForm 
                resetPsw={(psw) => this.props.submitReset({token:this.props.match.params.token, password: psw})} 
                setSnack={this.props.setSnack} 
              /> 
            }
          </Grid>
        )}
      </>
    );
  }
}

Login.propTypes = {
  submitLogin: PropTypes.func.isRequired,
};
const mapStateToProps = (state, props) => ({
  user: state.user,
});

export default withRouter(
  connect(
    mapStateToProps, 
    {
      submitLogin,
      submitForget,
      submitReset,
      setUser,
      setSnack
    }
  )(Login)
)
