import React from 'react'
import PropTypes from 'prop-types';

import {
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  FormControl
} from '@material-ui/core';

const NativeSelect = ({
  classnameInput,
  id,
  type,
  label,
  name,
  value,
  options,
  onChange,
  onBlur,
  setFieldValue,
  setFieldTouched,
  error,
  errorName,
  touched,
  required,
  context
}) => {
  return (
    <FormControl 
      className={classnameInput} 
      error={
        error &&
        error[errorName] !== undefined &&  
        touched && 
        touched[errorName]
      }
    >
      <InputLabel htmlFor={`input-${id}`}>{label}{required && '*'}</InputLabel>
      <Select
        id={`input-${id}`} 
        value={value} 
        onChange={(event) => {
          setFieldValue(name, event.target.value);
          onChange && onChange(context,event) 
        }}
        onBlur={ () => setFieldTouched(name, true) }
      > 
        <MenuItem value="" disabled></MenuItem>
        {options && options.map((p,i)=>(
          <MenuItem key={i} value={p.value}>{p.label}</MenuItem>
        ))}
      </Select>
      {
        error &&
        error[errorName]  &&  
        touched && 
        touched[errorName] && 
        <FormHelperText>{error[errorName]}</FormHelperText>
      }
    </FormControl> 
  )
}


NativeSelect.propTypes = {
  classnameInput: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  //options: PropTypes.array,
  options: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  error: PropTypes.object,
  errorName: PropTypes.string,
  required: PropTypes.bool,
  context: PropTypes.object
}

export default NativeSelect