import Home from './containers/Home';
import Login from './containers/Login';

import ClientiLista from './containers/Clienti';
import ClienteDettaglio from './containers/ClienteDettaglio';
import ClienteEdizione from './containers/ClienteEdizione';

import ProdottiOrdinamento from './containers/ProdottiOrdinamento';
import ProdottiLista from './containers/Prodotti';
import ProdottoEdizione from './containers/ProdottoEdizione';
import ProdottoDettaglio from './containers/ProdottoDettaglio';

import FornitoriLista from './containers/Fornitori';
import FornitoreEdizione from './containers/FornitoreEdizione';
import FornitoreDettaglio from './containers/FornitoreDettaglio';

import OperatoriLista from './containers/Operatori';
import OperatoreEdizione from './containers/OperatoreEdizione';
import OperatoreDettaglio from './containers/OperatoreDettaglio';

import ShippersLista from './containers/Shippers';
import ShipperEdizione from './containers/ShipperEdizione';
import ShipperDettaglio from './containers/ShipperDettaglio';

import AgentiLista from './containers/Agenti';
import AgenteEdizione from './containers/AgenteEdizione';
import AgenteDettaglio from './containers/AgenteDettaglio';
//import AgenteCommissioni from './containers/AgenteCommissioni';

//import CommissioniLista from './containers/Commissioni';

import CategorieLista from './containers/Categorie';
import Checkout from './containers/Checkout';
import Riepilogo from './containers/Riepilogo';
import OrdiniLista from './containers/Ordini';

import InventarioLista from './containers/Inventario'

export const routes = [
  {
    path:"/",
    exact: true,
    component: Home
  },
  {
    path:"/login",
    exact: true,
    component: Login,
    isPublic: true
  },
  {
    path:"/reset-password/:token",
    exact: true,
    component: Login,
    isPublic: true
  },
  {
    path:"/clienti",
    exact: true,
    component: ClientiLista
  },
  {
    path:"/cliente/add",
    exact: true,
    component: ClienteEdizione,
  },
  {
    path: "/cliente/edit/:id",
    exact: true,
    component: ClienteEdizione
  },
  {
    path: "/cliente/:id",
    exact: true,
    component: ClienteDettaglio
  },
  {
    path:"/prodotti",
    exact: true,
    component: ProdottiLista
  },
  {
    path: '/prodotti/ordinamento',
    exact: true,
    component: ProdottiOrdinamento
  },
  {
    path:"/prodotti/add",
    exact: true,
    component: ProdottoEdizione,
  },
  {
    path:"/prodotti/edit/:id",
    exact: true,
    component: ProdottoEdizione
  },
  {
    path:"/prodotti/copy/:id",
    exact: true,
    component: ProdottoEdizione
  },
  {
    path:"/prodotti/:id",
    exact: true,
    component: ProdottoDettaglio
  },
  {
    path:"/fornitori",
    exact: true,
    component: FornitoriLista
  },
  {
    path:"/fornitore/add",
    exact: true,
    component: FornitoreEdizione,
  },
  {
    path:"/fornitore/edit/:id",
    exact: true,
    component: FornitoreEdizione
  },
  {
    path:"/fornitore/:id",
    exact: true,
    component: FornitoreDettaglio
  },
  {
    path:"/operatori",
    exact: true,
    component: OperatoriLista
  },
  {
    path:"/operatore/add",
    exact: true,
    component: OperatoreEdizione,
  },
  {
    path:"/operatore/edit/:id",
    exact: true,
    component: OperatoreEdizione
  },
  {
    path:"/operatore/:id",
    exact: true,
    component: OperatoreDettaglio
  },
  {
    path:"/shippers",
    exact: true,
    component: ShippersLista
  },
  {
    path:"/shipper/add",
    exact: true,
    component: ShipperEdizione,
  },
  {
    path:"/shipper/edit/:id",
    exact: true,
    component: ShipperEdizione
  },
  {
    path:"/shipper/:id",
    exact: true,
    component: ShipperDettaglio
  },
  {
    path:"/agenti",
    exact: true,
    component: AgentiLista
  },
  {
    path:"/sub-agenti",
    exact: true,
    component: AgentiLista
  },
  {
    path:"/agente/add",
    exact: true,
    component: AgenteEdizione,
  },
  {
    path:"/agente/edit/:id",
    exact: true,
    component: AgenteEdizione
  },
  {
    path:"/agente/:id",
    exact: true,
    component: AgenteDettaglio
  },
  {
    path:"/categorie",
    exact: true,
    component: CategorieLista
  },
  {
    path:"/checkout",
    exact: true,
    component: Checkout,
  },
  {
    path:"/riepilogo-ordine/:id",
    exact: true,
    component: Riepilogo
  },
  {
    path:"/ordini",
    exact: true,
    component: OrdiniLista
  },
  {
    path:"/magazzino",
    exact: true,
    component: InventarioLista
  }
]
