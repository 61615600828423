import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { FormattedNumber } from 'react-intl';

const useStyles = makeStyles({
  root: {
    width: 300,
  },
  valuesBar: {
    width: 300,
    display: 'flex',
    justifyContent: 'space-between'
  }
});

export default function RangeSlider({ callback, min=0, max=100 }) {
  const classes = useStyles();
  const [value, setValue] = React.useState([0, max]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Slider
        value={value}
        onChange={handleChange}
        onChangeCommitted={callback}
        min={min}
        max={max}
      />
      <div className={classes.valuesBar}>
        <span>Min: <FormattedNumber value={value[0]} style="currency" currency="EUR" /></span>
        <span>Max: <FormattedNumber value={value[1]} style="currency" currency="EUR" /></span>
      </div>
    </div>
  );
}