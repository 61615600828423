import { RETURN_AGENTS, GET_AGENT_DETAILS, POST_AGENT } from '../actions';

const initialState = {
  list: {},
  selected: {},
  pagination: {}
}

export default function agents(state = initialState, action) {
  switch (action.type) {
    case RETURN_AGENTS:
      return {
        ...state,
        list: action.agents,
        selected: {},
      };
    case GET_AGENT_DETAILS:
    case POST_AGENT: 
      return {
        ...state,
        selected: action.agent
      };
    default:
      return state;
  }
}
